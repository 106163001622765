import React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../store/reducers';
import {push} from "connected-react-router";
import {TENANT_ROUTE_PATH} from "../../App";
import {Button, Card, CardBody, CardTitle, Jumbotron, Media} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {TenantPersona} from "../../components/TenantPersona";
import TenantUsersOverview from "./components/TenantUsersOverview";

const mapStateToProps = (state: AppState) => ({
    availableTenants: state.tenant.available
});

const mapDispatchToProps = {
    push
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};

const TenantSwitch = (props: Props) => {

    return (
        <>
            <h2 className="mt-5 mb-4">Your workspaces</h2>

            {props.availableTenants && props.availableTenants.map(tenant =>
                <Card className={'mb-3'} key={tenant.id}>
                    <CardBody>
                        <Media>
                            <Media left className={'mr-3'}>
                                <TenantPersona tenant={tenant} size={'75'}
                                               onClick={() => props.push(TENANT_ROUTE_PATH.replace(':tenantId?', tenant.id))}/>
                            </Media>
                            <Media body>
                                <CardTitle>
                                    <strong>{tenant.name}</strong>
                                </CardTitle>
                                <div className={'d-flex align-items-baseline'}>
                                    <div className={'flex-grow-1'}>
                                        <TenantUsersOverview tenantId={tenant.id} className={'mb-2'}/>
                                    </div>
                                    <div className={"text-right"}>

                                        <Button color={"secondary"} outline className={'mb-2'} tag={Link}
                                                to={'/workspaces/detail/' + tenant.id}>
                                            <FontAwesomeIcon icon={'cog'}/>
                                            Manage
                                        </Button>

                                        <Button color={"primary"} tag={Link} className={'mb-2 ml-2'}
                                                to={TENANT_ROUTE_PATH.replace(':tenantId?', tenant.id)}>
                                            Open
                                            <FontAwesomeIcon icon={'chevron-right'} className={'ml-2 mr-0'}/>
                                        </Button>

                                    </div>
                                </div>
                            </Media>
                        </Media>
                    </CardBody>
                </Card>
            )
            }
            {props.availableTenants && props.availableTenants?.length === 0 &&
            <Jumbotron className="text-center rounded-0">
                <h4 className="text-muted">
                    <i>You don't have any workspaces yet. Either create new workspace or ask others to invite you to
                        existing one.</i>
                </h4>
            </Jumbotron>
            }
            <Button color={"primary"} tag={Link} to={'/workspaces/edit'}>
                <FontAwesomeIcon icon={'plus'}/>
                Create new workspace
            </Button>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantSwitch as any);
