import React, {useEffect, useMemo, useState} from 'react'
import Uppy, {UploadResult} from '@uppy/core'
import Tus, {TusOptions} from '@uppy/tus'
import {Dashboard} from '@uppy/react'
import {connect} from "react-redux";
import {AppState} from "../../../store/reducers";
import {getUppyTusConfiguration} from "../../../store/selectors/ApiSelectors";
import {Prompt} from "react-router";

const mapStateToProps = (state: AppState) => ({
    tusBaseConfig: getUppyTusConfiguration(state)
});

const mapDispatchToProps = {
};

type ComponentProps = {
    findingId: string,
    onFinished: (result: UploadResult) => void,
}
type Props = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & ComponentProps;

const MediaUploader = (props: Props) => {

    const [uploadInProgress, setUploadInProgress] = useState(false);
    
    const [uppyHasFiles, setUppyHasFiles] = useState(false);
    
    useEffect(() => {
        if (uploadInProgress) {
            window.onbeforeunload = () => true;
        } else {
            // @ts-ignore
            window.onbeforeunload = undefined;
        }
    }, [uploadInProgress])
    
    const uppy = useMemo(() => new Uppy({
        restrictions: {
            maxFileSize: 1 * 1024 * 1024 * 1024     // 1 GiB
        },
        autoProceed: false
    }).on('file-added', (file) => {
        // auto-fill in metadata form file 
        uppy.setFileMeta(file.id, {
            fileName: file.name,
            contentType: file.type,
        });
        setUppyHasFiles(true);
    }).on('file-removed', (file, reason) => {
        if (uppy.getFiles().length < 1) {
            setUppyHasFiles(false);
        }
    }).on('complete', (result) => {
        setUploadInProgress(false);
        // perform callback when finished
        props.onFinished(result);
    }).on('upload', (data) => {
        setUploadInProgress(true);
    }).on('cancel-all', () => {
        setUploadInProgress(false);
        setUppyHasFiles(false);
    }), [])

    // configure TUS endpoint with finding-dependent URL
    useEffect(() => {
        const tusOptions: TusOptions = {
            ...props.tusBaseConfig,
            endpoint: props.tusBaseConfig.endpoint + `/findings/${props.findingId}/attachments/tus`,
        };
        
        // upsert the TUS plugin for Uppy (safe upsert needed for re-rendes)
        let existingPlugin = uppy.getPlugin('Tus');
        if (existingPlugin){
            existingPlugin.setOptions(tusOptions);
        } else {
            uppy.use(Tus, tusOptions);
        }
    }, [props.findingId, props.tusBaseConfig])

    // cleanup
    React.useEffect(() => {
        return () => uppy.close()
    }, [])
    
    return (
        <div>
            <Prompt
                when={uploadInProgress}
                message='You have unsaved changes, are you sure you want to leave?'
            />
            <Dashboard uppy={uppy}
                       proudlyDisplayPoweredByUppy={false}
                       height={uppyHasFiles ? 550 : 200}
                       width={1028}
                       showProgressDetails={true}
                       locale={{
                           strings : {
                               dropPasteFiles: 'Drop files here to upload %{browseFiles}',
                               browseFiles: 'Browse files',
                           }
                       }}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaUploader as unknown as React.ComponentClass<ComponentProps>);