import * as React from "react";
import {Col, Row} from "reactstrap";
import {useState} from "react";

type Props = {
    animationDuration: number;
    toggleHandler: boolean;
    rightContent: React.ReactNode;
    leftContent: React.ReactNode;
};

export const DynamicView = (props: Props) => {
    const [show, setShow] = useState<boolean>(props.toggleHandler);

    const openHandler = React.useCallback(() => {
        setTimeout(() => setShow(true), props.animationDuration);
    }, [props.animationDuration]);
    
    React.useEffect(() => {
        if (props.toggleHandler) {
            if (props.animationDuration > 0){
                openHandler();
            } else {
                setShow(props.toggleHandler);
            }
        } else {
            setShow(props.toggleHandler);
        }
    }, [openHandler, props.animationDuration, props.toggleHandler]);

    // const closeHandler = React.useCallback(() => {
    //     setTimeout(() => setShow(false), props.animationDuration);
    // }, [props.animationDuration]);
    
    
    return (
        <Row className={"container-fixed"}>
            <Col xs={props.toggleHandler ? 6 : 12} className={"container-fixed-content"}>
                {props.rightContent}
            </Col>
            {show && <Col xs={props.toggleHandler ? 6 : 0} style={{flexShrink: 1}} className={"container-fixed-content bordered-left"}>
                {props.leftContent}
            </Col>}
        </Row>
    )
};