import {
  ExistingFindingRequestBody,
  Finding,
  FindingSchema,
  FindingSchemaResourceObject,
  FindingSingleResponse,
  NewFindingRequestBody,
} from '../../services/GeneratedApiTsClient';
import {ApiOnRejectedResult} from '../../services/ApiService/ApiService';
import {push} from 'connected-react-router';
import {AppState} from '../reducers';
import {processApiResponse, setError} from './ErrorActions';
import {showNotification, updateNotification} from './GUIActions';
import {requestListing} from './ListingActions';
import {UploadedAttachment} from "../reducers/FindingReducer";
import {
  getConfiguredAttachmentsApi,
  getConfiguredFindingsApi,
  getConfiguredSchemasApi
} from "../selectors/ApiSelectors";
import {getActiveTenantRoutePrefix, selectActiveTenantId} from "../selectors/TenantSelectors";

export const REQUEST_FINDING: string = 'REQUEST_FINDING';
export const RECEIVE_FINDING: string = 'RECEIVE_FINDING';
export const RECEIVE_FINDING_CLONE: string = 'RECEIVE_FINDING_CLONE';

export const REQUEST_EMPTY_WITH_SCHEMA: string = 'REQUEST_EMPTY_WITH_SCHEMA';
export const RECEIVE_EMPTY_WITH_SCHEMA: string = 'RECEIVE_EMPTY_WITH_SCHEMA';

export const REPLACE_FINDING_PROPERTY: string = 'REPLACE_FINDING_PROPERTY';
export const DELETE_UPLOADED_ATTACHMENT: string = 'DELETE_UPLOADED_ATTACHMENT';
export const UPDATE_ATTACHMENTS: string = 'UPDATE_ATTACHMENTS';
export const SET_ACTIVE_ATTACHMENT: string = 'SET_ACTIVE_ATTACHMENT';


export const REQUEST_FINDING_PERSIST: string = 'REQUEST_FINDING_PERSIST';
export const SUCCESS_FINDING_PERSIST: string = 'SUCCESS_FINDING_PERSIST';

export const REQUEST_FINDING_DELETE: string = 'REQUEST_FINDING_DELETE';
export const FINDING_DELETE_SUCCESS: string = 'FINDING_DELETE_SUCCESS';

export const CANCEL_REQUEST: string = 'CANCEL_REQUEST';

export const duplicateFinding = (id: string) => async (dispatch, getState: () => AppState) => {
  await requestFinding(id, true)(dispatch, getState);
  await persistFinding(true, () => showNotification({message: 'Successfully duplicated', type: 'success'}))(
    dispatch,
    getState,
  );
  await requestListing()(dispatch, getState);
};

export const requestFinding = (id: string, doClone: boolean = false) => async (dispatch, getState) => {
  let state: AppState = getState();
  if (state.finding.requestInProgress) {
    // Don't issue a duplicate request (we are already loading the requested data)
    return;
  }

  dispatch({type: REQUEST_FINDING});

  let findingsApi = getConfiguredFindingsApi(state);
  const tenantId = selectActiveTenantId(state);
  if(!tenantId) return;
  let finding: Finding | void | undefined = await findingsApi.apiFindingsGetById({tenantId, id}).then(
    async response => {
      return response.data!.attributes;
    },
    (error: ApiOnRejectedResult) => {
      processApiResponse(error, 'Error loading finding ID ' + id)(dispatch);
    },
  );

  if (!finding) {
    dispatch({type: CANCEL_REQUEST});
    return;
  }

  let schemaResourceObject:
    | FindingSchemaResourceObject
    | void
    | undefined = await findingsApi.apiFindingsGetByIdSchema({tenantId, id}).then(
    async response => {
      return response.data;
    },
    (error: ApiOnRejectedResult) => {
      processApiResponse(error, 'Error loading schema for finding ID ' + id)(dispatch);
    },
  );

  // TODO: handle in FindingDetail scene
  if (!schemaResourceObject) {
    setError('Schema for finding ID ' + id + ' does not exist.')(dispatch);
    return;
  }

  const findingAttachments = await findingsApi.apiFindingsGetByIdAttachments({tenantId, id}).then(
      async response => {
        return response.data;
      },(error: ApiOnRejectedResult) => {
        processApiResponse(error, 'Error loading attachments for finding ID ' + id)(dispatch);
      })
  const uploadedAttachments: UploadedAttachment[] | undefined = findingAttachments ? 
      findingAttachments.map(att => ({resource: att}))
      : undefined;
  
  const hasIncompleteAttachments = uploadedAttachments && uploadedAttachments.some((att) => att.resource.attributes.incomplete)
  
  dispatch({
    type: doClone ? RECEIVE_FINDING_CLONE : RECEIVE_FINDING,
    findingId: id,
    finding: finding,
    schema: schemaResourceObject.attributes,
    schemaId: schemaResourceObject.id,
    uploadedAttachments,
    hasIncompleteAttachments
  });
};

export const setAttachmentProperty = <T extends UploadedAttachment, K extends keyof T>(attachmentId: string, property: K, value: T[K]) => async (dispatch, getState: () => AppState) => {
  const {uploadedAttachments} = getState().finding;
  if (!uploadedAttachments) {
    return null;
  }
  const attachments = [...uploadedAttachments];
  const attachment = attachments &&  attachments.find(att => att.resource.id === attachmentId);
  const attachmentIndex = attachments &&  attachments.findIndex(att => att.resource.id === attachmentId);
  if (!attachment || attachmentIndex < 0 || !attachments) {
    return null;
  }
  const updatedAttachment: UploadedAttachment = {...attachment, [property]: value}
  attachments.splice(attachmentIndex, 1, updatedAttachment);
  await dispatch({type: UPDATE_ATTACHMENTS, payload: attachments})
}

export const setActiveAttachment = (attachmentId: string | null) => async (dispatch) => {
  await dispatch({type: SET_ACTIVE_ATTACHMENT, payload: attachmentId})
}

export const requestAttachmentThumbnail = (attachmentId: string) =>  async (dispatch, getState): Promise<Blob | null> => {
  let state: AppState = getState();
  const attachmentsApi = getConfiguredAttachmentsApi(state)
  const tenantId = selectActiveTenantId(state);
  const response = await attachmentsApi.apiAttachmentsGetByIdThumbnailRaw({tenantId, id: attachmentId}).then(
      async response => {
        if (response.raw.status === 200){  // thumbnail exists
          return await response.value();
        } else if (response.raw.status === 204){   // thumbnail empty
          return null;
        } else {
          console.log(response.raw);  // unexpected status TODO: consider error handling?
          return null; 
        }
      },(error: ApiOnRejectedResult) => {
        console.log({error})
        return null;
      });
  return response;
}


export const requestAttachmentDownload = (attachmentId: string) =>  async (dispatch, getState) => {
  let state: AppState = getState();
  const attachmentsApi = getConfiguredAttachmentsApi(state)
  const tenantId = selectActiveTenantId(state);
  const response = await attachmentsApi.apiAttachmentsGetByIdDownloadRaw({tenantId, id: attachmentId}).then(
      async response => {
        return response.raw.status === 200 ? response.value() : null;
      },(error: ApiOnRejectedResult) => {
        return null;
      })
  return response;
}

export const deleteAttachment =  (attachmentId: string) =>  async (dispatch, getState) => {
  let state: AppState = getState();
  const attachmentsApi = getConfiguredAttachmentsApi(state)
  const tenantId = selectActiveTenantId(state);
  const response = await attachmentsApi.apiAttachmentsDeleteByIdRaw({tenantId, id: attachmentId})
  if (!response || !response.raw.ok) {
    showNotification({message: 'Error deleting attachment', type: 'error'})
  } else {
    showNotification({message: 'Attachment was deleted', type: 'success'})
  }
  dispatch({
    type: DELETE_UPLOADED_ATTACHMENT,
    payload: attachmentId
  })
}

export const requestEmptyWithSchema = (schemaId: string) => async (dispatch, getState) => {
  if (getState().finding.loadingInProgress) {
    // Don't issue a duplicate request (we are already loading the requested data)
    return;
  }

  dispatch({type: REQUEST_EMPTY_WITH_SCHEMA});

  let state: AppState = getState();
  let schemasApi = getConfiguredSchemasApi(state);
  const tenantId = selectActiveTenantId(state);

  let schema: FindingSchema | void | undefined = await schemasApi.apiSchemasGetById({tenantId, id: schemaId}).then(
    async response => {
      return response!.data!.attributes;
    },
    (error: ApiOnRejectedResult) => {
      processApiResponse(error, 'Error loading schema ' + schemaId)(dispatch);
    },
  );

  if (!schema) {
    dispatch({type: CANCEL_REQUEST});
  } else {
    dispatch({
      type: RECEIVE_EMPTY_WITH_SCHEMA,
      schema: schema,
      schemaId: schemaId,
    });
  }
};

export const persistFinding = (doNotRoute?: boolean, onSuccess?: () => void) => async (dispatch, getState) => {
  let state: AppState = getState();
  if (state.finding.requestInProgress) {
    // Don't issue a duplicate request (we are already loading the requested data)
    return;
  }

  dispatch({type: REQUEST_FINDING_PERSIST});

  let findingsApi = getConfiguredFindingsApi(state);
  const tenantId = selectActiveTenantId(state);

  let findingId = state.finding.loadedFindingId;

  let patchedFinding: Finding | void;

  if (findingId != null) {
    let requestBody: ExistingFindingRequestBody = {
      data: {
        type: 'findings',
        id: state.finding.loadedFindingId,
        attributes: state.finding.loadedFinding,
      },
    };

    patchedFinding = await findingsApi
      .apiFindingsPatchById({tenantId, id: findingId, existingFindingRequestBody: requestBody})
      .then(
        (response: FindingSingleResponse) => {
          return response.data!.attributes;
        },
        (error: ApiOnRejectedResult) => {
          processApiResponse(error, 'Error patching finding ID ' + findingId)(dispatch);
        },
      );
  } else {
    if (!state.finding.loadedSchemaId) {
      setError('Cannot create finding, no schema selected.');
      dispatch({type: CANCEL_REQUEST});
      return;
    }

    let requestBody: NewFindingRequestBody = {
      data: {
        type: 'findings',
        attributes: state.finding.loadedFinding,
        relationships: {
          schema: {
            data: {
              type: 'schemas',
              id: state.finding.loadedSchemaId,
            },
          },
        },
      },
    };

    patchedFinding = await findingsApi.apiFindingsPost({tenantId, newFindingRequestBody: requestBody}).then(
      (response: FindingSingleResponse) => {
        findingId = response.data!.id;
        return response.data!.attributes;
      },
      (error: ApiOnRejectedResult) => {
        processApiResponse(error, 'Error patching finding ID ' + findingId)(dispatch);
      },
    );
  }

  if (!patchedFinding) {
    dispatch({type: CANCEL_REQUEST});
  } else {
    dispatch({
      type: SUCCESS_FINDING_PERSIST,
      patchedFinding: patchedFinding,
      patchedFindingId: findingId,
    });
    if (onSuccess) {
      onSuccess();
    }
    if (!doNotRoute) {
      const routePrefix = getActiveTenantRoutePrefix(state);
      dispatch(push(routePrefix+'/findings/detail/' + findingId));
    }
  }
};

export const replaceFindingProperty = (propertyName: string, newValue: any) => {
  return {type: REPLACE_FINDING_PROPERTY, propertyName, newValue};
};

export const deleteFinding = (id: string, doNotRoute?: boolean, refreshFindingsOnSuccess?: boolean) => async (dispatch, getState) => {
  dispatch({type: REQUEST_FINDING_DELETE});

  let state: AppState = getState();
  let findingsApi = getConfiguredFindingsApi(state);
  const tenantId = selectActiveTenantId(state);

  await findingsApi.apiFindingsDeleteById({tenantId, id}).then(
    () => {
      dispatch({type: FINDING_DELETE_SUCCESS});
      if(refreshFindingsOnSuccess) {
          requestListing()(dispatch, getState);
          showNotification({message: 'Successfully deleted', type: "info"});
      }
      if (!doNotRoute) {
        const routePrefix = getActiveTenantRoutePrefix(state);
        dispatch(push(routePrefix+'/findings'));
      }
    },
    (error: ApiOnRejectedResult) => {
      dispatch({type: CANCEL_REQUEST});
      processApiResponse(error, 'Error deleting finding ID: ' + id)(dispatch);
    },
  );
};

export default {
  requestFinding,
  requestEmptyWithSchema,
  deleteFinding,
  persistFinding,
  replaceFindingProperty,
};
