import {FindingSchema} from "../../services/GeneratedApiTsClient";
import {
    CANCEL_REQUEST, FLUSH_SCHEMA,
    RECEIVE_SCHEMA, RECEIVE_SCHEMA_CLONE,
    REPLACE_SCHEMA_PROPERTY, REQUEST_SCHEMA,
    REQUEST_SCHEMA_DELETE, REQUEST_SCHEMA_NEW,
    REQUEST_SCHEMA_PERSIST,
    SCHEMA_DELETE_SUCCESS, SCHEMA_NEW_SUCCESS, SUCCESS_SCHEMA_PERSIST
} from "../actions/SchemaActions";

export type SchemaReducerState = {
    loaded: boolean,
    requestInProgress: boolean,
    loadedSchema: FindingSchema,
    loadedSchemaId?: string
}
const emptySchema: FindingSchema = {
    name: "",
    color: "",
    mapIcon: "",
    mapPolygonIcon: "",
    definitionGroups: [
        {
            name: "Basic",
            definitions: []
        }
    ],
    nestedDataDefinitions: [],
    taxonomyTree: {},
};

export const SchemaReducerInitialState: SchemaReducerState = {
    loaded: false,
    requestInProgress: false,
    loadedSchemaId: undefined,
    loadedSchema: emptySchema,
};

export default (state: SchemaReducerState = SchemaReducerInitialState, action): SchemaReducerState => {
    
    switch (action.type) {
        case REPLACE_SCHEMA_PROPERTY:
            return {
                ...state,
                loadedSchema: {
                    ...state.loadedSchema,
                    [action.propertyName] : action.newValue
                }
            };
        case REQUEST_SCHEMA_PERSIST:
        case REQUEST_SCHEMA_DELETE:
        case REQUEST_SCHEMA_NEW:
            return {
                ...state,
                requestInProgress: true,
            };
        case SCHEMA_DELETE_SUCCESS:
            return {...state, requestInProgress: false, loaded: false};
        case REQUEST_SCHEMA: 
            return {
                ...state,
                requestInProgress: true,
                loaded: false
            };
        case RECEIVE_SCHEMA:
            return {
                ...state,
                requestInProgress: false,
                loaded: true,
                loadedSchema: action.schema,
                loadedSchemaId: action.schemaId
            };
        case RECEIVE_SCHEMA_CLONE:
            action.schema.attachmentNote = null
            return{
                ...state,
                requestInProgress: false,
                loaded: true,
                loadedSchema: action.schema,
                loadedSchemaId: undefined
            }
        case SCHEMA_NEW_SUCCESS:
            return {
                ...state,
                requestInProgress: false,
                loaded: true,
                loadedSchema: emptySchema,
                loadedSchemaId: undefined,
            };
        case SUCCESS_SCHEMA_PERSIST:
            return {
                ...state,
                requestInProgress: false,
                loaded: true,
                loadedSchema: action.patchedSchema,
                loadedSchemaId: action.patchedSchemaId,
            };
        case CANCEL_REQUEST:
            return {...state, requestInProgress: false};
        case FLUSH_SCHEMA:
            return SchemaReducerInitialState;
        default:
            return state;
    }
};