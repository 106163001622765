import * as React from "react";
import {DataCardInputElement} from "./DataCardInputElement";
import {FormFeedback, Input} from "reactstrap";

type Props = {
    title: string,
    titleAfter?: any;
    attributeName: string,
    data: {
        [key: string] : any
    },
    onInput: (e: any) => void,
    required?: boolean;
    min?: number,
    alignColumn?: boolean;
    gutterBottom?: boolean;
    autoFocus?: boolean;
}

export const DataCardNumberInputElement = (props:Props) =>
    <DataCardInputElement title={props.title} titleAfter={props.titleAfter} alignColumn={props.alignColumn} gutterBottom={props.gutterBottom}>
            <Input
                type="number"
                name={props.attributeName}
                defaultValue={props.data[props.attributeName]}
                onInput={props.onInput}
                onChange={props.onInput}
                required={props.required}
                autoFocus={props.autoFocus}
                min={props.min} />
            <FormFeedback>
                {props.required ? 'This field is required. ' : ''}
            </FormFeedback>
    </DataCardInputElement>;