import * as React from 'react';
import {Form, FormGroup, Input, Label} from 'reactstrap';
import ReactPaginate from 'react-paginate';
import {AppState} from '../../../store/reducers';
import {goToPage, setPageSize} from '../../../store/actions/ListingActions';
import {connect} from 'react-redux';

const mapStateToProps = (state: AppState) => ({
  listing: state.listing,
});

const mapDispatchToProps = {
  setPageSize: setPageSize,
  goToPage: goToPage,
};

type FindingsPaginationProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class FindingsPagination extends React.Component<FindingsPaginationProps> {
  render() {
    return (
      <div className="d-flex justify-content-end justify-content-md-end justify-content-center p-2 glass-effect pl-3 pr-3">

        <div className="d-flex flex-row flex-nowrap align-items-center">
          <div className="finding-view-counts d-none d-md-flex mr-4">
              <span className="font-weight-bold pr-1">{`${this.props.listing.loadedFindings.length} results `}</span>
              <span>{` of ${this.props.listing.totalRecords}`}</span>
          </div>
          <div className="d-none d-md-flex mr-4">
            <Form inline className="finding-view-items-per-page">
              <FormGroup id="formControlsSelect">
                <Label>Show:</Label>
                <Input
                  type="select"
                  placeholder="select"
                  onChange={e => {
                    this.props.setPageSize(+e.target.value);
                  }}
                  value={this.props.listing.pageSize}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Input>
              </FormGroup>
            </Form>
          </div>
          <div>
            <ReactPaginate
              pageCount={this.props.listing.pagesCount}
              forcePage={this.props.listing.currentPage - 1}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={this.props.goToPage}
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'page-item page-elipsis'}
              containerClassName={'pagination'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              activeClassName={'active'}
              previousClassName={'page-item page-prev'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item  page-next'}
              nextLinkClassName={'page-link'}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindingsPagination as any);
