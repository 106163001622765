import * as React from "react";
import {UncontrolledTooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useUID} from 'react-uid';

type Props = {
    title?: string,
    description?: string,
    children: any
}

export const DataCardElement = (props: Props) => {

    const uid = useUID();
    return (
        <div className="data-card-element">
            <span className="card-element-heading">
                {props.title}
    
                {props.description && <span className="card-heading-description">
                    <span id={"tooltip-target-"+uid}>
                        <FontAwesomeIcon icon="question-circle"/>
                    </span>
                    <UncontrolledTooltip placement="right" target={"tooltip-target-"+uid}>
                        {props.description}
                    </UncontrolledTooltip>
                </span>}
                
            </span>
            <span className="card-element-value">{props.children}</span>
        </div>
    );
};