import * as React from "react";
import {Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {setFilters} from "../../../store/actions/FilterActions";
import {Filter} from "../../../models/FilterModel";
import {WellKnownAttributes} from "../../../models/FindingWellKnownAttributes";
import {FILTER_OP} from "../../../models/FilterOperationModel";
import {push} from "connected-react-router";
import {Link} from "react-router-dom";
import {FindingSchemaBasicInfoResourceObject} from "../../../store/models/FindingSchemaBasicInfo";
import FindingsCount from "../../../components/FilterEditor/components/FindingsCount";
import {deleteSchema, requestSchema} from "../../../store/actions/SchemaActions";
import SchemaDeleteButton from "./SchemaDeleteButton";
import {openCodegenModal} from "../../../store/actions/GUIActions";
import {AppState} from "../../../store/reducers";
import {getActiveTenantRoutePrefix} from "../../../store/selectors/TenantSelectors";
import {isTenantUserInRole} from "../../../store/selectors/UserSelectors";
import {TenantUserRole} from "../../../services/GeneratedApiTsClient/models";


const mapStateToProps = (state: AppState) => ({
    tenantRoutePrefix: getActiveTenantRoutePrefix(state),
    canEdit: isTenantUserInRole(state, {role: [TenantUserRole.OWNER, TenantUserRole.COLLABORATOR]})
});

const mapDispatchToProps = {
    setFilters,
    deleteSchema,
    push,
    requestSchema,
    openCodegenModal
};
type ComponentProps = { schema: FindingSchemaBasicInfoResourceObject }
type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & ComponentProps;


const SchemaCard = (props: Props) => {
    
    if (!props.schema.attributes)
        return null;
    
    let filter = [new Filter(WellKnownAttributes.schemaId, FILTER_OP.eq, props.schema.id)];

    return (
        <Card style={{borderLeftColor: props.schema.attributes.color, borderLeftWidth: "1rem"}} className={"mb-3"}>
            <CardBody>
                <CardTitle><strong>{props.schema.attributes.name}</strong></CardTitle>
                <hr />
                {/*<p className={"mb-4"}>*/}
                {/*    Schema desctiption*/}
                {/*</p>*/}
                <Row className={'align-items-end'}>
                    <Col md={4}>
                        <div className={"text-center d-inline-block"}>
                            <h5>Findings</h5>
                            <h4><FindingsCount filters={filter} /></h4>
                        </div>
                    </Col>
                    <Col md={8} className={"text-right"}>

                        <Button disabled={!props.canEdit} color="secondary" outline={true} className={"mr-2"} type='button' onClick={() => {
                            props.requestSchema(props.schema.id);
                            props.openCodegenModal("NEW_FINDING");
                        }}>
                            <FontAwesomeIcon icon="code"/>
                            Import API script
                        </Button>

                        <Button color={"secondary"} outline={true} className={"mr-2"} onClick={(e) => {
                            props.setFilters(filter);
                            props.push(props.tenantRoutePrefix+"/findings");
                        }}>
                            <FontAwesomeIcon icon={'search'} />
                            Related Findings
                        </Button>

                        <Button outline color="secondary" tag={Link} className={"mr-2"} disabled={!props.canEdit} to={props.tenantRoutePrefix+'/schemas/clone/'+props.schema.id}>
                            <FontAwesomeIcon icon="copy"/>
                            Clone
                        </Button>
                        <Button color={"primary"} tag={Link} disabled={!props.canEdit} className={"mr-2"} to={props.tenantRoutePrefix+"/schemas/edit/"+props.schema.id}>

                            <FontAwesomeIcon icon={'edit'} />
                            Edit
                        </Button>

                        <SchemaDeleteButton id={props.schema.id} />
                    </Col>
                </Row>
            </CardBody>
        </Card>
        );

};

export default connect(mapStateToProps, mapDispatchToProps)(SchemaCard as unknown as React.ComponentClass<ComponentProps>);