import * as React from "react";
import {DataCardInputElement} from "./DataCardInputElement";
import {FormFeedback, Input} from "reactstrap";


type Props = {
    title: string,
    attributeName: string,
    data: {
        [key: string] : any
    },
    onInput: (e: any) => void,
    required?: boolean,
    pattern?: string,
}

export const DataCardColorInputElement = (props:Props) =>
    <DataCardInputElement title={props.title}>
            <Input type="color" name={props.attributeName} 
                   value={!props.data[props.attributeName] ? "#000000" : props.data[props.attributeName]} 
                   onInput={props.onInput} onChange={props.onInput}
                   pattern={props.pattern} required={props.required} />
            <FormFeedback>
                {props.required ? "This field is required. " : ""}
                {props.pattern ? "Value has to match pattern "+props.pattern+". " : ""}
            </FormFeedback>
    </DataCardInputElement>;