import * as React from "react";
import {Badge} from "reactstrap";


type Props = {
    tags: Array<string>
}

export const FindingTags = (props: Props) =>
    <span>
        {
            props.tags.map(function (tag, i) {
                return <Badge color="primary" key={'tag' + i} className="">{tag}</Badge>
            })
        }
    </span>;