import {createSelector} from "reselect";
import {selectActiveTenantLoading, selectAvailableTenants, selectAvailableTenantsLoading} from "./TenantSelectors";
import {selectIsUserLoading} from "./UserSelectors";

export const isAppStateReady = createSelector([
        selectAvailableTenants,
        selectAvailableTenantsLoading,
        selectIsUserLoading,
        selectActiveTenantLoading
    ], (
    availableTenants,
    availableTenantsLoading,
    isUserLoading,
    isActiveTenantLoading
    ) =>
    !isUserLoading &&
    selectAvailableTenants !== null && !availableTenantsLoading &&
    !isActiveTenantLoading
);
