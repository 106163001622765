import {TenantUser, UserScopedTenant} from "../../services/GeneratedApiTsClient";
import {TenantActions} from "../actions/TenantActions";

export type TenantEntityDraft = Omit<UserScopedTenant, 'id' | 'currentUserRole'>;

export type TenantReducerState = {
    available: UserScopedTenant[] | null,
    availableLoading: boolean,
    
    activeTenantId: string | null,
    activeTenantLoading: boolean,
    activeTenantUsers: TenantUser[],

    entityDraftLoading: boolean,
    entityDraftId: string | null,
    entityDraft: TenantEntityDraft | null
    
    userPatchLoading: boolean,
}

export const TenantReducerInitialState: TenantReducerState = {
    available: null,
    availableLoading: false,
    
    
    activeTenantId: null,
    activeTenantLoading: false,
    activeTenantUsers: [],

    entityDraftLoading: false,
    entityDraftId: null,
    entityDraft: null,

    userPatchLoading: false
};

export default function tenantReducer(state: TenantReducerState = TenantReducerInitialState, action): TenantReducerState {
    switch (action.type) {

        case TenantActions.TENANT_LOAD_AVAILABLE_INIT:
            return {...state, availableLoading: true};

        case TenantActions.TENANT_LOAD_AVAILABLE_SUCCESS:
            return {
                ...state,
                availableLoading: false,
                available: action.availableTenants
            };

        case TenantActions.TENANT_LOAD_AVAILABLE_CANCEL:
            return {...state, availableLoading: false};

        case TenantActions.TENANT_ACTIVE_SWITCH_INIT:
            return {...state, activeTenantLoading: true};

        case TenantActions.TENANT_ACTIVE_SWITCH_SUCCESS:
            return {
                ...state,
                activeTenantLoading: false,
                activeTenantId: action.tenantId,
                activeTenantUsers: action.tenantUsers
            };

        case TenantActions.TENANT_ACTIVE_SWITCH_CANCEL:
            return {...state, activeTenantLoading: false};

        case TenantActions.TENANT_ACTIVE_CLEAR:
            return {...state, activeTenantId: null, activeTenantUsers: []};

        case TenantActions.TENANT_ENTITY_DRAFT_LOAD_INIT:
        case TenantActions.TENANT_ENTITY_DRAFT_SAVE_INIT:
            return {...state, entityDraftLoading: true};

        case TenantActions.TENANT_ENTITY_DRAFT_LOAD_CANCEL:
        case TenantActions.TENANT_ENTITY_DRAFT_SAVE_CANCEL:
        case TenantActions.TENANT_ENTITY_DRAFT_SAVE_SUCCESS:
            return {...state, entityDraftLoading: false};

        case TenantActions.TENANT_ENTITY_DRAFT_LOAD_SUCCESS:
            return {
                ...state,
                entityDraftLoading: false,
                entityDraftId: action.id,
                entityDraft: action.entity
            };

        case TenantActions.TENANT_ENTITY_DRAFT_PATCH:
            return {...state, entityDraft: {...state.entityDraft, ...action.entityPatch}};

        case TenantActions.TENANT_ENTITY_DRAFT_CLEAR:
            return {...state, entityDraftId: null, entityDraft: null};
            
        case TenantActions.TENANT_USER_PATCH_INIT:
            return {...state, userPatchLoading: true};
            
        case TenantActions.TENANT_USER_PATCH_CANCEL:
        case TenantActions.TENANT_USER_PATCH_SUCCESS:
            return {...state, userPatchLoading: false};

        default:
            return state;
    }
};