import * as React from "react";
import {AppState} from "../../store/reducers";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import {
    flushSchema,
    persistSchema,
    replaceSchemaProperty,
    requestSchema,
    requestSchemaNew
} from "../../store/actions/SchemaActions";
import {
    FindingSchemaDefinitionGroup,
    FindingSchemaElement,
    FindingSchemaNestedDataDefinition,
} from "../../services/GeneratedApiTsClient";
import {Button, Card, CardBody} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DynamicFormBuilderRenderer} from "../../components/JQueryFormBuilder/DynamicFormBuilderRenderer";
import {DataCardTextInputElement} from "../../components/DataCardComponnents/DataCardTextInputElement";
import {DynamicDataFormCardTabsNav, TabType} from "./components/DynamicDataFormCardTabsNav";
import {DataCardColorInputElement} from "../../components/DataCardComponnents/DataCardColorInputElement";
import {getActiveTenantRoutePrefix} from "../../store/selectors/TenantSelectors";
import {goBack} from "connected-react-router";
import SpinnerOverlay from "../../components/Spinners/SpinnerOverlay";

const mapStateToProps = (state: AppState) => ({
    schema: state.schema,
    routePrefix: getActiveTenantRoutePrefix(state),
    tenantId: state.tenant.activeTenantId
});

const mapDispatchToProps = {
    requestSchema,
    requestSchemaNew,
    replaceSchemaProperty,
    persistSchema,
    flushSchema,
    goBack
};

type ComponentProps = {
    doClone?: boolean;
}
type Props = RouteComponentProps<{ id: string, schema: any }> &
    ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps & ComponentProps;

const SchemaEdit = (props: Props) => {

    const formEl = React.createRef<HTMLFormElement>();
    const dynFormEl = React.createRef<HTMLFormElement>();

    const formRef = React.createRef<DynamicFormBuilderRenderer>();

    const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
    const [activeTabType, setActiveTabType] = React.useState<TabType>(TabType.Group);
    const [wasValidated, setWasValidated] = React.useState<boolean>(false);
    const [activeDefinitions, setActiveDefinitions] = React.useState<undefined | Array<FindingSchemaElement>>();

    React.useEffect(() => {
        if (props.match.params.id) {
            if (props.tenantId) {
                props.requestSchema(props.match.params.id, props.doClone);
            }
        } else {
            if (props.tenantId) {
                props.requestSchemaNew();
            }
        }
        return () => {
            props.flushSchema()
        }
    }, [props.match.params.id, props.tenantId])
    React.useEffect(() => {
        // fill in the definitions of initially selected tab
        if (props.schema.loaded && activeDefinitions === undefined) {
            toggleTabs(activeTabIndex, activeTabType);   // performs refresh
        }
    }, [props.schema.loaded])

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        props.replaceSchemaProperty(name, value);
    };

    const toggleTabs = (newActiveTabIndex: number, newTabType: TabType, doSaveBefore: boolean = true) => {

        if (doSaveBefore) {
            saveDefinitionChangesToStore();
        }

        let newDefinitionGroup: FindingSchemaDefinitionGroup | FindingSchemaNestedDataDefinition;
        switch (newTabType) {
            case TabType.Group:
                newDefinitionGroup = props.schema.loadedSchema.definitionGroups![newActiveTabIndex];
                break
            case TabType.NestedData:
                newDefinitionGroup = props.schema.loadedSchema.nestedDataDefinitions![newActiveTabIndex];
                break;
        }

        if (newDefinitionGroup) {
            setActiveTabIndex(newActiveTabIndex)
            setActiveTabType(newTabType)
            setActiveDefinitions(newDefinitionGroup ? newDefinitionGroup.definitions : [])
        }
    };

    const saveDefinitionChangesToStore = () => {
        if (!formRef.current) return;

        let updatedDefinitions = formRef.current!.getDefinitions();

        switch (activeTabType) {
            case TabType.Group:
                let definitionGroups = props.schema.loadedSchema.definitionGroups;
                if (definitionGroups && definitionGroups[activeTabIndex]) {
                    definitionGroups[activeTabIndex].definitions = updatedDefinitions;
                }
                props.replaceSchemaProperty("definitionGroups", definitionGroups);
                break;
            case TabType.NestedData:
                let nestedDataDefinitions = props.schema.loadedSchema.nestedDataDefinitions;

                if (nestedDataDefinitions && nestedDataDefinitions[activeTabIndex]) {
                    nestedDataDefinitions[activeTabIndex].definitions = updatedDefinitions;
                }
                props.replaceSchemaProperty("nestedDataDefinitions", nestedDataDefinitions);
                break;
        }
    }

    const validate = () => {
        const formElCurrent = formEl.current!;
        //const dynFormElCurrent = dynFormEl.current!;
        const formLength = formElCurrent.length;

        if (!formElCurrent.checkValidity()) {
            for (let i = 0; i < formLength; i++) {
                const elem: any = formElCurrent[i];

                if (!elem.validity.valid) {
                    elem.scrollIntoView();
                    elem.focus();
                    return false;
                }
            }
            return false;
        }
        //
        // if (dynFormEl.checkValidity() === false) {
        //     for (let i = 0; i < dynFormLength; i++) {
        //         const elem: any = dynFormEl[i];
        //
        //         if (!elem.validity.valid) {
        //             const panes = document.getElementsByClassName("tab-pane active");
        //             for (let i = 0; i < panes.length; i++) {
        //                 panes[i].classList.remove('active');
        //             }
        //             elem.closest('.tab-pane').className += ' active';
        //             this.setState({
        //                 activeTab: elem.getAttribute('data-id')
        //             });
        //             elem.scrollIntoView();
        //             elem.focus();
        //             return false;
        //         }
        //     }
        //     return false;
        // }

        return true;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            saveDefinitionChangesToStore();
            props.persistSchema()
        } else {
            setWasValidated(true)
        }
    };

    const addNewTab = () => {
        saveDefinitionChangesToStore();

        let definitionGroups = props.schema.loadedSchema.definitionGroups ?? [];

        let newTabName = prompt("Name for the new group:");

        // no action when prompt is dismissed
        if (!newTabName) return;

        let newGroup: FindingSchemaDefinitionGroup = {
            name: newTabName,
            definitions: []
        };

        definitionGroups.push(newGroup)

        props.replaceSchemaProperty("definitionGroups", definitionGroups);
        toggleTabs(definitionGroups.length - 1, TabType.Group, false);
    };

    const addNewNestedData = () => {
        saveDefinitionChangesToStore();

        let nestedDataDefinitions = props.schema.loadedSchema.nestedDataDefinitions ?? [];

        let newNestedDataName = prompt("Name for the new group:");

        // no action when prompt is dismissed
        if (!newNestedDataName) return;

        let newNestedData: FindingSchemaNestedDataDefinition = {
            name: "nested-" + (new Date()).getTime(),
            label: newNestedDataName,
            definitions: []
        };

        nestedDataDefinitions.push(newNestedData)

        props.replaceSchemaProperty("nestedDataDefinitions", nestedDataDefinitions);
        toggleTabs(nestedDataDefinitions.length - 1, TabType.NestedData, false);
};

    const renameActiveTab = () => {
        saveDefinitionChangesToStore();

        switch (activeTabType) {
            case TabType.Group:
                let definitionGroups = props.schema.loadedSchema.definitionGroups;
                if (definitionGroups && Array.isArray(definitionGroups) && definitionGroups[activeTabIndex]) {

                    let newTabName = prompt("Name for the group:", definitionGroups[activeTabIndex].name);

                    // no action when prompt is dismissed
                    if (!newTabName) return;

                    definitionGroups[activeTabIndex].name = newTabName;
                }

                props.replaceSchemaProperty("definitionGroups", definitionGroups);
                break;
            case TabType.NestedData:
                let nestedDataDefinitions = props.schema.loadedSchema.nestedDataDefinitions;
                if (nestedDataDefinitions && Array.isArray(nestedDataDefinitions) && nestedDataDefinitions[activeTabIndex]) {

                    let newTabName = prompt("Name for the group:", nestedDataDefinitions[activeTabIndex].label);

                    // no action when prompt is dismissed
                    if (!newTabName) return;

                    nestedDataDefinitions[activeTabIndex].label = newTabName;
                }

                props.replaceSchemaProperty("nestedDataDefinitions", nestedDataDefinitions);
                break;
        }
    };

    const moveActiveTab = (newPosition: number,) => {
        saveDefinitionChangesToStore();

        switch (activeTabType) {
            case TabType.Group:
                let definitionGroups = props.schema.loadedSchema.definitionGroups;
                if (definitionGroups && Array.isArray(definitionGroups) && definitionGroups[activeTabIndex] && definitionGroups[newPosition]) {

                    // see: https://stackoverflow.com/a/2440723
                    definitionGroups.splice(newPosition, 0, definitionGroups.splice(activeTabIndex, 1)[0]);
                }

                props.replaceSchemaProperty("definitionGroups", definitionGroups);
                break;
            case TabType.NestedData:
                let nestedDataDefinitions = props.schema.loadedSchema.nestedDataDefinitions;
                if (nestedDataDefinitions && Array.isArray(nestedDataDefinitions) && nestedDataDefinitions[activeTabIndex] && nestedDataDefinitions[newPosition]) {

                    // see: https://stackoverflow.com/a/2440723
                    nestedDataDefinitions.splice(newPosition, 0, nestedDataDefinitions.splice(activeTabIndex, 1)[0]);
                }

                props.replaceSchemaProperty("nestedDataDefinitions", nestedDataDefinitions);
                break;
        }

        setActiveTabIndex(newPosition)
    };

    const deleteActiveTab = () => {
        saveDefinitionChangesToStore();

        switch (activeTabType) {
            case TabType.Group:
                let definitionGroups = props.schema.loadedSchema.definitionGroups;
                if (definitionGroups && Array.isArray(definitionGroups) && definitionGroups[activeTabIndex]) {

                    if (definitionGroups.length < 2)    // do not delete if there is only one tab left
                        return;

                    let confirmResult = window.confirm("Are you sure?");

                    if (confirmResult) {
                        definitionGroups.splice(activeTabIndex, 1);
                    }
                }

                props.replaceSchemaProperty("definitionGroups", definitionGroups);

                break;
            case TabType.NestedData:
                let nestedDataDefinitions = props.schema.loadedSchema.nestedDataDefinitions;
                if (nestedDataDefinitions && Array.isArray(nestedDataDefinitions) && nestedDataDefinitions[activeTabIndex]) {

                    let confirmResult = window.confirm("Are you sure?");

                    if (confirmResult) {
                        nestedDataDefinitions.splice(activeTabIndex, 1);
                    }
                }

                props.replaceSchemaProperty("nestedDataDefinitions", nestedDataDefinitions);

                break;
        }

        toggleTabs(Math.max(activeTabIndex - 1, 0), activeTabType, false);
    };

    return (
        <div>
            {(props.schema.requestInProgress || !props.schema.loaded) && <SpinnerOverlay/>}
            <form className={"container-fluid schema-edit" + (wasValidated ? " was-validated" : "")}
                  ref={formEl} noValidate>

                <div className="finding-heading">

                    <h1 className="page-heading">{props.schema.loadedSchemaId ? "Edit Schema" : "New Schema"}</h1>

                    <div className="buttons-group-margin">
                        <Button color="primary" type='button' onClick={onSubmit}>
                            <FontAwesomeIcon icon="save"/>
                            Save
                        </Button>

                        <Button
                            outline
                            color="secondary"
                            className="button-back" onClick={() => {
                            props.goBack();
                        }}>
                            <FontAwesomeIcon icon="times"/>
                            Cancel
                        </Button>
                    </div>
                </div>

                <div className="data-card">
                    <h2 className="data-card-heading">General information</h2>

                    <Card>
                        <CardBody className="grid">

                            <DataCardTextInputElement title="Name"
                                                      attributeName={"name"}
                                                      data={props.schema.loadedSchema}
                                                      onInput={handleInputChange}
                                                      required={true}
                                                      autoFocus={true}
                            />

                            <DataCardColorInputElement title="Color"
                                                       attributeName={"color"}
                                                       data={props.schema.loadedSchema}
                                                       onInput={handleInputChange}
                                                       required={true}
                            />
                        </CardBody>
                    </Card>
                </div>

            </form>
            <form className={'container-fluid' + (wasValidated ? " was-validated" : "")} onSubmit={onSubmit} noValidate
                  ref={dynFormEl}>
                <div className="data-card" id={'data-card'}>
                    <h2 className="data-card-heading">
                        Schema builder
                    </h2>
                    <DynamicDataFormCardTabsNav
                        definitionGroups={props.schema.loadedSchema.definitionGroups ? props.schema.loadedSchema.definitionGroups : []}
                        nestedDataDefinitions={props.schema.loadedSchema.nestedDataDefinitions ? props.schema.loadedSchema.nestedDataDefinitions : []}
                        activeTabIndex={activeTabIndex}
                        activeTabType={activeTabType}
                        onTabClick={toggleTabs}
                        onAddTabClick={addNewTab}
                        onAddNestedDataClick={addNewNestedData}
                        onActiveTabRenameClick={renameActiveTab}
                        onActiveTabMoveClick={moveActiveTab}
                        onActiveTabDeleteClick={deleteActiveTab}
                    />
                    <Card>


                        <CardBody>
                            {activeDefinitions && <DynamicFormBuilderRenderer formKey={"activeTab"}
                                                                              ref={formRef}
                                                                              definitions={activeDefinitions === undefined ? [] : activeDefinitions}
                                                                              onInput={() => {
                                                                              }}
                                                                              id={'tab-'}
                            />}
                        </CardBody>

                    </Card>
                </div>
            </form>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(SchemaEdit as unknown as React.ComponentClass<ComponentProps>);