import {IconProp, SizeProp as FontAwesomeIconSizeProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";

export const FileTypeIcon = (props: { contentType: string, fileName?: string, faSize?: FontAwesomeIconSizeProp, incomplete?: boolean}) => {

    let iconName: IconProp = "file";
    
    if (props.incomplete){
        iconName = "file-import";
    } else {
        if (props.contentType.startsWith("image")) iconName = "file-image";
        if (props.contentType.startsWith("audio")) iconName = "file-audio";
        if (props.contentType.startsWith("video")) iconName = "file-video";
    
        if (props.contentType.startsWith("application/vnd.openxmlformats-officedocument.wordprocessingml") ||
            props.contentType.startsWith("application/vnd.ms-word"))
            iconName = "file-word";
    
        if (props.contentType.startsWith("application/vnd.ms-excel")) iconName = "file-excel";
    
        if (props.contentType.startsWith("application/vnd.ms-powerpoint")) iconName = "file-powerpoint";
    
        if (props.contentType.startsWith("application/pdf")) iconName = "file-pdf";
    
        if (props.contentType.startsWith("application/x-ace") ||
            props.contentType.startsWith("application/x-bzip") ||
            props.contentType.startsWith("application/x-bzip2") ||
            props.contentType.startsWith("application/x-rar") ||
            props.contentType.startsWith("application/x-tar") ||
            props.contentType.startsWith("application/zip"))
            iconName = "file-archive";
    
        if (props.contentType.startsWith("text")) iconName = props.contentType === "text/csv" ? "file-csv" : "file-alt";
    }

    if (iconName === "file" || props.incomplete){
        let extension = (props.fileName ? props.fileName.match(/\.(\w+)$/) : undefined);

        return <span className={"fa-layers fa-fw"+(props.faSize ? " fa-"+props.faSize : "")}>
                <FontAwesomeIcon icon={iconName} className="text-primary"/>
            {extension && extension.length > 1 &&
            <span className="fa-layers-counter fa-layers-bottom-right bg-success text-nowrap" title={extension[1]}>{extension[1].toUpperCase()}</span>}
              </span>
    } else {
        return <FontAwesomeIcon icon={iconName} size={props.faSize} className="text-primary"/>
    }
}