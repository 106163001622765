import React from "react";
import {DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {TenantPersona} from "./TenantPersona";
import {UserScopedTenant} from "../services/GeneratedApiTsClient";
import {TenantUserPersona, UserForAvatar} from "./TenantUserPersona";

type Props = {
    children: any,
    tenant?: UserScopedTenant
    tenantUser?: UserForAvatar
}

const NavDropdownPersona = (props: Props ) => (

    <UncontrolledDropdown nav inNavbar className="nav-dropdown-persona" id="navDropdownPersona">
        <DropdownToggle nav caret>
            <div className="persona">
                {props.tenantUser && <TenantUserPersona user={props.tenantUser} size={'30'} className={'avatar'} />}
                {props.tenant && <TenantPersona tenant={props.tenant} size={'30'} className={'avatar'} />}
                <div className="persona-label">
                    <span className="name" data-hj-suppress>{props.tenantUser?.fullName}</span><br/>
                    <small className="text-muted">{props.tenant?.name}</small>
                </div>
            </div>
        </DropdownToggle>
        <DropdownMenu right>
            {props.children}
        </DropdownMenu>
    </UncontrolledDropdown>
);

export default NavDropdownPersona;
