export default class ViewColumn {
    group?: string;
    attribute: string;
    label: string;
    width: number;

    constructor(attribute: string, label: string, group?: string) {
        this.group = group;
        this.attribute = attribute;
        this.label = label;
        this.width = 150;
    }
}