import * as React from "react";
import {AppState} from "../../store/reducers";
import {connect} from "react-redux";
import {requestFindingSchemas} from "../../store/actions/FindingSchemasActions";
import {Button, Col, Jumbotron, Row} from "reactstrap";
import SchemaCard from "./components/SchemaCard";
import {FindingSchemaBasicInfoResourceObject} from "../../store/models/FindingSchemaBasicInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {getActiveTenantRoutePrefix} from "../../store/selectors/TenantSelectors";
import {isTenantUserInRole} from "../../store/selectors/UserSelectors";
import {TenantUserRole} from "../../services/GeneratedApiTsClient/models";
import SpinnerOverlay from "../../components/Spinners/SpinnerOverlay";


const mapStateToProps = (state: AppState) => ({
    findingSchemas: state.findingSchemas,
    tenantRoutePrefix: getActiveTenantRoutePrefix(state),
    tenantId: state.tenant.activeTenantId,
    canEdit: isTenantUserInRole(state, {role: [TenantUserRole.OWNER, TenantUserRole.COLLABORATOR]})
});

const mapDispatchToProps = {
    requestFindingSchemas: requestFindingSchemas,
};

type Props =
    ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps
    & {};

const Schemas = (props: Props) => {

    React.useEffect(()=>{
        if(props.tenantId){
            props.requestFindingSchemas()
        }
    }, [props.tenantId])

    return (

            <div className="container-fluid">

                <Row>
                    <Col xs={6}>
                        <h1 className="page-heading">Schemas</h1>
                    </Col>
                    <Col xs={6}>
                    </Col>
                </Row>

                <Button color={"primary"} disabled={!props.canEdit} tag={Link} to={props.tenantRoutePrefix+'/schemas/new'}>
                    <FontAwesomeIcon icon="plus" />
                    New Schema
                </Button>
                
                {!props.findingSchemas.loaded && <SpinnerOverlay/>}

                <div className={'mt-2'}>
                    {props.findingSchemas.schemasBasicInfo.map((schema : FindingSchemaBasicInfoResourceObject, index) => 
                            <SchemaCard schema={schema} key={index} />
                        )}


                    {props.findingSchemas.schemasBasicInfo.length < 1 && <Jumbotron className="text-center">
                        <h4 className="text-muted">
                            <i>No schemas yet</i>
                        </h4>
                    </Jumbotron>
                    }
                        
                </div>
                
            </div>
    )
}    

export default connect(mapStateToProps, mapDispatchToProps)(Schemas as any);