import {FindingResourceObject} from '../../../services/GeneratedApiTsClient';
import * as React from 'react';
import {ReactText} from 'react';
import {
  Grid,
  Table,
  TableBandHeader,
  TableColumnResizing,
  TableFixedColumns,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
  VirtualTable,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  Column,
  DataTypeProvider,
  IntegratedSelection,
  SelectionState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import {Sort, SortDirection} from '../../../models/SortModel';
import {
  FilterableAttribute,
  FilterableAttributesGroup,
  StaticFilterableAttributesGroup,
} from '../../../models/FilterableAttributesModel';
import {WellKnownAttributes} from '../../../models/FindingWellKnownAttributes';
import {connect} from 'react-redux';
import {FindingTags} from '../../FindingDetail/components/FindingTags';
import ViewColumn from '../../../store/models/ViewColumn';
import {RouteComponentProps} from 'react-router';
import {AppState} from '../../../store/reducers';
import {requestFindingSchemas} from '../../../store/actions/FindingSchemasActions';
import {setSelectedFindingIds} from '../../../store/actions/ListingActions';
import {setColumnsOrder, setColumnsWidths} from '../../../store/actions/ViewPreferencesActions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getActiveTenantRoutePrefix} from "../../../store/selectors/TenantSelectors";
import {push} from "connected-react-router";
import SpinnerOverlay from "../../../components/Spinners/SpinnerOverlay";

const mapStateToProps = (state: AppState) => ({
  findingSchemas: state.findingSchemas,
  viewPreferences: state.viewPreferences,
  selectedFindingIds: state.listing.selectedFindingIds,
  routePrefix: getActiveTenantRoutePrefix(state)
});

const mapDispatchToProps = {
  requestFindingSchemas,
  setColumnsWidths,
  setColumnsOrder,
  setSelectedFindingIds,
  push
};
type ComponentProps = {
  findings: Array<FindingResourceObject>;
  onSortingChange: (newSorting: Array<Sort>) => void;
}
type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps & ComponentProps;

type State = {
  columns: Array<Column>;
  sorting: Array<Sorting>;
  columnBands: Array<TableBandHeader.ColumnBands>;
  loaded: boolean;
  expandedRowIds: ReactText[] | undefined;
};

const attributeAccessor = (row: FindingResourceObject, attributeName: string) => {
  return row.attributes![attributeName];
};

const dynamicDataAccessor = (row: FindingResourceObject, attributeName: string) => {
  return row.attributes!.dynamicData![attributeName];
};

const taxonomyAccessor = (row: FindingResourceObject, attributeName: string) => {
  return row.attributes!.taxonomyName![attributeName];
};


class ListView extends React.Component<Props, State> {
  constructor(props, context: any) {
    super(props, context);

    this.state = {
      columns: [],
      sorting: [],
      loaded: false,
      columnBands: [],
      expandedRowIds: [],
    };

    this.setSorting = this.setSorting.bind(this);
    this.loadColumnBandsFromStore = this.loadColumnBandsFromStore.bind(this);
    this.getColumnWidthsForGrid = this.getColumnWidthsForGrid.bind(this);
    this.ClickableTableRow = this.ClickableTableRow.bind(this);
  }

  componentDidMount() {
    if (this.props.findingSchemas.loaded && this.props.viewPreferences.normalized) {
      this.loadColumnBandsFromStore();

      this.setState({
        loaded: true,
        columns: this.getColumnsFromStore(),
      });
    } else {
      this.props.requestFindingSchemas();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.findingSchemas.loaded && this.props.viewPreferences.normalized) {
        this.loadColumnBandsFromStore();

        this.setState({
          loaded: this.props.findingSchemas.loaded,
          columns: this.getColumnsFromStore(),
        });
      }
    }
  }

  getColumnsFromStore() {
    return this.props.viewPreferences.listViewColumns.map(column => {
      if (column.attribute.startsWith(WellKnownAttributes.dynamicData)) {
        return {
          name: column.attribute,
          title: column.label,
          getCellValue: row => dynamicDataAccessor(row, column.attribute.replace(/^(dynamicData\.)/, '')),
        };
      } else if (column.attribute.startsWith("taxonomyName")) {
        return {
          name: column.attribute,
          title: column.label,
          getCellValue: row => taxonomyAccessor(row, column.attribute.replace(/^(taxonomyName\.)/, '')),
        };
      } else {
        return {
          name: column.attribute,
          title: column.label,
          getCellValue: row => attributeAccessor(row, column.attribute),
        };
      }
    });
  }

  setSorting(newSorting: Array<Sorting>) {
    this.setState(
      {
        sorting: newSorting,
      },
      () => {
        this.props.onSortingChange(
          newSorting.map((sort: Sorting) => {
            let direction = sort.direction === 'asc' ? SortDirection.SORT_ASC : SortDirection.SORT_DESC;
            return new Sort(sort.columnName, direction);
          }),
        );
      },
    );
  }

  getColumnWidthsForGrid() {
    let source = StaticFilterableAttributesGroup.concat(this.props.findingSchemas.schemaAttributes);

    let customWidths = {};
    this.props.viewPreferences.listViewColumns.forEach(column => {
      customWidths[column.attribute] = column.width;
    });

    let output: any[] = [];

    source.forEach((attrGroup: FilterableAttributesGroup) => {
      attrGroup.attributes.forEach((attr: FilterableAttribute) => {
        output.push({
          columnName: attr.value,
          width: customWidths.hasOwnProperty(attr.value) ? customWidths[attr.value] : 100,
        });
      });
    });

    return output;
  }

  loadColumnBandsFromStore() {
    // working with an existing array in place to keep existing references (ReactGrid does not accept a new array, the changes does not propagate)
    let existingState = this.state.columnBands;

    existingState.length = 0; // empty the array, see: https://stackoverflow.com/a/1232046

    // temp bands storage
    let bands: {
      [key: string]: TableBandHeader.ColumnBands;
    } = {};

    this.props.viewPreferences.listViewColumns.forEach((column: ViewColumn) => {
      if (column.group == null) return;

      if (!bands.hasOwnProperty(column.group)) {
        bands[column.group] = {
          title: column.group,
          children: [],
        };
      }

      bands[column.group]!.children!.push({
        columnName: column.attribute,
      });
    });

    // populate the array again
    for (let band in bands) {
      existingState.push(bands[band]);
    }

    // set state just to trigger component re-render
    this.setState({
      columnBands: existingState,
    });
  }

  ClickableTableRow = ({row, ...restProps}) => {
    return (
      // @ts-ignore
      <Table.Row
        {...restProps}
        className={this.props.selectedFindingIds.includes(row.id) ? 'table-active-custom' : ''}
        onClick={() => this.setSelection(row.id)}
      />
    );
  };

  // custom checkbox, cannot figure out multi selection
  CustomCheckBox = ({row, selected, onToggle, children}: any) => {
    return (
      //@ts-ignore
      <Table.Cell
        className={'finding-table-checkbox-container'}
        row={row}
        onClick={() => this.setSelection(row.id)}
        selected={selected}>
        <div className={'checkbox ' + (selected ? 'checkbox-checked' : '')}>
          {selected ? <FontAwesomeIcon icon={'check'} size={'xs'} /> : ''}
        </div>
      </Table.Cell>
    );
  };
  
  CustomHeaderCheckBox = ({disabled, allSelected, someSelected, ...restProps}: any) => {
    const selected = someSelected || allSelected;
    const handleClick = () => {
      if (selected) {
        this.clearSelection()
      } else {
        this.setSelectionAll()
      }
    };
    return (
      <TableHeaderRow.Cell
        {...restProps}
        onClick={handleClick}
        className='cursor-pointer'
      >
        <div className={'checkbox checkbox-justified-center ' + (selected ? 'checkbox-checked' : '')}>
          {selected ? <FontAwesomeIcon icon={'minus'} size={'xs'} /> : null}
        </div>
      </TableHeaderRow.Cell>
    );
  };

  setSelectionAll = () => {
    console.log('viewing findings: ', this.props.findings);
    if (this.props.findings){
      const selection = this.props.findings.map(obj => obj.id);
      if (!selection.find(el => el === undefined)) {
        // @ts-ignore
        this.props.setSelectedFindingIds(selection);
      }
    }
  };
  
  clearSelection = () => {
    this.props.setSelectedFindingIds([]);
  };
  
  setSelection = id => {
    const isSelected = this.props.selectedFindingIds.find(selId => selId === id);
    const selection = !isSelected
      ? [...this.props.selectedFindingIds, id]
      : this.props.selectedFindingIds.filter(selectedId => selectedId !== id);
    this.props.setSelectedFindingIds(selection);
  };

  getRowId = (row: FindingResourceObject) => {
    return row.id as ReactText;
  };

  ClickableTableCell = props => {
    const {column, value, row} = props;
    
    if (column.name === WellKnownAttributes.documentName) {
      return (
          <Table.Cell {...props}>
            <button className={'finding-name-link'} onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.push(this.props.routePrefix+'/findings/detail/' + row.id);
            }} >
              {value}
              <sup className="ml-1">
                <FontAwesomeIcon icon={'external-link-square-alt'}  />
              </sup>
            </button>
          </Table.Cell>
      );
    }
    return <Table.Cell {...props} />;
  };


  render() {
    if (!this.state.loaded) {
      return <SpinnerOverlay />;
    } else {
      return (
        <Grid rows={this.props.findings} columns={this.state.columns} getRowId={this.getRowId}>
          <SelectionState selection={this.props.selectedFindingIds} />
          {/*<DragDropProvider />*/}

          <SortingState sorting={this.state.sorting} onSortingChange={this.setSorting} />

          <TagsTypeProvider for={[WellKnownAttributes.tags]} />
          <DateTypeProvider for={[WellKnownAttributes.date]} />

          <VirtualTable rowComponent={this.ClickableTableRow} cellComponent={this.ClickableTableCell} height="300px" />
          <TableColumnResizing
            columnWidths={this.getColumnWidthsForGrid()}
            onColumnWidthsChange={this.props.setColumnsWidths}
          />
          {/*<TableColumnReordering*/}
          {/*  order={this.props.viewPreferences.listViewColumns.map(column => column.attribute)}*/}
          {/*  onOrderChange={(nextOrder) => {*/}
          {/*    console.log('reordering: ', nextOrder)*/}
          {/*    this.props.setColumnsOrder(nextOrder)*/}
          {/*  }}*/}
          {/*/>*/}
          <TableHeaderRow showSortingControls />

          <IntegratedSelection />
          <TableSelection
            showSelectAll
            cellComponent={this.CustomCheckBox}
            headerCellComponent={this.CustomHeaderCheckBox}
          />
          <TableBandHeader columnBands={this.state.columnBands} />
          <TableFixedColumns leftColumns={[TableRowDetail.COLUMN_TYPE]} />
        </Grid>
      );
    }
  }
}

const TagsFormatter = ({value}) => <FindingTags tags={value} />;

const TagsTypeProvider = props => <DataTypeProvider formatterComponent={TagsFormatter} {...props} />;

const DateFormatter = ({value}) => <>{value ? value.toISOString().split('T')[0] : ''}</>;

const DateTypeProvider = props => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;


export default connect(mapStateToProps, mapDispatchToProps)(ListView as unknown as React.ComponentClass<ComponentProps>);
