import React, {useState} from 'react';
import HelpdeskButton from "../HelpdeskButton";
import NavDropdownPersona from "../NavDropdownPersona";
import {Collapse, DropdownItem, Nav, Navbar, NavbarBrand, NavbarToggler} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {AppState} from "../../store/reducers";
import {push} from "connected-react-router";
import {connect} from "react-redux";
import InvitationModal from "../Invite/InvitationModal";


const mapStateToProps = (state: AppState) => ({
    oidcUserProfile: state.oidc.user?.profile
});

const mapDispatchToProps = {
    push
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const SideWallpaperLayout: React.FC<Props> = (props) => {
    const [navbarOpen, toggleNavbarOpen] = useState(false);
    
    return (
        <div className="side-wallpaper-layout">
            <div style={{backgroundImage: 'url("images/photo@2x.jpg")'}} className="d-none d-md-flex wallpaper">
            </div>
            <div className="side-wallpaper-right">
                <div className="side-wallpaper-container">

                    <Navbar expand={'sm'}>
                        <NavbarBrand tag={Link} to={"#"}>
                            <img src="/images/logo.svg" alt="UniCatDB"/>
                        </NavbarBrand>
                        <NavbarToggler onClick={() => toggleNavbarOpen(!navbarOpen)}>
                            {' '}
                            <FontAwesomeIcon icon="bars"/>{' '}
                        </NavbarToggler>
                        <Collapse isOpen={navbarOpen} navbar>
                            {props.oidcUserProfile && <Nav navbar className={'ml-auto'}>
                                <NavDropdownPersona
                                    tenantUser={{
                                        fullName: props.oidcUserProfile.name ?? '',
                                        firstName: props.oidcUserProfile.given_name,
                                        lastName: props.oidcUserProfile.family_name,
                                        pictureUrl: props.oidcUserProfile.picture,
                                        idmSubjectId: props.oidcUserProfile.sub ?? ''
                                    }}
                                >
                                    <DropdownItem onClick={() => {
                                        window.open(process.env.REACT_APP_IDM_AUTHORITY, '_blank', 'noopener');
                                    }}>
                                        <FontAwesomeIcon className="mr-1 text-primary" icon={"user"}/>
                                        Your profile
                                    </DropdownItem>
                                    <DropdownItem onClick={() => {props.push('/signout')}}>
                                        <FontAwesomeIcon className="mr-1 text-primary" icon={"sign-out-alt"}/>
                                        Logout
                                    </DropdownItem>

                                </NavDropdownPersona>
                            </Nav>}
                        </Collapse>
                    </Navbar>
                    
                    {props.children}

                </div>
                <HelpdeskButton buttonText={'Helpdesk'}/>
            </div>

            <InvitationModal/>
            
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SideWallpaperLayout as any);