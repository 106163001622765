import * as React from "react";
import {CopyToClipboard} from "../../../components/CopyToClipboard";
import {DataCardElement} from "./DataCardElement";
import {DownloadFasta} from "../../../components/DownloadFasta";

type Props = {
    title?: string;
    value?: string;
    description?: string;
};

export const DataCardFastaElement = (props: Props) => {
    const formatFasta = () =>{
        if(!props.value) return "";
        const arrData = props.value.split(">");
        const list = arrData.map((record, key) => {
            return (record !== "")?<li key={key}>{record.split(/\r?\n/)[0]}</li>:""});
        
        return (<div><ul>{list}</ul>
            <CopyToClipboard textToCopy={props.value} copyMessage={"Copy to clipboard"} className={'mr-2'} />
            <DownloadFasta textToDownload={props.value} downloadMessage={"Download FASTA"} fileName={"finding.fasta"}/>
        </div>);
    }
    
    return (<DataCardElement title={props.title} description={props.description}>
        {formatFasta()}
    </DataCardElement>)
};