

export type LatLngBounds = {
    east: number;
    north: number;
    south: number;
    west: number;
}

/**
 * Serialize bounds to API format.
 * @param bounds
 * @param roundToBiggerBounds Optional up-rounding
 * @param separator
 */
export function serializeBounds(bounds: LatLngBounds, roundToBiggerBounds:boolean = false, separator: string = "|"): string {
    //"lat_lo|lng_lo|lat_hi|lng_hi"
    
    if (roundToBiggerBounds){
        return [
            Math.floor(bounds.south),
            Math.floor(bounds.west),
            Math.ceil(bounds.north),
            Math.ceil(bounds.east)
        ].join(separator);
    } else {
        return [bounds.south, bounds.west, bounds.north, bounds.east].join(separator);
    }
    
}
