import * as am4core from '@amcharts/amcharts4/core'

export function am4themes_unicatTheme(target) {
    if (target instanceof am4core.ColorSet) {
        target.list = [
            am4core.color("#028F68"),
            am4core.color("#4EC096"),
            am4core.color("#00603D"),
            am4core.color("#D0EDD2"),
            am4core.color("#01694d")
        ];
    }
}