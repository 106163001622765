import React, {useCallback, useRef, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {AppState} from '../../store/reducers';
import {goBack, push} from "connected-react-router";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DataCardTextInputElement} from "../../components/DataCardComponnents/DataCardTextInputElement";
import {
    patchTenantEntityDraft,
    requestAvailableTenants,
    saveTenantEntityDraft, switchTenant
} from "../../store/actions/TenantActions";
import SpinnerOverlay from "../../components/Spinners/SpinnerOverlay";

const mapStateToProps = (state: AppState) => ({
    tenantId: state.tenant.entityDraftId,
    tenant: state.tenant.entityDraft,
    loading: state.tenant.entityDraftLoading
});

const mapDispatchToProps = {
    patchTenantEntityDraft,
    requestAvailableTenants,
    switchTenant,
    push,
    goBack
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};

const TenantEdit = (props: Props) => {

    const [wasValidated, setWasValidated] = useState(false);

    const formEl = useRef<HTMLFormElement>(null);

    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const patch = {}
        patch[name] = value;

        props.patchTenantEntityDraft(patch);
    };

    const validate = useCallback(() => {
        if (formEl.current && !formEl.current.checkValidity()) {
            for (let i = 0; i < formEl.current.length; i++) {
                const elem: any = formEl.current[i];

                if (!elem.validity.valid) {
                    elem.scrollIntoView();
                    elem.focus();
                    return false;
                }
            }
            return false;
        }

        return true;
    }, []);


    const onSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            await dispatch(saveTenantEntityDraft());
            props.requestAvailableTenants();
            props.push('/workspaces');
        } else {
            setWasValidated(true);
        }
    };

    return (
        <form className={"unicat-form" + (wasValidated ? " was-validated" : "")}
              ref={formEl}
              noValidate
        >
            {props.loading && <SpinnerOverlay/>}

            <h2 className="mt-5 mb-4">
                {props.tenantId ? 'Edit workspace' : 'New workspace'}
            </h2>


            <div className="d-flex flex-row flex-wrap justify-content-between">
                <DataCardTextInputElement title="Name"
                                          attributeName={"name"}
                                          data={props.tenant ?? {}}
                                          onInput={handleInputChange}
                                          required={true}
                                          alignColumn
                                          gutterBottom
                                          autoFocus={true}
                />
            </div>

            <div className={'text-right mt-4'}>
                <Button
                    outline
                    color="secondary"
                    className="button-back mb-2" onClick={() => {
                    props.goBack();
                }}>
                    <FontAwesomeIcon icon="times"/>
                    Cancel
                </Button>

                <Button color="primary" type='button' className={'ml-2 mb-2'} onClick={onSubmit}>
                    <FontAwesomeIcon icon="save"/>
                    Save
                </Button>
            </div>
        </form>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantEdit as any);
