import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


type Props = {
    subtype?: string,
    children: any
}

export const DataCardHeaderElement = (props:Props) =>
    <div className="data-card-header">
        <FontAwesomeIcon icon="level-up-alt" rotation={90} className="icon" />
        {props.children}
    </div>;