import * as React from "react";
import Select from "react-select"
type Props = {
    onEnterKeyPressed?: (e) => void,
} & any
export const ThemedSelect = ({onEnterKeyPressed, ...rest}: Props) => {
    
    const handleKeyPress = React.useCallback((e) => {
        if (e.key === "Enter") {
            onEnterKeyPressed && onEnterKeyPressed(e);
        }
    }, [onEnterKeyPressed])
    
    return (
        <Select
            {...rest}
            theme={theme => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary25: '#D0EDD2',
                primary: '#028F68',
            },
            })}
            onKeyDown={handleKeyPress}
        />
    )
}