import {ApiOnRejectedResult} from "../../services/ApiService/ApiService";
import {push} from 'connected-react-router';
import {AppState} from "../reducers";
import {processApiResponse} from "./ErrorActions";
import {
    ExistingFindingSchemaRequestBody,
    FindingSchema,
    FindingSchemaResourceObject, FindingSchemaSingleResponse, NewFindingSchemaRequestBody
} from "../../services/GeneratedApiTsClient";
import {FLUSH_FINDING_SCHEMAS, requestFindingSchemas} from "./FindingSchemasActions";
import {getConfiguredSchemasApi} from "../selectors/ApiSelectors";
import {getActiveTenantRoutePrefix, selectActiveTenantId} from "../selectors/TenantSelectors";
import {reinitializeFindingSchema} from "../../utils/schemaDefinitionUtils";

export const REQUEST_SCHEMA: string = 'REQUEST_SCHEMA';
export const RECEIVE_SCHEMA: string = 'RECEIVE_SCHEMA';
export const RECEIVE_SCHEMA_CLONE: string = 'RECEIVE_SCHEMA_CLONE';

export const REPLACE_SCHEMA_PROPERTY: string = 'REPLACE_SCHEMA_PROPERTY';

export const REQUEST_SCHEMA_PERSIST: string = 'REQUEST_SCHEMA_PERSIST';
export const SUCCESS_SCHEMA_PERSIST: string = 'SUCCESS_SCHEMA_PERSIST';

export const REQUEST_SCHEMA_DELETE: string = 'REQUEST_SCHEMA_DELETE';
export const SCHEMA_DELETE_SUCCESS: string = 'SCHEMA_DELETE_SUCCESS';

export const REQUEST_SCHEMA_NEW: string = 'REQUEST_SCHEMA_NEW';
export const SCHEMA_NEW_SUCCESS: string = 'SCHEMA_NEW_SUCCESS';

export const CANCEL_REQUEST: string = 'CANCEL_REQUEST';
export const FLUSH_SCHEMA: string = 'FLUSH_SCHEMA';

export const requestSchema = (id: string, doClone: boolean = false) => async (dispatch, getState) => {

    if (getState().schema.loadingInProgress) {
        // Don't issue a duplicate request (we are already loading the requested data)
        return;
    }

    dispatch({type: REQUEST_SCHEMA});

    let state: AppState = getState();
    let schemasApi = getConfiguredSchemasApi(state);
    const tenantId = selectActiveTenantId(state);

    let schemaResourceObject: FindingSchemaResourceObject | void | undefined = await schemasApi.apiSchemasGetById({tenantId, id}).then(
        async (response) => {
            return response.data;
        },
        (error: ApiOnRejectedResult) => {
            processApiResponse(error, "Error loading schema ID " + id)(dispatch);
        },
    );
    
    if (!schemaResourceObject){
        dispatch({type: CANCEL_REQUEST});
        return;
    }
    
    if (doClone){
        reinitializeFindingSchema(schemaResourceObject.attributes);
    }
    
    dispatch({
        type: doClone ? RECEIVE_SCHEMA_CLONE : RECEIVE_SCHEMA,
        schema: schemaResourceObject.attributes,
        schemaId: schemaResourceObject.id
    });
    
};

export const requestSchemaNew = () => async (dispatch, getState) => {
    dispatch({type: REQUEST_SCHEMA_NEW});
    dispatch({type: SCHEMA_NEW_SUCCESS});
};

export const persistSchema = () => async (dispatch, getState) => {

    let state: AppState = getState();
    if (state.schema.requestInProgress) {
        // Don't issue a duplicate request (we are already loading the requested data)
        return;
    }

    dispatch({type: REQUEST_SCHEMA_PERSIST});

    let schemasApi = getConfiguredSchemasApi(state);
    const tenantId = selectActiveTenantId(state);

    let schemaId = state.schema.loadedSchemaId;

    let patchedSchema: FindingSchema | void;
    
    if (schemaId != null){
        let requestBody: ExistingFindingSchemaRequestBody = {
            data: {
                type: "schemas",
                id: state.schema.loadedSchemaId,
                attributes: state.schema.loadedSchema
            }
        };
        
        patchedSchema = await schemasApi.apiSchemasPatchById({tenantId, id: schemaId, existingFindingSchemaRequestBody: requestBody}).then(
            (response: FindingSchemaSingleResponse) => {
                return response.data!.attributes;
            },
            (error: ApiOnRejectedResult) => {
                processApiResponse(error, "Error patching schema ID " + schemaId)(dispatch);
            },
        );
    } else {
        
        let requestBody: NewFindingSchemaRequestBody = {
            data: {
                type: "schemas",
                attributes: state.schema.loadedSchema
            }
        };

        patchedSchema = await schemasApi.apiSchemasPost({tenantId, newFindingSchemaRequestBody: requestBody}).then(
            (response: FindingSchemaSingleResponse) => {
                schemaId = response.data!.id;
                return response.data!.attributes;
            },
            (error: ApiOnRejectedResult) => {
                processApiResponse(error, "Error patching schema ID " + schemaId)(dispatch);
            },
        );
    }

    if (!patchedSchema) {
        dispatch({type: CANCEL_REQUEST});
    } else {
        dispatch({
            type: SUCCESS_SCHEMA_PERSIST,
            patchedSchema: patchedSchema,
            patchedSchemaId: schemaId,
        });
        dispatch({type: FLUSH_FINDING_SCHEMAS});  // update other reducer
        
        const routePrefix = getActiveTenantRoutePrefix(state);
        dispatch(push(routePrefix+"/schemas/"));
    }
};

export const replaceSchemaProperty = (propertyName: string, newValue: any) => {
    
    return ({type: REPLACE_SCHEMA_PROPERTY, propertyName, newValue});
};

export const deleteSchema = (id: string) => async (dispatch, getState) => {

    dispatch({type: REQUEST_SCHEMA_DELETE});

    let state: AppState = getState();
    let schemasApi = getConfiguredSchemasApi(state);
    const tenantId = selectActiveTenantId(state);
    
    await schemasApi.apiSchemasDeleteById({tenantId, id}).then(
        () => {
            window.alert("Success!");
            dispatch({type: SCHEMA_DELETE_SUCCESS});
            dispatch({type: FLUSH_FINDING_SCHEMAS});  // update other reducer
            
            const routePrefix = getActiveTenantRoutePrefix(state);
            dispatch(push(routePrefix+"/schemas/"));
            dispatch(requestFindingSchemas())
        },
        (error: ApiOnRejectedResult) => {
            dispatch({type: CANCEL_REQUEST});
            processApiResponse(error, "Error deleting schema ID: " + id)(dispatch);
        },
    );
};

export const flushSchema = () => (dispatch, getState) => {
    dispatch({type: FLUSH_SCHEMA});
};


export default {
    requestSchema,
    requestSchemaNew,
    deleteSchema,
    persistSchema,
    replaceSchemaProperty,
    flushSchema
}