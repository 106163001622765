import * as React from 'react';
import {DataCardInputElement} from './DataCardInputElement';
import {FormFeedback, Input} from 'reactstrap';

type Props = {
  title: string;
  titleAfter?: any;
  attributeName: string;
  data: any,
  onInput: (e: any) => void;
  required?: boolean;
  pattern?: string;
  alignColumn?: boolean;
  gutterBottom?: boolean;
  formatValueData?: (valueData: any) => string
};

export const DataCardSimpleTextInputElement = (props: Props) => {
    // const getValue = () => {
    //     if (props.formatValueData && typeof props.data[props.attributeName] === 'object') {
    //         return props.formatValueData(props.data[props.attributeName])
    //     }
    //     return props.data[props.attributeName]
    // };
    
    return (
        <DataCardInputElement title={props.title} titleAfter={props.titleAfter} alignColumn={props.alignColumn} gutterBottom={props.gutterBottom}>
            <Input
                type="text"
                className="text-main"
                name={props.attributeName}
                defaultValue={props.data}
                onInput={props.onInput}
                onChange={props.onInput}
                pattern={props.pattern}
                required={props.required}
            />
            <FormFeedback>
                {props.required ? 'This field is required. ' : ''}
                {props.pattern ? 'Value has to match pattern ' + props.pattern + '. ' : ''}
            </FormFeedback>
        </DataCardInputElement>
    );
}