import {
    FilterQuery,
    FindingsApi,
    FindingSchemaArrayResponse,
    FindingSchemaDefinitionGroup,
    FindingSchemaElement,
    FindingSchemaFieldsQuery,
    FindingSchemaResourceObject,
    PageQuery, RelationshipResourceIdentifierArrayResponse,
    SchemasApi,
    
} from "../GeneratedApiTsClient";
import {
    AttributeType,
    DynamicFilterableAttributeTypes,
    FilterableAttribute,
    FilterableAttributesGroup
} from "../../models/FilterableAttributesModel";
import {WellKnownAttributes} from "../../models/FindingWellKnownAttributes";
import {Filter} from "../../models/FilterModel";
import {FILTER_OP, SupportedFilterOperations} from "../../models/FilterOperationModel";
import {Sort, SortDirection} from "../../models/SortModel";
import {LatLngBounds, serializeBounds} from "../../store/models/LatLngBounds";


const NESTED_OBJECT_SEPARATOR = ".";
const GEOPAGING_FILTER_NAME = "_latlngbounds";
const GEOPAGING_FILTER_SEPARATOR = "|";


export type ApiOnRejectedResult = Response | Error

/**
 * @export
 */ 
export default class ApiService {
    
    /**
     * Map database filed type to frontend filter attribute type
     *
     * @returns {*}
     * @constructor
     * @param attribute
     */
    static MapToAttributeType(attribute: FindingSchemaElement): AttributeType {
        if (attribute.type === "number") {
            return "number";
        } else if (attribute.type === "date") {
            return "date";
        } else if (attribute.type === "checkbox-group" || (attribute.type === "select" && attribute.multiple)) {
            return "textArray";
        } else {
            return "text";
        }
    }

    static async getDynamicFilterableAttributes(tenantId: string, schemasApi: SchemasApi):Promise<Array<FilterableAttributesGroup>> {
        
        let sort:string = "name";
        
        let fields: FindingSchemaFieldsQuery = {
            schemas: "name,definitionGroups"
        };
        
        let page: PageQuery = {
            number: 1,
            size: -1
        };
        
        
        let response = await schemasApi.apiSchemasGet({tenantId, sort, page, fields});
        
        let result:Array<FilterableAttributesGroup> = [];
            
        response.data!.forEach((schema: FindingSchemaResourceObject) => {
            
            schema.attributes!.definitionGroups!.forEach((definitonGroup: FindingSchemaDefinitionGroup) => {
           
                let groupAttributes: Array<FilterableAttribute> = [];

                definitonGroup.definitions!.forEach((attribute: FindingSchemaElement) => {

                    // include only meaningful attributes
                    if (DynamicFilterableAttributeTypes.includes(attribute.type!)){
                        groupAttributes.push({
                            name: attribute.label!,
                            value: WellKnownAttributes.dynamicData+NESTED_OBJECT_SEPARATOR+attribute.name,
                            type: this.MapToAttributeType(attribute),
                        });
                    }
                });
                
                result.push({
                    name: schema.attributes!.name+(schema.attributes!.definitionGroups!.length > 1 ? " - "+definitonGroup.name : ""),
                    attributes: groupAttributes
                });
            });
        });
        
        return result;
        
    }

    /**
     * Build API-ready FilterQuery object from an array of internal filter definition
     * @param filters
     * @returns {FilterQuery}
     */
    static buildFilterQuery(filters: Array<Filter>): FilterQuery {
        let filterQuery:FilterQuery = {};

        filters.forEach((filter: Filter | undefined) => {
            if (filter){
                filterQuery[filter.attribute] = SupportedFilterOperations[filter.operation].prefix + filter.value
            }
        });
        
        return filterQuery;
    }

    /**
     * Build API-ready sort query string from an array of internal sort definitions
     * 
     * @param sorting
     * @returns {string}
     */
    static buildSortQuery(sorting: Array<Sort>): string | undefined {
        if (sorting.length === 0){
            return undefined;
        } 
        
        return sorting.map((sort) => {
            return (sort.direction === SortDirection.SORT_DESC ? "-" : "")+sort.attribute;
        }).join(",");
    }

    /**
     * Build API-ready geopaging filter from map bounds.
     * @param bounds
     */
    static buildGeoPagingFilter(bounds: LatLngBounds): Filter | undefined {
        return bounds ? {
            attribute: GEOPAGING_FILTER_NAME,
            operation: FILTER_OP.eq,
            type: "text", // TODO: consider geo type
            value: serializeBounds(bounds, true, GEOPAGING_FILTER_SEPARATOR),
        } : undefined;
    }
    
    
    
    static async getSchemaListWithBasicInfo(tenantId: string, schemasApi: SchemasApi):Promise<FindingSchemaArrayResponse>{

        let fields: FindingSchemaFieldsQuery = {
            schemas: "name,color,mapIcon,mapPolygonIcon"
        };

        let page: PageQuery = {
            number: 1,
            size: -1
        };
        
        return schemasApi.apiSchemasGet({tenantId, page, fields});
    }

    static async getFindingsCountMatchingFilter(tenantId: string, findingsApi: FindingsApi, filters: Array<Filter> = []):Promise<number>{

        let fields: FindingSchemaFieldsQuery = {
            schemas: ""
        };

        let page: PageQuery = {
            number: 1,
            size: 1
        };

        let response;
        
        try {
            const filter = ApiService.buildFilterQuery(filters);
            response = await findingsApi.apiFindingsGet({tenantId, page, fields: fields,filter});
        } catch (err){
            throw err;
        }
        return response.meta.totalRecords;
    }

    static async hasSchemaDependencies(tenantId: string, schemasApi: SchemasApi, schemaId: string): Promise<boolean>{

        // temp fix of API - relationship resources included manually

        let schemaRelationshipsFindings: RelationshipResourceIdentifierArrayResponse;
        try {
            schemaRelationshipsFindings = await schemasApi.apiSchemasGetByIdRelationshipsFindings({tenantId, id: schemaId});
        } catch (err){
            throw err;
        }
        return (schemaRelationshipsFindings.data.length > 0);
    }
    
}


