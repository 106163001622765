import NewFindingDropdown from '../components/NewFindingDropdown';
import * as React from 'react';
import {AppState} from '../../../store/reducers';
import {connect} from 'react-redux';
import SearchBar from './SearchBar';
import ToolbarActions from "../components/ToolbarActions";
import ColumnsChooser from "../components/ColumnsChooser/ColumnsChooser";

const mapStateToProps = (state: AppState) => ({
  gui: state.gui,
});

const mapDispatchToProps = {};

type FindingsToolbarProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

class FindingsToolbar extends React.Component<FindingsToolbarProps> {
  render() {
    return (
      <div className="d-flex flex-wrap justify-content-between bordered border-bottom-0 p-2 pl-3 pr-3">
        <div className="order-1">
          <NewFindingDropdown />
          <span className="pl-2">
            <ColumnsChooser />
          </span>
        </div>
        <SearchBar className="order-2 " />
        <ToolbarActions />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindingsToolbar as any);
