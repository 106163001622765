import * as React from "react";
import {FilterableAttributesGroup} from "../../../models/FilterableAttributesModel";
import {Label} from "reactstrap";
import {ThemedSelect} from "../../../components/ThemedSelect";

type GraphSettingsProps = {
    aggregate: string,
    group?: string|null,
    onAggregateChange: (field: string) => void,
    onGroupChange: (field: string|null) => void,
    filterableAttributes: Array<FilterableAttributesGroup>,
    groupDisabled?: boolean,
};

export const GraphSettingsForm = (props: GraphSettingsProps) => {

    const options = React.useMemo(() => props.filterableAttributes.map(attributeGroup => ({
        label: attributeGroup.name,
        options: attributeGroup.attributes.map(attribute => ({
            label: attribute.name,
            value: attribute.value,
            type: attribute.type
        }))
    })), [props.filterableAttributes])

    const defaultAggregateOption = React.useMemo(() => {
            let option;
            options.forEach(attrOpt => {
                const attr = attrOpt.options.find(opt => opt.value === props.aggregate)
                if (attr) {
                    option = attr
                }
            });
            return option
        }
        , [options, props.aggregate]);
/*
    const defaultGroupOption = React.useMemo(() => {
            let option;
            options.forEach(attrOpt => {
                const attr = attrOpt.options.find(opt => opt.value === props.group)
                if (attr) {
                    option = attr
                }
            });
            return option
        }
        , [options, props.group]);
*/
    return (
        <div className="d-flex align-items-end">
            
                <Label for="attribute" className="mx-1">Aggregate field: </Label>
                    <ThemedSelect name={"attribute"}
                                  className={"flex-grow-1"}
                                  isSearchable={true}
                                  options={options}
                                  value={defaultAggregateOption}
                                  onChange={(input) => props.onAggregateChange(input.value)}/>
                                  
                {/*<Label for="group" className="mx-1">Group</Label>*/}
                {/*    <ThemedSelect name={"group"}*/}
                {/*                  isDisabled={props.groupDisabled}*/}
                {/*                  isClearable={true}*/}
                {/*                  className={"flex-grow-1"}*/}
                {/*                  isSearchable={true}*/}
                {/*                  options={options}*/}
                {/*                  value={defaultGroupOption}*/}
                {/*                  onChange={(input) => props.onGroupChange(input?.value)}/>*/}
        </div>
    );
}