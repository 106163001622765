import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../store/reducers";
import {Badge, Button, Carousel, CarouselControl, CarouselIndicators, CarouselItem, Modal, ModalBody} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {closeAttachmentsCarousel, showNotification} from "../store/actions/GUIActions";
import {requestAttachmentDownload, setActiveAttachment, setAttachmentProperty} from "../store/actions/FindingActions";
import {FileTypeIcon} from "./FileTypeIcon";
import {UploadedAttachment} from "../store/reducers/FindingReducer";
import {formatBytes} from "../utils/fileUtils";
import SpinnerInline from "./Spinners/SpinnerInline";

type Props = {}

export const AttachmentCarousel = (props: Props) => {
    const initialized = React.useRef<boolean>();

    const dispatch = useDispatch()
    const isActive = useSelector<AppState, boolean>(state => state.gui.attachmentsCarouselActive);
    const attachments = useSelector<AppState, UploadedAttachment[]>(state => state.finding.uploadedAttachments);
    const activeAttachmentId = useSelector<AppState, string | null>(state => state.finding.activeAttachmentId);
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [animating, setAnimating] = React.useState(false);

    const handleDownload = React.useCallback(
        async (id: string) => {
            const data = await dispatch(requestAttachmentDownload(id))
            if (!data) {
                await dispatch(showNotification({
                    message: `Error downloading attachment ${id}`,
                    type: 'error'
                }));
                return
            }
            await dispatch(setAttachmentProperty(id, "data", (data as unknown as Blob)))
        }, [dispatch])


    const goToIndex = React.useCallback((newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }, [animating])


    React.useEffect(() => {
        setLoading(false);
        setAnimating(false);
    }, [isActive]);

    React.useEffect(() => {
        if (attachments.length < 1 || animating) {
            return
        }
        const attachment = attachments[activeIndex];
        if (attachment.resource.id  && !attachment.resource.attributes.incomplete && attachment.thumbnail && !attachment.data) {
            handleDownload(attachment.resource.id)
        }
    }, [activeIndex, attachments, animating, handleDownload]);

    React.useEffect(() => {
        if (!activeAttachmentId || !isActive || attachments.length < 1 || animating) {
            return;
        }
        const attIndex = attachments.findIndex(att => att.resource.id === activeAttachmentId);
        if (!initialized.current && activeIndex !== attIndex) {
            goToIndex(attIndex)
        }
        initialized.current = true;
        const attachment = attachments.find(att => att.resource.id === activeAttachmentId);
        if (!attachment || attachment.resource.attributes.incomplete || attachment.data) {
            return;
        }

        handleDownload(activeAttachmentId)
    }, [activeAttachmentId, isActive, attachments, animating, activeIndex, handleDownload, goToIndex])

    const next = () => {
        if (animating || !attachments) return;
        const nextIndex = activeIndex === attachments.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating || !attachments) return;
        const nextIndex = activeIndex === 0 ? attachments.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const closeCarousel = React.useCallback(() => {
        dispatch(setActiveAttachment(null))
        dispatch(closeAttachmentsCarousel())
        initialized.current = undefined;
        setActiveIndex(0);
    }, [dispatch])

    const onDownload = async () => {
        if (!attachments) {
            return null;
        }
        const attachment = attachments[activeIndex];

        if (attachment.resource.attributes.incomplete){
            showNotification({
                message: 'Cannot download incompletely uploaded file. Finish uploading first.',
                type: 'info',
            })
            return;
        }
        
        let data: any = attachment.data;
        if (!attachment.data && attachment.resource.id) {
            setLoading(true)
            data = await dispatch(requestAttachmentDownload(attachment.resource.id))
            setLoading(false)

            if (!data) {
                await showNotification({
                    message: `Error downloading attachment ${attachment.resource.id}`,
                    type: 'error'
                });
                return
            }
        }
        const url = URL.createObjectURL(data);
        const link = document.createElement("a")
        link.href = url;
        link.setAttribute('download', `${attachment.resource.attributes.originalFilename}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode!.removeChild(link);

        showNotification({
            message: `${attachment.resource.attributes.originalFilename} downloaded`,
            type: 'success',
        })
    }

    const renderAttachment = (attachment: UploadedAttachment) => {
        const dataToRender = attachment.data || attachment.thumbnail;
        if (attachment.thumbnail) {
            return (
                <img
                    src={URL.createObjectURL(dataToRender)}
                    alt={attachment.resource.attributes.originalFilename}
                    className="fullscreen"
                />
            )
        }

        return (
            <div style={{height: 300, width: 300}} className="media-element-empty-preview border-dashed">
                <FileTypeIcon contentType={attachment.resource.attributes.contentType}
                              fileName={attachment.resource.attributes.originalFilename}
                              incomplete={attachment.resource.attributes.incomplete}
                              faSize={"5x"}/>
                {attachment.resource.attributes.incomplete && <Badge pill color="secondary" className={'mt-3'}>
                    INCOMPLETE UPLOAD
                </Badge>}
            </div>
        )
    }

    const getCaptionString = (att: UploadedAttachment) => {
        const size = formatBytes(att.resource.attributes.length);
        const uploadedDate = new Date(att.resource.attributes.uploadTimestamp).toLocaleDateString()
        return `${uploadedDate} ${size ? `/ ${size}` : ''}`
    }

    return <Modal isOpen={isActive} toggle={closeCarousel} fade={false} className={'modal-content-custom modal-full'}
                  size={"xl"}>
        <ModalBody>
            <div className="d-flex align-items-center mb-2">
                <Button color={'primary'}
                        size={'small'}
                        className="text-nowrap"
                        onClick={onDownload}
                        disabled={loading}
                >
                    {loading ? <SpinnerInline color={'white'}/> :
                        <>
                            <FontAwesomeIcon icon="download"/>
                            Download
                        </>
                    }
                </Button>
                <Button onClick={closeCarousel} outline color={"dark"} className={"text-white ml-auto"}>
                    <FontAwesomeIcon icon="times"/>
                    Close
                </Button>
            </div>
            <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                interval={false}
            >
                <CarouselIndicators items={attachments?.map(a => ({key: a.resource.id})) ?? []}
                                    activeIndex={activeIndex} onClickHandler={goToIndex}/>
                {attachments?.map((att, index) => (
                    <CarouselItem
                        tag="div"
                        key={att.resource.id}
                        onExiting={() => setAnimating(true)}
                        onExited={() => setAnimating(false)}
                    >
                        <div className='text-white text-center'>
                            <h5 className='font-weight-bold size'>{att.resource.attributes.originalFilename}</h5>
                            <span className='font-weight-lighter'>{getCaptionString(att)}</span>
                        </div>
                        <div className="slide-item">
                            {renderAttachment(att)}
                        </div>
                    </CarouselItem>
                ))}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous}/>
                <CarouselControl direction="next" directionText="Next" onClickHandler={next}/>
            </Carousel>
        </ModalBody>
    </Modal>
}