import * as React from "react";
import {useState} from "react";
import {
    Button,
    Form,
    FormGroup,
    FormText,
    Modal,
    ModalBody,
    ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {closeCodegenModal} from "../../store/actions/GUIActions";
import {CopyToClipboard} from "../CopyToClipboard";
import {AppState} from "../../store/reducers";
import {processApiResponse} from "../../store/actions/ErrorActions";
import {ICodeGenerator} from "../../utils/codeGenerators/ICodeGenerator";
import {PythonCodeGenerator} from "../../utils/codeGenerators/PythonCodeGenerator";
import Highlight from "react-highlight.js";
import {JupyterCodeGenerator} from "../../utils/codeGenerators/JupyterCodeGenerator";
import {selectActiveTenantId} from "../../store/selectors/TenantSelectors";

const mapStateToProps = (state: AppState) => ({
    isOpen: state.gui.codegenModalActive,
    modalType: state.gui.codegenModalType,
    filters: state.filter.filters,
    sorting: state.listing.sorting,
    schema: state.schema,
    activeTenantId: selectActiveTenantId(state),
});

const mapDispatchToProps = {
    closeModal: closeCodegenModal,
    processApiResponse
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};

const FindingsCodegenModal = (props: Props) => {

    const [activeTab, setActiveTab] = useState('0');

    const codeGenerators: { [key: string]: ICodeGenerator } = {
        "Python": new PythonCodeGenerator(),
        "Jupyter Notebook": new JupyterCodeGenerator(),
    }

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const renderCodegenTab = (codeGenerator: ICodeGenerator, tabId: string) => {

        let generatedCode = '';
        if (props.modalType === "LISTING"){
            generatedCode = codeGenerator.renderFilters(props.activeTenantId, props.filters);
        } else if (props.modalType === "NEW_FINDING" && props.schema.loadedSchemaId) {
            generatedCode = codeGenerator.renderNewFinding(props.activeTenantId, props.schema.loadedSchema, props.schema.loadedSchemaId);
        }
        
        return (
            <TabPane tabId={tabId} key={tabId}>
                <Form>
                    <FormGroup>

                        <Highlight language={'python'}>
                            {generatedCode}
                        </Highlight>

                        <FormText color="muted">
                            <CopyToClipboard
                                textToCopy={generatedCode}
                                className={"float-right"}/>
                            <Button outline
                                    color="secondary"
                                    size={'sm'}
                                    onClick={() => {window.open(process.env.REACT_APP_IDM_AUTHORITY+"/tokens",'_blank','noopener');}}>
                                <FontAwesomeIcon className="mr-1 text-primary" icon={"key"} />
                                Manage API Keys
                            </Button>
                        </FormText>
                    </FormGroup>
                </Form>
            </TabPane>

        );
    };


    return (
        <Modal isOpen={props.isOpen} onClosed={props.closeModal} size={"lg"} className={'scriptCodegenModal'}>
            <ModalHeader toggle={props.closeModal}>
                <FontAwesomeIcon icon="code" className={"mr-2"}/>
                API script code generator
            </ModalHeader>
            <ModalBody>

                <p>
                    UniCatDB offers programatic access using it's REST API which is described using the OpenApi standard
                    (a.k.a Swagger).
                    For direct API usage instructions and examples, <a
                    href={process.env.REACT_APP_API_BASE_PATH + "/swagger/index.html"} target={"_blank"} rel="noopener noreferrer">See interactive
                    documentation</a>, <strong>or use ready-made generated API client scripts of the current
                    view:</strong>
                </p>

                <Nav tabs>
                    {Object.keys(codeGenerators).map((key, index) =>
                        <NavItem key={key}>
                            <NavLink
                                className={activeTab === index + '' ? "active" : ""}
                                onClick={() => {
                                    toggle(index + '');
                                }}
                            >
                                {key}
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
                <TabContent activeTab={activeTab}>
                    {Object.values(codeGenerators).map((generator, index) => renderCodegenTab(generator, index + ''))}
                </TabContent>
            </ModalBody>
        </Modal>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FindingsCodegenModal as any);

