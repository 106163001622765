import * as React from 'react';
import {Redirect, Route, RouteComponentProps, Switch} from 'react-router';
import FindingDetail from "./scenes/FindingDetail/FindingDetail";
import FindingEdit from "./scenes/FindingEdit/FindingEdit";
import Findings from "./scenes/Findings/Findings";
import {connect} from "react-redux";
import {AppState} from "./store/reducers";
import Schemas from "./scenes/Schemas/Schemas";
import SchemaEdit from "./scenes/SchemaEdit/SchemaEdit";
import {FallBackScreen} from "./scenes/Error/FallBackScreen";
import ErrorScreen from "./scenes/Error/ErrorScreen";

import * as am4core from "@amcharts/amcharts4/core";

import {am4themes_unicatTheme} from "./components/Graphs/graphTheme";
import CallbackPage from "./components/CallbackPage";
import PrivateRoute from "./components/PrivateRoute";
import SignOutCallbackPage from "./components/SignOutCallbackPage";
import TenantSwitch from "./scenes/TenantSwitch/TenantSwitch";
import {
    clearLoadedTenant,
    clearTenantEntityDraft,
    loadTenant,
    loadTenantEntityDraft
} from "./store/actions/TenantActions";
import {isAppStateReady} from "./store/selectors/GuiSelectors";
import {SplashScreen} from "./scenes/Splash/SplashScreen";
import SignOut from "./components/SignOut";
import {getAvailableTenantIds} from "./store/selectors/TenantSelectors";
import NotAvailableScreen from "./scenes/Error/NotAvailableScreen";
import {NavbarLayout} from "./components/Layout/NavbarLayout";
import TenantEdit from "./scenes/TenantSwitch/TenantEdit";
import TenantDetail from "./scenes/TenantSwitch/TenantDetail";
import SideWallpaperLayout from "./components/Layout/SideWallpaperLayout";
import {TenantUserRole} from "./services/GeneratedApiTsClient";
import withTenantRoles from "./components/Hoc/withTenantRoles";
import TenantInvitation from "./scenes/TenantSwitch/TenantInvitation";

am4core.useTheme(am4themes_unicatTheme);

export const TENANT_ROUTE_PATH = '/t/:tenantId?';

const mapStateToProps = (state: AppState) => ({
    error: state.error,
    availableTenantIds: getAvailableTenantIds(state),
    isAppReady: isAppStateReady(state)
});

const mapDispatchToProps = {
    loadTenant,
    clearLoadedTenant,
    loadTenantEntityDraft,
    clearTenantEntityDraft,
}

type Props = RouteComponentProps<{ id: string; schema: any }>
    & ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps;

const App = (props: Props) => {
    /**
     * Load tenant and return TRUE if tenant is available to the user, clear and return FALSE otherwise.
     * @param tenantId
     */
    const checkTenant = (tenantId: string): boolean => {
        if (props.availableTenantIds.includes(tenantId)) {
            props.loadTenant(tenantId);
            return true;
        } else {
            props.clearLoadedTenant();
            return false;
        }
    }
    
    return props.error.errors.length > 0 ? <ErrorScreen/> : <>
        <Switch>
            <Route exact path="/signin-oidc" component={CallbackPage}/>
            <Route exact path="/signout-oidc" component={SignOutCallbackPage}/>
            <Route exact path="/signout" component={SignOut}/>
            <Route>
                {!props.isAppReady ? <SplashScreen/> : <Switch>
                    {/* TODO: implement last tenant selection from local storage*/}
                    <PrivateRoute exact path='/'><Redirect to={'/workspaces'}/></PrivateRoute>

                    <Route path={TENANT_ROUTE_PATH} render={({match: {url, params}}) => {

                        if (!params.tenantId) {
                            return <Redirect to={'/workspaces'}/>
                        }

                        const tenantOk = checkTenant(params.tenantId);

                        // encapsulated in private route to enforce logging-in
                        return !tenantOk ? <PrivateRoute><NotAvailableScreen/></PrivateRoute> : (
                            <NavbarLayout>
                                <Switch>
                                    <Route exact path={`${url}`}><Redirect to={`${url}/findings`}/></Route>
                                    <PrivateRoute exact path={`${url}/findings`} component={Findings}/>
                                    <PrivateRoute path={`${url}/findings/detail/:id`} component={FindingDetail}/>
                                    <PrivateRoute path={`${url}/findings/edit/:id`}
                                                  component={withTenantRoles(FindingEdit, [TenantUserRole.OWNER, TenantUserRole.COLLABORATOR])}/>

                                    <PrivateRoute path={`${url}/findings/clone/:id`}
                                                  render={(props: RouteComponentProps<{ id: string; schema: any }>) =>
                                                      <FindingEdit {...props} doClone={true}/>}
                                    />
                                    <PrivateRoute path={`${url}/findings/new/:schema`} component={FindingEdit}/>

                                    <PrivateRoute path={`${url}/schemas`} exact component={Schemas}/>
                                    <PrivateRoute path={`${url}/schemas/edit/:id`} component={SchemaEdit}/>
                                        <PrivateRoute path={`${url}/schemas/clone/:id`} render={(props: RouteComponentProps<{ id: string; schema: any }>) =>
                                            <SchemaEdit {...props} doClone={true} />}
                                        />
                                    <PrivateRoute path={`${url}/schemas/new`} component={SchemaEdit}/>
                                    <Route component={FallBackScreen}/>
                                </Switch>
                            </NavbarLayout>
                        );
                    }}/>

                    <Route path={'/workspaces'} render={({match: {url}}) =>
                        <SideWallpaperLayout>
                            <Switch>
                                <PrivateRoute exact path={`${url}`} component={TenantSwitch}/>
                                <PrivateRoute exact path={`${url}/invitation/:id/:key`}
                                                  render={({match}: RouteComponentProps<{ id: string, key: string}>) => {
                                                      return <TenantInvitation tenantId={match.params.id} invitationKey={match.params.key}/>;
                                                  }}
                                    />
                                <PrivateRoute exact path={`${url}/edit/:id?`}
                                              render={({match}: RouteComponentProps<{ id: string }>) => {
                                                  if (match.params.id) {
                                                      props.loadTenantEntityDraft(match.params.id);
                                                  } else {
                                                      props.clearTenantEntityDraft();
                                                  }
                                                  return <TenantEdit/>;
                                              }}
                                />
                                <PrivateRoute exact path={`${url}/detail/:id`}
                                              render={({match}: RouteComponentProps<{ id: string }>) => {
                                                  return <TenantDetail tenantId={match.params.id}/>;
                                              }}
                                />
                            </Switch>
                        </SideWallpaperLayout>
                    }/>

                    <Route component={FallBackScreen}/>
                </Switch>}
            </Route>
        </Switch>
    </>;

}


export default connect(mapStateToProps, mapDispatchToProps)(App as any);
