/* tslint:disable */
/* eslint-disable */
/**
 * UniCatDB API
 * UniCatDB application API documentation, with examples and live testing.  This API is built in accordance with the **JSON API 1.0 standard**. For general information, see [the documentation](http://jsonapi.org/format/).  -  JSON API standard requires use of the JSON API media type (application/vnd.api+json) for exchanging data. Clients must send all JSON API data with the headers `Content-Type: application/vnd.api+json` (POST, PATCH) and `Accept: application/vnd.api+json` (GET, DELETE). - [Relationships](http://jsonapi.org/format/#fetching-relationships) and their [inclusions](http://jsonapi.org/format/#fetching-includes) via the `include` query parameter, as specified by the standard, **are not implemented**, since there are no relationships present in the data model. - The standard does not prescribe any filtering strategies. This API implements two strategies which can be combined: **Basic filtering** based on the used [JSON API library](https://json-api-dotnet.github.io/#/filtering) and **Custom filtering** which allow for any possible query to the MongoDB server and can be extended and customize in the future. For more information about filtering, see the description of the `filter` down bellow. - **Non-standard PATCH behavior:** Sucessfull PATCH reuest always result in HTTP 200 response with the updated resource object, even if the server does not perform any additional modifications. HTTP 204 is never used in PATCH responses.  Attachment upload endpoint uses the **TUS protocol**. See [the documentation](https://tus.io/). When integrating, it is recommended to use one of the [official TUS client implementations](https://tus.io/implementations.html).  - **Supported TUS versions**:   -  1.0.0 - **Supported TUS extensions**:   - Creation   - Creation Defer Length   - Creation With Upload   - Checksum   - Checksum Trailer - **Required metadata**:   - `fileName` - name of the original file incl. file extension   - `contentType`- the MIME type of the file 
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    originalFilename: string;
    /**
     * MIME type of the file
     * @type {string}
     * @memberof Attachment
     */
    contentType: string;
    /**
     * Size of the file in bytes
     * @type {number}
     * @memberof Attachment
     */
    length: number;
    /**
     * ISO 8601 formatted date, UTC time of day component may be present and equals to zero (00:00:00.000Z)
     * @type {Date}
     * @memberof Attachment
     */
    uploadTimestamp: Date;
    /**
     * NULL or ISO 8601 formatted date, UTC time of day component may be present and equals to zero (00:00:00.000Z)
     * @type {Date}
     * @memberof Attachment
     */
    uploadCompletedTimestamp: Date | null;
    /**
     * TRUE if the attachments data are being uploaded (cannot download data or thumbnail), FALSE if completed
     * @type {boolean}
     * @memberof Attachment
     */
    incomplete: boolean;
}

export function AttachmentFromJSON(json: any): Attachment {
    return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'originalFilename': json['originalFilename'],
        'contentType': json['contentType'],
        'length': json['length'],
        'uploadTimestamp': (new Date(json['uploadTimestamp'])),
        'uploadCompletedTimestamp': (json['uploadCompletedTimestamp'] === null ? null : new Date(json['uploadCompletedTimestamp'])),
        'incomplete': json['incomplete'],
    };
}

export function AttachmentToJSON(value?: Attachment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'originalFilename': value.originalFilename,
        'contentType': value.contentType,
        'length': value.length,
        'uploadTimestamp': (value.uploadTimestamp.toISOString().substr(0,10)),
        'uploadCompletedTimestamp': (value.uploadCompletedTimestamp === null ? null : value.uploadCompletedTimestamp.toISOString().substr(0,10)),
        'incomplete': value.incomplete,
    };
}


