import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faBars,
    faChevronLeft,
    faChevronRight,
    faCog,
    faColumns,
    faCopy,
    faDrawPolygon,
    faEdit,
    faFileExport,
    faLifeRing,
    faMapMarker,
    faPlus,
    faSave,
    faSearch,
    faSlidersH,
    faThLarge,
    faThList,
    faTimes,
    faTrash,
    faTrashAlt,
    faDownload,
    faCross,
    faFileUpload,
    faFile,
    faCheck,
    faMinus,
    faQuestion,
    faList,
    faSitemap,
    faUndoAlt,
    faEraser,
    faFilter,
    faEyeSlash,
    faArrowRight,
    faPen,
    faArrowsAltH,
    faClone,
    faParagraph,
    faLevelUpAlt,
    faFileAlt,
    faFileImage,
    faFileAudio,
    faFileVideo,
    faFileWord,
    faFileExcel,
    faFilePowerpoint,
    faFilePdf,
    faFileArchive,
    faFileCsv,
    faUpload,
    faQuestionCircle,
    faExternalLinkAlt,
    faExternalLinkSquareAlt,
    faGripVertical,
    faClipboard,
    faKey,
    faSignOutAlt,
    faCode,
    faRandom,
    faUser,
    faUserPlus,
    faEnvelope,
    faFileImport,
    faExclamationTriangle,
    faPencilRuler
} from "@fortawesome/free-solid-svg-icons";

export const registerFontAwesome = () => {
    library.add(
        faSlidersH,
        faPlus,
        faTrashAlt,
        faThList,
        faThLarge,
        faCog,
        faTimes,
        faChevronLeft,
        faChevronRight,
        faFileExport,
        faCopy,
        faTrash,
        faEdit,
        faBars,
        faSave,
        faColumns,
        faMapMarker,
        faDrawPolygon,
        faSearch,
        faLifeRing,
        faDownload,
        faCross,
        faFileUpload,
        faFile,
        faCheck,
        faMinus,
        faQuestion,
        faList,
        faSitemap,
        faColumns,
        faUndoAlt,
        faEraser,
        faFilter,
        faEyeSlash,
        faArrowRight,
        faPen,
        faClone,
        faArrowsAltH,
        faLevelUpAlt,
        faParagraph,
        faFileImage,
        faFileAudio,
        faFileVideo,
        faFileWord,
        faFileExcel,
        faFilePowerpoint,
        faFilePdf,
        faFileArchive,
        faFileCsv,
        faFileAlt,
        faFileImport,
        faUpload,
        faQuestionCircle,
        faExternalLinkAlt,
        faExternalLinkSquareAlt,
        faGripVertical,
        faClipboard,
        faKey,
        faSignOutAlt,
        faDownload,
        faCode,
        faRandom,
        faUser,
        faUserPlus,
        faEnvelope,
        faExclamationTriangle,
        faPencilRuler
    );
}