import * as React from 'react';
import {useEffect, useState} from 'react';
import {AppState} from "../../../store/reducers";
import {openGraphView, openMapView} from "../../../store/actions/GUIActions";
import {connect} from "react-redux";
import ColumnChartGraph, {ColumnChartGraphData} from "../../../components/Graphs/ColumnChartGraph";
import PieChartGraph, {PieChartGraphData} from "../../../components/Graphs/PieChartGraph";
import ApiService from "../../../services/ApiService/ApiService";
import {getConfiguredChartsApi} from "../../../store/selectors/ApiSelectors";
import {selectActiveTenantId} from "../../../store/selectors/TenantSelectors";
import SpinnerOverlay from "../../../components/Spinners/SpinnerOverlay";

const mapStateToProps = (state: AppState) => ({
    gui: state.gui,
    chartsApi: getConfiguredChartsApi(state),
    tenantId: selectActiveTenantId(state),
    schemasBasicInfo: state.findingSchemas.schemasBasicInfo,
    filters: state.filter.filters
});

const mapDispatchToProps = {
    openMap: openMapView,
    openGraph: openGraphView,
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const MiniStatistics = (props: Props) => {

    const [loading, setLoading] = useState(false);

    const [taxonomyChartData, setTaxonomyChartData] = useState<PieChartGraphData>();
    const [schemaChartData, setSchemaChartData] = useState<ColumnChartGraphData>();

    useEffect(() => {

        let isMounted = true;
        setLoading(true);

        if(!props.tenantId)return;
        props.chartsApi.apiChartsFindingsFrequency({
            tenantId: props.tenantId,
            filter: ApiService.buildFilterQuery(props.filters),
            aggregate: "taxonomyHumanReadable",
            maxItemsPerGroup: 5
        }).then(taxonomyChart => {
            
            if (isMounted) {
                setTaxonomyChartData(taxonomyChart.data?.map(i => {
                    return ({
                        name: i.label,
                        value: i.value,
                    });
                }));
            }
            
            
            props.chartsApi.apiChartsFindingsFrequency({
                tenantId: props.tenantId,
                filter: ApiService.buildFilterQuery(props.filters),
                aggregate: "findingSchemaId"
            }).then(schemaFrequency => {
                if (isMounted) {
                    setSchemaChartData(schemaFrequency.data?.map(i => {
                        let schema = props.schemasBasicInfo.find(s => s.id === i.label)?.attributes;
                        return ({
                            name: schema?.name ?? i.label,
                            value: i.value,
                            color: schema?.color,
                        });
                    }));
                    
                    setLoading(false);
                }
            });
        });


        return () => {
            isMounted = false;
        }
    }, [props.chartsApi, props.filters, props.schemasBasicInfo, props.tenantId]);

    if (loading) {
        return <SpinnerOverlay/>

    } else {
        return (<>
                {schemaChartData &&
                <ColumnChartGraph data={schemaChartData} showLabels={false} disableGridLines={true} title={"Schemas"}/>}
                {taxonomyChartData && <PieChartGraph data={taxonomyChartData} hideLabels={true} title={"Species"}/>}
            </>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MiniStatistics as any);