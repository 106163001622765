import {
    CANCEL_RECEIVING_FINDING_SCHEMAS,
    FLUSH_FINDING_SCHEMAS, RECEIVE_FINDING_SCHEMAS,
    REQUEST_FINDING_SCHEMAS
} from "../actions/FindingSchemasActions";
import {FilterableAttributesGroup} from "../../models/FilterableAttributesModel";
import {FindingSchemaBasicInfoResourceObject} from "../models/FindingSchemaBasicInfo";


export type FindingSchemasReducerState = {
    schemasBasicInfo: Array<FindingSchemaBasicInfoResourceObject>,
    schemaAttributes: Array<FilterableAttributesGroup>,
    loadingInProgress: boolean,
    loaded: boolean,
}

export const FindingSchemasReducerInitialState : FindingSchemasReducerState = {
    schemasBasicInfo: [],
    schemaAttributes: [],
    loadingInProgress: false,
    loaded: false,
};

export default (state = FindingSchemasReducerInitialState, action) => {

    switch (action.type) {
        case REQUEST_FINDING_SCHEMAS:
            return {
                ...state,
                loadingInProgress: true
            };
        case CANCEL_RECEIVING_FINDING_SCHEMAS:
            return {
                ...state,
                loadingInProgress: false
            };
        case FLUSH_FINDING_SCHEMAS:
            return FindingSchemasReducerInitialState;
        case RECEIVE_FINDING_SCHEMAS:
            return {
                ...state,
                schemasBasicInfo: action.schemasBasicInfo,
                schemaAttributes: action.schemaAttributes,
                isLoading: false,
                loaded: true,
            };
        default:
            return state;
    }
};

