import ViewColumn from "../models/ViewColumn";
import {StaticFilterableAttributesGroup} from "../../models/FilterableAttributesModel";
import {AppState} from "../reducers";

export const RESET_VIEW_PREFERENCES: string = 'RESET_VIEW_PREFERENCES';
export const SET_COLUMNS_ORDER: string = 'SET_COLUMNS_ORDER';
export const SET_COLUMNS_WIDTH: string = 'SET_COLUMNS_WIDTH';
export const SET_COLUMNS: string = 'SET_COLUMNS';
export const VIEW_PREFERENCES_NORMALIZE: string = 'VIEW_PREFERENCES_NORMALIZE';

export const LOCAL_STORAGE_KEY = "UNICATDB_VIEW_PREFERENCES";

export const resetPreferences = () => {
    return {type: RESET_VIEW_PREFERENCES};
};

export const setColumnsOrder = (newOrder) => {
    return {type: SET_COLUMNS_ORDER, newOrder};
};

export const setColumnsWidths = (newWidths) => {
    return {type: SET_COLUMNS_WIDTH, newWidths};
};

export const setColumns = (newColumns) => {
    return {type: SET_COLUMNS, newColumns};
};

export const normalizeViewPreferences = () => (dispatch, getState: () => AppState) => {
    
    let state = getState();
    if (!state.findingSchemas.loaded)    return;
    
    let availableAttributesGroups = StaticFilterableAttributesGroup.concat(state.findingSchemas.schemaAttributes);

    // filter-out columns which are not present in the loaded schemas to prevent collisions
    let normalizedColumns = state.viewPreferences.listViewColumns
        .filter((vc: ViewColumn) => availableAttributesGroups.some(s => s.attributes.some(a => a.value === vc.attribute)));
        
    dispatch({type: VIEW_PREFERENCES_NORMALIZE, newColumns: normalizedColumns});

};


export default {}

