import ApiService, {ApiOnRejectedResult} from '../../services/ApiService/ApiService';
import {AppState} from '../reducers';
import {processApiResponse} from './ErrorActions';
import {hideNotification, showNotification, updateNotification} from './GUIActions';
import {Filter} from "../../models/FilterModel";
import {FILTER_OP} from "../../models/FilterOperationModel";
import Papa from 'papaparse';
import copy from 'copy-to-clipboard';
import {getConfiguredExportApi} from "../selectors/ApiSelectors";
import {selectActiveTenantId} from "../selectors/TenantSelectors";

export enum ExportActions {
    REQUEST_EXPORT = "REQUEST_EXPORT",
    RECEIVE_EXPORT = "RECEIVE_EXPORT",
    CANCEL_EXPORT = "CANCEL_FINDING_EXPORT",
}

export enum DownloadTarget {
    CsvSemicolon = "CsvSemicolon",
    Clipboard = "Clipboard"
}

function triggerCsvDownload(content: string) {
    // prepend UTF-8 BOM to make Excel recognize the encoding, see: https://stackoverflow.com/a/11399444
    var fileContent = "\ufeff" + content;

    // source: https://stackoverflow.com/q/61597868
    let blobx = new Blob([fileContent], {type: 'text/csv'}); // ! Blob
    let elemx = window.document.createElement('a');
    elemx.href = window.URL.createObjectURL(blobx); // ! createObjectURL
    elemx.download = "export-" + (new Date().toISOString()) + ".csv";
    elemx.style.display = 'none';
    document.body.appendChild(elemx);
    elemx.click();
    document.body.removeChild(elemx);
}

/**
 * Pushes CSV data to clipboard.
 * 
 * @param content CSV string
 * @return TRUE on success FALSE on failure
 */
const pushCsvToClipboard = (content: string):boolean => {

    let config = {
        delimiter: ';',
        skipEmptyLines: true
    };
    let parsed = Papa.parse<string[]>(content, config);

    // build HTML-table string
    let clipboardHtmlContent = `
    <html><body><table>
        <tbody>
        ${parsed.data.map(row => `<tr>${row.map(col => `<td>${col}</td>`).join('')}</tr>`).join('')}    
        </tbody>
    </table></body></html>
    `;
    
    // build plaintext tab-delimited string
    let clipboardTextContent = parsed.data.map(row => row.join('\t')).join('\r\n');

    let success = copy(clipboardHtmlContent, {
        format: "text/html",
        debug: true,
        onCopy: clipboardData => {
            // append plaintext fallback
            (clipboardData as DataTransfer).setData("text/plain", clipboardTextContent);
        }
    });
    
    return success;
}


export const downloadLoadedFinding = (target: DownloadTarget) => async (dispatch, getState) => {
    let state: AppState = getState();
    // ignore if no finding is loaded
    if (!state.finding.loaded || !state.finding.loadedFindingId || state.export.inProgress) {
        return;
    }

    dispatch({type: ExportActions.REQUEST_EXPORT});

    const exportApi = getConfiguredExportApi(state);
    const tenantId = selectActiveTenantId(state);

    let notificationHandle = showNotification({message: `Exporting data ...`, type: "info", doNotAutoClose: true});

    await exportApi.apiExportFindingsGetById({tenantId, id: state.finding.loadedFindingId}).then(
        async response => {
            
            if (target === DownloadTarget.CsvSemicolon){
                hideNotification(notificationHandle);
                triggerCsvDownload(response);
            } else if(target === DownloadTarget.Clipboard) {

                if (response.length > 100000){
                    updateNotification(notificationHandle, {
                        message: "Too large to copy to clipboard, please download CSV instead.",
                        type: "error",
                        doNotAutoClose: false
                    });
                } else {
                    if (pushCsvToClipboard(response)) {
                        updateNotification(notificationHandle, {
                            message: "Copied to clipboard",
                            type: "success",
                            doNotAutoClose: false
                        });
                    } else {
                        updateNotification(notificationHandle, {
                            message: "Copying to clipboard failed, probably not supported in your browser",
                            type: "error",
                            doNotAutoClose: false
                        });
                    }
                }
            }
            
            dispatch({type: ExportActions.RECEIVE_EXPORT});

        }, (error: ApiOnRejectedResult) => {
            dispatch({type: ExportActions.CANCEL_EXPORT});
            processApiResponse(error, "Error occured while requesting export.")
            console.error(error);
        });
}

export const downloadListing = (target: DownloadTarget) => async (dispatch, getState) => {
    let state: AppState = getState();
    // debounce
    if (state.export.inProgress) return;

    dispatch({type: ExportActions.REQUEST_EXPORT});

    let filters: Filter[] = state.filter.filters;

    let selected = state.listing.selectedFindingIds;
    if (selected.length > 0) {
        filters = [{attribute: "id", operation: FILTER_OP.isIn, type: "text", value: selected.join(",")}]
    }

    let filterQuery = ApiService.buildFilterQuery(filters);

    const exportApi = getConfiguredExportApi(state);
    const tenantId = selectActiveTenantId(state);

    let notificationHandle = showNotification({message: `Exporting data ...`, type: "info", doNotAutoClose: true});

    await exportApi.apiExportFindingsGet({tenantId, filter: filterQuery}).then(
        async response => {

            if (target === DownloadTarget.CsvSemicolon){
                hideNotification(notificationHandle);
                triggerCsvDownload(response);
            } else if(target === DownloadTarget.Clipboard) {
                
                if (response.length > 100000){
                    updateNotification(notificationHandle, {
                        message: "Too large to copy to clipboard, please download CSV instead.",
                        type: "error",
                        doNotAutoClose: false
                    });
                } else {
                    if (pushCsvToClipboard(response)){
                        updateNotification(notificationHandle, {
                            message: "Copied to clipboard",
                            type: "success",
                            doNotAutoClose: false
                        });
                    } else {
                        updateNotification(notificationHandle, {
                            message: "Copying to clipboard failed, probably not supported in your browser",
                            type: "error",
                            doNotAutoClose: false
                        });
                    }
                }
            }

            dispatch({type: ExportActions.RECEIVE_EXPORT});

        }, (error: ApiOnRejectedResult) => {
            dispatch({type: ExportActions.CANCEL_EXPORT});
            processApiResponse(error, "Error occured while requesting export.")
            console.error(error);
        });
}


export default {
    downloadLoadedFinding,
    downloadListing,
};
