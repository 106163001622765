import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {
  FilterableAttribute,
  FilterableAttributesGroup,
  StaticFilterableAttributesGroup,
} from '../../../../models/FilterableAttributesModel';
import Select from 'react-select';
import {connect} from 'react-redux';
import ViewColumn from '../../../../store/models/ViewColumn';
import {AppState} from '../../../../store/reducers';
import {requestFindingSchemas} from '../../../../store/actions/FindingSchemasActions';
import {resetPreferences, setColumns} from '../../../../store/actions/ViewPreferencesActions';
import {WellKnownAttributes} from "../../../../models/FindingWellKnownAttributes";
import {ItemInterface, ReactSortable} from "react-sortablejs";
import SpinnerRibbon from "../../../../components/Spinners/SpinnerRibbon";

type SelectOption = {
  label: string;
  value: string | Array<SelectOption>;
  groupLabel?: string;
};

type SelectOptgroup = {
  label: string;
  options: Array<SelectOption>;
};

const mapStateToProps = (state: AppState) => ({
  findingSchemas: state.findingSchemas,
  viewPreferences: state.viewPreferences,
  splitMode: state.gui.splitMode,
});

const mapDispatchToProps = {
  requestFindingSchemas,
  setColumns,
  resetPreferences,
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};

type SortableViewColumn = ItemInterface & {
  column: ViewColumn
}

type State = {
  isShown: boolean;
  loaded: boolean;
  listViewColumns: Array<SortableViewColumn>;
  optionsForSelect: Array<SelectOptgroup>;
};

const MANDATORY_COLUMNS: Array<string> = [WellKnownAttributes.documentName];


class ColumnsChooser extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isShown: false,
      loaded: false,
      listViewColumns: [],
      optionsForSelect: [],
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.handleOnOpened = this.handleOnOpened.bind(this);
    this.handleOnClosed = this.handleOnClosed.bind(this);
    this.handleApply = this.handleApply.bind(this);
    this.addColumn = this.addColumn.bind(this);
  }

  refreshInternalState() {
    if (!this.props.findingSchemas.loaded) {
      this.setState({
        loaded: false,
      });
      return;
    }

    this.setState(
      {
        listViewColumns: this.props.viewPreferences.listViewColumns.map((c:ViewColumn): SortableViewColumn => {
          return {
            column: c,
            id: c.group+"-"+c.attribute
          }
        }),
        loaded: true,
      },
      () => {
        this.refreshOptionsForSelect();
      },
    );
  }

  async componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;
    this.refreshInternalState();
  }

  toggleModal() {
    this.setState({
      isShown: !this.state.isShown,
    });
  }

  handleOnOpened() {
    this.props.requestFindingSchemas();
    this.refreshInternalState();
  }

  handleOnClosed() {
    //console.log("chooser closed");
    this.setState({
      loaded: false,
      listViewColumns: [],
      optionsForSelect: [],
    });
  }

  handleApply() {
    this.props.setColumns(this.state.listViewColumns.map(c => c.column));
    this.setState({
      isShown: false,
    });
  }

  isMandatory(attribute: string) {
    return MANDATORY_COLUMNS.includes(attribute);
  }

  removeColumn(attribute: string) {

    if (this.isMandatory(attribute)) return;

    this.setState(
      {
        listViewColumns: this.state.listViewColumns.filter((column: SortableViewColumn) => column.column.attribute !== attribute),
      },
      () => {
        this.refreshOptionsForSelect();
      },
    );
  }

  addColumn(newColumn: SelectOption) {
    let addedColumns: Array<ViewColumn> = [];

    if (Array.isArray(newColumn.value)) {
      addedColumns = newColumn.value.map((col: SelectOption) => {
        return new ViewColumn(col.value as string, col.label, col.groupLabel);
      });
    } else {
      addedColumns = [new ViewColumn(newColumn.value, newColumn.label, newColumn.groupLabel)];
    }

    // remove allready present columns
    var that = this;
    let addedSortableColumns = addedColumns.filter((column) => {
      return that.state.listViewColumns.find((x: SortableViewColumn) => x.column.attribute === column.attribute) === undefined;
    }).map((c:ViewColumn): SortableViewColumn => {
      return {
        column: c,
        id: c.group+"-"+c.attribute
      }
    });

    this.setState(
      {
        listViewColumns: this.state.listViewColumns.concat(addedSortableColumns),
      },
      () => {
        this.refreshOptionsForSelect();
      },
    );
  }

  refreshOptionsForSelect() {
    let source = StaticFilterableAttributesGroup.concat(this.props.findingSchemas.schemaAttributes);
    let that = this;

    this.setState({
      optionsForSelect: source.map((attrGroup: FilterableAttributesGroup) => {
        let allOptionsDisabled: boolean = true;
        let groupOptions: any = attrGroup.attributes.map((attr: FilterableAttribute) => {
          let disabled: boolean = that.state.listViewColumns.find(x => x.column.attribute === attr.value) !== undefined;
          // @ts-ignore
          allOptionsDisabled &= disabled;

          return {
            label: attr.name,
            value: attr.value,
            // groupLabel: (attrGroup !== StaticFilterableAttributesGroup)? attrGroup.name : null,
            groupLabel: attrGroup.name,
            isDisabled: disabled,
          };
        });

        return {
          label: attrGroup.name,
          options: [
            {
              label: '(ALL OF ' + attrGroup.name + ')',
              value: groupOptions,
              groupLabel: attrGroup.name,
              isDisabled: allOptionsDisabled,
            },
          ].concat(groupOptions),
        };
      }),
    });
  }

  render() {
    return (
      <React.Fragment>
        <Button color="link" className="btn-hover-light-link" onClick={this.toggleModal}>
          <FontAwesomeIcon icon="columns"/> <span>Customize table</span>
        </Button>

        <Modal
          isOpen={this.state.isShown}
          toggle={this.toggleModal}
          onOpened={this.handleOnOpened}
          onClosed={this.handleOnClosed}
          className="spinner-overlay-container">
          <ModalHeader toggle={this.toggleModal}>
            <FontAwesomeIcon icon="columns"/> Customize table
          </ModalHeader>
          <ModalBody>
            {!this.state.loaded ? (
              <SpinnerRibbon/>
            ) : (
              <div>
                <div className="columnsList">
                  <h5>Add Column</h5>
                  <Select
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: '#D0EDD2',
                        primary: '#028F68',
                      },
                    })}
                    onChange={this.addColumn}
                    options={this.state.optionsForSelect}
                    value={null} // do not keep the selected value selected
                    //autoFocus={true}
                  />

                  <hr/>

                  <ReactSortable
                      list={this.state.listViewColumns}
                      setList={newState => this.setState({ listViewColumns: newState })}
                      handle={".sortable-handle"}
                  >
                    {this.state.listViewColumns.map((column: SortableViewColumn, index) => {
                      const previous = index > 0 ? this.state.listViewColumns[index-1] : undefined;
                      const renderGroupLabel = !previous || previous.column.group !== column.column.group;
                      
                      return (
                          <div key={column.id} >
                            {renderGroupLabel && <h5 className={"group-label"}>{column.column.group}</h5>}
                            <div className={"column-selector " + (this.isMandatory(column.column.attribute) ? "column-mandatory" : "")}>
                              <div className={"column-grip sortable-handle"}>
                                <FontAwesomeIcon icon={"grip-vertical"} />
                              </div>
                              <div className="column-label sortable-handle" id={column.id.toString()}>
                                {column.column.label}
                              </div>
                              <Button color="link"
                                      className={`btn-hover-light-link ${this.isMandatory(column.column.attribute) ? "invisible" : ""}`}
                                      onClick={e => this.removeColumn(column.column.attribute)}>
                                <FontAwesomeIcon icon="eye-slash"/> Hide
                              </Button>
                            </div>
                          </div>
                      );
                    })}

                  </ReactSortable>
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="link"
              onClick={() => {
                this.props.resetPreferences();
              }}
              className="btn-hover-light-link">
              <FontAwesomeIcon className="mr-1 text-primary" icon={"undo-alt"}/>
              Restore default
            </Button>
            <div>
              <Button outline color="secondary" onClick={this.toggleModal}>
                Cancel
              </Button>
              <Button color="primary confirm" type="submit" disabled={!this.state.loaded}
                      onClick={this.handleApply}>
                Apply
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnsChooser as any);
