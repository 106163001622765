const validityStates = [
    "badInput",
    "rangeOverflow",
    "rangeUnderflow",
    "stepMismatch",
    "tooLong",
    "tooShort",
    "typeMismatch",
    "valueMissing",
]

export const isValidFloatNumber = (field: any): boolean => {
    let validityErrors: string[] = [];
    for (let key of validityStates) {
        // If there's an error, change valid value
        if (field.validity[key]) {
            validityErrors.push(key)
        }
    }
    
    // there's more errors to resolve before float check
    if (validityErrors.length > 1) {
        return false
    }
    
    // number check
    if (isNaN(field.value) || !field.value) {
        return false
    }
    
    // there's different error than floating point
    if (validityErrors[0] !== 'stepMismatch' ) {
        return false
    }
    
    // there's actual step error
    if (field.step && field.value % field.step !== 0) {
        return false
    }
    
    return true
}