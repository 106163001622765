import * as React from 'react';
import FilterEditor from '../../../components/FilterEditor/FilterEditor';
import {Button} from 'reactstrap';
import {AppState} from '../../../store/reducers';
import {clearFilters} from '../../../store/actions/FilterActions';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {SupportedFilterOperations} from '../../../models/FilterOperationModel';
import {Filter} from '../../../models/FilterModel';
import {WellKnownAttributes} from '../../../models/FindingWellKnownAttributes';

const mapStateToProps = (state: AppState) => ({
    filter: state.filter,
    findingSchema: state.findingSchemas,
});

const mapDispatchToProps = {
    clearFilters: clearFilters,
};
type ComponentProps = { className?: string };
type SearchBarProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & ComponentProps;
type State = {
    showModal: boolean;
};

class SearchBar extends React.Component<SearchBarProps, State> {
    constructor(props: SearchBarProps) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    toggleModal = () => {
        this.setState(prevState => ({showModal: !prevState.showModal}));
    };

    renderSelectedFilter = (filter: Filter, index: number) => {
        const attribute = filter.attribute === WellKnownAttributes.schemaId ? 'Schema' : filter.attribute;

        let value = "";
        if (filter.attribute === WellKnownAttributes.schemaId) {
            value = filter.value.split(",").map((item) => {
                return this.props.findingSchema.schemasBasicInfo.find(schema => schema.id === item).attributes.name;
            }).join(", ")
        } else {
            value = filter.value;
        }

        return (
            <div key={index} className="d-flex flex-row flex-nowrap px-1">
                <div className="text-black-50 text-nowrap">{`${attribute} ${
                    SupportedFilterOperations[filter.operation].humanReadable
                }`}</div>
                <div className="text-main text-nowrap pl-1">{value}</div>
            </div>
        );
    };

    render() {
        return (
            <div
                className={'d-flex flex-fill align-items-center justify-content-center finding-search-bar-position ' + this.props.className}>
                <div
                    onClick={this.toggleModal}
                    className="d-flex justify-content-between cursor-pointer bordered rounded p-2 finding-search-bar-component overflow-auto">
                    <div className="d-flex align-items-center justify-content-start">
                        <FontAwesomeIcon icon={'filter'} size={'lg'} className={'text-primary'}/>
                        {this.props.filter.filters && this.props.filter.filters.length === 0 && (
                            <span className="pl-2 text-black-50">Filter findings</span>
                        )}
                        <div style={{maxWidth: 'inherit'}} className="d-flex flex-row flex-nowrap overflow-auto">
                            {this.props.filter.filters.map((filter, index) => this.renderSelectedFilter(filter, index))}
                        </div>
                    </div>
                    <div className="text-primary justify">Options</div>
                </div>
                {this.props.filter.filters && this.props.filter.filters.length > 0 && (
                    <Button color="link" className="btn-hover-light-link dark" onClick={this.props.clearFilters}>
                        <FontAwesomeIcon icon={'times'} className="text-danger"/>
                        Reset filter
                    </Button>
                )}
                <FilterEditor show={this.state.showModal} onClose={this.toggleModal}/>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar as unknown as React.ComponentClass<ComponentProps>);
