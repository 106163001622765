import React from 'react';
import NavMenu from '../NavMenu';
import {AttachmentCarousel} from "../AttachmentsCarousel";
import "react-toastify/dist/ReactToastify.css";
import FindingsCodegenModal from "../CodeGenerator/FindingsCodegenModal";
import InvitationModal from "../Invite/InvitationModal";

export const NavbarLayout = props => (
    <div className="unicatdb">
        <NavMenu/>
        
        {props.children}
        
        <AttachmentCarousel/>
        <FindingsCodegenModal/>
        <InvitationModal/>
    </div>
);