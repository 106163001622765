import * as React from 'react';

type Props = { 
    titleAfter?: any;
  title: string;
  children: any;
  alignColumn?: boolean;
  gutterBottom?: boolean;
  className? :string;
};

export const DataCardInputElement = (props: Props) => (
  <div
    className={`d-flex p-0 w-49-lg-p w-100-p ${props.alignColumn ? 'flex-column' : 'flex-row'} ${
      props.gutterBottom ? 'mb-3' : ''
    } ${props.className}`}>
    <p className="card-input-heading">{props.title}{props.titleAfter}</p>
    {props.children}
  </div>
);
