require("../../../../node_modules/formBuilder/dist/form-builder.min.js");        // not pretty but works

// configure the class for runtime loading
if (!window.fbControls) window.fbControls = [];

window.fbControls.push(function(controlClass, allControlClasses) {
    const controlTextarea = allControlClasses.textarea
    /**
     * FASTA data control class
     */
    class controlFastaData extends controlTextarea {
        /**
         * Class configuration - return the icons & label related to this control
         * @returndefinition object
         */
        static get definition() {
            return {
                icon: '📃',
                i18n: {
                    default: 'FASTA data',
                },
            }
        }

        configure() {
            super.configure();
        }

        /**
         * build a div DOM element
         * @return {DOMElement} DOM Element to be injected into the form.
         */
        build() {
            this.input = super.build();
            this.input.setAttribute("placeholder", "[Paste contents of the FASTA file here]")
            this.input.setAttribute("class", "form-control")
            
            // this.legend = this.markup('legend', "📃 FASTA data:");
            this.inputGroupText = this.markup('div', "📃 FASTA data:", {class: "input-group-text"})
            this.inputGroupPrepend = this.markup('div', [this.inputGroupText], {class: "input-group-prepend"})
            this.inputGroup = this.markup('div', [this.inputGroupPrepend, this.input], {class: "input-group"})
            
            
            // this.fieldset = this.markup('fieldset', [this.legend, this.input]);
            
            return this.inputGroup;
        }
    }

    // register FASTA data control
    controlTextarea.register('fasta', controlFastaData)
})