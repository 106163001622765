import * as React from "react";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown
} from 'reactstrap';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {openInvitationModal} from "../../store/actions/GUIActions";
import {AppState} from "../../store/reducers";
import {UncontrolledButtonDropdownProps} from "reactstrap/lib/ButtonDropdown";
import {TenantInviteeRoleNames} from "../../store/models/TenantInviteeRoleNames";

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = {
    openInvitationModal
};

type ComponentProps = UncontrolledButtonDropdownProps & {
    tenantId: string
    disabled?: boolean
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & ComponentProps;

const InvitationDropdown = ({tenantId, openInvitationModal, disabled, ...restProps}: Props) => {

    return (
        <UncontrolledButtonDropdown {...restProps}>
            <DropdownToggle caret color="primary btn-shadows dropdown-finding" disabled={disabled}>
                <FontAwesomeIcon icon="user-plus"/>
                Invite new ...
            </DropdownToggle>
            <DropdownMenu>
                {TenantInviteeRoleNames.map(r =>
                    <DropdownItem key={r.value} onClick={() => openInvitationModal(tenantId, r.value)}>
                        {r.label}
                    </DropdownItem>
                )}
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationDropdown as unknown as React.ComponentClass<ComponentProps>);

