/* tslint:disable */
/* eslint-disable */
/**
 * UniCatDB API
 * UniCatDB application API documentation, with examples and live testing.  This API is built in accordance with the **JSON API 1.0 standard**. For general information, see [the documentation](http://jsonapi.org/format/).  -  JSON API standard requires use of the JSON API media type (application/vnd.api+json) for exchanging data. Clients must send all JSON API data with the headers `Content-Type: application/vnd.api+json` (POST, PATCH) and `Accept: application/vnd.api+json` (GET, DELETE). - [Relationships](http://jsonapi.org/format/#fetching-relationships) and their [inclusions](http://jsonapi.org/format/#fetching-includes) via the `include` query parameter, as specified by the standard, **are not implemented**, since there are no relationships present in the data model. - The standard does not prescribe any filtering strategies. This API implements two strategies which can be combined: **Basic filtering** based on the used [JSON API library](https://json-api-dotnet.github.io/#/filtering) and **Custom filtering** which allow for any possible query to the MongoDB server and can be extended and customize in the future. For more information about filtering, see the description of the `filter` down bellow. - **Non-standard PATCH behavior:** Sucessfull PATCH reuest always result in HTTP 200 response with the updated resource object, even if the server does not perform any additional modifications. HTTP 204 is never used in PATCH responses.  Attachment upload endpoint uses the **TUS protocol**. See [the documentation](https://tus.io/). When integrating, it is recommended to use one of the [official TUS client implementations](https://tus.io/implementations.html).  - **Supported TUS versions**:   -  1.0.0 - **Supported TUS extensions**:   - Creation   - Creation Defer Length   - Creation With Upload   - Checksum   - Checksum Trailer - **Required metadata**:   - `fileName` - name of the original file incl. file extension   - `contentType`- the MIME type of the file 
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttachmentArrayResponse,
    AttachmentArrayResponseFromJSON,
    AttachmentArrayResponseToJSON,
    AttachmentFieldsQuery,
    AttachmentFieldsQueryFromJSON,
    AttachmentFieldsQueryToJSON,
    AttachmentSingleResponse,
    AttachmentSingleResponseFromJSON,
    AttachmentSingleResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    FilterQuery,
    FilterQueryFromJSON,
    FilterQueryToJSON,
    FindingSingleResponse,
    FindingSingleResponseFromJSON,
    FindingSingleResponseToJSON,
    PageQuery,
    PageQueryFromJSON,
    PageQueryToJSON,
    RelationshipResourceIdentifierArrayResponse,
    RelationshipResourceIdentifierArrayResponseFromJSON,
    RelationshipResourceIdentifierArrayResponseToJSON,
} from '../models';

export interface ApiAttachmentsDeleteByIdRequest {
    tenantId: string;
    id: string;
}

export interface ApiAttachmentsGetRequest {
    tenantId: string;
    sort?: string;
    page?: PageQuery;
    fields?: AttachmentFieldsQuery;
    filter?: FilterQuery;
}

export interface ApiAttachmentsGetByIdRequest {
    tenantId: string;
    id: string;
    fields?: AttachmentFieldsQuery;
}

export interface ApiAttachmentsGetByIdDownloadRequest {
    tenantId: string;
    id: string;
}

export interface ApiAttachmentsGetByIdFindingsRequest {
    tenantId: string;
    id: string;
}

export interface ApiAttachmentsGetByIdRelationshipsFindingsRequest {
    tenantId: string;
    id: string;
}

export interface ApiAttachmentsGetByIdThumbnailRequest {
    tenantId: string;
    id: string;
}

/**
 * no description
 */
export class AttachmentsApi extends runtime.BaseAPI {

    /**
     * Delete a specific attachment, if exists
     */
    async apiAttachmentsDeleteByIdRaw(requestParameters: ApiAttachmentsDeleteByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiAttachmentsDeleteById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAttachmentsDeleteById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/attachments/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a specific attachment, if exists
     */
    async apiAttachmentsDeleteById(requestParameters: ApiAttachmentsDeleteByIdRequest): Promise<void> {
        await this.apiAttachmentsDeleteByIdRaw(requestParameters);
    }

    /**
     * Get paged list of attachments matching optional criteria
     */
    async apiAttachmentsGetRaw(requestParameters: ApiAttachmentsGetRequest): Promise<runtime.ApiResponse<AttachmentArrayResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiAttachmentsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.fields !== undefined) {
            queryParameters['fields'] = requestParameters.fields;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/attachments`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentArrayResponseFromJSON(jsonValue));
    }

    /**
     * Get paged list of attachments matching optional criteria
     */
    async apiAttachmentsGet(requestParameters: ApiAttachmentsGetRequest): Promise<AttachmentArrayResponse> {
        const response = await this.apiAttachmentsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a specific attachment, if exists
     */
    async apiAttachmentsGetByIdRaw(requestParameters: ApiAttachmentsGetByIdRequest): Promise<runtime.ApiResponse<AttachmentSingleResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiAttachmentsGetById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAttachmentsGetById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.fields !== undefined) {
            queryParameters['fields'] = requestParameters.fields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/attachments/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentSingleResponseFromJSON(jsonValue));
    }

    /**
     * Get a specific attachment, if exists
     */
    async apiAttachmentsGetById(requestParameters: ApiAttachmentsGetByIdRequest): Promise<AttachmentSingleResponse> {
        const response = await this.apiAttachmentsGetByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get raw data of a specific attachment, if present (attachment is NOT in incomplete state)
     */
    async apiAttachmentsGetByIdDownloadRaw(requestParameters: ApiAttachmentsGetByIdDownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiAttachmentsGetByIdDownload.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAttachmentsGetByIdDownload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/attachments/{id}/download`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get raw data of a specific attachment, if present (attachment is NOT in incomplete state)
     */
    async apiAttachmentsGetByIdDownload(requestParameters: ApiAttachmentsGetByIdDownloadRequest): Promise<Blob> {
        const response = await this.apiAttachmentsGetByIdDownloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get finding related to an attachment
     */
    async apiAttachmentsGetByIdFindingsRaw(requestParameters: ApiAttachmentsGetByIdFindingsRequest): Promise<runtime.ApiResponse<FindingSingleResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiAttachmentsGetByIdFindings.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAttachmentsGetByIdFindings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/attachments/{id}/finding`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindingSingleResponseFromJSON(jsonValue));
    }

    /**
     * Get finding related to an attachment
     */
    async apiAttachmentsGetByIdFindings(requestParameters: ApiAttachmentsGetByIdFindingsRequest): Promise<FindingSingleResponse> {
        const response = await this.apiAttachmentsGetByIdFindingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get resource identifiers for a given relationship of an attachment
     */
    async apiAttachmentsGetByIdRelationshipsFindingsRaw(requestParameters: ApiAttachmentsGetByIdRelationshipsFindingsRequest): Promise<runtime.ApiResponse<RelationshipResourceIdentifierArrayResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiAttachmentsGetByIdRelationshipsFindings.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAttachmentsGetByIdRelationshipsFindings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/attachments/{id}/relationships/finding`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RelationshipResourceIdentifierArrayResponseFromJSON(jsonValue));
    }

    /**
     * Get resource identifiers for a given relationship of an attachment
     */
    async apiAttachmentsGetByIdRelationshipsFindings(requestParameters: ApiAttachmentsGetByIdRelationshipsFindingsRequest): Promise<RelationshipResourceIdentifierArrayResponse> {
        const response = await this.apiAttachmentsGetByIdRelationshipsFindingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get raw thumbnail data of a specific attachment, if available (attachment is NOT in incomplete state)
     */
    async apiAttachmentsGetByIdThumbnailRaw(requestParameters: ApiAttachmentsGetByIdThumbnailRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiAttachmentsGetByIdThumbnail.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiAttachmentsGetByIdThumbnail.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/attachments/{id}/thumbnail`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get raw thumbnail data of a specific attachment, if available (attachment is NOT in incomplete state)
     */
    async apiAttachmentsGetByIdThumbnail(requestParameters: ApiAttachmentsGetByIdThumbnailRequest): Promise<Blob> {
        const response = await this.apiAttachmentsGetByIdThumbnailRaw(requestParameters);
        return await response.value();
    }

}
