import {FindingResourceObject} from "../../services/GeneratedApiTsClient";
import {Sort} from "../../models/SortModel";
import {
    CANCEL_REQUEST,
    CLEAR_CACHE,
    GO_TO_PAGE,
    RECEIVE_LISTING,
    REQUEST_LISTING, SET_SELECTED_FINDING_IDS,
    SET_PAGE_SIZE,
    SET_SORTING,
} from "../actions/ListingActions";

export type ListingReducerState = {
    loaded: boolean,
    requestInProgress: boolean,
    loadedFindings: Array<FindingResourceObject>,
    selectedFindingIds: string[],
    totalRecords: number,
    pagesCount: number,
    pageSize: number,
    currentPage: number,
    sorting: Array<Sort>,
}

export const ListingReducerInitialState: ListingReducerState = {
    loaded: false,
    requestInProgress: false,
    loadedFindings: [],
    selectedFindingIds: [],
    totalRecords: 0,
    pagesCount: 1,
    pageSize: 20,
    currentPage: 1,
    sorting: [],
};

export default (state: ListingReducerState = ListingReducerInitialState, action): ListingReducerState => {
    
    switch (action.type) {
        case GO_TO_PAGE:
            return {
                ...state,
                loaded: false,
                currentPage: action.page,
            };
        case SET_PAGE_SIZE:
            return {
                ...state,
                loaded: false,
                pageSize: action.pageSize,
            };
        case SET_SORTING:
            return {
                ...state,
                loaded: false,
                sorting: action.sorting,
            };
        case REQUEST_LISTING:
            return {
                ...state,
                loaded: false,
                requestInProgress: true
            };
        case RECEIVE_LISTING:
            return {
                ...state,
                loaded: true,
                requestInProgress: false,
                loadedFindings: action.loadedFindings,
                selectedFindingIds: state.selectedFindingIds            // remove findings which are not present in the newly-loaded findings collection
                    .filter(selectedId => action.loadedFindings
                        .map(f => f.id)
                        .includes(selectedId)
                    ),
                totalRecords: action.totalRecords,
                pagesCount: (action.totalRecords === 0) ? 1 : Math.ceil(action.totalRecords / state.pageSize)
            };
        case CANCEL_REQUEST:
            return {...state, requestInProgress: false};
        case CLEAR_CACHE:
            return {
                ...state,
                loaded: false,
                requestInProgress: false,
                loadedFindings: [],
                selectedFindingIds: [],
                totalRecords: 0,
                pagesCount: 1,
                currentPage: 1,
            };
        case SET_SELECTED_FINDING_IDS:
            return {...state, selectedFindingIds: action.payload};
        default:
            return state;
    }
};