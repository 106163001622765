import {push, replace} from 'connected-react-router'
import {AppState} from "../reducers";
import {ApiOnRejectedResult} from "../../services/ApiService/ApiService";
import {ErrorResponse, ErrorResponseItem} from "../../services/GeneratedApiTsClient";

export enum ErrorActions {
    SET_ERROR = 'SET_ERROR',
    RESET_STORE = 'RESET_STORE',
    RESET_STORE_AND_AUTH = 'RESET_STORE_AND_AUTH', 
}

export enum ErrorType { 
    RECOVERABLE_ERROR,
    FATAL_ERROR,
    NOT_AVAILABLE_ERROR
}

export const processApiResponse = (response: ApiOnRejectedResult, messagePrefix = "") => async (dispatch) => {

    // log error to console in development environment
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.error(response);
    }
    
    // handle fetch response
    if ("status" in response) {
        if (response.ok) return;
        
        var details = response.statusText;

        // use server-side messages, if present in the response
        var responseContent: ErrorResponse | any = await response.text().then(function(text) {
            return text ? JSON.parse(text) : {}
        });
        if ("errors" in responseContent){
            details = responseContent.errors.map((e: ErrorResponseItem) => e.title).join("; ")
        }

        if (response.status === 401) {
            // TODO: API response 401 handling
            // dispatch({type: AuthActions.LOGOUT});
        } else if (response.status === 404) {
            dispatch(push("/404"));
        } else if (response.status === 403) {
            dispatch({
                type: ErrorActions.SET_ERROR,
                error: {
                    type: ErrorType.NOT_AVAILABLE_ERROR,
                    message: messagePrefix,
                    details: details
                }
            });
        } else if (response.status === 409) {
            dispatch({
                type: ErrorActions.SET_ERROR,
                error: {
                    type: ErrorType.RECOVERABLE_ERROR,
                    message: messagePrefix,
                    details: details
                }
            });
        } else if (response.status >= 400) {    // other errors
            dispatch({
                type: ErrorActions.SET_ERROR,
                error: {
                    type: ErrorType.FATAL_ERROR,
                    message: messagePrefix,
                    details: details
                }
            });
        }
    // handle other errors
    } else {
        dispatch({
            type: ErrorActions.SET_ERROR,
            error: {
                type: ErrorType.RECOVERABLE_ERROR,
                message: messagePrefix,
                details: response.message,
            }
        });
    }
};

export const setError = (message = 'Unexpected error happened.', errorType: ErrorType = ErrorType.RECOVERABLE_ERROR, details: string|undefined = undefined) => (dispatch) => {
    dispatch({
        type: ErrorActions.SET_ERROR,
        error: {
            type: errorType,
            message: message,
            details: details,
        }
    });
};

export const resolveErrors = (clearCache = false) => async (dispatch, getState: () => AppState) => {
    const { error } = getState();

    if (clearCache){
        localStorage.clear();
    }
    
    if (error.errors.find(error => error.type === ErrorType.FATAL_ERROR)) {
        // hard-reload on if a fatal error occured
        await dispatch(replace('/'));
        window.location.reload();         
    } else {
        // soft-reload on non-fatal errors
        dispatch(replace('/'));     // redirect must precede resetting the store to prevent connected router from navigating back to the original path
        dispatch({
            type: (clearCache ? ErrorActions.RESET_STORE_AND_AUTH : ErrorActions.RESET_STORE),
        });
    }
};