import * as React from "react";

import {GoogleMap, InfoWindow, Marker, Polygon} from '@react-google-maps/api';
import connect from "react-redux/es/connect/connect";
import {AppState} from "../../../store/reducers";
import {requestMapFindings, setMapViewport} from "../../../store/actions/MapPanelActions";
import {FindingMapInfoResourceObject} from "../../../store/models/FindingMapInfo";
import {closeMapView} from "../../../store/actions/GUIActions";
import {LatLngGps} from "../../../services/GeneratedApiTsClient/models";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState} from "react";
import {getActiveTenantRoutePrefix} from "../../../store/selectors/TenantSelectors";
import _ from "lodash";
import SpinnerOverlay from "../../../components/Spinners/SpinnerOverlay";

const mapStateToProps = (state: AppState) => ({
    mapPanel: state.mapPanel,
    selectedFindingIds: state.listing.selectedFindingIds,
    globalFilters: state.filter.filters,
    routePrefix: getActiveTenantRoutePrefix(state)
});

const mapDispatchToProps = {
    setMapViewport,
    requestMapFindings,
    closeMapView,
};

type Props = ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps
    & {}

const containerStyle = {
    height: '100%',
    width: '100%'
};

interface MultiMarker {
    position: LatLngGps
    markers: Array<FindingMapInfoResourceObject>
}

const MapPanel = (props: Props) => {

    const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
    const [mapRefreshedOnce, setMapRefreshedOnce] = React.useState(false);

    const [infoWindowPosition, setInfoWindowPosition] = React.useState<google.maps.LatLng | null>(null);
    const [visibleInfoWindow, setVisibleInfoWindow] = React.useState<boolean>(false);

    const [selectedMultiMarker, setSelectedMultiMarker] = React.useState<MultiMarker | undefined>()

    const [markers, setMarkers] = React.useState<Array<MultiMarker>>([])

    React.useEffect(() => {
        if (mapRef) {
            const selectedFinding: FindingMapInfoResourceObject[] = props.mapPanel.markerData.filter((marker: FindingMapInfoResourceObject) => marker.id === props.selectedFindingIds[0]);
            let multiMarkers = _.groupBy(props.mapPanel.markerData, (marker: FindingMapInfoResourceObject) => {
                return [marker.attributes.locationGpsPoint?.lat, marker.attributes.locationGpsPoint?.lng]
            })
            setMarkers(Object.entries(multiMarkers).map((item) => ({
                position: positionFromString(item[0]),
                markers: item[1]
            } as MultiMarker)));
            if (selectedFinding.length > 1) {

            }

            if (selectedFinding[0] && selectedFinding[0].attributes && selectedFinding[0].attributes.locationGpsPoint) {
                const bounds = new google.maps.LatLngBounds();
                const userPos = new google.maps
                    .LatLng(selectedFinding[0].attributes.locationGpsPoint!.lat, selectedFinding[0].attributes.locationGpsPoint!.lng);

                // add user location and thre nearest stores in bounds
                bounds.extend(userPos);
                // take(corners, 3).forEach(({ latitude, longitude }) => {
                //     const cornerPos = new window.google.maps.LatLng(latitude, longitude);
                //     return bounds.extend(cornerPos);
                // });
                mapRef.panTo(selectedFinding[0].attributes.locationGpsPoint);
            }
        } else {
            console.log("mapRef is NULL");
        }
    }, [mapRef, props.mapPanel.markerData, props.selectedFindingIds]);

    // useEffect(() =>{
    //     if (!mapRef.current) return;
    //    
    //     mapRef.current.fitBounds(props.mapPanel.bounds);
    //     console.log("fit bounds");
    //    
    // }, [props.mapPanel.bounds]);

    const positionFromString = (pos: string): LatLngGps => {
        let gpsPos = pos.split(",")
        return {lat: parseFloat(gpsPos[0]), lng: parseFloat(gpsPos[1])}
    }
    const refreshMap = () => {

        if (mapRef) {
            let bounds = mapRef.getBounds();
            if (bounds) {
                props.setMapViewport(bounds.toJSON())
            }
            //mapRef.fitBounds(bounds)


        } else {
            console.log("mapRef is NULL");
        }

    };


    const refreshMapOnce = () => {

        if (mapRef && !mapRefreshedOnce) {
            refreshMap();
            setMapRefreshedOnce(true);
        }
    };


    const latLngCenter = (locationGpsArea: Array<LatLngGps>): LatLngGps => {

        let lats = locationGpsArea.map(c => c.lat);
        let lngs = locationGpsArea.map(c => c.lng);

        return {
            lat: lats.reduce((a, b) => a + b, 0) / lats.length,
            lng: lngs.reduce((a, b) => a + b, 0) / lngs.length,
        }
    };

    const onLoad = React.useCallback(function callback(mapRef: google.maps.Map) {
        mapRef.setCenter({lat: 48.979714, lng: 14.4497171});// todo: calculate map center and zoom based on all data
        mapRef.setZoom(4);
        setMapRef(mapRef);
    }, [])

    const onUnmount = React.useCallback(function callback(mapRef) {
        setMapRef(null)
    }, [])

    const handleMapElemClick = (e: google.maps.MapMouseEvent, finding: MultiMarker) => {
        setVisibleInfoWindow(true);
        setInfoWindowPosition(e.latLng);

        setSelectedMultiMarker(finding)
    }
    return (
        <div className="dynamic-panel">
            <Button outline color={"secondary"} onClick={props.closeMapView} className={"collapse-button"}>
                <FontAwesomeIcon icon={'times'}/> Close
            </Button>

            {props.mapPanel.requestInProgress && props.mapPanel.markerData.length === 0 &&
            <SpinnerOverlay/>}
            <GoogleMap
                id="map"
                zoom={4}
                options={{fullscreenControl: false}}
                mapContainerStyle={containerStyle}

                mapTypeId="terrain"
                onIdle={refreshMapOnce}
                onDragEnd={refreshMap}
                onZoomChanged={refreshMap}

                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                {markers.map((markerData: MultiMarker) =>

                    <div key={JSON.stringify(markerData.position)}>
                        <Marker
                            onClick={(e) => {
                                handleMapElemClick(e, markerData)
                            }}
                            position={markerData.position}
                            animation={markerData.markers.find((marker) => {
                                return props.selectedFindingIds.includes(marker.id)
                            }) && google.maps.Animation.BOUNCE}
                            zIndex={markerData.markers.find((marker) => {
                                return props.selectedFindingIds.includes(marker.id)
                            }) ? 99999 : undefined}
                        />
                        {markerData.markers.map(marker =>
                            <div key={marker.id}>
                                marker.location &&
                                <Polygon
                                    onClick={(e) => {
                                        handleMapElemClick(e, markerData)
                                    }}
                                    path={marker.attributes.locationGpsArea}
                                />
                            </div>
                        )}

                    </div>
                )}
                {visibleInfoWindow && infoWindowPosition &&
                <InfoWindow
                    onCloseClick={() => {
                        setVisibleInfoWindow(false)
                    }}
                    position={infoWindowPosition}>
                    <>
                        <ul>
                            {selectedMultiMarker?.markers.map((marker) =>
                                <li key={marker.id}>
                                    {(props.selectedFindingIds.includes(marker.id))?
                                        <p className="mb-0 font-weight-bold">{marker.attributes.documentName}</p>
                                        :
                                        <p className="mb-0">{marker.attributes.documentName}</p>
                                    }
                                    <a href={props.routePrefix + "/findings/detail/" + marker.id} target="_blank"
                                       rel="noopener noreferrer">Detail</a>
                                    <hr/>
                                </li>
                            )}
                        </ul>
                    </>
                </InfoWindow>
                }
            </GoogleMap>


        </div>

    );
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MapPanel));