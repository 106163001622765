import * as React from "react";

import {Tenant} from "../services/GeneratedApiTsClient";
import Avatar, {ReactAvatarProps} from "react-avatar";
import { UniCatAvatar } from "./UniCatAvatar";

export type Props = Pick<ReactAvatarProps, "size" | "onClick" | "className"> & {
    tenant: Tenant
}

export const TenantPersona = (props: Props) => {

    // TODO: remove ts-ignore after TS definitions fix in "react-avatar"
    // @ts-ignore
    const getRandomColor: (s:string) => string = Avatar.getRandomColor;

    return <UniCatAvatar name={props.tenant.name}
                         className={'rounded ' + props.className ?? ''}
                         size={props.size}
                         onClick={props.onClick}
                         color={getRandomColor(props.tenant.id)}
                         maxInitials={3}
                         style={props.onClick ? {cursor: 'pointer'} : {}}
    />
} 