import * as React from 'react';
import {connect} from 'react-redux';
import {Finding, FindingSchema, LatLngGps, TaxonomyName} from '../../services/GeneratedApiTsClient';
import {Button, Card, CardBody, Container, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ItemMinimap from '../../components/ItemMinimap/ItemMinimap';
import {WellKnownAttributes} from '../../models/FindingWellKnownAttributes';
import {DynamicFormRenderer} from '../../components/JQueryFormBuilder/DynamicFormRenderer';
import TagsInput from 'react-tagsinput';
import {AppState} from '../../store/reducers';
import {RouteComponentProps} from 'react-router';
import {
    persistFinding,
    replaceFindingProperty,
    requestEmptyWithSchema,
    requestFinding,
} from '../../store/actions/FindingActions';
import {setError} from '../../store/actions/ErrorActions';
import {DataCardTextInputElement} from '../../components/DataCardComponnents/DataCardTextInputElement';
import {DataCardInputElement} from '../../components/DataCardComponnents/DataCardInputElement';
import {CopyToClipboard} from '../../components/CopyToClipboard';
import {TaxonomyInput} from "./components/TaxonomyInput";
import {isValidFloatNumber} from "../../utils/validationHelper";
import {DataCardNumberInputElement} from "../../components/DataCardComponnents/DataCardNumberInputElement";
import {DataCardDateInputElement} from "../../components/DataCardComponnents/DataCardDateInputElement";
import moment from "moment";
import {getActiveTenantRoutePrefix} from "../../store/selectors/TenantSelectors";
import {goBack, push} from "connected-react-router";
import SpinnerOverlay from "../../components/Spinners/SpinnerOverlay";
import {NestedDataInput} from "./components/NestedDataInput";

const mapStateToProps = (state: AppState) => ({
    finding: state.finding,
    routePrefix: getActiveTenantRoutePrefix(state),
    tenantId: state.tenant.activeTenantId
});

const mapDispatchToProps = {
    requestFinding,
    requestEmptyWithSchema,
    replaceFindingProperty,
    persistFinding,
    setError,
    goBack,
    push
};

type State = {
    wasValidated: boolean;
};

type ComponentProps = {
    doClone?: boolean;
}
type Props = RouteComponentProps<{ id: string; schema: any }> &
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & ComponentProps;

const FindingEdit = (props: Props) => {
    /*
    public static defaultProps = {
        doClone: false,
    };
    */

    const formEl = React.createRef<HTMLFormElement>();

    const [wasValidate, setWasValidate] = React.useState<boolean>(false);

    React.useEffect(() => {
        if(props.tenantId) {
            if (props.match.params.id) {
                props.requestFinding(props.match.params.id, props.doClone);
            } else if (props.match.params.schema) {
                props.requestEmptyWithSchema(props.match.params.schema);
            }
        }
    }, [props.tenantId])


    const handleInputChange = event => {
        const target = event.target;

        let value = target.value;
        switch (target.type) {
            case 'checkbox':
                value = target.checked;
                break;
            case 'date':
                value = moment.utc(value, 'YYYY-MM-DD').startOf('day').toDate();
                break
        }
        const name = target.name;

        props.replaceFindingProperty(name, value);
    };

    const handleTaxonomyChange = (newTaxonomy: TaxonomyName) => {
        props.replaceFindingProperty('taxonomyName', newTaxonomy);
    }

    const handleCopyTaxonomyToScientificName = () => {

        let taxonomyName = props.finding.loadedFinding.taxonomyName;
        if (taxonomyName === undefined) return;

        let taxonomyKeys: Array<keyof TaxonomyName> = ["genus", "species"];

        let scientificName = taxonomyKeys.map(key => taxonomyName ? taxonomyName[key] : undefined).filter(value => value && value.length > 0).join(" ");

        if (taxonomyName.authorship && taxonomyName.authorship.length > 0) {
            scientificName += " (" + taxonomyName.authorship + ")";
        }

        props.replaceFindingProperty(WellKnownAttributes.taxonomyHumanReadable, scientificName);
    }

    const handleDynamicFormInputChange = data => {
        let patchedData = {...props.finding.loadedFinding.dynamicData, ...data};
        props.replaceFindingProperty('dynamicData', patchedData);
    };

    const handlePointChange = (point: LatLngGps | null) => {
        props.replaceFindingProperty('locationGpsPoint', point);
    };

    const handlePolygonChange = (polygon: Array<LatLngGps>) => {
        props.replaceFindingProperty('locationGpsArea', polygon);
    };

    const handleTagsChange = (tags, changed, changedIndexes) => {
        props.replaceFindingProperty('tags', tags);
    };

    const validate = () => {
        if (formEl && formEl.current) {
            const formLength = formEl.current.length;
            for (let i = 0; i < formLength; i++) {
                const elem: any = formEl.current[i];
                if (!elem.validity.valid) {
                    if (elem.type !== 'number' || !isValidFloatNumber(elem)) {
                        elem.scrollIntoView({behavior: 'smooth'});
                        elem.focus();
                        elem.classList.add('is-invalid')
                        return false;
                    }
                    elem.classList.add('is-valid')
                }
                elem.classList.add('is-valid')
            }
        }
        return true;
    };

    const onSubmit = e => {
        e.preventDefault();
        if (validate()) {
            props.persistFinding();
        } else {
            setWasValidate(true)
        }
    };
    const renderHeader = (loadedFindingId: any, loadedSchema: any) => (
        <>
            <div className="buttons-group-margin">
                <div className="d-flex justify-content-start">
                    <Button
                        outline
                        color="secondary"
                        onClick={() => {
                            props.goBack();
                        }}>
                        <FontAwesomeIcon icon="chevron-left"/>
                        Back
                    </Button>
                </div>
                <div className="d-flex justify-content-around flex-wrap mt-2 mt-md-0">
                    <Button color="primary" type="button" onClick={onSubmit}>
                        <FontAwesomeIcon icon="save"/>
                        Save
                    </Button>
                    <Button
                        outline
                        color="secondary"
                        className="button-back"
                        onClick={() => {
                            if (loadedFindingId) {
                                props.push(props.routePrefix + '/findings/detail/' + loadedFindingId);
                            } else {
                                props.goBack();
                            }
                        }}>
                        <FontAwesomeIcon icon="times"/>
                        Cancel
                    </Button>
                </div>
            </div>
            <h3 className="text-center pt-4">{loadedFindingId ? 'Edit Finding' : 'New Finding'}</h3>
            <h1 className="page-heading text-center pt-1 text-primary">{loadedSchema!.name} Schema</h1>
        </>
    );
    const renderGeneralInfoCard = (loadedFinding: Finding) => (
        <div className="data-card">
            <h2 className="data-card-heading">General information</h2>
            <Card className="d-flex flex-fill">
                <CardBody className="d-flex flex-row flex-wrap justify-content-between">
                    <DataCardTextInputElement
                        title="Name"
                        attributeName={WellKnownAttributes.documentName}
                        data={loadedFinding}
                        onInput={handleInputChange}
                        required={true}
                        alignColumn
                        gutterBottom
                        autoFocus={true}
                    />
                    <DataCardTextInputElement
                        title="Dataset"
                        attributeName={WellKnownAttributes.documentSet}
                        data={loadedFinding}
                        onInput={handleInputChange}
                        alignColumn
                        gutterBottom
                    />
                    <DataCardNumberInputElement
                        title="Amount"
                        attributeName={WellKnownAttributes.amount}
                        data={loadedFinding}
                        onInput={handleInputChange}
                        required={true}
                        min={1}
                        alignColumn
                        gutterBottom
                    />
                    <DataCardDateInputElement
                        title="Date"
                        attributeName={WellKnownAttributes.date}
                        data={loadedFinding}
                        onInput={handleInputChange}
                        alignColumn
                        gutterBottom
                    />

                    <DataCardInputElement title={'Tags'} alignColumn gutterBottom>
                        <TagsInput
                            value={loadedFinding.tags}
                            onChange={handleTagsChange}
                            onlyUnique={true}
                            removeKeys={[]}
                            tagProps={{
                                className: 'react-tagsinput-tag badge badge-primary',
                                classNameRemove: 'react-tagsinput-remove',
                            }}
                        />
                    </DataCardInputElement>
                </CardBody>
            </Card>
        </div>
    );
    const renderTaxonomyCard = (loadedFinding: Finding) => (
        <div className="data-card">
            <h2 className="data-card-heading">Taxonomy</h2>
            <Card className="d-flex flex-fill">
                <CardBody className="d-flex flex-row flex-wrap justify-content-between">

                    <TaxonomyInput
                        title="Structured taxonomy"
                        alignColumn
                        gutterBottom
                        taxonomyName={loadedFinding.taxonomyName}
                        onInput={handleTaxonomyChange}
                    />

                    <DataCardTextInputElement
                        title="Scientific name"
                        titleAfter={<Button size="sm" color="secondary" outline className="ml-3"
                                            onClick={handleCopyTaxonomyToScientificName}><FontAwesomeIcon
                            icon="clone"/>Copy from taxonomy</Button>}
                        attributeName={WellKnownAttributes.taxonomyHumanReadable}
                        data={loadedFinding}
                        onInput={handleInputChange}
                        alignColumn
                        gutterBottom
                    />
                </CardBody>
            </Card>
        </div>
    );
    const renderSchemaInfoCard = (schema: FindingSchema, finding) => {
        return (
            <>
            <div className="data-card">
                <h2 className="data-card-heading">
                    <span className="text-primary font-weight-bold">{schema.name.toUpperCase()}</span> Information
                </h2>
                <Card>
                    <CardBody>
                        <Row className="justify-content-between highlight-labels">
                            {schema.definitionGroups && schema.definitionGroups.map((group, index) => (
                                <div
                                    key={index}
                                    className={`${index > 1 && 'mt-4'} ${
                                        schema.definitionGroups!.length > 1 ? ' w-100-p w-49-lg-p' : 'w-100-p'
                                    }`}>
                                    <span className="schema-section-heading">{group.name}</span>
                                    <div className="schema-section-hr"/>
                                    <DynamicFormRenderer
                                        formKey={'tab-' + index}
                                        definitions={group.definitions!}
                                        data={finding.dynamicData!}
                                        onInput={handleDynamicFormInputChange}
                                    />
                                </div>
                            ))}

                            {schema.nestedDataDefinitions && schema.nestedDataDefinitions.map((nestedDefinition, index) => (
                                <NestedDataInput
                                    key={index}
                                    inputKey={index.toString()}
                                    label={nestedDefinition.label}
                                    definitions={nestedDefinition.definitions}
                                    data={finding.dynamicData[nestedDefinition.name] ?? []}
                                    onInput={(d) => {handleDynamicFormInputChange({
                                        [nestedDefinition.name]: d
                                    })}}
                                />
                            ))}
                        </Row>
                    </CardBody>
                </Card>
            </div>
            </>
        );
    };
    const renderMediaCard = (loadedFinding: Finding) => {
        return (
            <div className="data-card">
                <h2 className="data-card-heading">Media Information</h2>
                <Card>
                    <CardBody>
                        <DataCardTextInputElement
                            title="Note"
                            attributeName={WellKnownAttributes.attachmentNote}
                            data={loadedFinding}
                            onInput={handleInputChange}
                            alignColumn
                            gutterBottom
                        />
                    </CardBody>
                </Card>
            </div>
        );
    };

    const renderLocationCard = (loadedFinding: Finding) => {
        return (
            <div className="data-card">
                <h2 className="data-card-heading">Location Information</h2>
                <Card>
                    <CardBody className="d-flex flex-row flex-wrap justify-content-between">
                        <DataCardTextInputElement
                            title="Locality note"
                            attributeName={WellKnownAttributes.locationDescription}
                            data={loadedFinding}
                            onInput={handleInputChange}
                            alignColumn
                            gutterBottom
                        />
                        <DataCardInputElement title={'GPS Location'} alignColumn gutterBottom>
                            <div
                                className="form-text text-main d-flex flex-row align-items-center justify-content-between">
                                {loadedFinding.locationGpsPoint
                                    ? `${loadedFinding.locationGpsPoint.lat.toPrecision(8)}, ${loadedFinding.locationGpsPoint.lng.toPrecision(8)}`
                                    : ''}
                                <div>
                                    <CopyToClipboard
                                        textToCopy={
                                            loadedFinding.locationGpsPoint
                                                ? `${loadedFinding.locationGpsPoint.lat}, ${loadedFinding.locationGpsPoint.lng}`
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                        </DataCardInputElement>
                    </CardBody>
                </Card>
            </div>
        );
    };
    
    return (
        <div>
            {(!props.finding.loaded || props.finding.requestInProgress) ? <SpinnerOverlay/> :
                <div>
                    <Container className="finding-edit">
                        <form
                            ref={formEl}
                            className={'unicat-form'}
                            noValidate>
                            {renderHeader(props.finding.loadedFindingId, props.finding.loadedSchema)}
                            {renderGeneralInfoCard(props.finding.loadedFinding)}
                            {renderTaxonomyCard(props.finding.loadedFinding)}
                            {props.finding.loadedSchema && renderSchemaInfoCard(props.finding.loadedSchema, props.finding.loadedFinding)}
                            {renderMediaCard(props.finding.loadedFinding)}
                            {renderLocationCard(props.finding.loadedFinding)}
                        </form>
                    </Container>
                    <ItemMinimap
                        point={props.finding.loadedFinding.locationGpsPoint}
                        polygon={props.finding.loadedFinding.locationGpsArea}
                        markerColor={props.finding.loadedSchema ? props.finding.loadedSchema.color : undefined}
                        isEditable={true}
                        handlePointChange={handlePointChange}
                        handlePolygonChange={handlePolygonChange}
                        toolbarProps={'justify-content-end'}
                    />
                </div>
            }
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(FindingEdit as unknown as React.ComponentClass<ComponentProps>);
