import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {routerMiddleware} from 'connected-react-router';
import {AppState, history, rootReducer} from "./reducers";
import {FilterReducerInitialState} from "./reducers/FilterReducer";
import {FindingReducerInitialState} from "./reducers/FindingReducer";
import {ListingReducerInitialState} from "./reducers/ListingReducer";
import {FindingSchemasReducerInitialState} from "./reducers/FindingSchemasReducer";
import {getViewPreferencesReducerInitialState} from "./reducers/ViewPreferencesReducer";
import {MapPanelReducerInitialState} from "./reducers/MapPanelReducer";
import {ExportReducerInitialState} from "./reducers/ExportReducer";
import UserManager from "../services/UserManager";
import {loadUser} from "redux-oidc";
import {TenantReducerInitialState} from "./reducers/TenantReducer";
import {appInitializationMiddleware} from "./middleware/appInitializationMiddleware";

export default function configureStore() {
    

    const middleware = [
        thunk,
        routerMiddleware(history),
        appInitializationMiddleware
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION__) {
        // @ts-ignore
        enhancers.push((window as any).__REDUX_DEVTOOLS_EXTENSION__());
    }
    
    const rootState = {
        filter: FilterReducerInitialState,
        finding: FindingReducerInitialState,
        listing: ListingReducerInitialState,
        findingSchemas: FindingSchemasReducerInitialState,
        viewPreferences: getViewPreferencesReducerInitialState(),
        mapPanel: MapPanelReducerInitialState,
        export: ExportReducerInitialState,
        tenant: TenantReducerInitialState,
    };
    
    // load from data
    
    const store = createStore(
        rootReducer,
        rootState as AppState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    loadUser(store, UserManager);

    // if (process.env.NODE_ENV !== "production") {
    //     if (module.hot) {
    //         module.hot.accept('./reducers', () => {
    //             store.replaceReducer(rootReducer)
    //         })
    //     }
    // }

    return store
    
}