/**
 * Generate map icon with custom color.
 * 
 * @param baseIcon
 * @param newColor
 */
export const colorizeMarker = (baseIcon: google.maps.Symbol, newColor: string | null): google.maps.Symbol => {
    let tempIcon: google.maps.Symbol = Object.create(baseIcon);
    tempIcon.fillColor = newColor;
    return tempIcon;
};


/**
 * Generate map icon with custom opacity.
 * @param baseIcon
 * @param newOpacity Float from 0 to 1
 * @returns {*}
 */
export const fadeMarker = (baseIcon: google.maps.Symbol, newOpacity: number) => {
    let tempIcon: google.maps.Symbol = Object.create(baseIcon);
    tempIcon.fillOpacity = newOpacity;
    tempIcon.strokeOpacity = newOpacity;
    return tempIcon;
};