/**
 * Minimap for displaying and editing location data of a single item (finding)
 */
import * as React from 'react';
import {
    GoogleMap,
    InfoBox,
    Marker,
    Polygon,
    Polyline,
    StandaloneSearchBox,
} from '@react-google-maps/api';
import simplify from 'simplify-js';
import {Button, ButtonGroup, Container} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getCenterOfCoordsArray} from "../../utils/gpsUtils";
import {useEffect, useState} from "react";
import {LatLngGps} from "../../services/GeneratedApiTsClient";
import {colorizeMarker, fadeMarker} from "../../utils/mapIconUtils";
import {MapIconCollection} from "../../models/MapIconsCollection";

const containerStyle = {
    height: '80vh',
    width: '100%'
};

type Props = {
    point: LatLngGps | null,
    polygon: LatLngGps[],
    markerColor?: string,
    isEditable: boolean,
    toolbarProps: string,
    handlePointChange: (p: LatLngGps | null) => void,
    handlePolygonChange: (p: LatLngGps[]) => void,
}

const ItemMinimap= (props: Props) => {

    const [point, setPoint] = useState(props.point);
    const [polygon, setPolygon] = useState(props.polygon);
    const [markerColor, setMarkerColor] = useState(props.markerColor);
    const [originalPoint, setOriginalPoint] = useState(props.point);
    const [originalPolygon, setOriginalPolygon] = useState(props.polygon);
    const [intermediatePolygon, setIntermediatePolygon] = useState(props.polygon);
    const [drawingMode, setDrawingMode] = useState(false);
    const [polylineCapturing, setPolylineCapturing] = useState(false);

    const [path, setPath] = useState<google.maps.LatLng[]>([]);

    const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
    const [polygonComponent, setPolygonComponent] = useState<google.maps.Polygon | null>(null);

    const [googleMapSearchBox, setGoogleMapSearchBox] = useState<google.maps.places.SearchBox | null>(null);
    const [searchResultPoint, setSearchResultPoint] = useState<google.maps.LatLng | null>(null);


    /**
     * Update on external marker color change
     * @param nextProps
     */

    useEffect(() => {
        if (props.markerColor !== markerColor) {
            setMarkerColor(props.markerColor)
        }
    }, [markerColor, props.markerColor])

    const handlePointChange = props.handlePointChange;
    const handlePolygonChange = props.handlePolygonChange;

    const onMarkerDragEnd = (event) => {
        handlePointChange(event.latLng.toJSON());
        setPoint(event.latLng.toJSON());
    }

    const placePoint = () => {
        if (mapRef !== null) {
            const centerJson = mapRef.getCenter()?.toJSON();
            if (centerJson){
                handlePointChange(centerJson);
                setPoint(centerJson);
            }
        }
    }

    const removePoint = () => {
        handlePointChange(null);
        setPoint(null);
    }

    const resetPoint = () => {
        handlePointChange(originalPoint ?? null);
        setPoint(originalPoint);
    }

    const renderMarker = () => {
        return (
            <span>
        {point && (
            <Marker
                position={point}
                icon={colorizeMarker(MapIconCollection.pin, markerColor ?? null)}
                draggable={props.isEditable && !drawingMode}
                onDragEnd={onMarkerDragEnd}
                zIndex={100}
                options={{
                    clickable: !drawingMode,
                }}
            />
        )}

                {props.isEditable && originalPoint && (
                    <Marker
                        position={originalPoint}
                        icon={fadeMarker(colorizeMarker(MapIconCollection.pin, markerColor ?? null),0.5)}
                        zIndex={10}
                        options={{
                            clickable: false,
                        }}
                    />
                )}
      </span>
        );
    }


    /**
     * Handle mouse movement on the map to capture a freehand polyline drawing
     * @param e
     */
    const onMouseMoveOnMap = (e) => {
        if (!polylineCapturing) return;

        setPath(path => [...path, e.latLng]);
    }

    /**
     * Handle mouse clicks on the map to toggle freehand polyline capturing
     * @param e
     */
    const onMouseClickOnMap = (e) => {

        if (!drawingMode) return;
        if (mapRef) {
            if (polylineCapturing) {
                // deactivate hand-drawn polyline capturing and process the data

                let polylineForSimplification: { x, y }[] = []; // prepare temp object with a correct key format
                path.forEach(polylineElement => {
                    polylineForSimplification.push({
                        x: polylineElement.lat(),
                        y: polylineElement.lng(),
                    });
                });

                setPath([])

                // calculate tolerance to compensate for different scale (hardcoded value, based on map zoom)
                let zoom = mapRef?.getZoom();
                let tolerance = Math.pow(10, -((zoom === undefined) ? 1 : zoom / 4));

                // perform symplification
                let simplifiedPolyline = simplify(polylineForSimplification, tolerance, true);

                let newPolygon: google.maps.LatLngLiteral[] = []; // prepare new polygon

                simplifiedPolyline.forEach(polylineElement => {
                    newPolygon.push({
                        lat: polylineElement.x,
                        lng: polylineElement.y,
                    });
                });

                handlePolygonChange(newPolygon);

                setPolygon(newPolygon);
                setIntermediatePolygon(newPolygon);
                setPolylineCapturing(false);
                setDrawingMode(false);
            } else {
                // activate hand-drawn polyline capturing
                setPolylineCapturing(true);
            }
        } else {
            console.log("mapRef is null");
        }
    }

    /**
     * Toggle polygon redrawing tool's state
     */
    const redrawPolygon = () => {
        if (drawingMode) {
            // deactivate drawing mode

            setPath(path => [])

            setPolylineCapturing(false);
            setDrawingMode(false);
        } else {
            // activate drawing mode
            setDrawingMode(true);
            setPolylineCapturing(false);
        }
    }


    const onEditPolygon = () => {
        if (polygonComponent) {
            const newPolygon: LatLngGps[] = [];

            polygonComponent.getPath().forEach(polygonElement => {
                newPolygon.push({
                    lat: polygonElement.lat,
                    lng: polygonElement.lng
                });
            });

            handlePolygonChange(newPolygon);
            setPolygon(newPolygon);
        } else {
            console.log("polygonComponent is null");
        }
    }
    /**
     * Clear polygon data
     */
    const removePolygon = () => {
        handlePolygonChange([]);

        setPolygon([]);
        setIntermediatePolygon([]);
    }

    /**
     * Revert polygon back to the original state
     */

    const resetPolygon = () => {
        if (polygonComponent) {
            handlePolygonChange(originalPolygon);

            setPolygon(originalPolygon);
            setIntermediatePolygon(originalPolygon);

            polygonComponent?.setPath(originalPolygon);
        } else {
            console.log("polygonComponent is null");
        }
    }

    const renderPolygon = () => {
        return (
            <span>
        {intermediatePolygon.length > 0 && (
            <Polygon
                onLoad={onLoadPolygonComponent}
                path={intermediatePolygon} // intermediatePolygon used instead of this.state.polygon to prevent unwanted triggering of props change which defeats google in-map undo button
                editable={props.isEditable && !drawingMode}
                draggable={props.isEditable}
                options={{
                    strokeColor: markerColor,
                    fillColor: markerColor,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillOpacity: 0.6,
                    clickable: !drawingMode,
                    zIndex: 9,
                }}
                onMouseUp={onEditPolygon}
            />
        )}

                {props.isEditable && (
                    <Polygon
                        path={originalPolygon}
                        options={{
                            strokeColor: markerColor,
                            fillColor: markerColor,
                            strokeOpacity: 0.5,
                            strokeWeight: 2,
                            fillOpacity: 0.3,
                            clickable: false,
                            zIndex: 9,
                        }}
                    />
                )}
      </span>
        );
    }


    const getDefaultCenter = React.useCallback( () => {
        const compoundPoints = point ? [...polygon, point] : polygon;
        return getCenterOfCoordsArray(compoundPoints);
    }, [point, polygon])

    const onLoadPolygonComponent = (polygonComponent: google.maps.Polygon) => {
        setPolygonComponent(polygonComponent);
    }

    const onLoadMap = React.useCallback(function callback(mapRef) {
        // TODO: default zoom is not optimal, use bounding box to calculate appropriate value and center
        mapRef.setCenter(getDefaultCenter());
        mapRef.setZoom(originalPoint && originalPolygon.length < 1 ? 1 : 4);
        setMapRef(mapRef)
    }, [getDefaultCenter, originalPoint, originalPolygon.length])
    const onUnmountMap = React.useCallback(function callback(mapRef) {
        setMapRef(null)
    }, [])

    const onPlacesChange = () => {
        if (mapRef) {
            const places = googleMapSearchBox?.getPlaces();
            const bounds = new window.google.maps.LatLngBounds();

            places?.forEach(place => {
                if (place?.geometry?.viewport) {
                    bounds.union(place.geometry.viewport)
                } else if (place?.geometry?.location) {
                    bounds.extend(place.geometry.location)
                }
            });

            const nextCenter = bounds.getCenter();

            mapRef?.panTo(nextCenter);
            mapRef?.fitBounds(bounds);

            setSearchResultPoint(nextCenter);
        } else {
            console.log("mapRef is null")
        }
    }

    const onSearchResultMarkerClick = () => {
        if (searchResultPoint){
            const newPoint: LatLngGps = {
                lat: searchResultPoint.lat(),
                lng: searchResultPoint.lng()
            };
    
            handlePointChange(newPoint);
            setPoint(newPoint);
            setSearchResultPoint(null);
        }
    }

    const onLoadSearchBox = (searchBoxRef: google.maps.places.SearchBox) => {
        setGoogleMapSearchBox(searchBoxRef);
    }

    return (
        <div className={'itemMinimap' + (props.isEditable ? ' editable' : '')}>
            {props.isEditable && (
                <Container>
                    <div className={`toolbar ${props.toolbarProps}`}>
                        <div className="toolbar-group">
                            <label className="link">
                                {' '}
                                <FontAwesomeIcon className="text-primary" icon="map-marker"/> Pin location
                            </label>

                            <ButtonGroup className="buttons-width">
                                <Button onClick={placePoint} color={'primary'}>
                                    Place
                                </Button>
                                <Button onClick={resetPoint} color={'secondary'}>
                                    Reset
                                </Button>
                                <Button onClick={removePoint} color={'danger'}>
                                    Remove
                                </Button>
                            </ButtonGroup>
                        </div>

                        <div className="toolbar-group">
                            <label>
                                {' '}
                                <FontAwesomeIcon className="text-primary" icon="pen"/> <span
                                className={drawingMode ? "font-weight-bold" : ''}>Draw location boundaries</span>
                            </label>

                            <ButtonGroup className="buttons-width">
                                <Button
                                    onClick={redrawPolygon}
                                    active={drawingMode}
                                    color={drawingMode ? 'primary' : 'primary'}>
                                    Draw
                                </Button>
                                <Button onClick={resetPolygon} color={'secondary'}>
                                    Reset
                                </Button>
                                <Button onClick={removePolygon} disabled={polygon.length <= 0}
                                        color={'danger'}>
                                    Remove
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </Container>
            )}
            <GoogleMap
                onLoad={onLoadMap}
                onUnmount={onUnmountMap}
                mapContainerStyle={containerStyle}
                options={{fullscreenControl: false}}
                mapTypeId="terrain"
                onMouseMove={onMouseMoveOnMap}
                onClick={onMouseClickOnMap}
            >
                {renderMarker()}
                {renderPolygon()}

                {props.isEditable && <StandaloneSearchBox
                    onLoad={onLoadSearchBox}
                    //controlPosition={2}
                    onPlacesChanged={onPlacesChange}
                >
                    <input
                        type="text"
                        placeholder="Search (phrase or coordinates)"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `340px`,
                            height: `40px`,
                            top: `70px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `16px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            position: "absolute",
                            left: "50%",
                            marginLeft: "-170px"
                        }}
                    />
                </StandaloneSearchBox>}

                {searchResultPoint && <>
                    <Marker
                        position={searchResultPoint}
                        icon={colorizeMarker(MapIconCollection.pinWithDot, markerColor ?? null)}
                        onClick={onSearchResultMarkerClick}
                        zIndex={10000}
                        options={{
                            clickable: true,
                            animation: 1 /*Animation.BOUNCE*/
                        }}
                    />
                    <InfoBox
                        position={searchResultPoint}
                        options={{closeBoxURL: ``, enableEventPropagation: true}}
                    >
                        <div
                            style={{backgroundColor: `yellow`, opacity: 0.75, padding: `5px`, borderRadius: `3px`}}>
                            <div style={{fontSize: `10px`, color: `#08233B`}}>
                                Click the pin to set position here
                            </div>
                        </div>
                    </InfoBox>
                </>}
                <Polyline
                    options={{
                        clickable: false, // this is important to prevent colisions with onMouseClickOnMap
                        zIndex: 1000
                    }}
                    path={path}
                />
            </GoogleMap>
        </div>
    );

}

ItemMinimap.defaultProps = {
    point: null,
    polygon: [],
    markerColor: '#2959e0',
    isEditable: false,
    toolbarProps: '',
    handlePointChange: p => console.error('Implement handlePointChange!'),
    handlePolygonChange: p => console.error('Implement handlePolygonChange!'),
};
export default React.memo(ItemMinimap);