import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../store/reducers';
import {Invitation} from "../../services/GeneratedApiTsClient";
import {getConfiguredTenantsApi} from "../../store/selectors/ApiSelectors";
import {TenantPersona} from "../../components/TenantPersona";
import {TenantUserPersona} from "../../components/TenantUserPersona";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from "reactstrap";
import {TenantInviteeRoleNames} from "../../store/models/TenantInviteeRoleNames";
import {processApiResponse} from "../../store/actions/ErrorActions";
import {ApiOnRejectedResult} from "../../services/ApiService/ApiService";
import {push} from "connected-react-router";
import {generatePath} from "react-router";
import {TENANT_ROUTE_PATH} from "../../App";
import {requestAvailableTenants} from "../../store/actions/TenantActions";
import SpinnerOverlay from "../../components/Spinners/SpinnerOverlay";

const mapStateToProps = (state: AppState, props: TenantInvitationProps) => ({
    tenantsApi: getConfiguredTenantsApi(state),
    userOfSelf: {
        fullName: state.oidc.user?.profile.name ?? '',
        firstName: state.oidc.user?.profile.given_name,
        lastName: state.oidc.user?.profile.family_name,
        pictureUrl: state.oidc.user?.profile.picture,
        idmSubjectId: state.oidc.user?.profile.sub ?? ''
    },
});

const mapDispatchToProps = {
    push,
    requestAvailableTenants
};

type TenantInvitationProps = {
    tenantId: string,
    invitationKey: string
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & TenantInvitationProps;

const TenantInvitation = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [invitation, setInvitation] = useState<Invitation | null | false>(null);

    useEffect(() => {
        setLoading(true);
        setInvitation(null);

        props.tenantsApi.apiTenantsGetByIdInvitationsByKey({tenantId: props.tenantId, key: props.invitationKey})
            .then((result) => {
                setInvitation(result);
                setLoading(false);
            }, () => {
                setInvitation(null);
                setLoading(false);
            });

    }, [props.tenantId, props.tenantsApi, props.invitationKey]);

    const accept = () => {
        if (!invitation || !invitation.tenant) return;

        setLoading(true);

        props.tenantsApi.apiTenantsGetByIdInvitationsByKeyRedeem({tenantId: props.tenantId, key: props.invitationKey})
            .then(() => {
                setLoading(false);
                props.requestAvailableTenants()
                props.push(generatePath(TENANT_ROUTE_PATH, {tenantId: invitation.tenant.id}));
            }, (error: ApiOnRejectedResult) => {
                if ("status" in error && error.status === 409) {
                    setLoading(false);
                    setInvitation(false);
                } else {
                    processApiResponse(error, 'Error accepting invitation');
                }
            });
    }


    return (
        <div className="text-center">
            {loading && <SpinnerOverlay/>}

            <h2 className="mt-5 mb-4">
                Workspace invitation
            </h2>

            {(invitation) && <>

                <div className="d-flex justify-content-center align-items-center">
                    <TenantUserPersona user={invitation.inviter} size={'50'}/>
                    <FontAwesomeIcon icon='plus' className={'text-muted mx-3'}/>
                    <TenantUserPersona user={props.userOfSelf} size={'50'}/>
                    <FontAwesomeIcon icon='arrow-right' className={'text-muted mx-3'}/>
                    <TenantPersona tenant={invitation.tenant} size={'50'}/>
                </div>

                <p className={'my-4'}>User <strong>{invitation.inviter.fullName}</strong> has invited You to join them the
                    workspace <strong>{invitation.tenant.name}</strong> as <strong>{TenantInviteeRoleNames.find(x => x.value === invitation.inviteeRole)?.label}</strong>.
                </p>

                <Button color={'primary'} className={'mt-2'} onClick={accept}>Accept invitation</Button>

            </>}

            {(invitation === null) && <>
                <p>Invitation link has expired. Please ask the workspace owner to issue a new invitation for you.</p>
            </>}

            {(invitation === false) && <>
                <p>You already have access to the workspace.</p>
                <Button color={'primary'} className={'mt-2'}
                        onClick={() => props.push(generatePath(TENANT_ROUTE_PATH, {tenantId: props.tenantId}))}>Open</Button>
            </>}

        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantInvitation as any);
