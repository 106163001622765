import * as React from "react";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import ApiService from "../../../services/ApiService/ApiService";
import {AppState} from "../../../store/reducers";
import connect from "react-redux/es/connect/connect";
import {deleteSchema} from "../../../store/actions/SchemaActions";
import {showNotification} from "../../../store/actions/GUIActions";
import {ModalConfirm} from "../../../components/ModalConfirm";
import {getConfiguredSchemasApi} from "../../../store/selectors/ApiSelectors";
import {selectActiveTenantId} from "../../../store/selectors/TenantSelectors";
import {isTenantUserInRole} from "../../../store/selectors/UserSelectors";
import {TenantUserRole} from "../../../services/GeneratedApiTsClient/models";
import SpinnerInline from "../../../components/Spinners/SpinnerInline";

const mapStateToProps = (state: AppState) => ({
    schemasApi: getConfiguredSchemasApi(state),
    tenantId: selectActiveTenantId(state),
    canEdit: isTenantUserInRole(state, {role: [TenantUserRole.OWNER, TenantUserRole.COLLABORATOR]})
});

const mapDispatchToProps = {
    deleteSchema
};

type Props = ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps
    & {
    id: string
}

const SchemaDeleteButton = (props: Props) => {

    const [loaded, setLoaded] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [visibleModal, setVisibleModal] = useState(false);

    const toggleVisibleModal = () => setVisibleModal(!visibleModal);

    useEffect(() => {
        setLoaded(false);
        setDisabled(true);

        ApiService.hasSchemaDependencies(props.tenantId, props.schemasApi, props.id)
            .then((result) => {

                setLoaded(true);
                setDisabled(result);
            });

    }, [props.id, props.schemasApi, props.tenantId]);

    return (
        <Button color={"danger"} className={!disabled && props.canEdit ?"": "disabled"} onClick={() => {
            if (disabled)
                return showNotification({
                    message: `Cannot delete schema with dependencies`,
                    type: "error",
                    doNotAutoClose: false
                });
            else if( !props.canEdit){
                return showNotification({
                    message: `You don't have sufficient permissions`,
                    type: "error",
                    doNotAutoClose: false
                });
            }
            toggleVisibleModal();
        }}>
            {loaded ? <FontAwesomeIcon icon={'trash-alt'}/> : <SpinnerInline className={'spinner-inverse'}/>}
            <span>Delete</span>
            <ModalConfirm visible={visibleModal} titleText={"Delete"} bodyText={"Are you sure?"}
                          btnConfirmLabel={"Delete"} btnCancelLabel={"Cancel"} onClickConfirm={() => {
                props.deleteSchema(props.id);
                toggleVisibleModal();
            }} onClickCancel={toggleVisibleModal}/>
        </Button>
    );

};

export default connect(mapStateToProps, mapDispatchToProps)(SchemaDeleteButton);