import * as React from 'react';
import {FindingSchemaElement} from "../../../services/GeneratedApiTsClient";
import {DynamicFormRenderer} from "../../../components/JQueryFormBuilder/DynamicFormRenderer";
import {Button, Card, CardBody} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type DynamicDataArray = Array<{ [key: string]: object; }>;

type Props = {
    inputKey: string,
    label: string
    definitions: Array<FindingSchemaElement>;
    data: DynamicDataArray;
    onInput: (DynamicDataArray) => void;
};

export const NestedDataInput = (props: Props) => {

    /**
     * Bubble-up updates in data
     * @param data
     * @param index
     */
    const handleDynamicFormInput = (data, index) => {
        let newData = [...props.data];
        newData[index] = data;

        props.onInput(newData);
    }

    /**
     * Add new empty data entry, to the beginning
     */
    const add = () => {
        let newData = [...props.data];
        newData.unshift({});

        props.onInput(newData);
    }

    /**
     * Remove data entry on given index
     * @param index
     */
    const remove = (index) => {
        let newData = [...props.data];
        newData.splice(index, 1);

        props.onInput(newData);
    }

    return (
        <div className={'mt-4 w-100-p w-49-lg-p'}>
            <span className="schema-section-heading">
                <FontAwesomeIcon icon="pencil-ruler"/> {props.label}
            </span>
            <div className="schema-section-hr"/>
            <Button color="primary" onClick={add} className={'mb-2'}>
                <FontAwesomeIcon icon="plus"/>
                Add entry
            </Button>
            
            {props.data.map((d, idx) =>
                <Card
                    // force re-render on data array shape change, needed to properly reflect removal and addition of elements
                    key={idx + '#' + props.data.length}
                    className={'mb-4'}>
                    <CardBody>
                        {/*reverse ordering - most recent with lowest index*/}
                        <h4>#{props.data.length - idx}</h4>
                        <DynamicFormRenderer
                            formKey={'nested-' + props.inputKey + '-' + idx}
                            definitions={props.definitions}
                            data={d}
                            onInput={(newData) => handleDynamicFormInput(newData, idx)}
                        />
                        <Button color="link"
                                className="btn-hover-light-link dark"
                                onClick={() => remove(idx)}
                        >
                            <FontAwesomeIcon icon="trash-alt" className="text-danger"/>
                            Remove entry
                        </Button>
                    </CardBody>
                </Card>
            )}
        </div>
    );
}