import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


type Props = {
    name: string,
    value: string | null | undefined,
    onClick: () => void
};

export const FindingTaxonomyElement = (props: Props) => {
    
    if (!props.value) return <></>;
    return (
        <div className={"text-center d-inline-block mx-2"}>
            <em className="d-block small text-muted">{props.name}</em>
            <UncontrolledButtonDropdown>
                <DropdownToggle size="sm" color="">
                    {props.value}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={props.onClick}>
                        <FontAwesomeIcon icon="search" className="mr-2"/>Related Findings
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </div>
    );
}