import * as React from 'react';
import {Button, Card, CardBody, CardText, CardTitle} from "reactstrap";
import {useDispatch} from "react-redux";
import {replace} from "connected-react-router";

type Props = {
    onResolveError?: () => void
}

export const NotAvailableScreen = (props: Props) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        if (props.onResolveError) props.onResolveError();
        dispatch(replace('/'))
    };

    return <div className="p-5">
        <div className="d-flex flex-fill justify-content-center align-content-center">
            <Card className="mw-50 md- mt-6">
                <CardBody className='text-center'>
                    <CardTitle className="h2 mb-3">Not available</CardTitle>
                    <CardText className="mb-5">Requested page or resource is not available to you.</CardText>
                    <div className="d-flex justify-content-center align-content-center mb-3">
                        <Button onClick={handleClick} color="primary"
                                block={true} className="p-3 w-75">
                            Back to homepage
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    </div>
};

export default NotAvailableScreen;