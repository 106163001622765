import {connect} from 'react-redux';
import {AppState} from '../../store/reducers';
import * as React from 'react';
import {requestListing, setSorting} from '../../store/actions/ListingActions';
import {Jumbotron} from 'reactstrap';
import ListView from './components/ListView';
import FindingsPagination from './containers/FindingsPagination';
import FindingsToolbar from './containers/FindingsToolbar';
import PreviewCards from "./containers/PreviewCards";
import SpinnerOverlay from "../../components/Spinners/SpinnerOverlay";

const mapStateToProps = (state: AppState) => ({
    listing: state.listing,
    tenantId: state.tenant.activeTenantId
});

const mapDispatchToProps = {
    requestListing: requestListing,
    setSorting: setSorting,
};

type FindingsTableProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const FindingsTableDry = (props: FindingsTableProps) => {
    
    React.useEffect(()=>{
        if(props.tenantId){
            props.requestListing();
        }
    }, [props.tenantId])

    return (
            <div className={'findings-grid'}>
                <div className="grid-first-row">
                    <PreviewCards/>
                </div>
                <FindingsToolbar/>
                <div className="finding-view-body spinner-overlay-container">
                    {!props.listing.loaded && <SpinnerOverlay/>}
                    {props.listing.loadedFindings != null && props.listing.loadedFindings.length > 0 ? (
                        <ListView findings={props.listing.loadedFindings} onSortingChange={props.setSorting}/>
                    ) : (
                        <Jumbotron className="text-center rounded-0">
                            <h4 className="text-muted">
                                <i>No results</i>
                            </h4>
                        </Jumbotron>
                    )}
                </div>
                <div className="grid-paging bordered">
                    <FindingsPagination/>
                </div>
            </div>
    )
}

export const FindingsTable = connect(mapStateToProps, mapDispatchToProps)(FindingsTableDry as any);
