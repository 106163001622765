import * as React from "react";
import {
    Button,
    Form,
    FormGroup,
    FormText, Input,
    Modal,
    ModalBody,
    ModalHeader
} from 'reactstrap';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {closeInvitationModal} from "../../store/actions/GUIActions";
import {CopyToClipboard} from "../CopyToClipboard";
import {AppState} from "../../store/reducers";
import {generateAbsolutePath} from "../../utils/absoluteUrl";
import {TenantInviteeRoleNames} from "../../store/models/TenantInviteeRoleNames";
import {Invitation} from "../../services/GeneratedApiTsClient";
import SpinnerRibbon from "../Spinners/SpinnerRibbon";

const mapStateToProps = (state: AppState) => ({
    isOpen: state.gui.invitationModalActive,
    isLoading: state.gui.invitationModalLoading,
    invitation: state.gui.inviteModalInvitation
});

const mapDispatchToProps = {
    closeModal: closeInvitationModal
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};

const InvitationModal = (props: Props) => {

    const invitationLink = props.invitation ? generateAbsolutePath('/workspaces/invitation/:id/:key', {
        id: props.invitation.tenant.id,
        key: props.invitation.key
    }) : undefined

    const sendMail = (invitation?: Invitation) => {
        if (!invitation) return;

        const subject = `Invitation to join ${invitation.tenant.name} at UniCatDB`;
        const body = `Hello!\n\nJoin me in UniCatDB workspace ${invitation.tenant.name} as `+
            `${TenantInviteeRoleNames.find(x => x.value === props.invitation?.inviteeRole)?.label} using this link: \n\n`+
            invitationLink+
            `\n\nPlease note this link is for one-time-use only and it will expire at ${invitation.expiresAt.toISOString()}`;

        window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    }

    return (
        <Modal isOpen={props.isOpen} onClosed={props.closeModal} size={"lg"} className={'invitationModal'}>
            <ModalHeader toggle={props.closeModal}>
                <FontAwesomeIcon icon="user-plus" className={"mr-2"}/>
                New invitation
            </ModalHeader>
            <ModalBody>
                {props.isLoading && <SpinnerRibbon className={'my-5'}/>}

                {props.invitation && <>
                    <p>
                        Here's a new invitation link for someone to join
                        workspace <strong>{props.invitation.tenant.name}</strong> as <strong>{TenantInviteeRoleNames.find(x => x.value === props.invitation?.inviteeRole)?.label}</strong>:
                    </p>

                    <Form>
                        <FormGroup>

                            <Input readOnly={true} value={invitationLink}/>

                            <FormText color="muted">
                                <Button outline color="secondary" size={'sm'} className={"float-right ml-2"}
                                        onClick={() => sendMail(props.invitation)}>
                                    <FontAwesomeIcon icon="envelope"/>
                                    Send by email
                                </Button>

                                <CopyToClipboard
                                    textToCopy={invitationLink}
                                    className={"float-right"}/>

                            </FormText>
                        </FormGroup>
                    </Form>

                    <p>
                        <strong>Please note:</strong> This invitation link expires
                        at <strong>{props.invitation.expiresAt.toISOString()}</strong> and is intendet for single person
                        only: once redeemd, it will no longer work. Shoud you like to invite additional people, create a
                        separate invitations for all of them.
                    </p>

                </>}


            </ModalBody>
        </Modal>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitationModal as any);
    
