/* tslint:disable */
/* eslint-disable */
/**
 * UniCatDB API
 * UniCatDB application API documentation, with examples and live testing.  This API is built in accordance with the **JSON API 1.0 standard**. For general information, see [the documentation](http://jsonapi.org/format/).  -  JSON API standard requires use of the JSON API media type (application/vnd.api+json) for exchanging data. Clients must send all JSON API data with the headers `Content-Type: application/vnd.api+json` (POST, PATCH) and `Accept: application/vnd.api+json` (GET, DELETE). - [Relationships](http://jsonapi.org/format/#fetching-relationships) and their [inclusions](http://jsonapi.org/format/#fetching-includes) via the `include` query parameter, as specified by the standard, **are not implemented**, since there are no relationships present in the data model. - The standard does not prescribe any filtering strategies. This API implements two strategies which can be combined: **Basic filtering** based on the used [JSON API library](https://json-api-dotnet.github.io/#/filtering) and **Custom filtering** which allow for any possible query to the MongoDB server and can be extended and customize in the future. For more information about filtering, see the description of the `filter` down bellow. - **Non-standard PATCH behavior:** Sucessfull PATCH reuest always result in HTTP 200 response with the updated resource object, even if the server does not perform any additional modifications. HTTP 204 is never used in PATCH responses.  Attachment upload endpoint uses the **TUS protocol**. See [the documentation](https://tus.io/). When integrating, it is recommended to use one of the [official TUS client implementations](https://tus.io/implementations.html).  - **Supported TUS versions**:   -  1.0.0 - **Supported TUS extensions**:   - Creation   - Creation Defer Length   - Creation With Upload   - Checksum   - Checksum Trailer - **Required metadata**:   - `fileName` - name of the original file incl. file extension   - `contentType`- the MIME type of the file 
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AttachmentArrayResponse,
    AttachmentArrayResponseFromJSON,
    AttachmentArrayResponseToJSON,
    AttachmentPutResponse,
    AttachmentPutResponseFromJSON,
    AttachmentPutResponseToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ExistingFindingRequestBody,
    ExistingFindingRequestBodyFromJSON,
    ExistingFindingRequestBodyToJSON,
    FilterQuery,
    FilterQueryFromJSON,
    FilterQueryToJSON,
    FindingArrayResponse,
    FindingArrayResponseFromJSON,
    FindingArrayResponseToJSON,
    FindingFieldsQuery,
    FindingFieldsQueryFromJSON,
    FindingFieldsQueryToJSON,
    FindingSchemaSingleResponse,
    FindingSchemaSingleResponseFromJSON,
    FindingSchemaSingleResponseToJSON,
    FindingSingleResponse,
    FindingSingleResponseFromJSON,
    FindingSingleResponseToJSON,
    NewFindingRequestBody,
    NewFindingRequestBodyFromJSON,
    NewFindingRequestBodyToJSON,
    PageQuery,
    PageQueryFromJSON,
    PageQueryToJSON,
    RelationshipResourceIdentifierResponse,
    RelationshipResourceIdentifierResponseFromJSON,
    RelationshipResourceIdentifierResponseToJSON,
} from '../models';

export interface ApiFindingsDeleteByIdRequest {
    tenantId: string;
    id: string;
}

export interface ApiFindingsGetRequest {
    tenantId: string;
    sort?: string;
    page?: PageQuery;
    fields?: FindingFieldsQuery;
    filter?: FilterQuery;
}

export interface ApiFindingsGetByIdRequest {
    tenantId: string;
    id: string;
    fields?: FindingFieldsQuery;
}

export interface ApiFindingsGetByIdAttachmentsRequest {
    tenantId: string;
    id: string;
}

export interface ApiFindingsGetByIdRelationshipAttachmentsRequest {
    tenantId: string;
    id: string;
}

export interface ApiFindingsGetByIdRelationshipSchemaRequest {
    tenantId: string;
    id: string;
}

export interface ApiFindingsGetByIdSchemaRequest {
    tenantId: string;
    id: string;
}

export interface ApiFindingsPatchByIdRequest {
    tenantId: string;
    id: string;
    existingFindingRequestBody?: ExistingFindingRequestBody;
}

export interface ApiFindingsPostRequest {
    tenantId: string;
    newFindingRequestBody?: NewFindingRequestBody;
}

export interface ApiFindingsPutByIdAttachmentsRequest {
    tenantId: string;
    id: string;
    file?: Blob;
}

/**
 * no description
 */
export class FindingsApi extends runtime.BaseAPI {

    /**
     * Delete a specific finding, if exists
     */
    async apiFindingsDeleteByIdRaw(requestParameters: ApiFindingsDeleteByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiFindingsDeleteById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFindingsDeleteById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/findings/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a specific finding, if exists
     */
    async apiFindingsDeleteById(requestParameters: ApiFindingsDeleteByIdRequest): Promise<void> {
        await this.apiFindingsDeleteByIdRaw(requestParameters);
    }

    /**
     * Get paged list of findings matching optional criteria
     */
    async apiFindingsGetRaw(requestParameters: ApiFindingsGetRequest): Promise<runtime.ApiResponse<FindingArrayResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiFindingsGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.fields !== undefined) {
            queryParameters['fields'] = requestParameters.fields;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/findings`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindingArrayResponseFromJSON(jsonValue));
    }

    /**
     * Get paged list of findings matching optional criteria
     */
    async apiFindingsGet(requestParameters: ApiFindingsGetRequest): Promise<FindingArrayResponse> {
        const response = await this.apiFindingsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a specific finding, if exists
     */
    async apiFindingsGetByIdRaw(requestParameters: ApiFindingsGetByIdRequest): Promise<runtime.ApiResponse<FindingSingleResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiFindingsGetById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFindingsGetById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.fields !== undefined) {
            queryParameters['fields'] = requestParameters.fields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/findings/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindingSingleResponseFromJSON(jsonValue));
    }

    /**
     * Get a specific finding, if exists
     */
    async apiFindingsGetById(requestParameters: ApiFindingsGetByIdRequest): Promise<FindingSingleResponse> {
        const response = await this.apiFindingsGetByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get attachments related to a finding
     */
    async apiFindingsGetByIdAttachmentsRaw(requestParameters: ApiFindingsGetByIdAttachmentsRequest): Promise<runtime.ApiResponse<AttachmentArrayResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiFindingsGetByIdAttachments.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFindingsGetByIdAttachments.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/findings/{id}/attachments`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentArrayResponseFromJSON(jsonValue));
    }

    /**
     * Get attachments related to a finding
     */
    async apiFindingsGetByIdAttachments(requestParameters: ApiFindingsGetByIdAttachmentsRequest): Promise<AttachmentArrayResponse> {
        const response = await this.apiFindingsGetByIdAttachmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get resource identifier for a given relationship of a finding
     */
    async apiFindingsGetByIdRelationshipAttachmentsRaw(requestParameters: ApiFindingsGetByIdRelationshipAttachmentsRequest): Promise<runtime.ApiResponse<RelationshipResourceIdentifierResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiFindingsGetByIdRelationshipAttachments.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFindingsGetByIdRelationshipAttachments.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/findings/{id}/relationships/attachments`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RelationshipResourceIdentifierResponseFromJSON(jsonValue));
    }

    /**
     * Get resource identifier for a given relationship of a finding
     */
    async apiFindingsGetByIdRelationshipAttachments(requestParameters: ApiFindingsGetByIdRelationshipAttachmentsRequest): Promise<RelationshipResourceIdentifierResponse> {
        const response = await this.apiFindingsGetByIdRelationshipAttachmentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get resource identifier for a given relationship of a finding
     */
    async apiFindingsGetByIdRelationshipSchemaRaw(requestParameters: ApiFindingsGetByIdRelationshipSchemaRequest): Promise<runtime.ApiResponse<RelationshipResourceIdentifierResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiFindingsGetByIdRelationshipSchema.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFindingsGetByIdRelationshipSchema.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/findings/{id}/relationships/schema`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RelationshipResourceIdentifierResponseFromJSON(jsonValue));
    }

    /**
     * Get resource identifier for a given relationship of a finding
     */
    async apiFindingsGetByIdRelationshipSchema(requestParameters: ApiFindingsGetByIdRelationshipSchemaRequest): Promise<RelationshipResourceIdentifierResponse> {
        const response = await this.apiFindingsGetByIdRelationshipSchemaRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get schema related to a finding
     */
    async apiFindingsGetByIdSchemaRaw(requestParameters: ApiFindingsGetByIdSchemaRequest): Promise<runtime.ApiResponse<FindingSchemaSingleResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiFindingsGetByIdSchema.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFindingsGetByIdSchema.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/findings/{id}/schema`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindingSchemaSingleResponseFromJSON(jsonValue));
    }

    /**
     * Get schema related to a finding
     */
    async apiFindingsGetByIdSchema(requestParameters: ApiFindingsGetByIdSchemaRequest): Promise<FindingSchemaSingleResponse> {
        const response = await this.apiFindingsGetByIdSchemaRaw(requestParameters);
        return await response.value();
    }

    /**
     * Notes concerinig the payload:  * **Use ISO date format** (e.g. 2018-10-08), time of day must be ommited or zero (e.g. 2018-10-08T00:00:00.000Z)  * Partial updates are supported. Ommited attributes will not be changed.  * Schema realtionship cannot be changed on existing findings (internal policy). Should the schema relationship be present in the request, HTTP 409 response will be returned and changes won\'t be saved.   * **Non-standard PATCH behavior:** Sucessfull PATCH reuest always result in HTTP 200 response with the updated resource object, even if the server does not perform any additional modifications. HTTP 204 is never used in PATCH responses. 
     * Update an existing finding, if exists
     */
    async apiFindingsPatchByIdRaw(requestParameters: ApiFindingsPatchByIdRequest): Promise<runtime.ApiResponse<FindingSingleResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiFindingsPatchById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFindingsPatchById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/vnd.api+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/findings/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ExistingFindingRequestBodyToJSON(requestParameters.existingFindingRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindingSingleResponseFromJSON(jsonValue));
    }

    /**
     * Notes concerinig the payload:  * **Use ISO date format** (e.g. 2018-10-08), time of day must be ommited or zero (e.g. 2018-10-08T00:00:00.000Z)  * Partial updates are supported. Ommited attributes will not be changed.  * Schema realtionship cannot be changed on existing findings (internal policy). Should the schema relationship be present in the request, HTTP 409 response will be returned and changes won\'t be saved.   * **Non-standard PATCH behavior:** Sucessfull PATCH reuest always result in HTTP 200 response with the updated resource object, even if the server does not perform any additional modifications. HTTP 204 is never used in PATCH responses. 
     * Update an existing finding, if exists
     */
    async apiFindingsPatchById(requestParameters: ApiFindingsPatchByIdRequest): Promise<FindingSingleResponse> {
        const response = await this.apiFindingsPatchByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Notes concerinig the payload:  * **Use ISO date format** (e.g. 2018-10-08), time of day must be ommited or zero (e.g. 2018-10-08T00:00:00.000Z)  * ID of the inserted entity is created dynamically by the server, **providing client-generated ID in the request will result in HTTP 403 error** 
     * Insert new finding to the database
     */
    async apiFindingsPostRaw(requestParameters: ApiFindingsPostRequest): Promise<runtime.ApiResponse<FindingSingleResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiFindingsPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/vnd.api+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/findings`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewFindingRequestBodyToJSON(requestParameters.newFindingRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindingSingleResponseFromJSON(jsonValue));
    }

    /**
     * Notes concerinig the payload:  * **Use ISO date format** (e.g. 2018-10-08), time of day must be ommited or zero (e.g. 2018-10-08T00:00:00.000Z)  * ID of the inserted entity is created dynamically by the server, **providing client-generated ID in the request will result in HTTP 403 error** 
     * Insert new finding to the database
     */
    async apiFindingsPost(requestParameters: ApiFindingsPostRequest): Promise<FindingSingleResponse> {
        const response = await this.apiFindingsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Notes concerinig the payload:  * File size is limited to 10 MB 
     * Upload a new attachment to an existing finding
     */
    async apiFindingsPutByIdAttachmentsRaw(requestParameters: ApiFindingsPutByIdAttachmentsRequest): Promise<runtime.ApiResponse<AttachmentPutResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiFindingsPutByIdAttachments.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFindingsPutByIdAttachments.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/{tenantId}/findings/{id}/attachments`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentPutResponseFromJSON(jsonValue));
    }

    /**
     * Notes concerinig the payload:  * File size is limited to 10 MB 
     * Upload a new attachment to an existing finding
     */
    async apiFindingsPutByIdAttachments(requestParameters: ApiFindingsPutByIdAttachmentsRequest): Promise<AttachmentPutResponse> {
        const response = await this.apiFindingsPutByIdAttachmentsRaw(requestParameters);
        return await response.value();
    }

}
