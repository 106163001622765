import {
    Button,
    ButtonGroup, ButtonToolbar,
    DropdownItem,
    DropdownMenu, DropdownToggle,
    UncontrolledButtonDropdown
} from "reactstrap";
import {FindingSchemaDefinitionGroup, FindingSchemaNestedDataDefinition} from "../../../services/GeneratedApiTsClient";
import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export enum TabType {
    Group,
    NestedData
}

type Props = {
    definitionGroups: Array<FindingSchemaDefinitionGroup>,
    nestedDataDefinitions: Array<FindingSchemaNestedDataDefinition>,
    activeTabIndex: number,
    activeTabType: TabType,
    onTabClick: (tabIndex: number, tabType: TabType) => void
    onAddTabClick: () => void
    onAddNestedDataClick: () => void
    onActiveTabDeleteClick: () => void
    onActiveTabRenameClick: () => void
    onActiveTabMoveClick: (newPosition: number, tabType: TabType) => void
}

export const DynamicDataFormCardTabsNav = (props : Props) =>
    <ButtonToolbar className={"schema-edit-tabs"}>
        
        {/* Tabs */}
        {props.definitionGroups.map((group: FindingSchemaDefinitionGroup, index) =>
                <ButtonGroup key={index}>
                    <Button color={props.activeTabIndex === index && props.activeTabType === TabType.Group ? "primary" : "default"}    
                            onClick={() => props.onTabClick(index, TabType.Group)}
                    >
                        {group.name}
                    </Button>
                    {props.activeTabIndex === index && props.activeTabType === TabType.Group && <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="primary" size="sm">
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={props.onActiveTabRenameClick}>Rename</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => props.onActiveTabMoveClick(index-1, TabType.Group)} disabled={index === 0}>Move left</DropdownItem>
                            <DropdownItem onClick={() => props.onActiveTabMoveClick(index+1, TabType.Group)} disabled={index === props.definitionGroups.length - 1}>Move right</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={props.onActiveTabDeleteClick} disabled={props.definitionGroups.length < 2}>Delete</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>}
                </ButtonGroup>
            )}

        {/* Nested data elements */}
        {props.nestedDataDefinitions.map((group: FindingSchemaNestedDataDefinition, index) =>
            <ButtonGroup key={index}>
                <Button color={props.activeTabIndex === index && props.activeTabType === TabType.NestedData ? "primary" : "default"}
                        onClick={() => props.onTabClick(index, TabType.NestedData)}
                >
                    <FontAwesomeIcon icon={"pencil-ruler"} /> {group.label}
                </Button>
                {props.activeTabIndex === index && props.activeTabType === TabType.NestedData && <UncontrolledButtonDropdown>
                    <DropdownToggle caret color="primary" size="sm">
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={props.onActiveTabRenameClick}>Rename</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => props.onActiveTabMoveClick(index-1, TabType.NestedData)} disabled={index === 0}>Move left</DropdownItem>
                        <DropdownItem onClick={() => props.onActiveTabMoveClick(index+1, TabType.NestedData)} disabled={index === props.nestedDataDefinitions.length - 1}>Move right</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={props.onActiveTabDeleteClick}>Delete</DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>}
            </ButtonGroup>
        )}

        <UncontrolledButtonDropdown>
            <DropdownToggle outline={true} color={"secondary"} caret>
                <FontAwesomeIcon icon={"plus"} />
                Add new
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={props.onAddTabClick}><FontAwesomeIcon icon={"pencil-ruler"} style={{visibility: "hidden"}} /> Single-entry group</DropdownItem>
                <DropdownItem onClick={props.onAddNestedDataClick}><FontAwesomeIcon icon={"pencil-ruler"} /> Multi-entry group</DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>

    </ButtonToolbar>;
