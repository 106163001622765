import React from "react";
import {connect} from "react-redux";
import {isTenantUserInRole} from "../../store/selectors/UserSelectors";
import NotAvailableScreen from "../../scenes/Error/NotAvailableScreen";
import {TenantUserRole} from "../../services/GeneratedApiTsClient";
import {AppState} from "../../store/reducers";
import {RouteComponentProps} from "react-router";

/**
 * HOC to check if active tenant user have AT LEAST ONE of the given roles.
 * 
 * @param WrappedComponent
 * @param roles
 */
export default <P extends object>(WrappedComponent: React.ComponentType<P>, roles: TenantUserRole[]) => {

    const mapStateToProps = (state: AppState) => ({
        isInRole: isTenantUserInRole(state, {role: roles})
    });

    const mapDispatchToProps = {};

    type ComponentProps = {}

    type Props = RouteComponentProps<{ id: string; schema: any }> &
        ReturnType<typeof mapStateToProps> &
        typeof mapDispatchToProps & ComponentProps;

    // TODO: Replace NotAvailableScreen with new component with more fitting message
    const WithTenantRolesHOC = (props: Props) => props.isInRole ? <WrappedComponent {...(props as P)}/> : <NotAvailableScreen/>

    return connect(mapStateToProps, mapDispatchToProps)(WithTenantRolesHOC as unknown as React.ComponentClass<ComponentProps>);
};