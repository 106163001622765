import {FindingSchema, FindingSchemaElement} from "../services/GeneratedApiTsClient";


/**
 * Re-initialize finding schema object, as if it was newly created (in-place, modifies original object)
 * 
 * @param schema
 */
export const reinitializeFindingSchema = (schema: FindingSchema) => {
    let timestampSeed = new Date().getTime();

    schema.definitionGroups?.forEach(dg => timestampSeed = reinitializeDefinitionNames(dg.definitions, timestampSeed))
    
    schema.nestedDataDefinitions?.forEach(ndd => {
        ndd.name = getInitialDefinitionName(ndd.name, ++timestampSeed);
        timestampSeed = reinitializeDefinitionNames(ndd.definitions, timestampSeed);
    })
}

/**
 *  Re-initialize definition names (in-place, modifies original object)
 *  
 * @param definitions
 * @param timestampSeed UNIX timestamp initial value
 * @return UNIX timestamp final value
 */
const reinitializeDefinitionNames = (definitions: Array<FindingSchemaElement>, timestampSeed: number): number => {

    definitions.forEach(d => d.name = d.name ? getInitialDefinitionName(d.name, ++timestampSeed): d.name);
    
    return timestampSeed 
}

/**
 * Re-initialize given dynamic/nested data definition name (as if it was newly created)
 * 
 * @param name Original name
 * @param timestamp UNIX timestamp
 * @return string New name
 */
const getInitialDefinitionName = (name: string, timestamp: number): string => name.replace(/^([a-z-]+)\d+.*$/, "$1" + timestamp)