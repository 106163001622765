import * as React from 'react';
import connect from "react-redux/es/connect/connect";
import {AppState} from "../../store/reducers";
import {Badge, Button, Card, CardBody, CardFooter, CardSubtitle, CardText, CardTitle} from "reactstrap";
import {ErrorType, resolveErrors, setError} from "../../store/actions/ErrorActions";
import HelpdeskButton from "../../components/HelpdeskButton";
import NotAvailableScreen from "./NotAvailableScreen";

const mapStateToProps = (state: AppState) => ({
    errors: state.error.errors,
});


const wallpaper = {
    'backgroundImage': 'url("images/error-photo@2x.jpg")',  // https://unsplash.com/photos/OG8L9s1bYKc
    'filter': 'blur',
};

const mapDispatchToProps = {
    resolveErrors,
    setError,
};

type Props =
    ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps
    & {};

type ErrorInfoType = {
    isNonRecoverable: boolean, subtitle: string, title: string, message: string, buttonText: string
}

function ErrorScreen(props: Props) {
    const [errorInfo, setErrorInfo] = React.useState<ErrorInfoType>({} as ErrorInfoType);
    const [onlyNotAvailableError, setOnlyNotAvailableError] = React.useState<boolean>(false)

    React.useEffect(() => {
        const errorList = props.errors.filter(error => error.type === ErrorType.FATAL_ERROR);
        const isFatalError = errorList.length !== 0;
        const error = isFatalError ? errorList[0] : props.errors[0];
        setOnlyNotAvailableError(props.errors.filter(item => item.type === ErrorType.NOT_AVAILABLE_ERROR).length === props.errors.length)
        setErrorInfo({
            isNonRecoverable: isFatalError,
            subtitle: error.message,
            title: isFatalError ? 'Fatal error' : 'Something went wrong',
            message: isFatalError ? 'Please try to reset the application to clear browser cache. Note that you will be logged out.' : 'Please try to repeat your last request.',
            buttonText: isFatalError ? 'Reset application' : 'Back to findings'
        })
    }, [props.errors]);

    return (
        <>
            <div style={wallpaper} className="wallpaper wallpaper--fullsized wallpaper--blurred"/>
            {onlyNotAvailableError ? <NotAvailableScreen onResolveError={() => {props.resolveErrors(false);}} /> :
            <div className="fullsize p-5">
                <div className="logo-container">
                    <img src="/images/logo-light.svg" className="login-logo" alt="UnicatDB logo"/>
                </div>
                <div className="d-flex flex-fill justify-content-center align-content-center">
                    <Card className="mw-50 md- mt-6">
                        <CardBody>
                            <CardTitle className="h1 mb-3">{errorInfo.title}</CardTitle>
                            <CardSubtitle className="lead mb-3 font-italic">{errorInfo.subtitle}</CardSubtitle>
                            <CardText className="mb-5">{errorInfo.message}</CardText>
                            <div className="d-flex justify-content-center align-content-center mb-3">
                                <Button onClick={() => props.resolveErrors(errorInfo.isNonRecoverable)} color="primary"
                                        block={true} className="p-3 w-75">
                                    {errorInfo.buttonText}
                                </Button>
                            </div>
                        </CardBody>
                        {errorInfo.isNonRecoverable ? null : <CardFooter className="d-flex align-items-center">
                            In case of recurring problems please <Badge
                            onClick={() => props.resolveErrors(errorInfo.isNonRecoverable)} color="danger"
                            style={{cursor: 'pointer', marginLeft: '3px'}}>Reset app</Badge>
                        </CardFooter>}
                        <CardFooter className="text-center">
                            <HelpdeskButton buttonText={"Contact Helpdesk"}/>
                        </CardFooter>
                    </Card>
                </div>
            </div>
            }
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorScreen as any);