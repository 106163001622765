import * as React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import {useLayoutEffect, useRef} from "react";

export type ColumnChartGraphData = {
    name: string;
    value: number;
    color?: string;
}[];

type Props = {
    data: ColumnChartGraphData;
    title?: string;
    showLabels: boolean;
    disableGridLines?: boolean;
};

const ColumnChartGraph = (props: Props) => {
    
    const chartRef = useRef<am4charts.XYChart|undefined>(undefined);
    const seriesRef = useRef<am4charts.ColumnSeries|undefined>(undefined);

    // replace chart series data on change
    useLayoutEffect(() => {
        if(!chartRef.current) return;
        chartRef.current.data = props.data;
    },[props.data])

    useLayoutEffect(() => {
        let chart = am4core.create('column-container', am4charts.XYChart);

        chart.data = props.data;
        
        if (props.title){
            // enable title
            let title = chart.titles.create();
            title.text = props.title;
            title.fontSize = 10;
        }

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'name';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.labels.template.disabled = !props.showLabels;
        categoryAxis.renderer.grid.template.disabled = !!props.disableGridLines;
        categoryAxis.renderer.line.disabled = !!props.disableGridLines;

        let label = categoryAxis.renderer.labels.template;
        label.rotation = -45;
        label.horizontalCenter = "right";
        label.verticalCenter = "top";
        label.truncate = true;
        label.maxWidth = 200;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.labels.template.disabled = !props.showLabels;
        valueAxis.renderer.grid.template.disabled = !!props.disableGridLines;
        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = 'value';
        series.dataFields.categoryX = 'name';
        series.name = 'Series';
        series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
        series.columns.template.fillOpacity = 0.8;
        series.columns.template.propertyFields.fill = 'color';
        series.columns.template.propertyFields.stroke = 'color';

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;

        chartRef.current = chart;
        seriesRef.current = series;

        return () => {
            chart.dispose();
        };
    },[props.data, props.disableGridLines, props.showLabels, props.title]);

    return <div id="column-container" style={{width: '100%', height: '100%'}} />;
}

export default ColumnChartGraph;
