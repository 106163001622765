import rootReducer from "./ListingActions";
import {Filter} from "../../models/FilterModel";
import {requestMapFindings} from "./MapPanelActions";

export enum FilterActions {
    SET_FILTERS = 'SET_FILTERS',
    CLEAR_FILTERS = 'CLEAR_FILTERS',    
}

export const setFilters = (filters: Array<Filter>) => (dispatch) => {
    dispatch({type: FilterActions.SET_FILTERS, filters});
    dispatch(rootReducer.reloadListing());
    dispatch(requestMapFindings(true));
};

export const clearFilters = () => (dispatch) => {
    dispatch({type: FilterActions.CLEAR_FILTERS});
    dispatch(rootReducer.reloadListing());
    dispatch(requestMapFindings(true));
};

export default {
    setFilters,
    clearFilters
}