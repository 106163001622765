import {createSelector} from "reselect";
import {AppState} from "../reducers";
import {TENANT_ROUTE_PATH} from "../../App";
import {UserScopedTenant} from "../../services/GeneratedApiTsClient";

export const selectActiveTenantId = (state: AppState) => state.tenant.activeTenantId ?? '';

export const selectAvailableTenants = (state: AppState) => state.tenant.available;

export const selectAvailableTenantsLoading = (state: AppState) => state.tenant.availableLoading;

export const selectActiveTenantLoading = (state: AppState) => state.tenant.activeTenantLoading;

export const selectActiveTenantUsers = (state: AppState) => state.tenant.activeTenantUsers;

export const getAvailableTenantIds = createSelector([selectAvailableTenants], (available) =>
    available?.map(t => t.id) ?? []
);

export const getActiveTenant = createSelector([selectActiveTenantId, selectAvailableTenants], (tenantId, available) =>
    available?.find(t => t.id === tenantId) ?? null
);

export const getActiveTenantRoutePrefix = createSelector([selectActiveTenantId], (tenantId) =>
    TENANT_ROUTE_PATH.replace(':tenantId?', tenantId)
);

export const selectAvailableTenantById = (state: AppState, props: {tenantId: string}): UserScopedTenant | null => state.tenant.available?.find(t => t.id === props.tenantId) ?? null;

