import {FILTER_OP} from "./FilterOperationModel";
import {AttributeType} from "./FilterableAttributesModel";

/**
 * Findings filter model
 */
export class Filter {

    attribute: string;
    operation:  FILTER_OP;
    value: string;
    type: AttributeType;


    constructor(attribute: string = "", operation = FILTER_OP.contains, value: string = "", type: AttributeType|null = null) {
        this.attribute = attribute;
        this.operation = operation;
        this.value = value;
        this.type = type as AttributeType;
    }
}