import * as React from 'react';
import {AppState} from '../../../store/reducers';
import {Button, Row} from 'reactstrap';
import {openGraphView, openMapView} from '../../../store/actions/GUIActions';
import {connect} from 'react-redux';
import Heatmap from "../components/Heatmap";
import MiniStatistics from "../components/MiniStatistics";

const mapStateToProps = (state: AppState) => ({
    gui: state.gui,
    schemasBasicInfo: state.findingSchemas.schemasBasicInfo,
    filters: state.filter.filters
});

const mapDispatchToProps = {
    openMap: openMapView,
    openGraph: openGraphView,
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const PreviewCard = props => {
    return (
        <div className="test-card position-relative">
            <div className="d-flex flex-fill w-100-p h-100-p">{props.children}</div>
            <div className="d-flex flex-column h-100-p ">
                {/*<span className="text-main font-weight-bold mb-3">{props.title}</span>*/}
                <Button outline color={'secondary'} onClick={props.onClick} size={"sm"}>
                    {props.buttonText}
                </Button>
            </div>
        </div>
    );
};

const PreviewCards = (props: Props) => {

    const renderStatisticsCard = () => {
        if (props.gui.graphPanelActive) {
            return null;
        }
        return (
            <PreviewCard title={'Statisctics'} buttonText={'Show statistics'} onClick={props.openGraph}>
                <MiniStatistics />
            </PreviewCard>
        );
    };

    const renderMapCard = () => {
        if (props.gui.mapPanelActive) {
            return null;
        }
        return (
            <PreviewCard title={'Map'} buttonText={'Show map'} onClick={props.openMap}>
                <Heatmap />
            </PreviewCard>
        );
    };

    return (
        <Row>
            {renderStatisticsCard()}
            {!props.gui.splitMode && <div className="mx-lg-2" />}
            {renderMapCard()}
        </Row>
    );

}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewCards as any);
