/* tslint:disable */
/* eslint-disable */
/**
 * UniCatDB API
 * UniCatDB application API documentation, with examples and live testing.  This API is built in accordance with the **JSON API 1.0 standard**. For general information, see [the documentation](http://jsonapi.org/format/).  -  JSON API standard requires use of the JSON API media type (application/vnd.api+json) for exchanging data. Clients must send all JSON API data with the headers `Content-Type: application/vnd.api+json` (POST, PATCH) and `Accept: application/vnd.api+json` (GET, DELETE). - [Relationships](http://jsonapi.org/format/#fetching-relationships) and their [inclusions](http://jsonapi.org/format/#fetching-includes) via the `include` query parameter, as specified by the standard, **are not implemented**, since there are no relationships present in the data model. - The standard does not prescribe any filtering strategies. This API implements two strategies which can be combined: **Basic filtering** based on the used [JSON API library](https://json-api-dotnet.github.io/#/filtering) and **Custom filtering** which allow for any possible query to the MongoDB server and can be extended and customize in the future. For more information about filtering, see the description of the `filter` down bellow. - **Non-standard PATCH behavior:** Sucessfull PATCH reuest always result in HTTP 200 response with the updated resource object, even if the server does not perform any additional modifications. HTTP 204 is never used in PATCH responses.  Attachment upload endpoint uses the **TUS protocol**. See [the documentation](https://tus.io/). When integrating, it is recommended to use one of the [official TUS client implementations](https://tus.io/implementations.html).  - **Supported TUS versions**:   -  1.0.0 - **Supported TUS extensions**:   - Creation   - Creation Defer Length   - Creation With Upload   - Checksum   - Checksum Trailer - **Required metadata**:   - `fileName` - name of the original file incl. file extension   - `contentType`- the MIME type of the file 
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ExistingFindingSchemaRequestBody,
    ExistingFindingSchemaRequestBodyFromJSON,
    ExistingFindingSchemaRequestBodyToJSON,
    FilterQuery,
    FilterQueryFromJSON,
    FilterQueryToJSON,
    FindingArrayResponse,
    FindingArrayResponseFromJSON,
    FindingArrayResponseToJSON,
    FindingSchemaArrayResponse,
    FindingSchemaArrayResponseFromJSON,
    FindingSchemaArrayResponseToJSON,
    FindingSchemaFieldsQuery,
    FindingSchemaFieldsQueryFromJSON,
    FindingSchemaFieldsQueryToJSON,
    FindingSchemaSingleResponse,
    FindingSchemaSingleResponseFromJSON,
    FindingSchemaSingleResponseToJSON,
    NewFindingSchemaRequestBody,
    NewFindingSchemaRequestBodyFromJSON,
    NewFindingSchemaRequestBodyToJSON,
    PageQuery,
    PageQueryFromJSON,
    PageQueryToJSON,
    RelationshipResourceIdentifierArrayResponse,
    RelationshipResourceIdentifierArrayResponseFromJSON,
    RelationshipResourceIdentifierArrayResponseToJSON,
} from '../models';

export interface ApiSchemasDeleteByIdRequest {
    tenantId: string;
    id: string;
}

export interface ApiSchemasGetRequest {
    tenantId: string;
    sort?: string;
    page?: PageQuery;
    fields?: FindingSchemaFieldsQuery;
    filter?: FilterQuery;
}

export interface ApiSchemasGetByIdRequest {
    tenantId: string;
    id: string;
    fields?: FindingSchemaFieldsQuery;
}

export interface ApiSchemasGetByIdFindingsRequest {
    tenantId: string;
    id: string;
}

export interface ApiSchemasGetByIdRelationshipsFindingsRequest {
    tenantId: string;
    id: string;
}

export interface ApiSchemasPatchByIdRequest {
    tenantId: string;
    id: string;
    existingFindingSchemaRequestBody?: ExistingFindingSchemaRequestBody;
}

export interface ApiSchemasPostRequest {
    tenantId: string;
    newFindingSchemaRequestBody?: NewFindingSchemaRequestBody;
}

/**
 * no description
 */
export class SchemasApi extends runtime.BaseAPI {

    /**
     * Delete a specific schema, if exists
     */
    async apiSchemasDeleteByIdRaw(requestParameters: ApiSchemasDeleteByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiSchemasDeleteById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSchemasDeleteById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/schemas/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a specific schema, if exists
     */
    async apiSchemasDeleteById(requestParameters: ApiSchemasDeleteByIdRequest): Promise<void> {
        await this.apiSchemasDeleteByIdRaw(requestParameters);
    }

    /**
     * Get paged list of schemas matching optional criteria
     */
    async apiSchemasGetRaw(requestParameters: ApiSchemasGetRequest): Promise<runtime.ApiResponse<FindingSchemaArrayResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiSchemasGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.fields !== undefined) {
            queryParameters['fields'] = requestParameters.fields;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/schemas`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindingSchemaArrayResponseFromJSON(jsonValue));
    }

    /**
     * Get paged list of schemas matching optional criteria
     */
    async apiSchemasGet(requestParameters: ApiSchemasGetRequest): Promise<FindingSchemaArrayResponse> {
        const response = await this.apiSchemasGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a specific schema, if exists
     */
    async apiSchemasGetByIdRaw(requestParameters: ApiSchemasGetByIdRequest): Promise<runtime.ApiResponse<FindingSchemaSingleResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiSchemasGetById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSchemasGetById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.fields !== undefined) {
            queryParameters['fields'] = requestParameters.fields;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/schemas/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindingSchemaSingleResponseFromJSON(jsonValue));
    }

    /**
     * Get a specific schema, if exists
     */
    async apiSchemasGetById(requestParameters: ApiSchemasGetByIdRequest): Promise<FindingSchemaSingleResponse> {
        const response = await this.apiSchemasGetByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get findings related to a schema
     */
    async apiSchemasGetByIdFindingsRaw(requestParameters: ApiSchemasGetByIdFindingsRequest): Promise<runtime.ApiResponse<FindingArrayResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiSchemasGetByIdFindings.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSchemasGetByIdFindings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/schemas/{id}/findings`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindingArrayResponseFromJSON(jsonValue));
    }

    /**
     * Get findings related to a schema
     */
    async apiSchemasGetByIdFindings(requestParameters: ApiSchemasGetByIdFindingsRequest): Promise<FindingArrayResponse> {
        const response = await this.apiSchemasGetByIdFindingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get resource identifiers for a given relationship of a schema
     */
    async apiSchemasGetByIdRelationshipsFindingsRaw(requestParameters: ApiSchemasGetByIdRelationshipsFindingsRequest): Promise<runtime.ApiResponse<RelationshipResourceIdentifierArrayResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiSchemasGetByIdRelationshipsFindings.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSchemasGetByIdRelationshipsFindings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/schemas/{id}/relationships/findings`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RelationshipResourceIdentifierArrayResponseFromJSON(jsonValue));
    }

    /**
     * Get resource identifiers for a given relationship of a schema
     */
    async apiSchemasGetByIdRelationshipsFindings(requestParameters: ApiSchemasGetByIdRelationshipsFindingsRequest): Promise<RelationshipResourceIdentifierArrayResponse> {
        const response = await this.apiSchemasGetByIdRelationshipsFindingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Notes concerinig the payload:  * Partial updates are supported. Ommited attributes will not be changed.  * Some attributes are modified by the server:    * Properties *definitions[].name*, if present, are transformed by the server into a more human-readable form by appending the sluggified *definitions[].label*, if present. Allready transformed properties are skipped to prevent unwanted changes (subsequent change in *definitions[].label* does not affect *definitions[].name*).   * **Non-standard PATCH behavior:** Sucessfull PATCH reuest always result in HTTP 200 response with the updated resource object, even if the server does not perform any additional modifications. HTTP 204 is never used in PATCH responses. 
     * Update an existing schema, if exists
     */
    async apiSchemasPatchByIdRaw(requestParameters: ApiSchemasPatchByIdRequest): Promise<runtime.ApiResponse<FindingSchemaSingleResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiSchemasPatchById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSchemasPatchById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/vnd.api+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/schemas/{id}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ExistingFindingSchemaRequestBodyToJSON(requestParameters.existingFindingSchemaRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindingSchemaSingleResponseFromJSON(jsonValue));
    }

    /**
     * Notes concerinig the payload:  * Partial updates are supported. Ommited attributes will not be changed.  * Some attributes are modified by the server:    * Properties *definitions[].name*, if present, are transformed by the server into a more human-readable form by appending the sluggified *definitions[].label*, if present. Allready transformed properties are skipped to prevent unwanted changes (subsequent change in *definitions[].label* does not affect *definitions[].name*).   * **Non-standard PATCH behavior:** Sucessfull PATCH reuest always result in HTTP 200 response with the updated resource object, even if the server does not perform any additional modifications. HTTP 204 is never used in PATCH responses. 
     * Update an existing schema, if exists
     */
    async apiSchemasPatchById(requestParameters: ApiSchemasPatchByIdRequest): Promise<FindingSchemaSingleResponse> {
        const response = await this.apiSchemasPatchByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Notes concerinig the payload:  * ID of the inserted entity is created dynamically by the server, **providing client-generated ID in the request will result in HTTP 403 error**  * Some attributes are modified by the server:    * Properties *definitions[].name*, if present, are transformed by the server into a more human-readable form by appending the sluggified *definitions[].label*, if present. 
     * Insert new schema to the database
     */
    async apiSchemasPostRaw(requestParameters: ApiSchemasPostRequest): Promise<runtime.ApiResponse<FindingSchemaSingleResponse>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiSchemasPost.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/vnd.api+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{tenantId}/schemas`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewFindingSchemaRequestBodyToJSON(requestParameters.newFindingSchemaRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FindingSchemaSingleResponseFromJSON(jsonValue));
    }

    /**
     * Notes concerinig the payload:  * ID of the inserted entity is created dynamically by the server, **providing client-generated ID in the request will result in HTTP 403 error**  * Some attributes are modified by the server:    * Properties *definitions[].name*, if present, are transformed by the server into a more human-readable form by appending the sluggified *definitions[].label*, if present. 
     * Insert new schema to the database
     */
    async apiSchemasPost(requestParameters: ApiSchemasPostRequest): Promise<FindingSchemaSingleResponse> {
        const response = await this.apiSchemasPostRaw(requestParameters);
        return await response.value();
    }

}
