import * as React from 'react';
import {connect} from 'react-redux';
import {Finding, TenantUserRole} from '../../services/GeneratedApiTsClient';
import {Alert, Card, CardBody, Col, Container, Row} from 'reactstrap';
import {DataCardTextElement} from './components/DataCardTextElement';
import ItemMinimap from '../../components/ItemMinimap/ItemMinimap';
import {FindingTags} from './components/FindingTags';
import {AppState} from '../../store/reducers';
import {deleteFinding, requestFinding} from '../../store/actions/FindingActions';
import {RouteComponentProps} from 'react-router';
import DetailsActions from './containers/DetailsActions';
import MapGraph from '../../components/Graphs/MapGraph';
import {DynamicDataCardElement} from "./components/DynamicDataCardElement";
import FindingTaxonomy from "./components/FindingTaxonomy";
import {getCenterOfCoordsArray} from "../../utils/gpsUtils";
import {ServerMediaElement} from "./containers/ServerMediaElement";
import {UploadedAttachment} from "../../store/reducers/FindingReducer";
import moment from "moment";
import MediaUploader from "./components/MediaUploader";
import {UploadResult} from "@uppy/core";
import {showNotification} from "../../store/actions/GUIActions";
import {isTenantUserInRole} from "../../store/selectors/UserSelectors";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SpinnerOverlay from "../../components/Spinners/SpinnerOverlay";

const mapStateToProps = (state: AppState) => ({
    finding: state.finding,
    tenantId: state.tenant.activeTenantId,
    canEdit: isTenantUserInRole(state, {role: [TenantUserRole.OWNER, TenantUserRole.COLLABORATOR]})
});

const mapDispatchToProps = {
    deleteFinding,
    requestFinding
};

type Props = RouteComponentProps<{ id: string }> & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};


const FindingDetail = (props: Props) => {
    //id = this.props.match.params.id;
    //private mapRef;
    const [id, setId] = React.useState<string>(props.match.params.id)
    const mapRef = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        if(props.tenantId){
            props.requestFinding(id)   
        }
    }, [id, props.tenantId])
    

    const renderHeader = (finding: Finding) => {
        return (
            <>
                <DetailsActions findingId={id}/>
                <h1 className="page-heading text-center pt-4">{finding.documentName}</h1>


                <div className="text-center">
                    <div className="d-inline-block">
                        {finding.taxonomyName && <FindingTaxonomy taxonomyName={finding.taxonomyName}/>}
                        <hr/>
                        {finding.taxonomyHumanReadable && (
                            <div className="taxonomy text-center">
                                <em className="small text-muted mr-2">Scientific name:</em>
                                {finding.taxonomyHumanReadable}
                            </div>
                        )}
                    </div>
                </div>

                {finding.tags && (
                    <div className="d-flex justify-content-center">
                        <FindingTags tags={finding.tags}/>
                    </div>
                )}
            </>
        );
    };

    const renderGeneralInfoCard = (finding: Finding) => {
        return (
            <div className="data-card">
                <h2 className="data-card-heading">General information</h2>
                <Row className="justify-content-center divider">
                    <Col xl={hasGpsCoords(finding) ? "7" : "12"} style={{padding: 0}}>
                        <Card className="d-flex flex-fill">
                            <CardBody>
                                <DataCardTextElement title="Dataset" value={finding.documentSet}/>
                                <DataCardTextElement title="Amount" value={finding.date ? finding.amount : ''}/>
                                <DataCardTextElement title="Date"
                                                     value={finding.date ? moment(finding.date).format("YYYY-MM-DD") : ''}/>
                                <DataCardTextElement title="By Person" value={finding.person}/>
                                <DataCardTextElement
                                    title="Note"
                                    value={finding.note}
                                />
                                {/*todo: doplnit last modified*/}
                                {/*<DataCardTextElement title="Last Modified" value={'doplnit podle api'} />*/}
                            </CardBody>
                        </Card>
                    </Col>
                    {hasGpsCoords(finding) && (
                        <Col style={{alignSelf: 'center'}}>
                            <div className="d-flex flex-fill ml-lg-3">
                                <MapGraph
                                    coords={finding.locationGpsPoint || getCenterOfCoordsArray(finding.locationGpsArea)}
                                    markerColor={props.finding.loadedSchema!.color}
                                    onClick={() => {
                                        if (mapRef && mapRef.current) {
                                            mapRef.current.scrollIntoView({behavior: 'smooth'});
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        );
    };

    const renderSchemaInfoCard = (schema, finding) => {
        return (
            <div className="data-card">
                <h2 className="data-card-heading">
                    <span className="text-primary font-weight-bold">{schema.name} Schema</span> Information
                </h2>
                <Card>
                    <CardBody>
                        <Row className="justify-content-between">
                            {schema.definitionGroups.map((group, i) => (
                                <div key={i}
                                     className={`${i > 1 && 'mt-4'} ${schema.definitionGroups!.length > 1 ? ' w-100-p w-49-lg-p' : 'w-100-p'}`}>
                                    <span className="schema-section-heading">{group.name}</span>
                                    <div className="schema-section-hr"/>
                                    {group.definitions.map((definition, j) =>
                                        <DynamicDataCardElement
                                            definition={definition}
                                            value={finding.dynamicData![definition.name!]}
                                            key={j}
                                        />)}
                                </div>
                            ))}

                            {schema.nestedDataDefinitions.map((nestedDataElement, i) => (
                                <div key={'nested-'+i}
                                     className={'mt-4 w-100-p w-49-lg-p'}>
                                    <span className="schema-section-heading"><FontAwesomeIcon icon="pencil-ruler"/> {nestedDataElement.label}</span>
                                    <div className="schema-section-hr"/>
                                    
                                    <>
                                        
                                    {finding.dynamicData![nestedDataElement.name!] && finding.dynamicData![nestedDataElement.name!].map((nestedData, j) => (
                                        <div key={'nested-'+i+'-'+j}
                                             className={`${j > 0 ? 'mt-4' : ''} w-100-p w-49-lg-p`}>
                                            <span className="schema-section-heading">
                                                {/*reverse ordering - most recent with lowest index*/}
                                                #{finding.dynamicData![nestedDataElement.name!].length - j}
                                            </span>
                                            <div className="schema-section-hr"/>
                                            {nestedDataElement.definitions.map((definition, j) =>
                                                <DynamicDataCardElement
                                                    definition={definition}
                                                        value={nestedData[definition.name!]}
                                                    key={j}
                                                />)}
                                        </div>
                                    ))}
                                    
                                    {(!finding.dynamicData![nestedDataElement.name!] || !finding.dynamicData![nestedDataElement.name!].length) && <span>∅</span>}
                                    </>
                                </div>
                            ))}
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    };

    const handleUploadFinished = (result: UploadResult) => {
        if (result.successful.length){
            showNotification({message: `Successfully uploaded ${result.successful.length} files.`, type: "success"});
        }
        for (const failed of result.failed) {
            showNotification({message: `Error uploading file ${failed.name}: ${failed.error}`, type: "error", doNotAutoClose: true});
        }
        props.requestFinding(id);
    };
    
    const renderMediaCard = (findingId: string | undefined, finding: Finding, hasIncompleteAttachments: boolean, attachments?: UploadedAttachment[]) => {
        return (
            <div className="data-card">
                <h2 className="data-card-heading">Media Information</h2>
                <Card>
                    <CardBody>
                        <DataCardTextElement
                            title="Note"
                            value={finding.attachmentNote}
                        />
                        <hr />
                        <div className="data-card-media-grid">
                            {attachments && attachments.map((attachment) => (
                                <ServerMediaElement key={attachment.resource.id} file={attachment.resource} canEdit={props.canEdit}/>
                            ))}
                        </div>
                        
                        {hasIncompleteAttachments && <Alert color={'warning'}>
                            <h5><FontAwesomeIcon icon={'exclamation-triangle'} /> There are incomplete attachments!</h5>
                            They are either uploading at the moment or their upload was interrupted.
                            In case of the latter, upload can be resumed by selecting the <em>exact same file</em> and upload will continue where it left off.
                        </Alert>}
                        
                        {findingId && <MediaUploader findingId={findingId} onFinished={handleUploadFinished}/>}
                    </CardBody>
                </Card>
            </div>
        );
    };

    const renderLocationCard = (finding: Finding) => {
        return (
            <div className="data-card">
                <h2 className="data-card-heading">Location Information</h2>
                <Row className="justify-content-center divider">
                    <Card className="d-flex flex-grow-1">
                        <CardBody>
                            <DataCardTextElement title="Locality" value={finding.locationDescription}/>
                            {finding.locationGpsPoint && <DataCardTextElement
                                title="GPS Location"
                                value={`${finding.locationGpsPoint!.lat.toPrecision(8)}, ${finding.locationGpsPoint!.lng.toPrecision(8)}`}
                            />}
                        </CardBody>
                    </Card>
                </Row>
            </div>
        );
    };

    const hasGpsCoords = (finding: Finding) => {
        if (!finding.locationGpsPoint && !finding.locationGpsArea) {
            return false;
        }

        const hasGpsPoint = !!finding.locationGpsPoint
        const hasGpsArea = !!finding.locationGpsArea && finding.locationGpsArea.length !== 0;

        return hasGpsPoint || hasGpsArea;
    };

    return (
        <>
            {(!props.finding.loadedFinding || !props.finding.loadedSchema || !props.finding.loaded)?
                <SpinnerOverlay/>
            :
                <div>
                {!props.finding.loaded && <SpinnerOverlay/>}
                <Container>
                <div className="finding-detail">
                {renderHeader(props.finding.loadedFinding)}
                {renderGeneralInfoCard(props.finding.loadedFinding)}    
                {props.finding.loadedSchema?.definitionGroups && renderSchemaInfoCard(props.finding.loadedSchema, props.finding.loadedFinding)}
                {/* added condition to enable re-rendering */}
                {props.finding.uploadedAttachments && renderMediaCard(props.finding.loadedFindingId, props.finding.loadedFinding, props.finding.hasIncompleteAttachments, props.finding.uploadedAttachments)}
                {renderLocationCard(props.finding.loadedFinding)}
                </div>
                </Container>
                {hasGpsCoords(props.finding.loadedFinding) && (
                    <div ref={mapRef} className="map-container">
                        <ItemMinimap
                            point={props.finding.loadedFinding.locationGpsPoint}
                            polygon={props.finding.loadedFinding.locationGpsArea}
                            markerColor={props.finding.loadedSchema ? props.finding.loadedSchema?.color : undefined}
                        />
                    </div>
                )}
                </div>
            }
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(FindingDetail as any);
