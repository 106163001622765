/* tslint:disable */
/* eslint-disable */
/**
 * UniCatDB API
 * UniCatDB application API documentation, with examples and live testing.  This API is built in accordance with the **JSON API 1.0 standard**. For general information, see [the documentation](http://jsonapi.org/format/).  -  JSON API standard requires use of the JSON API media type (application/vnd.api+json) for exchanging data. Clients must send all JSON API data with the headers `Content-Type: application/vnd.api+json` (POST, PATCH) and `Accept: application/vnd.api+json` (GET, DELETE). - [Relationships](http://jsonapi.org/format/#fetching-relationships) and their [inclusions](http://jsonapi.org/format/#fetching-includes) via the `include` query parameter, as specified by the standard, **are not implemented**, since there are no relationships present in the data model. - The standard does not prescribe any filtering strategies. This API implements two strategies which can be combined: **Basic filtering** based on the used [JSON API library](https://json-api-dotnet.github.io/#/filtering) and **Custom filtering** which allow for any possible query to the MongoDB server and can be extended and customize in the future. For more information about filtering, see the description of the `filter` down bellow. - **Non-standard PATCH behavior:** Sucessfull PATCH reuest always result in HTTP 200 response with the updated resource object, even if the server does not perform any additional modifications. HTTP 204 is never used in PATCH responses.  Attachment upload endpoint uses the **TUS protocol**. See [the documentation](https://tus.io/). When integrating, it is recommended to use one of the [official TUS client implementations](https://tus.io/implementations.html).  - **Supported TUS versions**:   -  1.0.0 - **Supported TUS extensions**:   - Creation   - Creation Defer Length   - Creation With Upload   - Checksum   - Checksum Trailer - **Required metadata**:   - `fileName` - name of the original file incl. file extension   - `contentType`- the MIME type of the file 
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewTenantRequestBody
 */
export interface NewTenantRequestBody {
    /**
     * Friendly name
     * @type {string}
     * @memberof NewTenantRequestBody
     */
    name: string;
}

export function NewTenantRequestBodyFromJSON(json: any): NewTenantRequestBody {
    return NewTenantRequestBodyFromJSONTyped(json, false);
}

export function NewTenantRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewTenantRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

export function NewTenantRequestBodyToJSON(value?: NewTenantRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
    };
}


