import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
    buttonText?: string
}

export default (props : Props) => (
    
    <a className={"btn btn-link"} href={"https://unicatdb.atlassian.net/servicedesk/customer/portals"} target={"_blank"} rel="noreferrer noopener">
        <FontAwesomeIcon className="mr-2" icon={"question"} />
        {props.buttonText || "Helpdesk"}
    </a>
);
