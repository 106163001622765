import React, {PureComponent} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AppState} from '../../../store/reducers';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {deleteFinding, duplicateFinding} from '../../../store/actions/FindingActions';
import {processApiResponse} from "../../../store/actions/ErrorActions";
import ExportToolbarButton from "./ExportToolbarButton";
import {ModalConfirm} from "../../../components/ModalConfirm";
import {isTenantUserInRole} from "../../../store/selectors/UserSelectors";
import {TenantUserRole} from "../../../services/GeneratedApiTsClient/models";

const mapStateToProps = (state: AppState) => ({
    listing: state.listing,
    filter: state.filter,
    canEdit: isTenantUserInRole(state, {role: [TenantUserRole.OWNER, TenantUserRole.COLLABORATOR]})
});

const mapDispatchToProps = {
    duplicateFinding,
    deleteFinding,
    processApiResponse,
};

interface ToolbarActionsState {
    visibleModal: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & RouteComponentProps;

class ToolbarActions extends PureComponent<Props, ToolbarActionsState> {

    constructor(props) {
        super(props)
        this.state = {
            visibleModal: false,
        }
    }

    toggleVisibleModal = () => {
        this.setState((state) => ({visibleModal: !state.visibleModal}));
    };

    render() {
        const noSelection = this.props.listing.selectedFindingIds.length === 0;
        return (
            <div className="d-flex flex-row align-items-center order-3">
                <ExportToolbarButton/>
                <div
                    className={'btn btn-hover-light ' + ((!noSelection && this.props.canEdit)?'':'disabled')}
                    onClick={() => {
                        if (!noSelection && this.props.canEdit) {
                            this.toggleVisibleModal();
                        }
                    }}>
                    <FontAwesomeIcon icon="trash" className="mr-1 text-danger"/>
                    Delete
                </div>
                <ModalConfirm visible={this.state.visibleModal} titleText={"Delete"} bodyText={"Are you sure?"}
                              btnConfirmLabel={"Delete"} btnCancelLabel={"Cancel"} onClickConfirm={() => {
                    this.props.listing.selectedFindingIds.forEach(id => this.props.deleteFinding(id, true, true));
                    this.toggleVisibleModal();
                }}
                              onClickCancel={this.toggleVisibleModal}/>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarActions as any);
