import {ErrorActions, ErrorType} from "../actions/ErrorActions";

export type ApplicationError = {
    type: ErrorType,
    message: string,
    details?: string
}

export type ErrorReducerState = {
    errors: ApplicationError[]
}

export const ErrorReducerInitialState: ErrorReducerState = {
    errors: [],
};

export default function errorReducer(state: ErrorReducerState = ErrorReducerInitialState, action) {
    switch (action.type) {
        case ErrorActions.SET_ERROR:
            return {
                ...state,
                errors: [
                    ...state.errors,
                    {
                        message: action.error.message,
                        type: action.error.type,
                        details: action.error.details
                    }
                ]
            };
        default:
            return state
    }
}