import * as React from 'react';
import {Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {AttachmentResourceObject} from "../../../services/GeneratedApiTsClient/models";
import {useDispatch} from "react-redux";
import {
    deleteAttachment,
    requestAttachmentDownload,
    requestAttachmentThumbnail,
    setActiveAttachment,
    setAttachmentProperty
} from "../../../store/actions/FindingActions";
import {openAttachmentsCarousel, showNotification} from "../../../store/actions/GUIActions";
import {FileTypeIcon} from "../../../components/FileTypeIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SpinnerInline from "../../../components/Spinners/SpinnerInline";

type MediaElementProps = {
    file: AttachmentResourceObject;
    canEdit?: boolean;
};

export const ServerMediaElement = (props: MediaElementProps) => {
    const [deleteModal, setDeleteModal] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isDownloading, setIsDownloading] = React.useState(false);
    const [thumbnail, setThumbnail] = React.useState<any>();
    const dispatch = useDispatch();


    const requestThumbnail = React.useCallback( async () => {
        if (!props.file || !props.file.id) {
            return null;
        }
        setIsLoading(true);
        const response = await dispatch(requestAttachmentThumbnail(props.file.id));
        setIsLoading(false);

        if (!response) {
            return null
        }
        setThumbnail(response);
        await dispatch(setAttachmentProperty(props.file.id, "thumbnail", (response as unknown as Blob)))
    }, [dispatch, props.file]);

    const handleDelete = async () => {
        if (!props.file || !props.file.id) {
            return;
        }
        await dispatch(deleteAttachment(props.file.id));
    }
    const handleDownload = async () => {
        if (!props.file || !props.file.id) {
            return;
        }
        setIsDownloading(true);
        const data = await dispatch(requestAttachmentDownload(props.file.id))
        setIsDownloading(false);

        if (!data) {
            showNotification({
                message: `Error downloading ${props.file.attributes.originalFilename}`,
                type: 'error'
            });
            return;
        }

        const url = URL.createObjectURL(data);
        const link = document.createElement("a")
        link.href = url;
        link.setAttribute('download', `${props.file.attributes.originalFilename}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode!.removeChild(link);
        
        dispatch(setAttachmentProperty(props.file.id, "data", (data as unknown as Blob)))
    }

    React.useEffect(() => {
        if (!props.file) {
            return;
        }
        
        if (!props.file.attributes.incomplete){
            requestThumbnail();
        }
        
    }, [props.file, requestThumbnail]);

    const openAttachment = async () => {
        if (!props.file.id) {
            return null;
        }
        await dispatch(setActiveAttachment(props.file.id));
        await dispatch(openAttachmentsCarousel());
    };
    const toggleDelete = () => setDeleteModal(!deleteModal);

    const renderPreview = () => {
        if (!thumbnail) {
            if (isLoading) {
                return <div className="preview d-flex justify-content-center align-items-center">
                    <SpinnerInline/>
                </div>
            } else {
                return (
                    <div className={"preview d-flex align-items-center justify-content-center flex-column" + (props.file.attributes.incomplete ? ' incomplete': '')}
                         onClick={openAttachment}>
                        <FileTypeIcon contentType={props.file.attributes.contentType}
                                      fileName={props.file.attributes.originalFilename}
                                      incomplete={props.file.attributes.incomplete}
                                      faSize={"3x"}
                        />
                        {props.file.attributes.incomplete && <Badge pill color="secondary" className={'mt-2'}>
                            INCOMPLETE UPLOAD
                        </Badge>}
                    </div>
                );
            }
        }
        return <img
            src={URL.createObjectURL(thumbnail)}
            alt={'attachment'}
            onClick={openAttachment}
            className="preview"
        />;
    };

    return (
        <div className="media-element">
            {renderPreview()}
            <Row className="media-element-action-bar">
                <span className={"media-element-filename"} title={props.file.attributes.originalFilename}>{props.file.attributes.originalFilename}</span>
                {!props.file.attributes.incomplete && (isDownloading ? <SpinnerInline/> :
                <Button
                    color={'link'}
                    className={`text-primary font-weight-bold p-0 text-nowrap`}
                    onClick={handleDownload}
                    title={"Download"}>
                    <FontAwesomeIcon icon="download"/>
                </Button>)}
                {props.canEdit && <Button
                    color={'link'}
                    className={`text-danger font-weight-bold p-0 text-nowrap`}
                    onClick={toggleDelete}
                    title={"Delete"}>
                    <FontAwesomeIcon icon="trash-alt" className={"text-danger"}/>
                </Button>}
            </Row>
            <Modal isOpen={deleteModal} toggle={toggleDelete} centered>
                <ModalHeader toggle={toggleDelete}>Delete {props.file.attributes.originalFilename}?</ModalHeader>
                <ModalBody>
                    The action cannot be undone.
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end align-items-end">
                    <Button  outline color="secondary" onClick={toggleDelete}>Cancel</Button>
                    <Button color="danger" onClick={handleDelete}>Delete</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};
