
export type AttributeType = "text" | "date" | "number" | "textArray"  // todo: add type string[] autocomplete

export type FilterableAttribute = {
    name: string,
    value: string,
    type: AttributeType
};

export type FilterableAttributesGroup = {
    name: string,
    attributes: Array<FilterableAttribute>
};

export const StaticFilterableAttributesGroup: Array<FilterableAttributesGroup> = [{
    name: "Common attributes",
    attributes: [
        {name: "Document Name", value: "documentName", type: "text"},
        {name: "Dataset", value: "documentSet", type: "text"},
        {name: "Amount", value: "amount", type: "number"},
        {name: "Date", value: "date", type: "date"},
        {name: "Location", value: "locationDescription", type: "text"},
        {name: "Note", value: "note", type: "text"},
        {name: "Tags", value: "tags", type: "textArray"},
    ]},{
    name: "Taxonomy",
    attributes: [
        {name: "Scientific name", value: "taxonomyHumanReadable", type: "text"},
        {name: "Authorship", value: "taxonomyName.authorship", type: "text"},
    ]},{
    name: "Taxonomy (individual levels)",
    attributes: [
        {name: "Taxonomy [all levels]", value: "taxonomyHierarchyId", type: "text"},
        {name: "Kingdom", value: "taxonomyName.kingdom", type: "text"},
        {name: "Phylum", value: "taxonomyName.phylum", type: "text"},
        {name: "Class", value: "taxonomyName.class", type: "text"},
        {name: "Order", value: "taxonomyName.order", type: "text"},
        {name: "Family", value: "taxonomyName.family", type: "text"},
        {name: "Genus", value: "taxonomyName.genus", type: "text"},
        {name: "Species", value: "taxonomyName.species", type: "text"},
    ]}
];


/**
 * Types of dynamic data attributes which can be filtered by
 * (excluding elements that doesn't hold a value e.g. header or paragraph).
 *
 * @type {string[]}
 */
export const DynamicFilterableAttributeTypes = ["checkbox-group","date","number","radio-group","select","text","textarea"];
