import React from 'react';
import {Link, NavLink as RRNavLink} from 'react-router-dom';
import {Collapse, DropdownItem, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {connect} from 'react-redux';
import {AppState} from '../store/reducers';
import NavDropdownPersona from './NavDropdownPersona';
import {getActiveTenant, getActiveTenantRoutePrefix} from "../store/selectors/TenantSelectors";
import {push} from "connected-react-router";
import {getTenantUserOfSelf} from "../store/selectors/UserSelectors";

const mapStateToProps = (state: AppState) => ({
  tenantRoutePrefix: getActiveTenantRoutePrefix(state),
  activeTenant: getActiveTenant(state),
  activeTenantUser: getTenantUserOfSelf(state)
});

const mapDispatchToProps = {
  push
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};

const NavMenuNew = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = React.useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const logout = () => {
    props.push("/signout");
  }
  
  return (
    <Navbar expand="md" sticky={'top'} className={'glass-effect'}>
      <NavbarBrand tag={Link} to={props.tenantRoutePrefix}>
        <img src="/images/logo.svg" alt="UniCatDB" />
      </NavbarBrand>
      <NavbarToggler onClick={toggle}>
        {' '}
        <FontAwesomeIcon icon="bars" />{' '}
      </NavbarToggler>
      <Collapse isOpen={isOpen} navbar>
        <Nav navbar className="mr-auto">
          <NavItem>
            <NavLink to={props.tenantRoutePrefix+'/findings'} tag={RRNavLink} activeClassName="active">
              <FontAwesomeIcon className="mr-2 text-primary nav-icon" icon={'list'} />
              Findings
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to={props.tenantRoutePrefix+'/schemas'} tag={RRNavLink} activeClassName="active">
              <FontAwesomeIcon className="mr-2 text-primary nav-icon" icon={'sitemap'} />
              Schemas
            </NavLink>
          </NavItem>
        </Nav>
        <Nav navbar>
          <NavItem className="d-flex align-items-center">
            <NavLink href="https://unicatdb.atlassian.net/servicedesk/customer/portals" target="_blank">
              <FontAwesomeIcon className="mr-2 text-primary nav-icon" icon={"question"} />
              Helpdesk
            </NavLink>
              
          </NavItem>
          <NavDropdownPersona
            tenantUser={props.activeTenantUser ?? undefined}
            tenant={props.activeTenant ?? undefined}
          >
            
            <DropdownItem onClick={() => {props.push("/workspaces")}}>
              <FontAwesomeIcon className="mr-1 text-primary" icon={"random"} />
              Switch workspace
            </DropdownItem>
            <DropdownItem onClick={() => {props.push("/workspaces/detail/"+props.activeTenant?.id)}}>
              <FontAwesomeIcon className="mr-1 text-primary" icon={"cog"} />
              Manage workspace
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => {window.open(process.env.REACT_APP_IDM_AUTHORITY,'_blank','noopener');}}>
              <FontAwesomeIcon className="mr-1 text-primary" icon={"user"} />
              Your profile
            </DropdownItem>
            <DropdownItem onClick={() => {window.open(process.env.REACT_APP_IDM_AUTHORITY+"/tokens",'_blank','noopener');}}>
              <FontAwesomeIcon className="mr-1 text-primary" icon={"key"} />
              API Access
            </DropdownItem>
            <DropdownItem onClick={logout}>
              <FontAwesomeIcon className="mr-1 text-primary" icon={"sign-out-alt"} />
              Logout
            </DropdownItem>
          </NavDropdownPersona>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMenuNew as any);
