import {LatLngGps} from "../services/GeneratedApiTsClient/models";

export const getCenterOfCoordsArray = (coordsArray: LatLngGps[] | undefined): LatLngGps => {
    if (!coordsArray || coordsArray.length === 0 || !coordsArray[0]) {
        return {lat: 48.9786197, lng: 14.4482139} // lokace franty laly
    }
    
    let bounds = {lowLat: coordsArray[0].lat, highLat: coordsArray[0].lat, lowLng: coordsArray[0].lng, highLng: coordsArray[0].lng}

    coordsArray.forEach(point => {
        if (!point) {
            return
        }
        if (point.lat > bounds.highLat) bounds.highLat = point.lat;
        if (point.lat < bounds.lowLat) bounds.lowLat = point.lat;
        if (point.lng > bounds.highLng) bounds.highLng = point.lng;
        if (point.lng < bounds.lowLng) bounds.lowLng = point.lng;
    })

    const centerLat = bounds.lowLat + ((bounds.highLat - bounds.lowLat) / 2);
    const centerLng = bounds.lowLng + ((bounds.highLng - bounds.lowLng) / 2);

    return {lat: centerLat, lng: centerLng}
}