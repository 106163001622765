import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


type Props = {
    children: any
}

export const DataCardParagraphElement = (props:Props) =>
    <div className="data-card-paragraph data-card-element">
        <FontAwesomeIcon icon="paragraph" className="icon" />
        {props.children}
    </div>;