import * as React from 'react';

type Props = {
    title: string,
    children: React.ReactNode,
    className?: string,
}

const GraphContainer = ({title, children, className}: Props) => {
    return (
        <div style={{height: '100%'}} className={"p-3 " + className}>
            {/*<div className="text-center text-main font-weight-bold">*/}
            {/*    {title}*/}
            {/*</div>*/}
            {children}
        </div>
    );
};

export default GraphContainer;