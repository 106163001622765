import React from "react";
import {connect} from "react-redux";
import {Route, RouteProps} from "react-router";
import {AppState} from "../store/reducers";
import UserManager from "../services/UserManager";
import {SplashScreen} from "../scenes/Splash/SplashScreen";

type Props = ReturnType<typeof mapStateToProps> & RouteProps;

const PrivateRoute = ({oidc, children, ...rest}: Props) => {

    if (
        (!oidc.user || oidc.user.expired) &&
        !oidc.isLoadingUser     // don't initiate redirect if the redux-oidc middleware is loading session from local storage
    ) {
        UserManager.signinRedirect(
            {
                data: {
                    path: window.location.pathname
                }
            }
        );
    }

    return (
        <Route {...rest}>
            {!oidc.user || oidc.user.expired ? <SplashScreen/> : children}
        </Route>
    );
};

const mapStateToProps = (state: AppState) => ({
    oidc: state.oidc
});

export default connect(mapStateToProps)(PrivateRoute);