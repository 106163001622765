import * as React from "react";
import {DataCardInputElement} from "./DataCardInputElement";
import {FormFeedback, Input} from "reactstrap";

const moment = require('moment');

type Props = {
    title: string,
    titleAfter?: any;
    attributeName: string,
    data: {
        [key: string] : any
    },
    onInput: (e: any) => void
    required?: boolean;
    alignColumn?: boolean;
    gutterBottom?: boolean;
    autoFocus?: boolean;
}

export const DataCardDateInputElement = (props:Props) =>
    <DataCardInputElement title={props.title} titleAfter={props.titleAfter} alignColumn={props.alignColumn} gutterBottom={props.gutterBottom}>
            <Input
                type="date"
                name={props.attributeName}
                value={props.data[props.attributeName] ? moment(props.data[props.attributeName]).format("YYYY-MM-DD") : ""}
                onInput={props.onInput}
                onChange={props.onInput}
                required={props.required}
                autoFocus={props.autoFocus}
            />
        <FormFeedback>
            {props.required ? 'This field is required. ' : ''}
        </FormFeedback>
    </DataCardInputElement>;