import {AppState} from "../reducers";
import {processApiResponse} from "./ErrorActions";
import {getConfiguredTenantsApi} from "../selectors/ApiSelectors";
import {push} from "connected-react-router";
import {TENANT_ROUTE_PATH} from "../../App";
import {Dispatch} from "redux";
import {TenantEntityDraft} from "../reducers/TenantReducer";
import {
    ExistingTenantRequestBody, ExistingTenantUserRequestBody,
    NewTenantRequestBody, TenantUser, TenantUserRole,
    UserScopedTenant
} from "../../services/GeneratedApiTsClient";
import {flushFindingSchemas} from "./FindingSchemasActions";

export enum TenantActions {
    TENANT_LOAD_AVAILABLE_INIT = "TENANT_LOAD_AVAILABLE_INIT",
    TENANT_LOAD_AVAILABLE_SUCCESS = "TENANT_LOAD_AVAILABLE_SUCCESS",
    TENANT_LOAD_AVAILABLE_CANCEL = "TENANT_LOAD_AVAILABLE_CANCEL",

    TENANT_ACTIVE_SWITCH_INIT = "TENANT_ACTIVE_SWITCH_INIT",
    TENANT_ACTIVE_SWITCH_SUCCESS = "TENANT_ACTIVE_SWITCH_SUCCESS",
    TENANT_ACTIVE_SWITCH_CANCEL = "TENANT_ACTIVE_SWITCH_CANCEL",
    TENANT_ACTIVE_CLEAR = "TENANT_ACTIVE_CLEAR",

    TENANT_ENTITY_DRAFT_LOAD_INIT = "TENANT_ENTITY_DRAFT_LOAD_INIT",
    TENANT_ENTITY_DRAFT_LOAD_SUCCESS = "TENANT_ENTITY_DRAFT_LOAD_SUCCESS",
    TENANT_ENTITY_DRAFT_LOAD_CANCEL = "TENANT_ENTITY_DRAFT_LOAD_CANCEL",
    TENANT_ENTITY_DRAFT_PATCH = "TENANT_ENTITY_DRAFT_PATCH",
    TENANT_ENTITY_DRAFT_CLEAR = "TENANT_ENTITY_DRAFT_CLEAR",
    TENANT_ENTITY_DRAFT_SAVE_INIT = "TENANT_ENTITY_DRAFT_SAVE_INIT",
    TENANT_ENTITY_DRAFT_SAVE_SUCCESS = "TENANT_ENTITY_DRAFT_SAVE_CANCEL",
    TENANT_ENTITY_DRAFT_SAVE_CANCEL = "TENANT_ENTITY_DRAFT_SAVE_CANCEL",

    TENANT_USER_PATCH_INIT = "TENANT_USER_PATCH_INIT",
    TENANT_USER_PATCH_SUCCESS = "TENANT_USER_PATCH_CANCEL",
    TENANT_USER_PATCH_CANCEL = "TENANT_USER_PATCH_CANCEL",
}

export const requestAvailableTenants = () => async (dispatch: Dispatch, getState: () => AppState) => {
    let state = getState();
    if (state.tenant.availableLoading) return;

    dispatch({type: TenantActions.TENANT_LOAD_AVAILABLE_INIT});

    let tenantsApi = getConfiguredTenantsApi(state);
    let availableTenants = await tenantsApi.apiTenantsAvailableGet().then(
        (response) => response,
        async (response) => {
            await processApiResponse(response);
            return undefined;
        }
    );

    if (availableTenants === undefined) {
        dispatch({type: TenantActions.TENANT_LOAD_AVAILABLE_CANCEL});
        return;
    }

    dispatch({type: TenantActions.TENANT_LOAD_AVAILABLE_SUCCESS, availableTenants});
};


export const switchTenant = (tenantId: string) => async (dispatch, getState: () => AppState) => {
    dispatch(push(TENANT_ROUTE_PATH.replace(':tenantId?', tenantId) + "/findings"));
};

export const loadTenant = (tenantId: string) => async (dispatch, getState: () => AppState) => {
    const state = getState();
    
    // prevent reloading if not necessary
    if (tenantId === state.tenant.activeTenantId) return;
    // prevent issuing duplicate requests 
    if (state.tenant.availableLoading) return;

    dispatch({type: TenantActions.TENANT_ACTIVE_SWITCH_INIT});

    let tenantsApi = getConfiguredTenantsApi(state);
    let tenantUsers = await tenantsApi.apiTenantsGetByIdUsers({tenantId: tenantId}).then(
        (response) => response,
        async (response) => {
            await processApiResponse(response);
            return undefined;
        }
    );

    if (tenantUsers === undefined) {
        dispatch({type: TenantActions.TENANT_ACTIVE_SWITCH_CANCEL});
        return;
    }

    dispatch(flushFindingSchemas());
    dispatch({type: TenantActions.TENANT_ACTIVE_SWITCH_SUCCESS, tenantId, tenantUsers});
};

export const clearLoadedTenant = () => async (dispatch, getState: () => AppState) => {
    dispatch({type: TenantActions.TENANT_ACTIVE_CLEAR});
};

export const loadTenantEntityDraft = (id: string) => async (dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.tenant.entityDraftLoading) return;

    dispatch({type: TenantActions.TENANT_ENTITY_DRAFT_LOAD_INIT});

    const tenantsApi = getConfiguredTenantsApi(state);
    const loadedEntityDraft = await tenantsApi.apiTenantsGetById({tenantId: id}).then(
        (response) => ({
            name: response.name
        }) as TenantEntityDraft,
        async (response) => {
            await processApiResponse(response);
            return undefined;
        }
    );

    if (loadedEntityDraft === undefined) {
        dispatch({type: TenantActions.TENANT_ENTITY_DRAFT_LOAD_CANCEL});
        return;
    }

    dispatch({type: TenantActions.TENANT_ENTITY_DRAFT_LOAD_SUCCESS, id: id, entity: loadedEntityDraft});
}

export const patchTenantEntityDraft = (entityPatch: Partial<TenantEntityDraft>) => (dispatch, getState: () => AppState) => {
    dispatch({type: TenantActions.TENANT_ENTITY_DRAFT_PATCH, entityPatch});
}

export const saveTenantEntityDraft = () => async (dispatch, getState: () => AppState) => {
    const state = getState();
    const draft = state.tenant.entityDraft;
    if (state.tenant.entityDraftLoading || draft === null) return;

    dispatch({type: TenantActions.TENANT_ENTITY_DRAFT_SAVE_INIT});

    let response: UserScopedTenant | undefined;
    // Create
    if (state.tenant.entityDraftId === null){
        
        const newTenantRequestBody: NewTenantRequestBody = {
            name: draft.name
        };
    
        const tenantsApi = getConfiguredTenantsApi(state);
        response = await tenantsApi.apiTenantsPost({newTenantRequestBody}).then(
            (response) => response,
            async (response) => {
                await processApiResponse(response);
                return undefined;
            }
        );
    // update
    } else {
        const existingTenantRequestBody: ExistingTenantRequestBody = {
            name: draft.name
        };

        const tenantsApi = getConfiguredTenantsApi(state);
        response = await tenantsApi.apiTenantsPutById({tenantId: state.tenant.entityDraftId, existingTenantRequestBody}).then(
            (response) => response,
            (response) => {
                processApiResponse(response, 'Error updating tenant ID ' + state.tenant.entityDraftId)(dispatch);
                return undefined;
            }
        );
    }

    if (response === undefined) {
        dispatch({type: TenantActions.TENANT_ENTITY_DRAFT_SAVE_CANCEL});
        return;
    }

    dispatch({type: TenantActions.TENANT_ENTITY_DRAFT_SAVE_SUCCESS});
    dispatch({type: TenantActions.TENANT_ENTITY_DRAFT_CLEAR});
}

export const patchTenantUser = (tenantId: string, userId: string, role: TenantUserRole) => async (dispatch, getState: () => AppState) => {
    const state = getState();
    if (state.tenant.userPatchLoading) return;

    dispatch({type: TenantActions.TENANT_USER_PATCH_INIT});

    let response: TenantUser | undefined;
    const existingTenantUserRequestBody: ExistingTenantUserRequestBody = {
        role: role
    };

    const tenantsApi = getConfiguredTenantsApi(state);
    response = await tenantsApi.apiTenantsPutByIdUsers({tenantId, userId, existingTenantUserRequestBody}).then(
        (response) => response,
        (response) => {
            processApiResponse(response, 'Error updating tenant user ID ' + userId)(dispatch);
            return undefined;
        }
    );

    if (response === undefined) {
        dispatch({type: TenantActions.TENANT_USER_PATCH_CANCEL});
        return;
    }

    dispatch({type: TenantActions.TENANT_USER_PATCH_SUCCESS});
}

export const clearTenantEntityDraft = () => (dispatch, getState: () => AppState) => {
    dispatch({type: TenantActions.TENANT_ENTITY_DRAFT_CLEAR});
}

export default {
    requestAvailableTenants,
    switchTenant,
    clearLoadedTenant,
    loadTenantEntityDraft,
    patchTenantEntityDraft,
    saveTenantEntityDraft,
    clearTenantEntityDraft,
    patchTenantUser
}