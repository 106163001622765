import * as React from "react";
import {FindingSchemaElement} from "../../services/GeneratedApiTsClient";
import $ from 'jquery';
import {registerJQuery} from "../../registerJQuery";

registerJQuery();

require("formBuilder/dist/form-builder.min.js");        // not pretty but works
require('./plugins/controlFastaData.js');

type Props = {
    formKey: string,
    definitions: Array<FindingSchemaElement>,
    onInput: (DynamicData) => void,
    id: string,
}

type State = any;

export class DynamicFormBuilderRenderer extends React.Component<Props, State> {

    formBuilderWrapperNode: any;
    formBuilderWrapperNodeJQuery: any;
    
    formBuilderInstance: any; 

    async componentDidMount() {
        
        let formBuilderOptions = {
            showActionButtons: false,
            disableHTMLLabels: true,
            dataType: 'json',
            disableFields: [
                'button',
                'file',
                'hidden',
                'autocomplete'
            ],
            disabledAttrs: [
                'access',
                'className',
                'name',
                'inline',
                'toggle',
                'value',
                'rows'
            ],
            typeUserDisabledAttrs: {
                'paragraph': [
                    'subtype'
                ],
                'select': [
                    'placeholder'           // todo: Disable placeholder for multiselec, it blocks other selected values (basic selects are fine)
                ],
                'date': [
                    'placeholder'
                ],
                'fasta': [
                    'placeholder'
                ],
            },
            typeUserAttrs: {
                text: {
                    subtype: {
                        options: {
                            'text': 'Plain text',
                            'email': 'Email address',
                            'tel': 'Phone number'
                        }
                    }
                }
            }
        };

        this.formBuilderWrapperNodeJQuery = $(this.formBuilderWrapperNode);

        this.formBuilderInstance = await this.formBuilderWrapperNodeJQuery.formBuilder(formBuilderOptions).promise;
        this.formBuilderInstance.actions.setData(JSON.stringify(this.props.definitions));
        
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.definitions !== prevProps.definitions) {
            if (!this.formBuilderInstance)
                return;
                
            if (this.formBuilderInstance.promise){ // wait for initialization if needed
                this.formBuilderInstance.promise.then(formBuilder => {
                    formBuilder.actions.setData(JSON.stringify(this.props.definitions));
                });
            } else {
                this.formBuilderInstance.actions.setData(JSON.stringify(this.props.definitions));
            }
        }
    }

    componentWillUnmount(){
        this.formBuilderWrapperNodeJQuery.formBuilder('destroy');        // destroy jQuery plugin, see: https://reactjs.org/docs/integrating-with-other-libraries.html
    }

    getDefinitions(): Array<FindingSchemaElement>{
        return this.formBuilderInstance.actions.getData();
    }

    render() {
        return (
            <div id={"dynamic-form-builder"} ref={el => (this.formBuilderWrapperNode = el)} className={"legacy-form-builder"}></div>
        );
    }
}
