import {PageQuery} from "../../services/GeneratedApiTsClient";
import ApiService, {ApiOnRejectedResult} from "../../services/ApiService/ApiService";
import {Sort} from "../../models/SortModel";
import {processApiResponse} from "./ErrorActions";
import {getConfiguredFindingsApi} from "../selectors/ApiSelectors";
import {selectActiveTenantId} from "../selectors/TenantSelectors";

export const REQUEST_LISTING: string = 'REQUEST_LISTING';
export const RECEIVE_LISTING: string = 'RECEIVE_LISTING';

export const CANCEL_REQUEST: string = 'CANCEL_REQUEST';

export const CLEAR_CACHE: string = 'CLEAR_CACHE';

export const GO_TO_PAGE: string = 'GO_TO_PAGE';

export const SET_SORTING: string = 'SET_SORTING';
export const SET_PAGE_SIZE: string = 'SET_PAGE_SIZE';

export const SET_SELECTED_FINDING_IDS = 'SET_SELECTED_FINDING_IDS';


export const requestListing = () => async (dispatch, getState) => {
    let state = getState();
    if (state.listing.loadingInProgress) {
        // Don't issue a duplicate request (we are already loading the requested data)
        return;
    }

    dispatch({type: REQUEST_LISTING});
    let findingsApi = getConfiguredFindingsApi(state);
    const tenantId = selectActiveTenantId(state);

    let pageQuery:PageQuery = {
        "number": state.listing.currentPage,
        "size": state.listing.pageSize,
    };

    let filterQuery = ApiService.buildFilterQuery(state.filter.filters);
    let sortQuery = ApiService.buildSortQuery(state.listing.sorting);
    if(!tenantId) return;
    let loadedFindings = await findingsApi.apiFindingsGet({tenantId, sort: sortQuery, page: pageQuery, filter: filterQuery})
        .catch((error: ApiOnRejectedResult) => {
            processApiResponse(error, "Error loading findings")(dispatch);
        });
    
    if (!loadedFindings) {
        dispatch({type: CANCEL_REQUEST});
    } else {
        dispatch({
            type: RECEIVE_LISTING,
            loadedFindings: loadedFindings.data,
            totalRecords: loadedFindings.meta!.totalRecords,
        });
    }
};

export const goToPage = (page: any) => (dispatch, getState) => {
    console.log('page');
    dispatch({
        type: GO_TO_PAGE,
        page: page.selected + 1,
    });
    dispatch(requestListing());
    
};

export const setPageSize = (pageSize: number) => (dispatch, getState) => {

    dispatch({
        type: SET_PAGE_SIZE,
        pageSize: pageSize,
    });
    dispatch(requestListing());

};

export const setSorting = (sorting: Array<Sort>) => (dispatch, getState) => {

    dispatch({
        type: SET_SORTING,
        sorting: sorting,
    });
    dispatch(requestListing());

};

export const reloadListing = () => (dispatch, getState) => {
    dispatch({type: CLEAR_CACHE});
    dispatch(requestListing());
};

export const setSelectedFindingIds = (selectedFindings: string[]) => (dispatch, getState) => {
    dispatch({type: SET_SELECTED_FINDING_IDS, payload: selectedFindings})
};



export default {
    requestListing,
    goToPage,
    setPageSize,
    setSorting,
    reloadListing
}