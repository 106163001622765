/* tslint:disable */
/* eslint-disable */
/**
 * UniCatDB API
 * UniCatDB application API documentation, with examples and live testing.  This API is built in accordance with the **JSON API 1.0 standard**. For general information, see [the documentation](http://jsonapi.org/format/).  -  JSON API standard requires use of the JSON API media type (application/vnd.api+json) for exchanging data. Clients must send all JSON API data with the headers `Content-Type: application/vnd.api+json` (POST, PATCH) and `Accept: application/vnd.api+json` (GET, DELETE). - [Relationships](http://jsonapi.org/format/#fetching-relationships) and their [inclusions](http://jsonapi.org/format/#fetching-includes) via the `include` query parameter, as specified by the standard, **are not implemented**, since there are no relationships present in the data model. - The standard does not prescribe any filtering strategies. This API implements two strategies which can be combined: **Basic filtering** based on the used [JSON API library](https://json-api-dotnet.github.io/#/filtering) and **Custom filtering** which allow for any possible query to the MongoDB server and can be extended and customize in the future. For more information about filtering, see the description of the `filter` down bellow. - **Non-standard PATCH behavior:** Sucessfull PATCH reuest always result in HTTP 200 response with the updated resource object, even if the server does not perform any additional modifications. HTTP 204 is never used in PATCH responses.  Attachment upload endpoint uses the **TUS protocol**. See [the documentation](https://tus.io/). When integrating, it is recommended to use one of the [official TUS client implementations](https://tus.io/implementations.html).  - **Supported TUS versions**:   -  1.0.0 - **Supported TUS extensions**:   - Creation   - Creation Defer Length   - Creation With Upload   - Checksum   - Checksum Trailer - **Required metadata**:   - `fileName` - name of the original file incl. file extension   - `contentType`- the MIME type of the file 
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ExistingTenantRequestBody,
    ExistingTenantRequestBodyFromJSON,
    ExistingTenantRequestBodyToJSON,
    ExistingTenantUserRequestBody,
    ExistingTenantUserRequestBodyFromJSON,
    ExistingTenantUserRequestBodyToJSON,
    Invitation,
    InvitationFromJSON,
    InvitationToJSON,
    NewInvitationRequestBody,
    NewInvitationRequestBodyFromJSON,
    NewInvitationRequestBodyToJSON,
    NewTenantRequestBody,
    NewTenantRequestBodyFromJSON,
    NewTenantRequestBodyToJSON,
    TenantUser,
    TenantUserFromJSON,
    TenantUserToJSON,
    UserScopedTenant,
    UserScopedTenantFromJSON,
    UserScopedTenantToJSON,
} from '../models';

export interface ApiTenantsGetByIdRequest {
    tenantId: string;
}

export interface ApiTenantsGetByIdInvitationsByKeyRequest {
    tenantId: string;
    key: string;
}

export interface ApiTenantsGetByIdInvitationsByKeyRedeemRequest {
    tenantId: string;
    key: string;
}

export interface ApiTenantsGetByIdUsersRequest {
    tenantId: string;
}

export interface ApiTenantsPostRequest {
    newTenantRequestBody?: NewTenantRequestBody;
}

export interface ApiTenantsPostByIdInvitationsRequest {
    tenantId: string;
    newInvitationRequestBody?: NewInvitationRequestBody;
}

export interface ApiTenantsPutByIdRequest {
    tenantId: string;
    existingTenantRequestBody?: ExistingTenantRequestBody;
}

export interface ApiTenantsPutByIdUsersRequest {
    tenantId: string;
    userId: string;
    existingTenantUserRequestBody?: ExistingTenantUserRequestBody;
}

/**
 * no description
 */
export class TenantsApi extends runtime.BaseAPI {

    /**
     * List tenants available to the current user
     */
    async apiTenantsAvailableGetRaw(): Promise<runtime.ApiResponse<Array<UserScopedTenant>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserScopedTenantFromJSON));
    }

    /**
     * List tenants available to the current user
     */
    async apiTenantsAvailableGet(): Promise<Array<UserScopedTenant>> {
        const response = await this.apiTenantsAvailableGetRaw();
        return await response.value();
    }

    /**
     * Get specific tenant available to the current user, if exists
     */
    async apiTenantsGetByIdRaw(requestParameters: ApiTenantsGetByIdRequest): Promise<runtime.ApiResponse<UserScopedTenant>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiTenantsGetById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenantId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserScopedTenantFromJSON(jsonValue));
    }

    /**
     * Get specific tenant available to the current user, if exists
     */
    async apiTenantsGetById(requestParameters: ApiTenantsGetByIdRequest): Promise<UserScopedTenant> {
        const response = await this.apiTenantsGetByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get invitation details (without redeeming)
     */
    async apiTenantsGetByIdInvitationsByKeyRaw(requestParameters: ApiTenantsGetByIdInvitationsByKeyRequest): Promise<runtime.ApiResponse<Invitation>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiTenantsGetByIdInvitationsByKey.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling apiTenantsGetByIdInvitationsByKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenantId}/invitations/{key}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationFromJSON(jsonValue));
    }

    /**
     * Get invitation details (without redeeming)
     */
    async apiTenantsGetByIdInvitationsByKey(requestParameters: ApiTenantsGetByIdInvitationsByKeyRequest): Promise<Invitation> {
        const response = await this.apiTenantsGetByIdInvitationsByKeyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Redeem invitation, associate current user to the tenant
     */
    async apiTenantsGetByIdInvitationsByKeyRedeemRaw(requestParameters: ApiTenantsGetByIdInvitationsByKeyRedeemRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiTenantsGetByIdInvitationsByKeyRedeem.');
        }

        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key','Required parameter requestParameters.key was null or undefined when calling apiTenantsGetByIdInvitationsByKeyRedeem.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenantId}/invitations/{key}/redeem`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Redeem invitation, associate current user to the tenant
     */
    async apiTenantsGetByIdInvitationsByKeyRedeem(requestParameters: ApiTenantsGetByIdInvitationsByKeyRedeemRequest): Promise<void> {
        await this.apiTenantsGetByIdInvitationsByKeyRedeemRaw(requestParameters);
    }

    /**
     * List users associated with a specific tenant available to the current user
     */
    async apiTenantsGetByIdUsersRaw(requestParameters: ApiTenantsGetByIdUsersRequest): Promise<runtime.ApiResponse<Array<TenantUser>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiTenantsGetByIdUsers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenantId}/users`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenantUserFromJSON));
    }

    /**
     * List users associated with a specific tenant available to the current user
     */
    async apiTenantsGetByIdUsers(requestParameters: ApiTenantsGetByIdUsersRequest): Promise<Array<TenantUser>> {
        const response = await this.apiTenantsGetByIdUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * New tenant will be created with current user as owner.  
     * Create new tenant
     */
    async apiTenantsPostRaw(requestParameters: ApiTenantsPostRequest): Promise<runtime.ApiResponse<UserScopedTenant>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewTenantRequestBodyToJSON(requestParameters.newTenantRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserScopedTenantFromJSON(jsonValue));
    }

    /**
     * New tenant will be created with current user as owner.  
     * Create new tenant
     */
    async apiTenantsPost(requestParameters: ApiTenantsPostRequest): Promise<UserScopedTenant> {
        const response = await this.apiTenantsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * <b>Required tenant role:</b> OWNER 
     * Create invitation for new potential user of the tenant
     */
    async apiTenantsPostByIdInvitationsRaw(requestParameters: ApiTenantsPostByIdInvitationsRequest): Promise<runtime.ApiResponse<Invitation>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiTenantsPostByIdInvitations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenantId}/invitations`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewInvitationRequestBodyToJSON(requestParameters.newInvitationRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationFromJSON(jsonValue));
    }

    /**
     * <b>Required tenant role:</b> OWNER 
     * Create invitation for new potential user of the tenant
     */
    async apiTenantsPostByIdInvitations(requestParameters: ApiTenantsPostByIdInvitationsRequest): Promise<Invitation> {
        const response = await this.apiTenantsPostByIdInvitationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Note: this endpint does not make any changes to the permissions or roles of associated users. 
     * Update an existing tenant, if exists
     */
    async apiTenantsPutByIdRaw(requestParameters: ApiTenantsPutByIdRequest): Promise<runtime.ApiResponse<UserScopedTenant>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiTenantsPutById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenantId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExistingTenantRequestBodyToJSON(requestParameters.existingTenantRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserScopedTenantFromJSON(jsonValue));
    }

    /**
     * Note: this endpint does not make any changes to the permissions or roles of associated users. 
     * Update an existing tenant, if exists
     */
    async apiTenantsPutById(requestParameters: ApiTenantsPutByIdRequest): Promise<UserScopedTenant> {
        const response = await this.apiTenantsPutByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * <b>Required tenant role:</b> OWNER 
     * Update an existing tenant user, if exists
     */
    async apiTenantsPutByIdUsersRaw(requestParameters: ApiTenantsPutByIdUsersRequest): Promise<runtime.ApiResponse<TenantUser>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling apiTenantsPutByIdUsers.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling apiTenantsPutByIdUsers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenantId}/users/{userId}`.replace(`{${"tenantId"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExistingTenantUserRequestBodyToJSON(requestParameters.existingTenantUserRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TenantUserFromJSON(jsonValue));
    }

    /**
     * <b>Required tenant role:</b> OWNER 
     * Update an existing tenant user, if exists
     */
    async apiTenantsPutByIdUsers(requestParameters: ApiTenantsPutByIdUsersRequest): Promise<TenantUser> {
        const response = await this.apiTenantsPutByIdUsersRaw(requestParameters);
        return await response.value();
    }

}
