import * as React from "react";

import {TenantUser} from "../services/GeneratedApiTsClient";
import Avatar, {ReactAvatarProps} from "react-avatar";
import { UniCatAvatar } from "./UniCatAvatar";

export type UserForAvatar = Omit<TenantUser, "id" | "role">;

export type Props = Pick<ReactAvatarProps, "size" | "onClick" | "className"> & {
    user: UserForAvatar
}

export const TenantUserPersona = (props: Props) => {

    // TODO: remove ts-ignore after TS definitions fix in "react-avatar"
    // @ts-ignore
    const getRandomColor: (s:string) => string = Avatar.getRandomColor;
    
    const getName: (u:UserForAvatar) => string = (u) => (u.firstName && u.lastName ? u.firstName+' '+u.lastName : u.fullName);

    return <UniCatAvatar name={getName(props.user)}
                         src={props.user.pictureUrl}
                         className={props.className}
                         size={props.size}
                         round={true}
                         onClick={props.onClick}
                         color={getRandomColor(props.user.idmSubjectId)}
                         maxInitials={2}
                         style={props.onClick ? {cursor: 'pointer'} : {}}
    />
} 