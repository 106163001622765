import {GUIActions, GUIActionsType} from '../actions/GUIActions';
import {Invitation} from "../../services/GeneratedApiTsClient";

type GUIReducerState = {
  splitMode: boolean;
  mapPanelActive: boolean;
  graphPanelActive: boolean;
  attachmentsCarouselActive: boolean;
  codegenModalActive:boolean;
  codegenModalType: "LISTING" | "NEW_FINDING";
  invitationModalActive: boolean;
  invitationModalLoading: boolean;
  inviteModalInvitation?: Invitation;
};

export const GUIReducerInitialState: GUIReducerState = {
  splitMode: false,
  mapPanelActive: false,
  graphPanelActive: false,
  attachmentsCarouselActive: false,
  codegenModalActive: false,
  codegenModalType: "LISTING",
  invitationModalActive: false,
  invitationModalLoading: false,
};

export default function GUIReducer(state = GUIReducerInitialState, actions: GUIActions): GUIReducerState {
  switch (actions.type) {
    case GUIActionsType.OPEN_MAP_VIEW:
      return {
        ...state,
        mapPanelActive: true,
        graphPanelActive: false,
        splitMode: true,
      };
    case GUIActionsType.CLOSE_MAP_VIEW:
      return {
        ...state,
        mapPanelActive: false,
        splitMode: false,
      };
    case GUIActionsType.OPEN_GRAPH_VIEW:
      return {
        ...state,
        graphPanelActive: true,
        mapPanelActive: false,
        splitMode: true,
      };
    case GUIActionsType.CLOSE_GRAPH_VIEW:
      return {
        ...state,
        graphPanelActive: false,
        splitMode: false,
      };
    case GUIActionsType.SHOW_ATTACHMENT_GALLERY:
      return {
        ...state,
        attachmentsCarouselActive: true,
      };
    case GUIActionsType.HIDE_ATTACHMENT_GALLERY:
      return {
        ...state,
        attachmentsCarouselActive: false,
      }
    case GUIActionsType.SHOW_CODEGEN_MODAL:
      return {
        ...state,
        codegenModalActive: true,
        codegenModalType: actions.codegenModalType ?? "LISTING"
      };
    case GUIActionsType.HIDE_CODEGEN_MODAL:
      return {
        ...state,
        codegenModalActive: false,
      }
    case GUIActionsType.REQUEST_INVITATION_MODAL:
      return {
        ...state,
        invitationModalActive: true,
        invitationModalLoading: true,
      };
    case GUIActionsType.SHOW_INVITATION_MODAL:
      return {
        ...state,
        invitationModalLoading: false,
        inviteModalInvitation: actions.invitation
      }
      
    case GUIActionsType.HIDE_INVITATION_MODAL:
      return {
        ...state,
        invitationModalActive: false,
        invitationModalLoading: false,
        inviteModalInvitation: undefined,
      }
    default:
      return state;
  }
}
