import {ExportActions} from "../actions/ExportActions";

export type ExportReducerState = {
    inProgress: boolean,
}

export const ExportReducerInitialState: ExportReducerState = {
    inProgress: false,
};

export default function exportReducer (state: ExportReducerState = ExportReducerInitialState, action) {
    switch (action.type) {
        case ExportActions.REQUEST_EXPORT:
            return { ...state, inProgress: true};
        case ExportActions.RECEIVE_EXPORT:
        case ExportActions.CANCEL_EXPORT:
            return { ...state, inProgress: false};
        default:
            return state;
    }
};