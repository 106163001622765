import * as React from "react";
import {Button} from "reactstrap";
import {TaxonomyName} from "../../../services/GeneratedApiTsClient/models";
import {Filter} from "../../../models/FilterModel";
import {WellKnownAttributes} from "../../../models/FindingWellKnownAttributes";
import {FILTER_OP} from "../../../models/FilterOperationModel";
import {AppState} from "../../../store/reducers";
import {setFilters} from "../../../store/actions/FilterActions";
import {push} from "connected-react-router";
import {connect} from "react-redux";
import {FindingTaxonomyElement} from "./FindingTaxonomyElement";
import {getActiveTenantRoutePrefix} from "../../../store/selectors/TenantSelectors";


const mapStateToProps = (state: AppState) => ({
    tenantRoutePrefix: getActiveTenantRoutePrefix(state)
});

const mapDispatchToProps = {
    setFilters,
    push
};

type ComponentProps = {
    taxonomyName: TaxonomyName
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & ComponentProps;

type State = {};

class FindingTaxonomy  extends React.Component<Props, State> {

    taxonomyKeys: Array<keyof TaxonomyName> = ["kingdom","phylum","_class","order","family","genus","species"];

    filterByTaxonomy(toLevel: keyof TaxonomyName)
    {
        let taxonomyName = this.props.taxonomyName;

        if (!taxonomyName) return;

        let filters: Array<Filter> = [];

        for(let index in this.taxonomyKeys){
            let taxonomyKey = this.taxonomyKeys[index];

            let value = taxonomyName[taxonomyKey];
            if(!value)
                continue;

            filters.push(new Filter(WellKnownAttributes.taxonomyName[taxonomyKey], FILTER_OP.eqi, value, "text"));

            if(toLevel === taxonomyKey)
                break;
        }

        this.props.setFilters(filters);
        this.props.push(this.props.tenantRoutePrefix+"/findings");
    }


    render(){
        return (
            <div>

                <FindingTaxonomyElement name="Kingdom" value={this.props.taxonomyName.kingdom} onClick={() => this.filterByTaxonomy("kingdom")} />
                <FindingTaxonomyElement name="Phylum" value={this.props.taxonomyName.phylum} onClick={() => this.filterByTaxonomy("phylum")} />
                <FindingTaxonomyElement name="Class" value={this.props.taxonomyName._class} onClick={() => this.filterByTaxonomy("_class")} />
                <FindingTaxonomyElement name="Order" value={this.props.taxonomyName.order} onClick={() => this.filterByTaxonomy("order")} />
                <FindingTaxonomyElement name="Family" value={this.props.taxonomyName.family} onClick={() => this.filterByTaxonomy("family")} />
                <FindingTaxonomyElement name="Genus" value={this.props.taxonomyName.genus} onClick={() => this.filterByTaxonomy("genus")} />
                <FindingTaxonomyElement name="Species" value={this.props.taxonomyName.species} onClick={() => this.filterByTaxonomy("species")} />

                {this.props.taxonomyName.authorship && <div className={"text-center d-inline-block mx-2"}>
                    <em className="d-block small text-muted">Authorship</em>
                    <Button size="sm" color="">
                        {this.props.taxonomyName.authorship}
                    </Button>
                </div>}

            </div>
        )

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(FindingTaxonomy as unknown as React.ComponentClass<ComponentProps>);