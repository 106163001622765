import {createSelector} from "reselect";
import {AppState} from "../reducers";
import {selectActiveTenantUsers} from "./TenantSelectors";
import {TenantUserRole} from "../../services/GeneratedApiTsClient";

export const selectIsUserLoading = (state: AppState) => state.oidc.isLoadingUser;

export const selectCurrentUsersIdmSubjectId = (state: AppState) => state.oidc.user?.profile?.sub;

export const getTenantUserOfSelf = createSelector(
    [selectActiveTenantUsers, selectCurrentUsersIdmSubjectId],
    (activeTenantUsers, idmSubjectId) =>
    activeTenantUsers.find(t => t.idmSubjectId === idmSubjectId) ?? null
);

/**
 * Test if user is in at least one of the given roles.
 * 
 * @param state
 * @param props
 */
export const isTenantUserInRole = (state: AppState, props: {role: TenantUserRole[]}) : boolean => {
    const currentRole = getTenantUserOfSelf(state)?.role;
    if (currentRole){
        return props.role.includes(currentRole)
    }
    return false;
};