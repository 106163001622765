import * as React from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ButtonProps} from "reactstrap/lib/Button";

type Props = Omit<ButtonProps, "onClick"> & {
    textToDownload: string;
    downloadMessage?: string;
    fileName?: string;
};

export const DownloadFasta: any = ({textToDownload, downloadMessage, fileName, ...buttonProps}: Props) => {

    const defaultButtonText = 'Download Fasta';
    const defaultFileText = 'fasta';

    const download = async () => {
        const element = document.createElement("a");
        const file = new Blob([textToDownload], {type: 'application/x-fasta'})
        element.href = URL.createObjectURL(file);
        element.download = fileName ?? defaultFileText;
        document.body.appendChild(element);
        element.click();
    }

    return (
        <Button outline color="secondary" size={'sm'} onClick={download} {...buttonProps}>
            <FontAwesomeIcon icon="file-export"/>
            {downloadMessage ?? defaultButtonText}
        </Button>
    );
};
