import {
    FindingSchema,
    FindingSchemaElement,
    FindingSchemaNestedDataDefinition,
} from "../../services/GeneratedApiTsClient";
import {Filter} from "../../models/FilterModel";
import {ICodeGenerator} from "./ICodeGenerator";
import {SupportedFilterOperations} from "../../models/FilterOperationModel";


export class PythonCodeGenerator implements ICodeGenerator {

    private readonly isTestServer: boolean = false;

    constructor() {
        this.isTestServer = !!(process.env.REACT_APP_CODEGEN_IS_TEST_SERVER && process.env.REACT_APP_CODEGEN_IS_TEST_SERVER === 'true');
    }

    private import() {
        return `# Make sure to install the 'unicatdb' package to your environment, preferably using pip:
# > 'pip install unicatdb'

import unicatdb`;
    }

    private configuration() {
        return `# Paste your Personal access token from https://account.unicatdb.org/\nconfiguration = unicatdb.Configuration(
    access_token='<PASTE YOUR API KEY TOKEN HERE>'${this.isTestServer ? `,
    server=unicatdb.Servers.TEST_UNICATDB_ORG` : ''}
)`;
    }

    public renderFilters(tenantId: string, filters: Array<Filter>): string {

        return `${this.import()}
from unicatdb.openapi_client import FindingArrayResponse, PageQuery
from pprint import pprint

${this.configuration()}

# Query the data - apply filtering during the API call to leave the heavy-lifting to the server
with unicatdb.Client(configuration) as client:

    # workspace ID
    workspace_id = "${tenantId}"${filters && filters.length > 0 ? `

    # filtering
    filter_expressions = {
${filters.map(f => '        "' + f.attribute + '": "' + SupportedFilterOperations[f.operation].prefix + f.value + '"').join(',\n')}
    }` : ''}

    # get first twenty records
    page_query = PageQuery(number=1, size=20)

    # disable paging - uncomment next line to get all records
    #page_query = PageQuery(number=1, size=-1)
    
    findings: FindingArrayResponse = client.findings.api_findings_get(
        workspace_id,${filters && filters.length > 0 ? `
        filter=filter_expressions,` : ''}
        page=page_query
    )

    # pretty-print results - replace with your processing logic
    pprint(findings)
`;

    }

    public renderNewFinding(tenantId: string, schema: FindingSchema, schemaId: string): string {

        const attributes: FindingSchemaElement[] = schema.definitionGroups?.flatMap(x => x.definitions).filter(x => x && x.name) ?? [];
        const nestedDataAttributes: FindingSchemaNestedDataDefinition[] = schema.nestedDataDefinitions?.filter(x => x && x.name) ?? [];

        return `${this.import()}  
from unicatdb.openapi_client import FindingSingleResponse, FindingResourceObject, \\
    NewFindingRequestBody, RelationshipResourceIdentifier, ResponseRelationshipOneToOne, \\
    FindingResourceObjectRelationships, TaxonomyName, Finding
from pprint import pprint

${this.configuration()}

# Create a new finding in schema '${schema.name}' 
with unicatdb.Client(configuration) as client:

    # EXAMPLE of fully populated finding

    # new_finding = Finding(
    #     document_name="API codegen generated finding 1234",
    #     amount=333,
    #     document_set="Example set",
    #     date="2020-12-31T00:00:00.000Z",
    #     location_description="Example location",
    #     location_gps_point=(LatLngGps(lat=41.123,lng=51.654)),
    #     location_gps_area=[
    #         LatLngGps(lat=41.123, lng=51.654),
    #         LatLngGps(lat=41.124, lng=51.656),
    #         LatLngGps(lat=41.125, lng=51.657),
    #     ],
    #     note="Example note",
    #     tags=["My tag1", "My tag2"],
    #     taxonomy_human_readable="Felis silvestris f. catus (Ragni a Randi, 1986)",
    #     taxonomy_name=(TaxonomyName(
    #         kingdom="Animalia",
    #         phylum="Chordata",
    #         _class="Mammalia",
    #         order="Carnivora",
    #         family="Felidae",
    #         genus="Felis",
    #         species="silvestris",
    #         authorship="Ragni a Randi, 1986"
    #     )),
    #     attachment_note="Example attachment note",
    #     dynamic_data=({
${attributes.map(f => '    #        "' + f.name + '": None').join(',\n') + (nestedDataAttributes.length ? ',' : '')}
${nestedDataAttributes.map(n =>
            '    #        "' + n.name + '": [\n' +
            '    #            {\n' + n.definitions.filter(x => x && x.name).map(f => '    #                "' + f.name + '": None').join(',\n') + '\n    #            },\n' +
            '    #            {\n' + n.definitions.filter(x => x && x.name).map(f => '    #                "' + f.name + '": None').join(',\n') + '\n    #            }\n' +
            '    #        ]'
        ).join(',\n')}
    #     })
    # )

    new_finding = Finding(
        document_name="API codegen generated finding 1234",
        amount=1,
        document_set=None,
        date=None,
        person=None,
        location_description=None,
        location_gps_point=None,
        location_gps_area=None,
        note=None,
        tags=None,
        taxonomy_human_readable=None,
        taxonomy_name=(TaxonomyName(
            kingdom=None,
            phylum=None,
            _class=None,
            order=None,
            family=None,
            genus=None,
            species=None,
            authorship=None
        )),
        attachment_note=None,
        dynamic_data=({
${attributes.map(f => '            "' + f.name + '": None').join(',\n') + (nestedDataAttributes.length ? ',' : '')}
${nestedDataAttributes.map(n =>
            '            "' + n.name + '": [\n' +
            '                {\n' + n.definitions.filter(x => x && x.name).map(f => '                    "' + f.name + '": None').join(',\n') + '\n                },\n' +
            '                {\n' + n.definitions.filter(x => x && x.name).map(f => '                    "' + f.name + '": None').join(',\n') + '\n                }\n' +
            '            ]'
        ).join(',\n')}
        })
    )

    # assign to schema
    new_finding_relationships = FindingResourceObjectRelationships(
        schema=(ResponseRelationshipOneToOne(
            data=(RelationshipResourceIdentifier(
                type="schemas",
                id="${schemaId}"     # ID of schema '${schema.name}'
            ))
        ))
    )

    # construct request payload
    create_finding_request = NewFindingRequestBody(data=(
        FindingResourceObject(
            type="findings",
            attributes=new_finding,
            relationships=new_finding_relationships
        )
    ))

    # workspace ID
    workspace_id = "${tenantId}"

    try:
        # insert new finding (make POST API call with request payload)
        insert_result: FindingSingleResponse = client.findings.api_findings_post(
            workspace_id,
            new_finding_request_body=create_finding_request
        )
        
        # pretty-print inserterted finding
        pprint(insert_result)
        
    except Exception as e:
        # add custom error handling code her
        print("Error occured when insering new finding: " + e.__str__())

`;

    }
}