import ApiService, {ApiOnRejectedResult} from "../../services/ApiService/ApiService";
import {AppState} from "../reducers";
import {processApiResponse} from "./ErrorActions";
import {FindingSchemaBasicInfoResourceObject} from "../models/FindingSchemaBasicInfo";
import {normalizeViewPreferences} from "./ViewPreferencesActions";
import {getConfiguredSchemasApi} from "../selectors/ApiSelectors";
import {selectActiveTenantId} from "../selectors/TenantSelectors";

export const REQUEST_FINDING_SCHEMAS: string = 'REQUEST_FINDING_SCHEMAS';
export const RECEIVE_FINDING_SCHEMAS: string = 'RECEIVE_FINDING_SCHEMAS';
export const CANCEL_RECEIVING_FINDING_SCHEMAS: string = 'CANCEL_RECEIVING_FINDING_SCHEMAS';
export const FLUSH_FINDING_SCHEMAS: string = 'FLUSH_FINDING_SCHEMAS';

export const requestFindingSchemas = () => async (dispatch, getState) => {
    if (getState().findingSchemas.loadingInProgress || getState().findingSchemas.loaded) {
        // Don't issue a duplicate request (we already have or are loading the requested data)
        return;
    }

    dispatch({type: REQUEST_FINDING_SCHEMAS});

    let state: AppState = getState();
    let schemasApi = getConfiguredSchemasApi(state);
    let tenantId = selectActiveTenantId(state);

    if(!tenantId) return;
    let basicInfoResponse : Array<FindingSchemaBasicInfoResourceObject> | void = await ApiService.getSchemaListWithBasicInfo(tenantId, schemasApi)
        .then((response) => {
            return response.data as Array<FindingSchemaBasicInfoResourceObject>
        })
        .catch((error: ApiOnRejectedResult) => {
            processApiResponse(error, "Error occured when refreshing schemas basic info")(dispatch);
        });

    let dynamicAttributesResponse = await ApiService.getDynamicFilterableAttributes(tenantId, schemasApi)
        .catch((error: ApiOnRejectedResult) => {
            processApiResponse(error, "Error occured when refreshing schemas dynamic attributes")(dispatch);
        });

    if (basicInfoResponse && basicInfoResponse && dynamicAttributesResponse) {
        dispatch({
            type: RECEIVE_FINDING_SCHEMAS,
            schemasBasicInfo: basicInfoResponse,
            schemaAttributes: dynamicAttributesResponse
        });
        dispatch(normalizeViewPreferences())
    } else {
        dispatch({type: CANCEL_RECEIVING_FINDING_SCHEMAS});
    }
};

export const flushFindingSchemas = () => {
    return {type: FLUSH_FINDING_SCHEMAS};
};

export default {
    requestFindingSchemas,
    flushFindingSchemas
}

