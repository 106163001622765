import * as React from 'react';
import {DataCardElement} from './DataCardElement';

type Props = {
  title?: string;
  value?: string | string[] | number;
  description?: string;
};

export const DataCardTextElement = (props: Props) => {
  const getFormattedValue = () => {
    if (!props.value) return '∅'
    if (typeof props.value === 'object') {
      return props.value.join(', ')
    }
    return props.value
  }
  return   <DataCardElement title={props.title} description={props.description}>{getFormattedValue()}</DataCardElement>
};
