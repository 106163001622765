import {
    CANCEL_REQUEST,
    FINDING_DELETE_SUCCESS,
    RECEIVE_EMPTY_WITH_SCHEMA,
    RECEIVE_FINDING,
    RECEIVE_FINDING_CLONE,
    REPLACE_FINDING_PROPERTY,
    REQUEST_EMPTY_WITH_SCHEMA,
    REQUEST_FINDING,
    REQUEST_FINDING_DELETE,
    REQUEST_FINDING_PERSIST,
    SUCCESS_FINDING_PERSIST,
    DELETE_UPLOADED_ATTACHMENT, UPDATE_ATTACHMENTS, SET_ACTIVE_ATTACHMENT
} from "../actions/FindingActions";
import {
    AttachmentResourceObject,
    Finding,
    FindingSchema,
    TaxonomyName
} from "../../services/GeneratedApiTsClient";

export type UploadedAttachment = {
    resource: AttachmentResourceObject,
    thumbnail?: Blob,
    data?: Blob,
}

export type FindingReducerState = {
    loaded: boolean,
    requestInProgress: boolean,
    loadedFindingId?: string,
    loadedFinding: Finding,
    loadedSchema?: FindingSchema,
    loadedSchemaId?: string
    uploadedAttachments: UploadedAttachment[], // attachments from server to show in finding detail
    activeAttachmentId: string | null,
    hasIncompleteAttachments: boolean,
}
const emptyFinding: Finding = {
    owner: "",
    permissions: [],
    documentName: "",
    dynamicData: {},
    documentSet: "",
    amount: undefined,
    date: undefined,
    person: "",
    locationDescription: "",
    locationGpsPoint: undefined,
    locationGpsArea: [],
    note: "",
    tags: [],
    taxonomyName: new  class implements TaxonomyName {}(),
    taxonomyHumanReadable: "",
    attachmentNote: ""
};

export const FindingReducerInitialState: FindingReducerState = {
    loaded: false,
    requestInProgress: false,
    loadedFindingId: undefined,
    loadedFinding: emptyFinding,
    loadedSchema: undefined,
    loadedSchemaId: undefined,
    uploadedAttachments: [],
    activeAttachmentId: null,
    hasIncompleteAttachments: false
};

export default (state: FindingReducerState = FindingReducerInitialState, action): FindingReducerState => {
    
    switch (action.type) {
        case REPLACE_FINDING_PROPERTY:
            return {
                ...state,
                loadedFinding: {
                    ...state.loadedFinding,
                    [action.propertyName] : action.newValue
                }
            };
        case DELETE_UPLOADED_ATTACHMENT:
            return {
                ...state,
                uploadedAttachments: state.uploadedAttachments.filter(att => att.resource.id !== action.payload)
            };
        case UPDATE_ATTACHMENTS:
            return {
                ...state,
                uploadedAttachments: action.payload
            };
        case SET_ACTIVE_ATTACHMENT: 
            return {
                ...state,
                activeAttachmentId: action.payload
            }
        case REQUEST_FINDING_PERSIST:
        case REQUEST_FINDING_DELETE:
            return {
                ...state,
                requestInProgress: true,
            };
        case FINDING_DELETE_SUCCESS:
            return {...state, requestInProgress: false, loaded: false};
        case REQUEST_FINDING: 
        case REQUEST_EMPTY_WITH_SCHEMA: 
            return {
                ...state,
                requestInProgress: true,
                loaded: false
            };
        case RECEIVE_FINDING:
            return {
                ...state,
                requestInProgress: false,
                loaded: true,
                loadedFindingId: action.findingId,
                loadedFinding: action.finding,
                loadedSchema: action.schema,
                loadedSchemaId: action.schemaId,
                uploadedAttachments: action.uploadedAttachments,
                hasIncompleteAttachments: action.hasIncompleteAttachments
            };
        case RECEIVE_FINDING_CLONE:

            action.finding.attachmentNote = null;
            
            return {
                ...state,
                requestInProgress: false,
                loaded: true,
                loadedFindingId: undefined,
                loadedFinding: action.finding,
                loadedSchema: action.schema,
                loadedSchemaId: action.schemaId,
                uploadedAttachments: [],
            };
        case RECEIVE_EMPTY_WITH_SCHEMA:
            return {
                ...state,
                requestInProgress: false,
                loaded: true,
                loadedSchema: action.schema,
                loadedSchemaId: action.schemaId,
                loadedFindingId: undefined,
                loadedFinding: emptyFinding
            };
        case SUCCESS_FINDING_PERSIST:
            return {
                ...state,
                requestInProgress: false,
                loaded: true,
                loadedFinding: action.patchedFinding,
                loadedFindingId: action.patchedFindingId,
            };
        case CANCEL_REQUEST:
            return {...state, requestInProgress: false};
        default:
            return state;
    }
};