import * as React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import {useLayoutEffect, useRef} from "react";
import {PieChart} from "@amcharts/amcharts4/charts";

export type PieChartGraphData = {
    name: string;
    value: number;
    color?: string;
}[];

type PieChartGraphProps = {
    data: PieChartGraphData;
    hideLabels?: boolean;
    title?: string;
};

const PieChartGraph = (props: PieChartGraphProps) => {

    const chartRef = useRef<PieChart|undefined>(undefined);
    const seriesRef = useRef<am4charts.PieSeries|undefined>(undefined);

    // replace chart series data on change
    useLayoutEffect(() => {
        if(!chartRef.current) return;
        chartRef.current.data = props.data;
    },[props.data])

    useLayoutEffect(() => {
        let chart = am4core.create('piechart-container', am4charts.PieChart);
        // Set inner radius
        chart.innerRadius = am4core.percent(25);
        chart.radius = am4core.percent(50);

        if (props.title){
            // enable title
            let title = chart.titles.create();
            title.text = props.title;
            title.fontSize = 10;
        }

        // chart.data = props.data;

        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.category = 'name';
        pieSeries.dataFields.value = 'value';
        pieSeries.slices.template.stroke = am4core.color('#fff');
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template.propertyFields.fill = "color";

        pieSeries.labels.template.maxWidth = 130;
        pieSeries.labels.template.wrap = true;
        // pieSeries.alignLabels = false;
        pieSeries.labels.template.text = "{category}";
        
        if (props.hideLabels){
            pieSeries.labels.template.disabled = true;
            pieSeries.ticks.template.disabled = true;
        }

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        chart.data = props.data;
        
        chartRef.current = chart;
        seriesRef.current = pieSeries;

        return () => {
            chart.dispose();
        };
    },[props.data, props.hideLabels, props.title]);

    return <div id="piechart-container" style={{width: '100%', height: '100%'}} />;
}

export default PieChartGraph;
