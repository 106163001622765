import * as React from "react";
import {Filter} from "../../../models/FilterModel";
import {FILTER_OP, SupportedFilterOperationsFor} from "../../../models/FilterOperationModel";
import {AttributeType, FilterableAttributesGroup} from "../../../models/FilterableAttributesModel";
import {Button, FormGroup, Input, InputGroup, InputGroupAddon} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ThemedSelect} from "../../ThemedSelect";

type Props = {
    filter: Filter,
    index: number,
    handleSelectChange: (index: number, name: string, input: { label: string, value: FILTER_OP, type: AttributeType }) => void,
    handleInputChange: (index: number, e: any) => void,
    handleRemove: (index: number) => void,
    onEnterKeyPressed: (e) => void,
    filterableAttributes: Array<FilterableAttributesGroup>
};

const FilterInputGroup = (props: Props) => {

    const attributeOptions = React.useMemo(() => props.filterableAttributes.map(attributeGroup => ({
        label: attributeGroup.name,
        options: attributeGroup.attributes.map(attribute => ({
            label: attribute.name,
            value: attribute.value,
            type: attribute.type
        }))
    })), [props.filterableAttributes])

    const defaultAttrOption = React.useMemo(() => {
            let option;
            attributeOptions.forEach(attrOpt => {
                const attr = attrOpt.options.find(opt => opt.value === props.filter.attribute)
                if (attr) {
                    option = attr
                }
            });
            return option
        }
        , [attributeOptions, props.filter.attribute]);


    const operationOptions = React.useMemo(() => props.filter.type ? Object.keys(SupportedFilterOperationsFor[props.filter.type]).map((key) => ({
        label: SupportedFilterOperationsFor[props.filter.type][key].humanReadable,
        value: key
    })) : [], [props.filter]);

    let defaultOperationOption = React.useMemo(() => operationOptions.find(opt => opt.value === props.filter.operation), [operationOptions, props.filter.operation]);

    return (
        <FormGroup>
            <InputGroup className="modal-column">
                <ThemedSelect name={"attribute"}
                              isSearchable={true}
                              options={attributeOptions}
                              value={defaultAttrOption}
                              onChange={(input) => props.handleSelectChange(props.index, 'attribute', input)}/>
                <ThemedSelect name={"operation"} options={operationOptions} value={defaultOperationOption} isSearchable={true}
                              onChange={(input) => props.handleSelectChange(props.index, 'operation', input)}/>
                <Input
                    type={props.filter.type === "textArray" ? "text" : props.filter.type}
                    placeholder={props.filter.type ? "Enter value" : ""}
                    value={props.filter.value}
                    name="value"
                    onChange={(e) => props.handleInputChange(props.index, e)}
                    required={true}
                    disabled={!props.filter.type}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            props.onEnterKeyPressed(e)
                        }
                    }}
                />
                <InputGroupAddon addonType="append">
                    <Button color="link" className="btn-hover-light button-times"
                            onClick={() => props.handleRemove(props.index)}>
                        <FontAwesomeIcon icon="times"/>
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        </FormGroup>

    )
};

export default FilterInputGroup;
