import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {OidcProvider} from 'redux-oidc';
import {ConnectedRouter} from 'connected-react-router';
import App from './App';
import {unregister} from './registerServiceWorker';
import configureStore from "./store/store";
import {history} from "./store/reducers";
import {registerFontAwesome} from "./registerFontAwesome";
import {hotjar} from 'react-hotjar';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import UserManager from "./services/UserManager";
import {LoadScript} from "@react-google-maps/api";
import {ToastContainer} from "react-toastify";
import './styles/css/main.css';     // global stylesheet

// print build version to console
console.info("Build: " + process.env.REACT_APP_BUILD_VERSION)

// disable service worker to prevent unwanted caching
unregister();

// register other library integrations
const googleMapsLibraries = ['geometry', 'drawing', 'places'];
registerFontAwesome();

// Tracking by hotjar
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
    hotjar.initialize(1598115, 6);
}

// Get the application-wide store instance
const store = configureStore();

// render
const render = () => ReactDOM.render(
    <Provider store={store}>
        <OidcProvider userManager={UserManager} store={store}>
            <ConnectedRouter history={history}>
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
                            libraries={googleMapsLibraries}
                            version={"quarterly"}
                >
                    <App/>
                    {/* notifications */}
                    <ToastContainer autoClose={3000}/>
                </LoadScript>
            </ConnectedRouter>
        </OidcProvider>
    </Provider>,
    document.getElementById('root')
);
render(App);

// hot-reload support
if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}