import * as React from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import {LatLngGps} from "../../services/GeneratedApiTsClient/models";

type MapGraphProps = {
    coords: LatLngGps;
    markerColor: string;
    onClick: () => void;
}

class MapGraph extends React.Component<MapGraphProps> {
    private map;
    
    componentDidMount() {
        let map = am4core.create("container", am4maps.MapChart);
        map.geodata = am4geodata_worldLow;
        map.projection = new am4maps.projections.Projection();

        // Disable pan and zoom, see: https://www.amcharts.com/docs/v4/tutorials/disabling-zoom-and-pan-on-a-map-chart/
        
        // Disable zoom and pan
        map.maxZoomLevel = 1;
        map.seriesContainer.draggable = false;
        map.seriesContainer.resizable = false;
        map.chartContainer.wheelable = false;

        // disable double-click zoom
        map.seriesContainer.events.disableType("doublehit");
        map.chartContainer.background.events.disableType("doublehit");

        let polygonSeries = map.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.useGeodata = true;
        // polygonSeries.events.on('hit', this.props.onClick);
        
        let imageSeries = map.series.push(new am4maps.MapImageSeries());
        let imageSeriesTemplate = imageSeries.mapImages.template;
        
        let circle = imageSeriesTemplate.createChild(am4core.Circle);
        circle.radius = 4;
        circle.fill = am4core.color(this.props.markerColor);
        circle.stroke = am4core.color(this.props.markerColor);
        circle.strokeWidth = 2;
        circle.nonScaling = true;
        circle.tooltipText = "{title}";

        imageSeriesTemplate.propertyFields.latitude = "latitude";
        imageSeriesTemplate.propertyFields.longitude = "longitude";

        imageSeries.data = [{
            "latitude": this.props.coords.lat,
            "longitude": this.props.coords.lng,
            "title": "found here"
        }];
        
        this.map = map;
    }

    componentWillUnmount() {
        if (this.map) {
            this.map.dispose();
        }
    }
    render() {
        return (
            <div id="container" className="d-flex flex-fill map-graph" onClick={this.props.onClick}/>
        );
    }
}

export default MapGraph;