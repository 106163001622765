import React from "react";
import {connect} from "react-redux";
import UserManager from "../services/UserManager";
import {RouteComponentProps} from "react-router";
import {AppState} from "../store/reducers";
import {setError} from "../store/actions/ErrorActions";
import {push} from "connected-react-router";
import {SplashScreen} from "../scenes/Splash/SplashScreen";


const mapStateToProps = (state: AppState) => ({
    
});

const mapDispatchToProps = {
    setError,
    push
};


type ComponentProps = {
}

type Props = RouteComponentProps<{ id: string; schema: any }> &
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & ComponentProps;


class SignOut extends React.Component<Props> {
    
    componentDidMount() {
        UserManager.signoutRedirect();
    }

    render() {
    return <SplashScreen />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignOut as unknown as React.ComponentClass<ComponentProps>);
