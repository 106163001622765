import * as React from "react";
import {useEffect, useLayoutEffect, useRef, useState} from "react";

import {AppState} from "../../../store/reducers";
import connect from "react-redux/es/connect/connect";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import {MapChart} from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {useSelector} from "react-redux";
import {Filter} from "../../../models/FilterModel";
import ApiService from "../../../services/ApiService/ApiService";
import {getConfiguredChartsApi} from "../../../store/selectors/ApiSelectors";
import {selectActiveTenantId} from "../../../store/selectors/TenantSelectors";

const mapStateToProps = (state: AppState) => ({
    globalFilters: state.filter.filters,
    chartsApi: getConfiguredChartsApi(state),
    tenantId: selectActiveTenantId(state)
});

const mapDispatchToProps = {};

type Props = ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps
    & {}

type HeatmapDataItem = {
    lat: number;
    lng: number;
    count: number;
}
// see: https://gist.github.com/xposedbones/75ebaef3c10060a3ee3b246166caab56
const mapNumberToInterval = (number, in_min, in_max, out_min, out_max) =>
    (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min

const Heatmap = (props: Props) => {
    // references
    const chartRef = useRef<MapChart | undefined>(undefined);
    const seriesRef = useRef<am4maps.MapImageSeries | undefined>(undefined);

    //const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Array<HeatmapDataItem>>([]);
    const filters = useSelector<AppState, Array<Filter>>(state => state.filter.filters);

    // settings
    const CIRCLE_RADIUS_MIN = 2;
    const CIRCLE_RADIUS_MAX = 10;

    // re-calculate data on filters change
    useEffect(() => {
        let isMounted = true;
        //setLoading(true);


        // fetch data
        if(!props.tenantId)return;
        props.chartsApi.apiChartsFindingsMinimap({
            tenantId: props.tenantId,
            filter: ApiService.buildFilterQuery(filters)
        }).then(miniMapData => {
            // map to amcharts compatible format
            const filteredMapData: HeatmapDataItem[] = miniMapData.data
                .filter(i => i.count && i.count > 0 && i.coordinates && i.coordinates.lat && i.coordinates.lng)
                .map(i => ({
                    lat: i.coordinates!.lat,
                    lng: i.coordinates!.lng,
                    count: i.count!,
                }))
            // normalize count to defined radius range
            const counts = filteredMapData.map(i => i.count);
            const normalizedMapData = filteredMapData.map(i => {
                i.count = mapNumberToInterval(
                    i.count,
                    Math.min.apply(null, counts),
                    Math.max.apply(null, counts),
                    CIRCLE_RADIUS_MIN,
                    CIRCLE_RADIUS_MAX);
                return i;
            })
            if (isMounted) {
                // replace data
                setData(normalizedMapData);
                //setLoading(false);
            }
        })


        return () => {
            isMounted = false;
        }
    }, [filters, props.chartsApi, props.tenantId]);

    // replace chart series data on change
    useLayoutEffect(() => {
        if (!seriesRef.current) return;
        seriesRef.current.data = data;
    }, [data])


    useLayoutEffect(() => {
        am4core.useTheme(am4themes_animated);

        // Create map instance
        let chart = am4core.create("heatmap-chartdiv", am4maps.MapChart);

        // Set map definition
        chart.geodata = am4geodata_worldLow;
        chart.projection = new am4maps.projections.Miller();
        let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

        // Exclude Antartica
        polygonSeries.exclude = ["AQ"];

        // Make map load polygon (like country names) data from GeoJSON
        polygonSeries.useGeodata = true;

        // Configure series
        let polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.polygon.fillOpacity = 0.6;

        // Add image series
        let imageSeries = new am4maps.MapImageSeries();
        imageSeries.mapImages.template.propertyFields.longitude = "lng";
        imageSeries.mapImages.template.propertyFields.latitude = "lat";

        let circle = imageSeries.mapImages.template.createChild(am4core.Circle);
        circle.fill = am4core.color("#00603D");
        circle.fillOpacity = 0.5;
        circle.propertyFields.radius = "count";
        circle.filters.push(new am4core.BlurFilter());

        let circle_ghost = imageSeries.mapImages.template.createChild(am4core.Circle);
        // circle_ghost.radius = 3;
        circle_ghost.propertyFields.radius = "count";
        circle_ghost.fill = am4core.color("#00603D");

        // animation - blink ghost once data appears on map
        circle_ghost.events.on("inited", function (event) {
            animateBullet(event.target);
        })

        function animateBullet(circle) {
            //let animation = circle.animate([{ property: "scale", from: 1, to: 5 }, { property: "opacity", from: 1, to: 0 }], 1000, am4core.ease.circleOut);

            // uncomment to animate in loop

            // animation.events.on("animationended", function(event){
            //     animateBullet(event.target.object);
            // })
        }

        chart.series.push(imageSeries);

        // add reference
        chartRef.current = chart;
        seriesRef.current = imageSeries;

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <>
            {/*{loading && <SpinnerOverlay/>}*/}
            <div id="heatmap-chartdiv" style={{width: "100%"}}></div>
        </>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(Heatmap);