import {FindingSchemaElement} from "../../../services/GeneratedApiTsClient";
import {DataCardHeaderElement} from "./DataCardHeaderElement";
import {DataCardParagraphElement} from "./DataCardParagraphElement";
import {DataCardTextElement} from "./DataCardTextElement";
import {DataCardFastaElement} from "./DataCardFastaElement";
import * as React from "react";

type DynamicDataCardElementProps = {
    definition: FindingSchemaElement;
    value: any;
};

export const DynamicDataCardElement = (props: DynamicDataCardElementProps) => {

    let convertedValue = props.value;

    // convert value to human readable, if needed
    // TODO: revise, if still needed (looks like duplicate
    if (typeof convertedValue === 'object' && convertedValue !== null) {
        convertedValue = convertedValue.map(val => {
            if (!props.definition.values) return null
            const item = props.definition.values.find((opt) => opt.value === val)
            return item ? item.label : val
        })
    } else {
        const value = props.definition.values && props.definition.values.find((opt) => opt.value === convertedValue)
        convertedValue = value ? value.label : convertedValue;
    }
    
    switch (props.definition.type) {
        case 'header':
            return <DataCardHeaderElement subtype={props.definition.subtype}>{props.definition.label}</DataCardHeaderElement>;
        case 'paragraph':
            return <DataCardParagraphElement>{props.definition.label}</DataCardParagraphElement>;
        case 'checkbox-group':
        case 'radio-group':
            let definitionLabel = props.definition.values!.find(v => v.value === convertedValue);
            return (
                <DataCardTextElement
                    title={props.definition.label}
                    value={definitionLabel ? definitionLabel.label : convertedValue}
                    description={props.definition.description}
                />
            );
        case 'select':
            if (props.definition.multiple) {
                // if (!convertedValue || !Array.isArray(convertedValue)) return null;
                return <DataCardTextElement title={props.definition.label} value={convertedValue} description={props.definition.description} />;
            }
        // fall through
        case 'number':
        case 'date':
        case 'text':
        case 'textarea':
            return <DataCardTextElement title={props.definition.label} value={convertedValue} description={props.definition.description} />;
        case 'fasta':
            return <DataCardFastaElement title={props.definition.label} value={convertedValue} description={props.definition.description} />;
        default:
            return null;
    }
};
