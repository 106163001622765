// @flow

export type FilterOperation = {
    /**
     * Symbolic name
     */
    name: string,

    /**
     * Api operation prefix
     */
    prefix: string,

    /**
     * Human-readable description, as in a sentence
     */
    humanReadable: string,
}

export enum FILTER_OP {
    eq =  'eq',
    eqi =  'eqi',
    ne =  "ne",
    lt =  "lt",
    le =  "le",
    gt =  "gt",
    ge =  "ge",
    contains =  "contains",
    // containsno: "containsno",            // TODO: Implement inverse of contains
    isIn =  "isIn",
    notIn =  "notIn",
}

export const SupportedFilterOperations  = 
{
    contains:   {name: "CONTAINS",  prefix: "like:",    humanReadable: "contains"},
    eq:         {name: "==",        prefix: "eq:",      humanReadable: "="},
    eqi:        {name: "==i",        prefix: "eqi:",     humanReadable: "= (case insensitive)"},
    ne:         {name: "!=",        prefix: "ne:",      humanReadable: "doesn't equal to"},
    lt:         {name: "<",         prefix: "lt:",      humanReadable: "is less than"},
    le:         {name: "<=",        prefix: "le:",      humanReadable: "is less or equal"},
    gt:         {name: ">",         prefix: "gt:",      humanReadable: "is greater than"},
    ge:         {name: ">=",        prefix: "ge:",      humanReadable: "is greater or equal"},
    // containsno:   {name: "DOESN'T CONTAIN",  prefix: "nlike:",    humanReadable: "doesn't contain"},
    isIn:        {name: "IS IN",    prefix: "in:",      humanReadable: "is in list"},
    notIn:       {name: "NOT IN",   prefix: "nin:",      humanReadable: "is not in list"},
};


export const SupportedFilterOperationsFor = {
    text: {
        contains:   {name: "CONTAINS",  prefix: "like:",    humanReadable: "contains"},
        eq:         {name: "==",        prefix: "eq:",      humanReadable: "equals to"},
        eqi:        {name: "==i",       prefix: "eqi:",     humanReadable: "equals to (case insensitive)"},
        ne:         {name: "!=",        prefix: "ne:",      humanReadable: "doesn't equal to"},
        // containsno:   {name: "DOESN'T CONTAIN",  prefix: "nlike:",    humanReadable: "doesn't contain"},
    },
    date: {
        eq:         {name: "==",        prefix: "eq:",      humanReadable: "equals to"},
        ne:         {name: "!=",        prefix: "ne:",      humanReadable: "doesn't equal to"},
        lt:         {name: "<",         prefix: "lt:",      humanReadable: "is less than"},
        le:         {name: "<=",        prefix: "le:",      humanReadable: "is less or equal"},
        gt:         {name: ">",         prefix: "gt:",      humanReadable: "is greater than"},
        ge:         {name: ">=",        prefix: "ge:",      humanReadable: "is greater or equal"},
    },
    number: {
        eq:         {name: "==",        prefix: "eq:",      humanReadable: "equals to"},
        ne:         {name: "!=",        prefix: "ne:",      humanReadable: "doesn't equal to"},
        lt:         {name: "<",         prefix: "lt:",      humanReadable: "is less than"},
        le:         {name: "<=",        prefix: "le:",      humanReadable: "is less or equal"},
        gt:         {name: ">",         prefix: "gt:",      humanReadable: "is greater than"},
        ge:         {name: ">=",        prefix: "ge:",      humanReadable: "is greater or equal"},
    },
    textArray: {
        // eq:         {name: "==",        prefix: "eq:",      humanReadable: "equals to"},         // TODO: add autocomplete to API for this function to work
        contains:   {name: "CONTAINS",  prefix: "like:",    humanReadable: "contains"},
        // containsno:   {name: "DOESN'T CONTAIN",  prefix: "nlike:",    humanReadable: "doesn't contain"},
    },
};