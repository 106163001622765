import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../store/reducers';
import {goBack} from "connected-react-router";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DataCardTextElement} from "../FindingDetail/components/DataCardTextElement";
import {selectAvailableTenantById} from "../../store/selectors/TenantSelectors";
import {Link} from "react-router-dom";
import {TenantUser, TenantUserRole} from "../../services/GeneratedApiTsClient";
import {TenantUserPersona} from "../../components/TenantUserPersona";
import {getConfiguredTenantsApi} from "../../store/selectors/ApiSelectors";
import {ThemedSelect} from "../../components/ThemedSelect";
import {patchTenantUser} from "../../store/actions/TenantActions";

import InvitationDropdown from "../../components/Invite/InvitationDropdown";

import {selectCurrentUsersIdmSubjectId} from "../../store/selectors/UserSelectors";
import SpinnerOverlay from "../../components/Spinners/SpinnerOverlay";


const mapStateToProps = (state: AppState, props: TenantDetailProps) => ({
    tenantsApi: getConfiguredTenantsApi(state),
    tenant: selectAvailableTenantById(state, props),
    tenantUserPatchLoading: state.tenant.userPatchLoading,
    currentUserIdmSubjectId: selectCurrentUsersIdmSubjectId(state)
});

const mapDispatchToProps = {
    goBack,
    patchTenantUser
};

type TenantDetailProps = {
    tenantId: string
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & TenantDetailProps;

const TenantDetail = (props: Props) => {

    const [loadedUsers, setLoadedUsers] = useState<TenantUser[]>([]);
    const [canEdit, setCanEdit] = useState<boolean>(false)
    const [isMultiOwners, setIsMultiOwners] = useState<boolean>(false)
    const roles = [
        {value: TenantUserRole.INACTIVE, label: "No access", icon: "user-lock"},
        {value: TenantUserRole.GUEST, label: "Guest", icon: "user-lock"},
        {value: TenantUserRole.COLLABORATOR, label: "Collaborator", icon: "user-lock"},
        {value: TenantUserRole.OWNER, label: "Owner", icon: "user-lock"},
    ]

    useEffect(() => {
        if (props.tenantUserPatchLoading) return;

        setLoadedUsers([]);

        props.tenantsApi.apiTenantsGetByIdUsers({tenantId: props.tenantId})
            .then((result) => {
                
                const currentUser: TenantUser[] = result.filter(item => {return item.idmSubjectId === props.currentUserIdmSubjectId});
                if(currentUser.length === 1){
                    setCanEdit(currentUser[0].role === TenantUserRole.OWNER)
                }
                
                setIsMultiOwners(result.filter(item => item.role === TenantUserRole.OWNER).length > 1)
                
                setLoadedUsers(result);
            });

    }, [props.tenantId, props.tenantsApi, props.tenantUserPatchLoading, props.currentUserIdmSubjectId]);

    return (
        <>
            {(loadedUsers.length === 0 || props.tenantUserPatchLoading) && <SpinnerOverlay/>}

            <h2 className="mt-5 mb-4">
                Manage workspace
            </h2>

            <div className={'text-right mt-4'}>
                <Button
                    outline
                    color="secondary"
                    className="button-back mb-2 float-left" onClick={() => {
                    props.goBack();
                }}>
                    <FontAwesomeIcon icon="chevron-left"/>
                    Back
                </Button>

                <Button color="primary" type='button' className={'ml-2 mb-2'} tag={Link}
                        disabled={!canEdit}
                        to={'/workspaces/edit/' + props.tenantId}>
                    <FontAwesomeIcon icon="pen"/>
                    Edit
                </Button>
            </div>

            <div className="data-card">
                <h2 className="data-card-heading">General information</h2>
                <Card className="d-flex flex-fill">
                    <CardBody>
                        <DataCardTextElement title="Name" value={props.tenant?.name}/>
                    </CardBody>
                </Card>
            </div>
            
            <div className={'text-right mt-4'}>
                    <InvitationDropdown tenantId={props.tenantId} disabled={loadedUsers.find(u => u.idmSubjectId === props.currentUserIdmSubjectId)?.role !== TenantUserRole.OWNER}/>
            </div>
            
            <div className="data-card mt-0">
                <h2 className="data-card-heading">Users</h2>
                <Card className="d-flex flex-fill">
                    <CardBody>
                        {loadedUsers.map(u =>
                            <Row key={u.id} className={'mb-3'}>
                                <Col sm={8} style={{opacity: u.role === TenantUserRole.INACTIVE ? 0.5 : 1}}>
                                    <TenantUserPersona user={u} size={'50'} className={'mr-2'}/>
                                    <span>{u.fullName}</span>
                                </Col>
                                <Col sm={4} className={'align-self-center'}>

                                        <ThemedSelect options={roles}
                                                      defaultValue={roles.find(r => r.value === u.role)}
                                                      isDisabled={!canEdit || (u.role === TenantUserRole.OWNER && !isMultiOwners)}
                                                      searchable={false} onChange={(input) => {
                                            props.patchTenantUser(props.tenantId, u.id, TenantUserRole[input.value])
                                        }}/>
                                    
                                </Col>
                            </Row>
                        )}
                    </CardBody>
                </Card>
            </div>

        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantDetail as any);
