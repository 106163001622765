import * as React from 'react';
import {useEffect, useState} from 'react';
import GraphContainer from '../../../components/Graphs/GraphContainer';
import {AppState} from '../../../store/reducers';
import {closeGraphView} from '../../../store/actions/GUIActions';
import {connect} from 'react-redux';
import PieChartGraph from '../../../components/Graphs/PieChartGraph';
import {GraphSettingsForm} from "./GraphSelectorInputGroup";
import {Button, Nav, NavItem, NavLink} from "reactstrap";
import ApiService from "../../../services/ApiService/ApiService";
import {FrequencyChartItem} from "../../../services/GeneratedApiTsClient/models";
import {StaticFilterableAttributesGroup} from "../../../models/FilterableAttributesModel";
import ColumnChartGraph from "../../../components/Graphs/ColumnChartGraph";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getConfiguredChartsApi} from "../../../store/selectors/ApiSelectors";
import {selectActiveTenantId} from "../../../store/selectors/TenantSelectors";
import SpinnerOverlay from "../../../components/Spinners/SpinnerOverlay";

const mapStateToProps = (state: AppState) => ({
    schemaAttributes: state.findingSchemas.schemaAttributes,
    filters: state.filter.filters,
    chartsApi: getConfiguredChartsApi(state),
    tenantId: selectActiveTenantId(state)
});

const mapDispatchToProps = {
  closeGraphView,
};

enum ChartTypes {
    Pie,
    Treemap,
    Bar,
    Column,
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};

const GraphPanel = (props: Props) => {

    const [loading, setLoading] = useState(false);
    
    const [aggregate, setAggregate] = useState("taxonomyHumanReadable");
    const [group, setGroup] = useState<string>();
    const [chartType, setChartType] = useState<ChartTypes>(ChartTypes.Pie);
    
    const [frequencyData, setFrequencyData] = useState<Array<FrequencyChartItem>>();
    //const [statisticalData, setStatisticalData] = useState<Array<StatisticalDataItem>>();

    const [groupDisabled, setGroupDisabled] = useState(true);
    
    useEffect(() => {
        if (!aggregate) return;
        
        // setStatisticalData(undefined);
        // setFrequencyData(undefined);
        const fetchData = async () => {
            setLoading(true);

            let frequency = await props.chartsApi.apiChartsFindingsFrequency({
                tenantId: props.tenantId,
                filter: ApiService.buildFilterQuery(props.filters),
                aggregate,
                group,
                maxItemsPerGroup: 20
            });

            setFrequencyData(frequency.data);

            /*
            let statistics = await props.chartsApi.apiChartsFindingsStatistics({
                tenantId: props.tenantId,
                filter: ApiService.buildFilterQuery(props.filters),
                aggregate,
                group
            });
            */
             

            //setStatisticalData(statistics.data);

            setLoading(false);
        }
        fetchData();
        
    },[aggregate, group, props.chartsApi, props.filters, props.tenantId]);
    
    useEffect(() => {
        
        if (chartType === ChartTypes.Pie){
            setGroup(undefined);
            setGroupDisabled(true);
        } else {
            setGroupDisabled(false);
        }
        
    },[chartType]);
    
    /*
    const barChartData = (): BarChartGraphDataType => {

        if (!frequencyData) return [];

        let groupped = frequencyData
            .reduce((prev, i) => {

                let key = (i.group ?? "other");

                if (!prev.hasOwnProperty(key)) {
                    prev[key] = {
                        category: key,
                        color: "#01ff00",
                        children: [],
                    };
                }

                prev[key].children.push({name: i.label, value:i.value})

                return prev;
            },[] as BarChartGraphDataType);

        let numeric_array: BarChartGraphDataType = [];
        for (let items in groupped){
            numeric_array.push( groupped[items] );
        }
        
        return numeric_array;
        
    };
    */
    
    return (
        <div className="dynamic-panel d-flex flex-column p-3">
            
            {loading && <SpinnerOverlay />}
            
            <div className={"d-flex align-items-baseline"}>
                <Nav tabs fill className={"mb-4 mr-4 flex-grow-1"}>
                    <NavItem>
                        <NavLink
                            className={chartType === ChartTypes.Pie ? "active" : ""}
                            onClick={() => setChartType(ChartTypes.Pie) }
                        >
                            Pie Chart
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={chartType === ChartTypes.Column ? "active" : ""}
                            onClick={() => setChartType(ChartTypes.Column) }
                        >
                            Column chart
                        </NavLink>
                    </NavItem>
                    {/*<NavItem>*/}
                    {/*    <NavLink*/}
                    {/*        className={chartType === ChartTypes.Bar ? "active" : ""}*/}
                    {/*        onClick={() => setChartType(ChartTypes.Bar) }*/}
                    {/*    >*/}
                    {/*        Bar chart*/}
                    {/*    </NavLink>*/}
                    {/*</NavItem>*/}
                    {/*<NavItem>*/}
                    {/*    <NavLink*/}
                    {/*        className={chartType === ChartTypes.Treemap ? "active" : ""}*/}
                    {/*        onClick={() => setChartType(ChartTypes.Treemap) }*/}
                    {/*    >*/}
                    {/*        Tree-map*/}
                    {/*    </NavLink>*/}
                    {/*</NavItem>*/}
                </Nav>
                
                <Button outline color={"secondary"} size={"sm"} onClick={props.closeGraphView}>
                    <FontAwesomeIcon icon={'times'} /> Close
                </Button>
            </div>
            
            <GraphSettingsForm group={group}
                               aggregate={aggregate}
                               onAggregateChange={(value) => setAggregate(value)}
                               onGroupChange={(value) => value ? setGroup(value) : setGroup(undefined)}
                               groupDisabled={groupDisabled}
                               filterableAttributes={StaticFilterableAttributesGroup.concat(props.schemaAttributes)} />
                        
            {chartType === ChartTypes.Pie && frequencyData && <GraphContainer title={'Pie Chart'} className="">
                <PieChartGraph data={frequencyData.map(i => ({name: i.label, value:i.value}))}/>
            </GraphContainer>}

            {chartType === ChartTypes.Column && frequencyData && <GraphContainer title={'Column chart'} className="">
                <ColumnChartGraph data={frequencyData.map(i => ({name: i.label, value:i.value}))} title={""} showLabels={true}/>
            </GraphContainer>}
            {/*{chartType === ChartTypes.Treemap && <GraphContainer title={'Document sets'} className="">*/}
            {/*    <TreemapGraph data={treemapGraphData}/>*/}
            {/*</GraphContainer>}*/}
            {/*{chartType === ChartTypes.Bar && <GraphContainer title={'Number of findings'}>*/}
            {/*    <BarChartGraph data={mockData}/>*/}
            {/*</GraphContainer>}*/}
            {/*{chartType === ChartTypes.Bar && frequencyData && <GraphContainer title={'Number of findings'}>*/}
            {/*    <BarChartGraph data={barChartData()}/>*/}
            {/*</GraphContainer>}*/}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphPanel);
