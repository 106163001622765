import {Filter} from "../../models/FilterModel";
import {FilterActions} from "../actions/FilterActions";


export type FilterReducerState = {
    filters: Array<Filter>,
}

export const FilterReducerInitialState: FilterReducerState = {
    filters: [] as Array<Filter>,
};

export default function filterReducer (state: FilterReducerState = FilterReducerInitialState, action) {
    switch (action.type) {
        case FilterActions.SET_FILTERS:
            return { ...state, filters: action.filters};
        case FilterActions.CLEAR_FILTERS:
            return { ...state, filters: []};
        default:
            return state;
    }
};