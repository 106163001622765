import {MapPanelActions} from "../actions/MapPanelActions";
import {LatLngBounds} from "../models/LatLngBounds";
import {FindingMapInfoResourceObject} from "../models/FindingMapInfo";

export type MapPanelReducerState = {
    bounds?: LatLngBounds,
    markerData: Array<FindingMapInfoResourceObject>,
    requestInProgress: boolean
}

export const MapPanelReducerInitialState: MapPanelReducerState = {
    bounds: undefined,
    markerData: [],
    requestInProgress: false
};

export default function filterReducer (state: MapPanelReducerState = MapPanelReducerInitialState, action) {
    switch (action.type) {

        case MapPanelActions.REQUEST_MAP_FINDINGS:
            return { ...state, requestInProgress: true};
            
        case MapPanelActions.RECEIVE_MAP_FINDINGS:
            return {
                ...state,
                requestInProgress: false,
                markerData: action.markerData
            };
            
        case MapPanelActions.CANCEL_RECEIVING_MAP_FINDINGS:
            return { ...state, requestInProgress: false};
            
        case MapPanelActions.FLUSH_MAP_FINDINGS:
            return { ...state, markerData: []};
            
        case MapPanelActions.SET_MAP_VIEWPORT:
            return { ...state, bounds: action.bounds};
        default:
            return state;
    }
};