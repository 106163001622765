import * as React from 'react';
import {FindingSchemaElement} from '../../services/GeneratedApiTsClient';
import $ from 'jquery';
import {registerJQuery} from "../../registerJQuery";

registerJQuery();
require('formBuilder/dist/form-render.min.js'); // not pretty but works

type DynamicData = {
    [key: string]: any;
};

type FormRenderElement = FindingSchemaElement & {
    userData?: Array<any>;
};

type Props = {
    formKey: string;
    definitions: Array<FindingSchemaElement>;
    data: DynamicData;
    onInput: (DynamicData) => void;
};

type State = any;

const TypeToClassNameMap: { [key: string]: string } = {
    text: 'form-control',
    number: 'form-control',
    select: 'form-control',
    'radio-group': '',
    'checkbox-group': '',
    textarea: 'form-control',
};

export class DynamicFormRenderer extends React.Component<Props, State> {
    fb: any;

    formRenderInstance: any;

    componentDidMount() {
        this.renderForm();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.definitions !== prevProps.definitions) {
            this.renderForm();
        }
    }
    
    renderForm() {
        let options = {
            dataType: 'json',
            formData: this.mergeDataToDefinitions(this.props.definitions, this.props.data),
        };
        this.formRenderInstance = $(this.fb).formRender(options);

        // register data change handler
        $('input, textarea, select', $('#dynamic-form-renderer-' + this.props.formKey)).on('input.unicat', event => {
            this.props.onInput(this.extractDataFromDefinitions(this.formRenderInstance.userData));
        });
    }

    mergeDataToDefinitions(
        definitions: Array<FindingSchemaElement>,
        data: DynamicData,
    ): Array<FormRenderElement | FindingSchemaElement> {
        return definitions.map((definition: FindingSchemaElement) => {

            let dataAugmentedDefinition: any = {
                ...definition,
                // scope name by form key to prevent conflicts
                name: this.props.formKey + '-' + definition.name,
                // force-disable rich text subtypes
                // TODO: impelemnt rich-text editors
                subtype: definition.type === 'textarea' ? 'textarea' : definition.type,
                class: TypeToClassNameMap.hasOwnProperty(definition.type!) ? TypeToClassNameMap[definition.type!] : '',
                dataId: this.props.formKey,
            };

            // inject data value, if available 
            if (!(definition.name == null || data[definition.name] === undefined)) {
                let dataValue = data[definition.name];
                dataAugmentedDefinition = {
                    ...dataAugmentedDefinition,
                    userData: Array.isArray(dataValue) ? dataValue : [dataValue],
                };
            }

            return dataAugmentedDefinition;
        });
    }

    extractDataFromDefinitions(definitions: Array<FormRenderElement>) {
        // console.log(definitions);
        let output = {};

        definitions.forEach(definition => {
            // skip non-input components (header, paragraph)
            if (definition.name === undefined) return;

            let dataValue: any = definition.userData !== undefined ? definition.userData : [];

            switch (definition.type) {
                case 'checkbox-group':
                    break;
                case 'number':
                    if (Array.isArray(dataValue) && dataValue.length > 0) {
                        dataValue = parseFloat(dataValue[0]);
                        if (isNaN(dataValue)) {
                            dataValue = null;
                        }
                    }
                    break;
                case 'select':
                    if (definition.multiple) {
                        break;
                    }
                // fall through
                default:
                    if (Array.isArray(dataValue) && dataValue.length > 0) {
                        dataValue = dataValue[0];
                    }
            }

            // de-scope name by form key
            const deScopedName = definition.name.replace(this.props.formKey + '-', "");

            output[deScopedName] = dataValue;
        });

        return output;
    }

    render() {
        return (
            <div id={'dynamic-form-renderer-' + this.props.formKey} ref={el => (this.fb = el)}>
                dynamic form
            </div>
        );
    }
}
