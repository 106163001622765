// export type SortDirection =SortDirection 'asc' | 'desc'

export enum SortDirection {
    SORT_ASC = 'asc',
    SORT_DESC = 'desc'
}

export class Sort {
    attribute: string;
    direction: SortDirection;
    
    constructor(attribute: string, direction: SortDirection = SortDirection.SORT_ASC){
        this.attribute = attribute;
        this.direction = direction;
    }
    
}