

export const WellKnownAttributes = {
    schemaId: "schema.id",
    dynamicData: "dynamicData",
    documentName: "documentName",
    documentSet: "documentSet",
    amount: "amount",
    date: "date",
    locationGpsPoint: "locationGpsPoint",
    locationGpsArea: "locationGpsArea",
    locationDescription: "locationDescription",
    note: "note",
    tags: "tags",
    person: "person",
    taxonomyHumanReadable: "taxonomyHumanReadable",
    attachmentNote: "attachmentNote",
    taxonomyName: {
        kingdom: "taxonomyName.kingdom",
        phylum: "taxonomyName.phylum",
        _class: "taxonomyName.class",
        order: "taxonomyName.order",
        family: "taxonomyName.family",
        genus: "taxonomyName.genus",
        species: "taxonomyName.species",
        authorship: "taxonomyName.authorship",
    }
};