import {createSelector} from "reselect";
import {AppState} from "../reducers";
import {
    AttachmentsApi,
    ChartsApi,
    Configuration, ExportApi,
    FindingsApi,
    SchemasApi, TenantsApi,
} from "../../services/GeneratedApiTsClient";
import {TusOptions} from "@uppy/tus";
import {selectActiveTenantId} from "./TenantSelectors";

export const selectApiAccessToken = (state: AppState) => state.oidc.user?.access_token;

export const getApiConfiguration = createSelector([selectApiAccessToken], (token) =>
    new Configuration({
        basePath: process.env.REACT_APP_API_BASE_PATH,
        accessToken: token,
    })
);

export const getUppyTusConfiguration = createSelector([selectApiAccessToken, selectActiveTenantId], (token, tenantId): TusOptions => {
    return {
        endpoint: `${process.env.REACT_APP_API_BASE_PATH}/${tenantId}`,
        headers: {
            authorization: `Bearer ${token}`,
        },
        chunkSize: 512*1000,     // 0.5MiB,
        retryDelays: [0]
    };
    }
);

export const getConfiguredFindingsApi = createSelector(
    [getApiConfiguration],
    (config) => new FindingsApi(config)
);

export const getConfiguredSchemasApi = createSelector(
    [getApiConfiguration],
    (config) => new SchemasApi(config)
);

export const getConfiguredChartsApi = createSelector(
    [getApiConfiguration],
    (config) => new ChartsApi(config)
);

export const getConfiguredAttachmentsApi = createSelector(
    [getApiConfiguration],
    (config) => new AttachmentsApi(config)
);

export const getConfiguredExportApi = createSelector(
    [getApiConfiguration],
    (config) => new ExportApi(config)
);

export const getConfiguredTenantsApi = createSelector(
    [getApiConfiguration],
    (config) => new TenantsApi(config)
);