import * as React from "react";
import {TaxonomyName} from "../../../services/GeneratedApiTsClient/models";
import {Input, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {DataCardInputElement} from "../../../components/DataCardComponnents/DataCardInputElement";
import {DataCardSimpleTextInputElement} from "../../../components/DataCardComponnents/DataCardSimpleTextInputElement";

type Props = {
    title: string;
    alignColumn?: boolean;
    gutterBottom?: boolean;
    
    taxonomyName: TaxonomyName | null | undefined
    onInput: (TaxonomyName) => void
}


export const TaxonomyInput = (props: Props) => {

    const handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        let updated = { ...props.taxonomyName};
        updated[name] = value;
        
        props.onInput(updated);
    };
    
    return (
        <>
        <DataCardInputElement title="Structured taxonomy" alignColumn={props.alignColumn} gutterBottom={props.gutterBottom} className="taxonomy-data-card">
        
            <InputGroup>
                <InputGroupAddon addonType="prepend"><InputGroupText>Kingdom</InputGroupText></InputGroupAddon>
                <Input
                    onInput={handleInputChange}
                    onChange={handleInputChange}
                    name={"kingdom"}
                    value={props.taxonomyName && props.taxonomyName.kingdom ? props.taxonomyName.kingdom : "" }/>
            </InputGroup>
            
            <InputGroup>
                <InputGroupAddon addonType="prepend"><InputGroupText>Phylum</InputGroupText></InputGroupAddon>
                <Input
                    onInput={handleInputChange}
                    onChange={handleInputChange}
                    name={"phylum"}
                    value={props.taxonomyName && props.taxonomyName.phylum ? props.taxonomyName.phylum : "" }/>
            </InputGroup>
            
            <InputGroup>
                <InputGroupAddon addonType="prepend"><InputGroupText>Class</InputGroupText></InputGroupAddon>
                <Input
                    onInput={handleInputChange}
                    onChange={handleInputChange}
                    name={"_class"}
                    value={props.taxonomyName && props.taxonomyName._class ? props.taxonomyName._class : "" }/>
            </InputGroup>
            
            <InputGroup>
                <InputGroupAddon addonType="prepend"><InputGroupText>Order</InputGroupText></InputGroupAddon>
                <Input
                    onInput={handleInputChange}
                    onChange={handleInputChange}
                    name={"order"}
                    value={props.taxonomyName && props.taxonomyName.order ? props.taxonomyName.order : "" }/>
            </InputGroup>
            
            <InputGroup>
                <InputGroupAddon addonType="prepend"><InputGroupText>Family</InputGroupText></InputGroupAddon>
                <Input
                    onInput={handleInputChange}
                    onChange={handleInputChange}
                    name={"family"}
                    value={props.taxonomyName && props.taxonomyName.family ? props.taxonomyName.family : "" }/>
            </InputGroup>
            
            <InputGroup>
                <InputGroupAddon addonType="prepend"><InputGroupText>Genus</InputGroupText></InputGroupAddon>
                <Input
                    onInput={handleInputChange}
                    onChange={handleInputChange}
                    name={"genus"}
                    value={props.taxonomyName && props.taxonomyName.genus ? props.taxonomyName.genus : "" }/>
            </InputGroup>
            
            <InputGroup>
                <InputGroupAddon addonType="prepend"><InputGroupText>Species</InputGroupText></InputGroupAddon>
                <Input
                    onInput={handleInputChange}
                    onChange={handleInputChange}
                    name={"species"}
                    value={props.taxonomyName && props.taxonomyName.species ? props.taxonomyName.species : "" }/>
            </InputGroup>
            
        </DataCardInputElement>

        <DataCardSimpleTextInputElement
            title="Authorship"
            attributeName={"authorship"}
            data={props.taxonomyName!.authorship}
            onInput={handleInputChange}
            alignColumn
            gutterBottom
        />
        </>
    );
}

