import * as React from "react";
import {FindingSchemaResourceObject, TenantUserRole} from "../../../services/GeneratedApiTsClient";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppState} from "../../../store/reducers";
import {requestFindingSchemas} from "../../../store/actions/FindingSchemasActions";
import {getActiveTenantRoutePrefix} from "../../../store/selectors/TenantSelectors";
import {isTenantUserInRole} from "../../../store/selectors/UserSelectors";
import SpinnerInline from "../../../components/Spinners/SpinnerInline";

const mapStateToProps = (state: AppState) => ({
    findingSchemas: state.findingSchemas,
    tenantRoutePrefix: getActiveTenantRoutePrefix(state),
    tenantId: state.tenant.activeTenantId,
    canEdit: isTenantUserInRole(state, {role: [TenantUserRole.OWNER, TenantUserRole.COLLABORATOR]})
});

const mapDispatchToProps = {
    requestFindingSchemas,
};
type ComponentProps = { className?: string }
type Props = ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps
    & ComponentProps

const NewFindingDropdown = (props: Props) => {

    React.useEffect(() => {
        if (props.tenantId) {
            props.requestFindingSchemas()
        }
    }, [props.tenantId])

    return (
        <UncontrolledButtonDropdown className={props.className}>
            <DropdownToggle caret color="primary btn-shadows dropdown-finding" disabled={!props.canEdit} >

                <FontAwesomeIcon icon="plus"/>
                New Finding
                {/*props.findingSchemas.loaded ? "New Finding" : <SpinnerInline />*/}

            </DropdownToggle>
            <DropdownMenu>

                {props.findingSchemas.schemasBasicInfo.length > 0
                    ? <>
                        <DropdownItem header>With Schema</DropdownItem>
                        {!props.findingSchemas.loaded && <DropdownItem header><SpinnerInline/></DropdownItem>}

                        {props.findingSchemas.schemasBasicInfo.map((schema: FindingSchemaResourceObject) =>
                            <DropdownItem tag={Link} to={props.tenantRoutePrefix + "/findings/new/" + schema.id}
                                          key={schema.id}>
                                {schema.attributes!.name}
                            </DropdownItem>
                        )}
                    </>
                    : <>
                        <DropdownItem header>(No schemas)</DropdownItem>
                        <DropdownItem tag={Link} to={props.tenantRoutePrefix + "/schemas/new/"}>
                            Create new schema
                        </DropdownItem>
                    </>
                }


            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );

}

export default connect(mapStateToProps, mapDispatchToProps)(NewFindingDropdown as unknown as React.ComponentClass<ComponentProps>);