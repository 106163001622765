import {AnyAction, Dispatch, Middleware} from "redux";
import {requestAvailableTenants} from "../actions/TenantActions";
import {AppState} from "../reducers";

export const appInitializationMiddleware: Middleware<Dispatch, AppState> = (store) => next => (action: AnyAction) => {
    
    // trigger effects on the user's (re)login
    if (action.type === 'redux-oidc/USER_FOUND' && action.payload?.profile?.sub){
        const oldState = store.getState();
        // ignore silent token refresh, if it is the same user
        if (oldState.oidc.user?.profile?.sub !== action.payload.profile.sub){
            const nextAction = next(action);
            requestAvailableTenants()(next, store.getState);
            return nextAction;
        }
    }
    
    return next(action);
};