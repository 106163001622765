import {toast, ToastOptions} from "react-toastify";
import {ReactText} from "react";
import {TypeOptions} from "react-toastify/dist/types";
import {Invitation, TenantInviteeRole} from "../../services/GeneratedApiTsClient";
import {getConfiguredTenantsApi} from "../selectors/ApiSelectors";
import {AppState} from "../reducers";
import {ApiOnRejectedResult} from "../../services/ApiService/ApiService";
import {processApiResponse} from "./ErrorActions";

export enum GUIActionsType {
    OPEN_MAP_VIEW = 'OPEN_MAP_VIEW',
    CLOSE_MAP_VIEW = 'CLOSE_MAP_VIEW',
    OPEN_GRAPH_VIEW = 'OPEN_GRAPH_VIEW',
    CLOSE_GRAPH_VIEW = 'CLOSE_GRAPH_VIEW',
    SHOW_ATTACHMENT_GALLERY = 'SHOW_ATTACHMENT_GALLERY',
    HIDE_ATTACHMENT_GALLERY = 'HIDE_ATTACHMENT_GALLERY',
    SHOW_CODEGEN_MODAL = 'SHOW_CODEGEN_MODAL',
    HIDE_CODEGEN_MODAL = 'HIDE_CODEGEN_MODAL',
    REQUEST_INVITATION_MODAL = 'REQUEST_INVITATION_MODAL',
    SHOW_INVITATION_MODAL = 'SHOW_INVITATION_MODAL',
    HIDE_INVITATION_MODAL = 'HIDE_INVITATION_MODAL',
}

export type GUIActions = {
    type: GUIActionsType,
    payload?: NotificationPayload
    codegenModalType?: "LISTING" | "NEW_FINDING",
    invitation?: Invitation
}

export type NotificationPayload = {
    message: string,
    type: "info" | "warning" | "error" | "success",
    doNotAutoClose?: boolean,
}

export const openMapView = () => (dispatch) => dispatch({type: GUIActionsType.OPEN_MAP_VIEW});
export const closeMapView = () => (dispatch) => dispatch({type: GUIActionsType.CLOSE_MAP_VIEW});
export const openGraphView = () => (dispatch) => dispatch({type: GUIActionsType.OPEN_GRAPH_VIEW});
export const closeGraphView = () => (dispatch) => dispatch({type: GUIActionsType.CLOSE_GRAPH_VIEW});

export const openAttachmentsCarousel = () => (dispatch) => {
    dispatch({type: GUIActionsType.SHOW_ATTACHMENT_GALLERY});
}
export const closeAttachmentsCarousel = () => (dispatch) => dispatch({type: GUIActionsType.HIDE_ATTACHMENT_GALLERY});

export const openCodegenModal = (type: "LISTING" | "NEW_FINDING") => (dispatch) => dispatch({type: GUIActionsType.SHOW_CODEGEN_MODAL, codegenModalType: type});
export const closeCodegenModal = () => (dispatch) => dispatch({type: GUIActionsType.HIDE_CODEGEN_MODAL});

/**
 * Show notification and return its handle (ID).
 * @param payload
 */
export const showNotification = (payload: NotificationPayload) : ReactText => {
    const options: ToastOptions = {
        autoClose: payload.doNotAutoClose ? false : 3000,
        closeButton: (!!payload.doNotAutoClose),
        hideProgressBar: true,
        type: payload.type
    }
    return toast(payload.message, options)
};

export const updateNotification = (id: ReactText, payload: NotificationPayload) => {

    const options: ToastOptions = {
        autoClose: payload.doNotAutoClose ? false : 3000,
        closeButton: (!!payload.doNotAutoClose),
        hideProgressBar: true,
        type: payload.type as TypeOptions,
        toastId: id
    }
    
    if (!toast.isActive(id)){
        toast(payload.message, options)
    } else {
        toast.update(id, {render: payload.message, ...options});
    }
}

/**
 * Hide notification, does nothing if the notification with given ID does not exist
 * @param id
 */
export const hideNotification = (id: ReactText) => {
    toast.dismiss(id);
}

export const openInvitationModal = (tenantId: string, inviteeRole: TenantInviteeRole) => async (dispatch, getState) => {
    let state: AppState = getState();
    if (state.gui.invitationModalLoading) {
        // Don't issue a duplicate request (we are already loading the requested data)
        return;
    }

    dispatch({type: GUIActionsType.REQUEST_INVITATION_MODAL});
    let tenantsApi = getConfiguredTenantsApi(state);

    const invitation = await tenantsApi.apiTenantsPostByIdInvitations({tenantId, newInvitationRequestBody: {inviteeRole}})
        .catch((error: ApiOnRejectedResult) => {
            processApiResponse(error, "Error creating invitation")(dispatch);
        });

    if (!invitation){
        return dispatch({type: GUIActionsType.HIDE_INVITATION_MODAL});
    }

    return dispatch({type: GUIActionsType.SHOW_INVITATION_MODAL, invitation});
};

export const closeInvitationModal = () => (dispatch) => dispatch({type: GUIActionsType.HIDE_INVITATION_MODAL});
