import { createUserManager } from 'redux-oidc';
import {UserManagerSettings, WebStorageStateStore} from "oidc-client";

const userManagerConfig: UserManagerSettings = {
  client_id: 'interactive',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/signin-oidc`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/signout-oidc`,
  response_type: 'token id_token',
  scope: 'openid profile UniCatDB.Api email',
  authority: process.env.REACT_APP_IDM_AUTHORITY,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/oidc-dist/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({store: localStorage})
};
const UserManager = createUserManager(userManagerConfig);

export default UserManager;
