import {combineReducers} from 'redux';
import FilterReducer from "./reducers/FilterReducer";
import FindingSchemasReducer from "./reducers/FindingSchemasReducer";
import ViewPreferencesReducer from "./reducers/ViewPreferencesReducer";
import FindingReducer from "./reducers/FindingReducer";
import ListingReducer from "./reducers/ListingReducer";
import {createBrowserHistory} from "history";
import {connectRouter} from 'connected-react-router'
import ErrorReducer from "./reducers/ErrorReducer";
import {ErrorActions} from "./actions/ErrorActions";
import SchemaReducer from "./reducers/SchemaReducer";
import MapPanelReducer from "./reducers/MapPanelReducer";
import GUIReducer from "./reducers/GUIReducer";
import ExportReducer from "./reducers/ExportReducer";
import { reducer as oidcReducer } from 'redux-oidc';
import tenantReducer from "./reducers/TenantReducer";

export const history = createBrowserHistory();

const appReducer = combineReducers({
    filter: FilterReducer,
    finding: FindingReducer,
    schema: SchemaReducer,
    listing: ListingReducer,
    findingSchemas: FindingSchemasReducer,
    viewPreferences: ViewPreferencesReducer,
    mapPanel: MapPanelReducer,
    error: ErrorReducer,
    gui: GUIReducer,
    export: ExportReducer,
    oidc: oidcReducer,
    tenant: tenantReducer,
    router: connectRouter(history)
});

export const rootReducer = (state, action) => {
    // TODO: check if condition is necessary
    if (action.type === ErrorActions.RESET_STORE) {
        state = { router: state.router };
    } else if (action.type === ErrorActions.RESET_STORE_AND_AUTH) {
        state = undefined;
    }
    return appReducer(state, action)
};

export type AppState = ReturnType<typeof rootReducer>