import {LatLngBounds} from "../models/LatLngBounds";
import {AppState} from "../reducers";
import {ApiFindingsGetRequest} from "../../services/GeneratedApiTsClient/apis";
import ApiService from "../../services/ApiService/ApiService";
import {WellKnownAttributes} from "../../models/FindingWellKnownAttributes";
import {FindingMapInfoResourceObject} from "../models/FindingMapInfo";
import {processApiResponse} from "./ErrorActions";
import {getConfiguredFindingsApi} from "../selectors/ApiSelectors";
import {selectActiveTenantId} from "../selectors/TenantSelectors";

export enum MapPanelActions {
    SET_MAP_VIEWPORT = 'SET_MAP_VIEWPORT',
    
    REQUEST_MAP_FINDINGS = "REQUEST_MAP_FINDINGS",
    RECEIVE_MAP_FINDINGS = "RECEIVE_MAP_FINDINGS",
    FLUSH_MAP_FINDINGS = "FLUSH_MAP_FINDINGS",
    
    CANCEL_RECEIVING_MAP_FINDINGS = "CANCEL_RECEIVING_MAP_FINDINGS",
    
}

export const requestMapFindings = (flushBeforeRequest:boolean = false) => async (dispatch, getState: () => AppState) => {
    let state = getState();
    if (state.mapPanel.requestInProgress) return;
    
    if (flushBeforeRequest)
        dispatch({type: MapPanelActions.FLUSH_MAP_FINDINGS});
    
    dispatch({type: MapPanelActions.REQUEST_MAP_FINDINGS});
    
    let findingsApi = getConfiguredFindingsApi(state);
    const tenantId = selectActiveTenantId(state);
    
    let params: ApiFindingsGetRequest = {
        tenantId,
        filter: ApiService.buildFilterQuery([
            ...state.filter.filters,
            ApiService.buildGeoPagingFilter(state.mapPanel.bounds)
        ]),
        page: {number: 1, size: -1},
        fields: {
            findings: [
                WellKnownAttributes.documentName,
                WellKnownAttributes.locationGpsPoint,
                WellKnownAttributes.locationGpsArea
            ].join(",")
        }  
    };
    
    let markerData = await findingsApi.apiFindingsGet(params).then(
        (response) => response.data as Array<FindingMapInfoResourceObject>,
        async (response) => {
            await processApiResponse(response);
            return undefined;
        }
    );
    
    if (markerData === undefined){
        dispatch({type: MapPanelActions.CANCEL_RECEIVING_MAP_FINDINGS});
        return;
    }
    
    dispatch({type: MapPanelActions.RECEIVE_MAP_FINDINGS, markerData});
};

export const setMapViewport = (bounds: LatLngBounds) => (dispatch) => {
    dispatch({type: MapPanelActions.SET_MAP_VIEWPORT, bounds});
    dispatch(requestMapFindings());
};


export default {
    setMapViewport,
    requestMapFindings
}