import * as React from 'react';
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {deleteFinding} from '../../../store/actions/FindingActions';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {AppState} from "../../../store/reducers";
import {downloadLoadedFinding, DownloadTarget} from "../../../store/actions/ExportActions";
import {useState} from "react";
import {ModalConfirm} from "../../../components/ModalConfirm";
import {getActiveTenantRoutePrefix} from "../../../store/selectors/TenantSelectors";
import {goBack} from "connected-react-router";
import {isTenantUserInRole} from "../../../store/selectors/UserSelectors";
import {TenantUserRole} from "../../../services/GeneratedApiTsClient";
import SpinnerInline from "../../../components/Spinners/SpinnerInline";

const mapStateToProps = (state: AppState) => ({
    export: state.export,
    tenantRoutePrefix: getActiveTenantRoutePrefix(state),
    canEdit: isTenantUserInRole(state, {role: [TenantUserRole.OWNER, TenantUserRole.COLLABORATOR]})
});

const mapDispatchToProps = {
    deleteFinding,
    downloadLoadedFinding,
    goBack
};

type ComponentProps = {
    findingId: string;
};

type Props =
    RouteComponentProps<{ id: string }>
    & ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps
    & ComponentProps;

const DetailsActions: React.FC<Props> = props => {

    const [visibleModal, setVisibleModal] = useState(false);

    const toggleVisibleModal = () => setVisibleModal(!visibleModal);


    return (
        <div className="buttons-group-margin">
            <div className="d-flex justify-content-start mt-2 mt-md-0">
                <Button outline color="secondary" onClick={() => {props.goBack();}}>
                    <FontAwesomeIcon icon="chevron-left"/>
                    Back
                </Button>
            </div>
            <div className="d-flex justify-content-around flex-wrap mt-2 mt-md-0">
                <UncontrolledButtonDropdown>
                    <DropdownToggle outline color="secondary" disabled={props.export.inProgress} caret>
                        {props.export.inProgress
                            ? <SpinnerInline/>
                            : <FontAwesomeIcon icon="file-export"/>
                        }
                        Export
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={() => props.downloadLoadedFinding(DownloadTarget.CsvSemicolon)}>
                            Download CSV
                        </DropdownItem>
                        <DropdownItem onClick={() => props.downloadLoadedFinding(DownloadTarget.Clipboard)}>
                            To clipboard
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
                <Button outline color="secondary" tag={Link} disabled={!props.canEdit} to={props.tenantRoutePrefix+'/findings/clone/' + props.findingId}>
                    <FontAwesomeIcon icon="copy"/>
                    Clone
                </Button>
                <Button color="primary" tag={Link} disabled={!props.canEdit} to={props.tenantRoutePrefix+'/findings/edit/' + props.findingId}>
                    <FontAwesomeIcon icon="edit"/>
                    Edit
                </Button>

                <Button
                    disabled={!props.canEdit}
                    color="danger"
                    onClick={() => {
                        toggleVisibleModal();
                    }}>
                    <FontAwesomeIcon icon="trash"/>
                    Delete
                </Button>
            </div>
            <ModalConfirm visible={visibleModal} titleText={"Delete"} bodyText={"Are you sure?"} btnConfirmLabel={"Delete"} btnCancelLabel={"Cancel"}
                          onClickConfirm={() => {props.deleteFinding(props.findingId); toggleVisibleModal()}} onClickCancel={toggleVisibleModal}/>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsActions as unknown as React.FC<ComponentProps>);
