import {Filter} from "../../../models/FilterModel";
import * as React from "react";
import ApiService from "../../../services/ApiService/ApiService";
import debounce from 'lodash/debounce';
import connect from "react-redux/es/connect/connect";
import {AppState} from "../../../store/reducers";
import {getConfiguredFindingsApi} from "../../../store/selectors/ApiSelectors";
import {selectActiveTenantId} from "../../../store/selectors/TenantSelectors";
import SpinnerInline from "../../Spinners/SpinnerInline";

const mapStateToProps = (state: AppState) => ({
    findingsApi: getConfiguredFindingsApi(state),
    tenantId: selectActiveTenantId(state)
});

const mapDispatchToProps = {};


type Props = ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps
    & {
    filters: [Filter],
    inverse?: boolean
}

const FindingsCount = (props: Props) => {

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [count, setCount] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        let isMounted = true;
        
        setLoaded(false);
        setCount(undefined);

        const refreshCount = async () => ApiService.getFindingsCountMatchingFilter(props.tenantId, props.findingsApi, props.filters).then((data) => {
            
            if (isMounted) {
                setCount(data);
                setLoaded(true);
            }
        })
        debounce(refreshCount, 1000)();
        
        return () => {
            isMounted = false;
        }
    }, [props.filters, props.findingsApi, props.tenantId]);
    return (
        <>
            {!loaded ?
                <div className="live-count-wrapper">
                    <SpinnerInline className={props.inverse ? "spinner-inverse" : "spinner"}/>
                </div>
                :
                <div className="live-count-wrapper font-weight-bold">
                    {count}
                </div>
            }
        </>

    )
}

export default connect(mapStateToProps, mapDispatchToProps)(FindingsCount);