import React from "react";
import {AppState} from "../../../store/reducers";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {downloadListing, DownloadTarget} from "../../../store/actions/ExportActions";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import {openCodegenModal} from "../../../store/actions/GUIActions";
import SpinnerInline from "../../../components/Spinners/SpinnerInline";

const mapStateToProps = (state: AppState) => ({
    export: state.export,
});

const mapDispatchToProps = {
    downloadListing,
    openCodegenModal
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const ExportToolbarButton = (props: Props) =>
    <UncontrolledButtonDropdown>
        <DropdownToggle color="" className={'btn-hover-light bg-white'} caret>
            {props.export.inProgress
                ? <SpinnerInline/>
                : <FontAwesomeIcon icon="file-export" className="mr-1 text-primary"/>
            }
            Export
        </DropdownToggle>
        <DropdownMenu>
            <DropdownItem onClick={() => props.downloadListing(DownloadTarget.CsvSemicolon)}>
                <FontAwesomeIcon icon="download" className="mr-1"/>
                Download CSV
            </DropdownItem>
            <DropdownItem onClick={() => props.downloadListing(DownloadTarget.Clipboard)}>
                <FontAwesomeIcon icon="clipboard" className="mr-1"/>
                To clipboard
            </DropdownItem>
            <DropdownItem onClick={() => props.openCodegenModal("LISTING")}>
                <FontAwesomeIcon icon="code" className="mr-1"/>
                Generate API script
            </DropdownItem>
        </DropdownMenu>
    </UncontrolledButtonDropdown>
export default connect(mapStateToProps, mapDispatchToProps)(ExportToolbarButton as any);