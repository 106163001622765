import {
    LOCAL_STORAGE_KEY,
    RESET_VIEW_PREFERENCES,
    SET_COLUMNS,
    SET_COLUMNS_ORDER,
    SET_COLUMNS_WIDTH, VIEW_PREFERENCES_NORMALIZE
} from "../actions/ViewPreferencesActions";
import {FilterableAttribute} from "../../models/FilterableAttributesModel";
import {StaticFilterableAttributesGroup} from "../../models/FilterableAttributesModel";
import ViewColumn from "../models/ViewColumn";
import {WellKnownAttributes} from "../../models/FindingWellKnownAttributes";

export type ViewPreferencesReducerState = {
    listViewColumns: Array<ViewColumn>,
    normalized: boolean
}

const saveStateToLocalStorage = (state: ViewPreferencesReducerState): void => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
};

export const getViewPreferencesReducerInitialState = () : ViewPreferencesReducerState => {

    let staticAttributesGroup = StaticFilterableAttributesGroup[0];
    
    let state: ViewPreferencesReducerState = {
        listViewColumns: staticAttributesGroup.attributes.map((attribute: FilterableAttribute) => (
            new ViewColumn(attribute.value, attribute.name, staticAttributesGroup.name)
        )),
        normalized: false
    };
    
    let savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedState != null){
        
        var savedStateParsed = JSON.parse(savedState);
        if (savedStateParsed.listViewColumns && Array.isArray(savedStateParsed.listViewColumns)){
            
            // force defaults reset if document name column is missing
            if (state.listViewColumns.find(col => col.attribute === WellKnownAttributes.documentName)){
                state.listViewColumns = savedStateParsed.listViewColumns
            }
            
            // TODO: consistency check
        }
    }
    
    return state;
};

export default (state: ViewPreferencesReducerState = getViewPreferencesReducerInitialState(), action) => {

    let updatedListViewColumns = state.listViewColumns.slice();
    let updatedState;
    
    switch (action.type) {
        case SET_COLUMNS_ORDER:

            
            updatedListViewColumns.sort(function(a: ViewColumn, b:ViewColumn){                           // inspired by https://stackoverflow.com/a/44063445
                return action.newOrder.indexOf(a.attribute) - action.newOrder.indexOf(b.attribute);
            });
            
            updatedState = {...state, listViewColumns: updatedListViewColumns};
            
            saveStateToLocalStorage(updatedState);
            return updatedState;
        case SET_COLUMNS_WIDTH:
            
            // convert array of width inputs to an object keyed by column name
            let newWidthsObject = action.newWidths.reduce(function(result, item) {
                result[item.columnName] = item.width;
                return result;
            }, {});

            updatedListViewColumns.map((column) => {
                if (newWidthsObject[column.attribute]) {
                    column.width = newWidthsObject[column.attribute];
                }
                return column;
            });

            updatedState = {...state, listViewColumns: updatedListViewColumns};

            saveStateToLocalStorage(updatedState);
            return updatedState;
        case SET_COLUMNS:

            updatedState = {...state, listViewColumns: action.newColumns};

            saveStateToLocalStorage(updatedState);
            return updatedState;

        case VIEW_PREFERENCES_NORMALIZE:

            updatedState = {
                ...state,
                listViewColumns: action.newColumns,
                normalized: true
            };

            saveStateToLocalStorage(updatedState);
            return updatedState;
            
        case RESET_VIEW_PREFERENCES:
            
            localStorage.removeItem(LOCAL_STORAGE_KEY);
            updatedState = getViewPreferencesReducerInitialState();
            updatedState.normalized = true;

            saveStateToLocalStorage(updatedState);
            return updatedState;
        default:
    }
    
    return state;
};


