import * as React from 'react';
import {connect} from 'react-redux';
import {Filter} from '../../models/FilterModel';

import FilterInputGroup from './components/FilterInputGroup';
import {
    AttributeType,
    FilterableAttributesGroup,
    StaticFilterableAttributesGroup
} from '../../models/FilterableAttributesModel';
import {WellKnownAttributes} from '../../models/FindingWellKnownAttributes';
import {FILTER_OP, SupportedFilterOperationsFor} from '../../models/FilterOperationModel';
import FindingsCount from './components/FindingsCount';
import {Button, Form, Modal, ModalBody, ModalFooter, ModalHeader,} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AppState} from '../../store/reducers';
import {requestFindingSchemas} from '../../store/actions/FindingSchemasActions';
import {clearFilters, setFilters} from '../../store/actions/FilterActions';
import {ThemedMultiSelect} from "../ThemedMultiSelect";
import SpinnerRibbon from "../Spinners/SpinnerRibbon";

const mapStateToProps = (state: AppState) => ({
    filter: state.filter,
    findingSchemas: state.findingSchemas,
});

const mapDispatchToProps = {
    requestFindingSchemas,
    clearFilters,
    setFilters,
};

type ComponentProps = {
    show: boolean;
    onClose: () => void;
}

type Props = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & ComponentProps;

type FilterState = {
    schemaFilterValue: Array<{ value: string, label: string }>;
    attributeFilters: Array<Filter>;
    processedFilters: Array<Filter>;
};

type State = FilterState & {
    loaded: boolean;
    humanReadableFilters: { attribute: string, operation: string, value: string }[]
};

type FilterableSchemas = {
    [key: string]: string;
};
const defaultSchemaValue = {
    value: "",
    label: "ANY"
};

class FilterEditorModal extends React.Component<Props, State> {
    // helper variable to store attibutes beeing offerd by the filter UI
    filterableAttributes: Array<FilterableAttributesGroup>;

    private filterableSchemaIds: FilterableSchemas = {};

    constructor(props: Props, context) {
        super(props, context);
        let filterState = this.getFilterStateFromStore(this.props.filter.filters);
        this.state = {
            loaded: false,
            schemaFilterValue: filterState.schemaFilterValue ?? [],
            attributeFilters: filterState.attributeFilters,
            processedFilters: this.props.filter.filters,
            humanReadableFilters: [],
        };
        this.filterableAttributes = StaticFilterableAttributesGroup;
    }

    async componentDidUpdate(prevProps) {
        if (this.props === prevProps) return;
        await this.refreshFilterableAttributes();
    }

    async refreshFilterableAttributes() {
        if (!this.props.findingSchemas.loaded) {
            this.setState({
                loaded: false,
            });
            return;
        }

        this.filterableAttributes = StaticFilterableAttributesGroup;

        // get human-readable attributes
        let dynamicAttributes = this.props.findingSchemas.schemaAttributes;
        this.filterableAttributes = this.filterableAttributes.concat(dynamicAttributes);

        this.filterableSchemaIds = {};
        let schemasResponse = this.props.findingSchemas.schemasBasicInfo;
        schemasResponse.forEach(schema => {
            // @ts-ignore
            this.filterableSchemaIds[schema.id] = schema.attributes.name;
        });

        this.setState({
            loaded: true,
        });
    }

    handleOnOpened = () => {
        this.props.requestFindingSchemas();
        let filterState = this.getFilterStateFromStore(this.props.filter.filters);

        this.setState(
            {
                attributeFilters: filterState.attributeFilters,
                schemaFilterValue: filterState.schemaFilterValue,
                processedFilters: this.props.filter.filters,
            },
            this.refreshFilterableAttributes,
        );
    };

    handleOnClosed = () => {
        this.setState(
            {
                loaded: false,
            },
            () => {
                this.filterableAttributes = StaticFilterableAttributesGroup;
            },
        );
    };

    handleSaveAndClose = (e) => {
        e.preventDefault();
        this.saveToStore();
        this.props.onClose();
    };

    handleClearAndSave = () => {
        this.clearFilters(() => {
            this.saveToStore();
        });
    };

    handleClear = () => {
        this.clearFilters();
    };

    setHumanReadableFilters = () => {

    }
    selectChanged = (filterIndex: number, name: string, input: { label: string, value: FILTER_OP, type: AttributeType }) => {
        const attributeName = name;
        const attributeValue = input.value;
        const attrType = name === 'attribute' ? input.type : undefined;
        this.setState((prevState: State) => {
            // create a copy
            let oldFilter = prevState.attributeFilters[filterIndex];
            let newFilter: Filter = new Filter(oldFilter.attribute, oldFilter.operation, oldFilter.value, oldFilter.type);
            console.log({oldFilter, newFilter})
            switch (attributeName) {
                case 'operation':
                    newFilter.operation = attributeValue;
                    break;
                case 'attribute':
                    newFilter.attribute = attributeValue;
                    newFilter.type = attrType!;

                    // change operation, if the currently selected one is not available for selected type
                    if (attrType && !SupportedFilterOperationsFor[attrType].hasOwnProperty(newFilter.operation)) {
                        newFilter.operation = Object.keys(SupportedFilterOperationsFor[attrType])[0] as FILTER_OP;
                    }

                    break;
                case 'value':
                    newFilter.value = attributeValue;
                    break;
                default:
                    console.error('Unsupported filter property');
            }

            prevState.attributeFilters[filterIndex] = newFilter;

            return {
                attributeFilters: prevState.attributeFilters,
                processedFilters: this.processFilters(prevState.attributeFilters, this.state.schemaFilterValue),
            };
        });
    }

    inputChanged = (filterIndex, event) => {
        let attributeValue = event.target.value;
        this.setState((prevState: State) => {
            let oldFilter = prevState.attributeFilters[filterIndex];
            let newFilter: Filter = new Filter(oldFilter.attribute, oldFilter.operation, oldFilter.value, oldFilter.type);
            newFilter.value = attributeValue
            prevState.attributeFilters[filterIndex] = newFilter;
            return {
                attributeFilters: prevState.attributeFilters,
                processedFilters: this.processFilters(prevState.attributeFilters, this.state.schemaFilterValue),
            };
        });
    };

    schemaFilterInputChanged = (values) => {


        if (values.length > 1 && values.some((v) => {
            return v.label === "ANY"
        })) {
            values.splice(values.findIndex((v) =>
                v.label === "ANY"
            ), 1);
        } else if (values.length === 1 && values.some(v => v.label === "ANY")) {
            values = []
        }

        this.setState({
            schemaFilterValue: values,
            processedFilters: this.processFilters(this.state.attributeFilters, values),
        });
    };

    clearSchemaFilter = () => {
        this.setState({
            schemaFilterValue: [],
            processedFilters: this.processFilters(this.state.attributeFilters, []),
        });
    };

    addFilter = () => {
        this.setState(prevState => {
            let newAttributeFilters = [...prevState.attributeFilters, new Filter()];
            return {
                attributeFilters: newAttributeFilters,
                processedFilters: this.processFilters(newAttributeFilters, this.state.schemaFilterValue),
            };
        });
    };

    removeFilter = index => {
        this.setState((prevState: State) => {
            prevState.attributeFilters.splice(index, 1);
            return {
                attributeFilters: prevState.attributeFilters,
                processedFilters: this.processFilters(prevState.attributeFilters, this.state.schemaFilterValue),
            };
        });
    };
    removeSchemaFilter = value => {
        this.setState((prevState: State) => {
            prevState.schemaFilterValue.splice(prevState.schemaFilterValue.findIndex((item) => {
                return item.value === value
            }), 1);
            return {
                attributeFilters: prevState.attributeFilters,
                schemaFilterValue: prevState.schemaFilterValue,
                processedFilters: this.processFilters(prevState.attributeFilters, prevState.schemaFilterValue),
            };
        });
    }

    clearFilters = (callback = () => {
    }) => {
        this.setState(
            {
                attributeFilters: [],
                schemaFilterValue: [],
                processedFilters: [],
            },
            callback,
        );
    };

    getFilterStateFromStore = (filters: Array<Filter>): FilterState => {
        let attributeFilters = filters.slice();
        let schemaFilterValue: Array<{ value: string, label: string }> = [];

        const labels = this.getSchemaOptions()
        const getLabel = (value: string): string => {
            const label = labels.find((item) => item.value === value)
            return label ? label.label : "";
        }

        for (let i = attributeFilters.length - 1; i >= 0; i--) {

            if (attributeFilters[i].attribute === WellKnownAttributes.schemaId) {
                attributeFilters[i].value.split(",").forEach((item) => {
                    schemaFilterValue.push({value: item, label: getLabel(item)})
                });
                attributeFilters.splice(i, 1);
            }
        }

        return {
            attributeFilters: attributeFilters,
            schemaFilterValue: schemaFilterValue,
            processedFilters: filters,
        };
    };

    processFilters = (attributeFilters: Array<Filter>, schemaFilterValue: Array<{ value: string, label: string }>): Array<Filter> => {
        // make a copy, remove incomplete attributeFilters in the process
        let processedFilters = attributeFilters.filter((filterUnderTest: Filter) => {
            return filterUnderTest.value !== '' && filterUnderTest.attribute !== '';
        });
        if (schemaFilterValue) {
            let filterValues: Array<string> = schemaFilterValue.map((item) => {
                return item.value;
            })
            filterValues.length > 0 && processedFilters.push(new Filter(WellKnownAttributes.schemaId,
                filterValues.length > 1 ? FILTER_OP.isIn : FILTER_OP.eq, filterValues.join(",")));
        }

        return processedFilters;
    };

    saveToStore = () => {
        this.props.setFilters(this.state.processedFilters);
    };

    getSchemaOptions = () => [defaultSchemaValue, ...Object.keys(this.filterableSchemaIds).map((key) => ({
        value: key,
        label: this.filterableSchemaIds[key]
    }))]

    render() {
        return (
            <Modal
                isOpen={this.props.show}
                toggle={this.props.onClose}
                onOpened={this.handleOnOpened}
                onClosed={this.handleOnClosed}
                className="spinner-overlay-container"
                size={'lg'}>
                <ModalHeader toggle={this.props.onClose}>
                    <FontAwesomeIcon icon="filter" className="mr-2"/> Filter findings
                </ModalHeader>
                <ModalBody>
                    {!this.state.loaded ? (
                        <SpinnerRibbon/>
                    ) : (
                        <Form onSubmit={this.handleSaveAndClose}>
                            <p className="input-label">Document Type</p>
                            <ThemedMultiSelect name="attribute"
                                               value={this.state.schemaFilterValue.length === 0 ? defaultSchemaValue : this.state.schemaFilterValue}
                                               onChange={this.schemaFilterInputChanged}
                                               isSearchable={false}
                                               options={this.getSchemaOptions()}
                            />
                            <hr/>

                            <div className="modal-column">
                                <span>Column Name</span>
                                <span>Filter</span>
                                <span>Value</span>
                            </div>

                            {this.state.attributeFilters.map((filter, index) => {
                                return (
                                    <FilterInputGroup
                                        filter={filter}
                                        index={index}
                                        key={index}
                                        handleRemove={this.removeFilter}
                                        handleSelectChange={this.selectChanged}
                                        handleInputChange={this.inputChanged}
                                        onEnterKeyPressed={this.handleSaveAndClose}
                                        filterableAttributes={this.filterableAttributes}
                                    />
                                );
                            })}

                            <div className="d-flex justify-content-between">
                                <Button color="primary" onClick={this.addFilter}>
                                    <FontAwesomeIcon icon="plus"/> Add Filter
                                </Button>
                                <Button
                                    color="link"
                                    className="btn-hover-light-link dark"
                                    onClick={this.handleClear}
                                    disabled={!this.state.schemaFilterValue && this.state.attributeFilters.length === 0}>
                                    <FontAwesomeIcon icon="eraser" className="text-danger"/>
                                    Clear Filters
                                </Button>
                            </div>

                        </Form>
                    )}
                </ModalBody>
                <ModalFooter className="d-flex justify-content-end align-items-end">
                    <Button outline color="secondary" onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    <div className="d-flex flex-column">
                        <span><FindingsCount filters={this.state.processedFilters}/> <span
                            className="font-weight-bold">items</span> match
                        </span>
                        <Button color="primary" type="submit" onClick={this.handleSaveAndClose}
                                disabled={!this.state.loaded}>
                            Apply filters
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterEditorModal as unknown as React.ComponentClass<ComponentProps>);
