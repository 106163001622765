import * as React from 'react';
import {Button} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import copy from 'copy-to-clipboard';
import {ButtonProps} from "reactstrap/lib/Button";

type Props = Omit<ButtonProps, "onClick"> & {
    textToCopy: string;
    copyMessage?: string;
};

export const CopyToClipboard: any = ({textToCopy, copyMessage, ...buttonProps}: Props) => {
    const defaultButtonText = 'Copy to clipboard';

    const [buttonText, setButtonText] = React.useState(copyMessage ?? defaultButtonText);

    function copyToClipboard() {

        let success = copy(textToCopy);
        if (success) {
            setButtonText('Copied!');
            setTimeout(() => {
                setButtonText(copyMessage ?? defaultButtonText)
            }, 1000);
        } else {
            setButtonText('Not supported');
        }
    }

    return (
        <Button outline color="secondary" size={'sm'} onClick={copyToClipboard} {...buttonProps}
                disabled={!document.queryCommandSupported('copy') || !textToCopy || (textToCopy.length < 1)}>
            <FontAwesomeIcon icon="clipboard"/>
            {buttonText}
        </Button>
    );
};
