import React from "react";
import {connect} from "react-redux";
import {CallbackComponent} from "redux-oidc";
import UserManager from "../services/UserManager";
import {RouteComponentProps} from "react-router";
import {AppState} from "../store/reducers";
import {ErrorType, setError} from "../store/actions/ErrorActions";
import {push} from "connected-react-router";
import {SplashScreen} from "../scenes/Splash/SplashScreen";


const mapStateToProps = (state: AppState) => ({
    
});

const mapDispatchToProps = {
    setError,
    push
};


type ComponentProps = {
}

type Props = RouteComponentProps<{ id: string; schema: any }> &
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps & ComponentProps;


class CallbackPage extends React.Component<Props> {
  render() {
    return (
      <CallbackComponent
        userManager={UserManager}
        successCallback={user => this.props.push(user.state.path ?? "/")}
        errorCallback={error => {
            this.props.setError("Authentication error.", ErrorType.FATAL_ERROR, error.name +'> '+error.message)
            this.props.push("/");
            // TODO: test error handling
            console.error(error);
        }}
        >
          <SplashScreen />
      </CallbackComponent>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage as unknown as React.ComponentClass<ComponentProps>);
