import * as React from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../store/reducers';
import {DynamicView} from './DynamicView';
import {FindingsTable} from './FindingsTable';
import MapPanel from './components/MapPanel';
import GraphPanel from './components/GraphPanel';

const mapStateToProps = (state: AppState) => ({
  gui: state.gui,
});

const mapDispatchToProps = {};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};

class Findings extends React.Component<Props> {
  renderDynamicContent = () => {
    if (this.props.gui.mapPanelActive) {
      return <MapPanel />;
    }
    if (this.props.gui.graphPanelActive) {
      return <GraphPanel />;
    }
  };

  render() {
    return (
      <DynamicView
        animationDuration={0}
        toggleHandler={this.props.gui.splitMode}
        leftContent={this.renderDynamicContent()}
        rightContent={<FindingsTable />}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Findings as any);
