import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

type Props = {
    visible: boolean;
    titleText: string;
    bodyText: string;
    btnConfirmLabel: string;
    btnCancelLabel: string;
    onClickConfirm: () => void;
    onClickCancel: () => void;
}

export const ModalConfirm = (props: Props) => {

    return (
        <Modal isOpen={props.visible}>
            <ModalHeader>{props.titleText}</ModalHeader>
            <ModalBody>{props.bodyText}</ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={props.onClickConfirm}>{props.btnConfirmLabel}</Button>{' '}
                <Button color="secondary" onClick={props.onClickCancel}>{props.btnCancelLabel}</Button>
            </ModalFooter>
        </Modal>
    );
}