import * as React from "react";
import {useEffect, useState} from "react";
import {AppState} from "../../../store/reducers";
import connect from "react-redux/es/connect/connect";
import {getConfiguredTenantsApi} from "../../../store/selectors/ApiSelectors";
import {TenantUser} from "../../../services/GeneratedApiTsClient";
import {TenantUserPersona} from "../../../components/TenantUserPersona";
import SpinnerInline from "../../../components/Spinners/SpinnerInline";

const mapStateToProps = (state: AppState) => ({
    tenantsApi: getConfiguredTenantsApi(state),
});

const mapDispatchToProps = {};

type Props = ReturnType<typeof mapStateToProps>
    & typeof mapDispatchToProps
    & {
    tenantId: string
}

const TenantUsersOverview = (props: Props) => {

    const [loadedUsers, setLoadedUsers] = useState<TenantUser[]>([]);

    useEffect(() => {
        let isMounted = true;
        setLoadedUsers([]);

        props.tenantsApi.apiTenantsGetByIdUsers({tenantId: props.tenantId})
            .then((result) => {
                if (isMounted) {
                    setLoadedUsers(result);
                }
            });
        return () => {
            isMounted = false;
        }
    }, [props.tenantId, props.tenantsApi]);

    return (
        <div>
            {loadedUsers.length === 0 && <SpinnerInline/>}
            {loadedUsers.map(u => <TenantUserPersona user={u} size={'30'} className={'mr-2'} key={u.id}/>)}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TenantUsersOverview);